import React from "react";
import { useDialog, useDialogReturnType } from "../../Dialog/useDialog";
import { OK_CANCEL } from "../../Dialog/DialogActionButtonsType";
import { RED_BUTTON_OVERRIDES } from "../../Dialog/RedButtonOverrides";
import { SiteType } from "../../../types/SiteType";
import { DialogText } from "../../Dialog/DialogText";
import { DialogContentInnerWrapper } from "../../Dialog/DialogContentInnerWrapper";
import { SiteLabel } from "../SiteLabel";
import styled from "styled-components";

const LabelWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export function useRemoveSiteFromTrialDialog(
  site: SiteType,
  onSiteRemoved: (site: SiteType) => void
): useDialogReturnType {
  const label = "Remove Site From Trial";
  const { name } = site;

  const content = (
    <DialogContentInnerWrapper>
      <LabelWrapper>
        <SiteLabel name={name} />
      </LabelWrapper>
      <DialogText>
        This will remove the site from the trial. All data from this site will
        change to “Site: Unknown”
      </DialogText>
    </DialogContentInnerWrapper>
  );

  const handleRemoveSite = () => {
    onSiteRemoved(site);
  };

  return useDialog({
    label,
    content,
    options: {
      showCloseButton: false,
      actionButtonOptions: {
        type: OK_CANCEL,
        okText: "Remove",
        onOkOverride: handleRemoveSite,
        okButtonOverrides: RED_BUTTON_OVERRIDES,
      },
    },
  });
}
