import { useParams } from "react-router";

interface RouteProps {
  subjectId: string;
}

export function captureSubjectIdParam(): number {
  const { subjectId } = useParams<RouteProps>();
  const subjectIdNumber = parseInt(subjectId) || null;

  if (!subjectIdNumber) {
    throw new Error("No patient found in route.");
  }

  return subjectIdNumber;
}
