import { RawPatientCases } from "../../rawTypes/RawPatientCases";

export function parsePatientCaseIds(
  rawPatientCases: RawPatientCases[]
): number[] {
  const allCaseIds = rawPatientCases.flatMap((study) => {
    const { series } = study;
    return series.flatMap((s) => {
      const { series_cases } = s;
      return series_cases.flatMap((seriesCase) => {
        const { case_id } = seriesCase;
        return case_id;
      });
    });
  });

  return [...new Set(allCaseIds)];
}
