import { useSelector } from "react-redux";
import { RootState } from "../../common/store/rootReducer";

export function useTrialId(): number {
  const trialId = useSelector<RootState, number | null>(
    (state) => state.trialId
  );
  if (trialId == null) {
    throw new Error("trialId is null on SideNav");
  }
  return trialId;
}
