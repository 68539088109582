import React from "react";
import { FC } from "react";
import FilterCategory from "../FilterCategory";
import { BmiFilters } from "./BmiFilters";
import { SmokingStatusFilters } from "./SmokingStatusFilters";

export const LifestyleFilters: FC = () => {
  const options = (
    <>
      <BmiFilters />
      <SmokingStatusFilters />
    </>
  );

  return <FilterCategory name="Lifestyle" options={options} />;
};
