import { EnrolmentDataProps } from "../components/EnrolmentChart";
import { formatFollowUp } from "../../../Analysis/common/utils/formatFollowUp";
import { SubjectType } from "../../../Analysis/common/types/SubjectType";
import { PROGRESSIVE_DISEASE } from "../../../Analysis/common/types/TumourResponseType";

export function parseEnrollmentData(
  subjects: SubjectType[]
): EnrolmentDataProps[] {
  const dataCount: EnrolmentDataProps[] = [];
  let total = 0;
  const totalSubtracts: number[] = [];

  for (const subject of subjects) {
    const { followUps } = subject;
    total += 1;

    for (const followUp of followUps) {
      const { order: fuOrder, tumourResponses } = followUp;

      while (dataCount.length <= fuOrder) {
        dataCount.push({
          name: " ",
          total: 0,
          success: 0,
          percentage: 0,
        });
      }

      dataCount[fuOrder].success += 1;
      if (tumourResponses.length) {
        const classificationIsPD = tumourResponses[0] === PROGRESSIVE_DISEASE;
        if (classificationIsPD) {
          dataCount[fuOrder].total -= 1;
        }
      }
    }
  }

  for (let outerIndex = dataCount.length - 1; outerIndex >= 0; outerIndex--) {
    let totalSubtract = 0;
    for (let innerIndex = 0; innerIndex < outerIndex; innerIndex++) {
      totalSubtract += dataCount[innerIndex].total;
    }
    totalSubtracts.unshift(totalSubtract);
  }

  dataCount.forEach((datum, index) => {
    datum.name = formatFollowUp(index);
    datum.total = total + totalSubtracts[index];
    datum.percentage = (datum.success * 100) / datum.total;
  });

  return dataCount;
}
