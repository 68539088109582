import { gql } from "@apollo/client/core";
import { UserType } from "../common/types/UserType";
import {
  parseUserFragment,
  USER_FRAGMENT,
  UserFragmentType,
} from "./UserFragment";
import { useParsedQueryReturnType } from "../common/queries/utils/useParsedQueryReturnType";
import { useParsedQuery } from "../common/queries/utils/useParsedQuery";

const QUERY = gql`
  query GetTenantUser($tenant_id: Int!) {
    users(where: { tenant_id: { _eq: $tenant_id } }) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

type Variables = {
  tenant_id: number;
};

type Data = {
  users: UserFragmentType[];
};

export function useTenantUsers(
  tenantId: number
): useParsedQueryReturnType<number, UserType[], Variables> {
  return useParsedQuery<number, UserType[], Data, Variables>({
    query: QUERY,
    input: tenantId,
    parseVariables,
    parseData,
  });
}

function parseVariables(tenantId: number): Variables {
  return { tenant_id: tenantId };
}

function parseData(data: Data): UserType[] {
  return data.users.map(parseUserFragment);
}
