import React, { ReactElement } from "react";
import ItemText from "../../common/components/text/ItemTextSize16";
import ItemSubtext from "../../common/components/text/ItemSubtext";
import ItemSubtext2 from "../../common/components/text/ItemSubtext2";
import TrialPreview from "./TrialPreview";
import TrialStatus from "./TrialStatus";
import { getMonthDateTime } from "../../common/utils/dateFormatUtils/getMonthDateTime";
import { ClickableTrialWrapper } from "./ClickableTrialWrapper";
import styled from "styled-components";
import { TrialType } from "../../common/types/TrialType";

const StyledTrialPreview = styled.div`
  width: 260px;
  height: 161px;
  background: ${(props) => props.theme.colors.background.secondary};
`;

const RecentTrialWrapper = styled.div`
  position: relative;
  padding: 0 10px 0 10px;
  :first-child {
    padding-left: 0px;
  }
  :last-child {
    padding-right: 0px;
  }
`;

const StyledTrialHeader = styled.div`
  margin-bottom: 8px;
  height: 34px;
`;

const TrialTextWrapper = styled.div`
  margin: 16px;
`;

const TitleWrapper = styled.div`
  word-wrap: normal;
  width: 150px;
`;

const TextSection = styled.div`
  margin-top: 16px;
`;

const TrialStatusWrapper = styled.div`
  position: absolute;
  margin: 10px 0 0 170px;
`;

const RecentTrial = ({
  id,
  name,
  status,
  estimatedCompletion,
  indicationName,
  investigationName,
}: TrialType): ReactElement => {
  return (
    <RecentTrialWrapper>
      <ClickableTrialWrapper trialId={id}>
        <StyledTrialPreview>
          <TrialStatusWrapper>
            <TrialStatus status={status} />
          </TrialStatusWrapper>
          <TrialPreview trialId={id} status={status} />
        </StyledTrialPreview>
        <TrialTextWrapper>
          <StyledTrialHeader>
            <TitleWrapper>
              <ItemText text={name} />
            </TitleWrapper>
          </StyledTrialHeader>
          <TextSection>
            <ItemSubtext2 text={"Indication Name"} />
            <ItemSubtext text={indicationName} />
          </TextSection>
          <TextSection>
            <ItemSubtext2 text={"Investigaional Product Name"} />
            <ItemSubtext text={investigationName} />
          </TextSection>
          <TextSection>
            <ItemSubtext2 text={"Estimated Completion"} />
            <ItemSubtext text={getMonthDateTime(estimatedCompletion)} />
          </TextSection>
        </TrialTextWrapper>
      </ClickableTrialWrapper>
    </RecentTrialWrapper>
  );
};

export default RecentTrial;
