import React, { ReactElement } from "react";
import IconButton from "../../../common/components/buttons/IconButton";
import { EllipsisIcon } from "../icons/EllipsisIcon";
import { Menu as MenuMui, MenuItem } from "@material-ui/core";
import styled from "styled-components";

export const StyledLink = styled.div`
  font-family: "Inter", verdana, arial, sans-serif;
  color: ${(props) => props.theme.colors.actionPrimary.active};
  padding-left: 10px;
`;

export const IconWrapper = styled.div`
  top: 3.5px;
  color: ${(props) => props.theme.colors.primary.primary50};
  position: relative;
`;

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
`;

interface MenuProps {
  children?: React.ReactChild | React.ReactChild[];
  id: string;
  anchorEl: null | HTMLElement;
  handleClick: React.MouseEventHandler;
  handleClose: React.MouseEventHandler;
}

const Menu = ({
  children,
  id,
  anchorEl,
  handleClick,
  handleClose,
}: MenuProps): ReactElement => {
  return (
    <div>
      <IconButton onClick={handleClick} icon={<EllipsisIcon />} />
      <MenuMui
        id="trial-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {children}
      </MenuMui>
    </div>
  );
};

export default Menu;
