import React, { FC } from "react";
import handleApolloError from "../../../common/utils/handleApolloError";
import { generateKaplanMeierData } from "../utils/generateKaplanMeierData";
import { kaplanMeierAttributes } from "./kaplanMeierAttributes";
import { TinyKaplanChart } from "./TinyKaplanChart";
import { useSubjectQuery } from "../../common/hooks/useSubjectQuery";
import { FlexAltLoading } from "../../../common/components/AltLoading";
import { main } from "../../../common/theme/main";

interface TinyKaplanChartContainerProps {
  trialId: number;
}

export const TinyKaplanChartContainer: FC<TinyKaplanChartContainerProps> = ({
  trialId,
}: TinyKaplanChartContainerProps) => {
  const { subjects, loading, error } = useSubjectQuery(trialId);

  if (loading)
    return (
      <FlexAltLoading
        size={36}
        thickness={4}
        color={main.colors.actionPrimary.hover}
      />
    );
  if (error) handleApolloError(error);
  if (!subjects) throw new Error("Subject null with no apollo error");

  const kaplanMeierData = generateKaplanMeierData(subjects);

  return (
    <TinyKaplanChart
      data={kaplanMeierData}
      attributes={kaplanMeierAttributes}
    />
  );
};
