import React from "react";
import { UserType } from "../../types/UserType";
import styled from "styled-components";
import { formatUserFullName } from "./utils/formatUserFullName";
import { ReactComponent as EditIcon } from "../../../assets/svgs/Edit.svg";
import { formatUserTitleAndOrganization } from "./utils/formatUserTitleAndOrganization";
import { SvgIcon } from "../icons/SvgIcon";
import { useEditUserDialog } from "./Dialogs/useEditUserDialog";
import { UserActionCallbacksType } from "./UserActionCallbacksType";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const NameText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const EditButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  cursor: pointer;
`;

const SubText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
`;

interface UserDetailsProps {
  user: UserType;
  actionCallbacks?: UserActionCallbacksType;
}

export function UserDetails({
  user,
  actionCallbacks,
}: UserDetailsProps): JSX.Element {
  const [setShowEditUserDialog, { dialog: editUserDialog }] = useEditUserDialog(
    user,
    actionCallbacks
  );

  const { email } = user;
  const fullName = formatUserFullName(user);
  const titleAndOrganization = formatUserTitleAndOrganization(user);

  const displayName = fullName ?? email;

  const handleClickEdit = () => {
    if (!actionCallbacks) {
      throw new Error("actionCallbacks not defined in handleOnClickEdit");
    }

    setShowEditUserDialog(true);
  };

  return (
    <>
      {editUserDialog}
      <Wrapper>
        <NameWrapper>
          <NameText>{displayName}</NameText>
          {actionCallbacks && (
            <EditButtonWrapper onClick={handleClickEdit}>
              <SvgIcon icon={EditIcon} size={16} />
            </EditButtonWrapper>
          )}
        </NameWrapper>
        {titleAndOrganization && <SubText>{titleAndOrganization}</SubText>}
        {fullName && <SubText>{email}</SubText>}
      </Wrapper>
    </>
  );
}
