import { createSelector } from "@reduxjs/toolkit";
import {
  trialViewFiltersActions,
  getTrialViewFilters,
} from "nota-predict-web/src/common/store/trialViewFiltersSlice";
import React, { ReactElement } from "react";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterCategory from "./FilterCategory";
import FilterCheckbox from "./FilterCheckbox";
import {
  DISPLAY_NAMES,
  TumourResponseType,
} from "../../common/types/TumourResponseType";
import { TUMOR_SPELLING } from "../../../common/utils/spellingConstants";

const getRecist1_1Filters = createSelector(
  [getTrialViewFilters],
  (trialViewFilters) => trialViewFilters.recist1_1
);

export const Recist1_1Filters: FC = () => {
  const dispatch = useDispatch();
  const responseFilters = useSelector(getRecist1_1Filters);

  const components: ReactElement[] = [];
  for (const key in responseFilters) {
    if (!Object.prototype.hasOwnProperty.call(responseFilters, key)) {
      continue;
    }

    const response = key as TumourResponseType;
    const isChecked = responseFilters[response] ?? false;

    const onFilterChanged = (checked: boolean) => {
      dispatch(
        trialViewFiltersActions.setRecist1_1({
          ...responseFilters,
          [response]: checked,
        })
      );
    };

    components.push(
      <FilterCheckbox
        key={response}
        label={DISPLAY_NAMES[response]}
        checked={isChecked}
        onChange={onFilterChanged}
        name={response.toString()}
      />
    );
  }

  return (
    <FilterCategory name={`${TUMOR_SPELLING} Burden`} options={components} />
  );
};
