import React from "react";
import styled from "styled-components";

interface ProgressBarProps {
  max: number;
  value: number;
  color: string;
  width?: number;
  height?: number;
}

interface StyledProgressBarProps {
  value: string | number;
  color: string;
}

const StyledProgressBar = styled.progress<
  StyledProgressBarProps & ProgressBarProps
>`
  width: ${(props) => (props.width ? `${props.width}px` : "100px")};
  border-radius: 20px;

  ::-webkit-progress-bar {
    height: ${(props) => (props.height ? `${props.height}px` : "12px")};
    border-radius: 12px;
    background-color: #f0f0f0;
  }

  ::-webkit-progress-value {
    height: ${(props) => (props.height ? `${props.height}px` : "12px")};
    border-radius: ${(props) =>
      props.value === 100 ? "12px" : "12px 0px 0px 12px"};
    background-color: ${(props) => props.color};
  }
`;

function ProgressBar({
  max,
  value,
  color,
  width,
  height,
}: ProgressBarProps): React.ReactElement<
  React.ProgressHTMLAttributes<HTMLProgressElement>
> {
  return (
    <StyledProgressBar
      max={max}
      value={value}
      color={color}
      width={width}
      height={height}
    />
  );
}

export default ProgressBar;
