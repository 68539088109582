import {
  Recist1_1Filters,
  TimepointsFilters,
} from "../../../../common/types/TrialViewFilterTypes";
import { FilterTypes } from "../../../../common/store/trialViewFiltersSlice";
import { FollowUpType } from "../../types/FollowUpType";

function recist1_1IsEnabled(
  followUp: FollowUpType,
  responseFilters: Recist1_1Filters
): boolean {
  // Return true if any of the tumour responses are enabled by a RECIST 1.1 filter
  if (followUp.order === 0) {
    return true;
  }
  const firstEnabledResponse = followUp.tumourResponses.find(
    (response) => responseFilters[response] === true
  );
  return firstEnabledResponse !== undefined;
}

function timepointIsEnabled(
  followUp: FollowUpType,
  timepointsFilters: TimepointsFilters
): boolean {
  return timepointsFilters[followUp.order];
}

// Given some filters, create a "filter function" which returns true if the passed follow-up passes the filters, and false otherwise
export function applyTimepointLevelFilters(filters: FilterTypes) {
  return (followUp: FollowUpType): boolean => {
    const isInEnabledTimepoint = timepointIsEnabled(
      followUp,
      filters.timepoints
    );
    const isInEnabledRecist1_1 = recist1_1IsEnabled(
      followUp,
      filters.recist1_1
    );
    return isInEnabledTimepoint && isInEnabledRecist1_1;
  };
}
