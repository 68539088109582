import { createSelector } from "@reduxjs/toolkit";
import React from "react";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterButton from "../FilterButton";
import FilterSubtext from "../FilterSubtext";
import { ButtonWrapper } from "../FilterInputWrapper";
import { EthnicityType } from "../../../common/types/EthnicityType";
import {
  getDemographicsFilters,
  trialViewFiltersActions,
} from "../../../../common/store/trialViewFiltersSlice";
import { capitalize } from "../../../../common/utils/capitalize";

const getEthnicityFilters = createSelector(
  [getDemographicsFilters],
  (demographicsFilters) => demographicsFilters.ethnicity
);

export const EthnicityFilters: FC = () => {
  const dispatch = useDispatch();
  const ethnicityFilters = useSelector(getEthnicityFilters);

  const ethnicityOptions = Object.keys(ethnicityFilters).map(
    (filter, index) => {
      const ethnicity = filter as EthnicityType;

      const updateEthnicityFilters = () => {
        dispatch(trialViewFiltersActions.toggleEthnicity(ethnicity));
      };

      const isChecked = ethnicityFilters[ethnicity];

      return (
        <FilterButton
          key={index}
          text={capitalize(filter)}
          onClick={updateEthnicityFilters}
          active={isChecked}
          width={90}
        />
      );
    }
  );

  const options = (
    <>
      <ButtonWrapper>{ethnicityOptions}</ButtonWrapper>
    </>
  );

  return (
    <>
      <FilterSubtext text="Ethnicity" />
      {options}
    </>
  );
};
