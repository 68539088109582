import React, { ReactElement, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip as ReChartsTooltip,
  Legend as ReChartsLegend,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import { getYAxisProps } from "../../common/utils/getYAxisProps";
import {
  getYAxisLabel,
  getYAxisLabelRight,
} from "../../common/utils/getYAxisLabel";
import { Legend } from "../../common/components/Legend";
import { MedianSurvivalChartTooltip } from "../../MedianSurvival/components/MedianSurvivalChartTooltip";
import { SubjectType } from "../../common/types/SubjectType";
import { FollowUpType } from "../../common/types/FollowUpType";
import {
  Filter,
  IPRO,
  RECIST,
  BOTH,
  FilterType,
} from "../../ChangeFromBaseline/components/Filter";
import { armFilterColors } from "../../common/utils/armColors";
import { sortFollowUps } from "../../common/utils/sortFollowUps";
import { SubjectAxisTick } from "./SubjectAxisTick";
import { getXAxisProps } from "../../common/utils/getXAxisProps";
import { TUMOR_SPELLING } from "../../../common/utils/spellingConstants";
import { getMaxValues } from "../utils/getMaxValues";
import { getPredictedSurvival } from "../utils/getPredictedSurvival";
import { DomainFilterType } from "../utils/getMaxValues";

const Wrapper = styled.div`
  flex: 1;
  position: relative;
`;

interface PatientJourneyTumourDetailsProps {
  subject: SubjectType;
  caseLink: string | undefined;
}

function PatientJourneyTumourDetails({
  subject,
  caseLink,
}: PatientJourneyTumourDetailsProps): ReactElement {
  const {
    followUps,
    trialArm: { number: armNumber },
  } = subject;

  const sortedFollowUps = sortFollowUps(followUps, true);

  const [filter, setFilter] = useState<FilterType>(RECIST);
  const showBoth = filter === BOTH;
  const showBurden = filter === RECIST || showBoth;
  const showSurvival = filter === IPRO || showBoth;

  const renderLegend = (props: any) => {
    return <Legend {...props} />;
  };

  const renderToolTip = (props: any) => {
    const { payload } = props;
    if (!payload || payload.length < 1) {
      return null;
    }
    return (
      <MedianSurvivalChartTooltip
        {...props}
        maxIPRO={getDomain(IPRO)}
        maxRECIST={getDomain(RECIST)}
      />
    );
  };

  const getBurden = (followUp: FollowUpType) => {
    const { tumourBurdens } = followUp;
    if (tumourBurdens.length < 1) {
      return null;
    }

    const { burden } = tumourBurdens[0];
    return burden;
  };

  const renderXAxisTick = (props: any) => {
    return (
      <SubjectAxisTick
        {...props}
        followUps={sortedFollowUps}
        caseLink={caseLink}
      />
    );
  };

  const getXAxisValue = (data: any) => {
    return data;
  };

  const getDomain = (filter: DomainFilterType): number => {
    return Math.ceil(getMaxValues(sortedFollowUps)[filter]);
  };

  const xAxisProps = getXAxisProps();
  const yAxisProps = getYAxisProps(true);
  const yAxisDiameterLabel = getYAxisLabel(`${TUMOR_SPELLING} Burden (mm)`);
  const survivalYAxisLabel = getYAxisLabelRight("Survival Months");
  return (
    <Wrapper>
      <ResponsiveContainer height={545}>
        <LineChart
          margin={{ top: 0, right: 20, left: 20, bottom: 75 }}
          data={sortedFollowUps}
        >
          <XAxis
            {...xAxisProps}
            padding={{ left: 40, right: 40 }}
            tick={renderXAxisTick}
            tickFormatter={getXAxisValue}
          />
          <YAxis
            yAxisId={RECIST}
            orientation={"left"}
            {...yAxisProps}
            domain={[0, getDomain(RECIST)]}
          >
            {showBurden && yAxisDiameterLabel}
          </YAxis>
          <YAxis
            yAxisId={IPRO}
            orientation={"right"}
            {...yAxisProps}
            domain={[0, getDomain(IPRO)]}
          >
            {showSurvival && survivalYAxisLabel}
          </YAxis>
          <ReChartsTooltip content={renderToolTip} />
          <ReChartsLegend
            align="left"
            verticalAlign="top"
            height={48}
            content={renderLegend}
          />
          {showBurden && (
            <Line
              isAnimationActive={false}
              type="linear"
              dataKey={getBurden}
              stroke={armFilterColors[armNumber][RECIST].color}
              dot={false}
              name={`${TUMOR_SPELLING} Burden`}
              legendType={"plainline"}
              yAxisId={RECIST}
            />
          )}
          {showSurvival && (
            <Line
              isAnimationActive={false}
              type="linear"
              dataKey={getPredictedSurvival}
              stroke={armFilterColors[armNumber][IPRO].color}
              dot={false}
              name={"Predicted Survival"}
              legendType={"plainline"}
              yAxisId={IPRO}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
      <Filter onFilterSelected={setFilter} activeFilter={filter} />
    </Wrapper>
  );
}

export default PatientJourneyTumourDetails;
