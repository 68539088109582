import React, { ReactElement } from "react";
import { TooltipItemWrapper } from "../../common/components/TooltipItem";
import {
  TooltipWrapper,
  CustomTooltipProps,
} from "../../common/components/Tooltip";
import styled from "styled-components";
import { TooltipItem } from "../../common/components/TooltipItem";
import { formatBinRange, SurvivalBinType } from "../utils/SurvivalBinType";
import { formatArmId } from "../../common/utils/formatArmId";

const TooltipHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.neutral.white80};
`;

const TooltipDivider = styled.div`
  height: 0;
  width: 100%;
  left: 8px;
  margin: 6px 0px;
  border: 0.5px solid ${(props) => props.theme.colors.neutral.white40};
`;

interface MortalityDistributionTooltipProps extends CustomTooltipProps {
  bin: SurvivalBinType;
}

export function MortalityDistributionTooltip({
  bin,
  ...props
}: MortalityDistributionTooltipProps): ReactElement | null {
  const subjectsText = formatBinRange(bin);
  const headerText = `Survival: ${subjectsText}`;

  const { payload } = props;
  if (!payload) {
    return null;
  }

  const formatCount = (value: number | string): string => {
    return `n=${value.toString()}`;
  };

  return (
    <TooltipWrapper>
      <TooltipItemWrapper>
        <TooltipHeaderWrapper>{headerText}</TooltipHeaderWrapper>
      </TooltipItemWrapper>
      <TooltipDivider />
      {payload.map((item: any, index: number) => {
        const { payload, dataKey, name, fill } = item;
        const armName = formatArmId(parseInt(name));
        const value = dataKey(payload);
        return (
          <TooltipItem
            key={index}
            data={{ color: fill, name: armName, value }}
            formatValue={formatCount}
          />
        );
      })}
    </TooltipWrapper>
  );
}
