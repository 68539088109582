import { createSelector } from "@reduxjs/toolkit";
import React from "react";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterSubtext from "../FilterSubtext";
import FilterNumberInput from "../FilterNumberInput";
import { NumberInputWrapper } from "../FilterInputWrapper";
import {
  getLifestyleFilters,
  trialViewFiltersActions,
} from "../../../../common/store/trialViewFiltersSlice";
import { capitalize } from "../../../../common/utils/capitalize";

const getBmiFilters = createSelector(
  [getLifestyleFilters],
  (lifestyleFilters) => lifestyleFilters.bmi
);

export const BmiFilters: FC = () => {
  const dispatch = useDispatch();
  const bmiFilters = useSelector(getBmiFilters);

  const bmiOptions = Object.entries(bmiFilters).map(
    ([filter, value], index) => {
      const updateBmiFilters = (number: number | null) => {
        dispatch(
          trialViewFiltersActions.setBmi({
            ...bmiFilters,
            [filter]: number,
          })
        );
      };
      return (
        <FilterNumberInput
          key={index}
          label={capitalize(filter)}
          onChange={updateBmiFilters}
          value={value}
        />
      );
    }
  );

  const options = <NumberInputWrapper>{bmiOptions}</NumberInputWrapper>;

  return (
    <>
      <FilterSubtext text="BMI" />
      {options}
    </>
  );
};
