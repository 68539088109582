import dayjs from "dayjs";

export function getFormattedMMMDDYYYY(date: Date): string {
  if (!date) return "N/A";
  return dayjs(date).format("MMM DD, YYYY");
}

export function getFormattedDetailed(date: Date): string {
  if (!date) return "N/A";
  return dayjs(date).format("DD MMM YY hh:mma");
}
