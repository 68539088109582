import { useCurrentUser } from "../UserManagement/useCurrentUser";
import { ApolloError } from "@apollo/client/errors";
import { UserRoleType } from "../../types/UserRoleType";

type useCanReturnType = {
  can?: boolean;
  error?: ApolloError;
  loading: boolean;
};

export function useCan(allowedRoles: UserRoleType[]): useCanReturnType {
  const { data: user, error, loading } = useCurrentUser();

  let can;

  if (!loading && !error && user) {
    const { roles } = user;
    can = roles.some((role) => allowedRoles.includes(role));
  }

  return {
    can,
    loading,
    error,
  };
}
