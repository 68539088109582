import React, { ReactElement } from "react";
import routes from "../../routes";
import styled from "styled-components";

type PageContainerProps = {
  isAppReady: boolean;
};

const Wrapper = styled.div``;

const PageContainer = ({ isAppReady }: PageContainerProps): ReactElement => {
  return <Wrapper>{isAppReady && routes}</Wrapper>;
};

export default PageContainer;
