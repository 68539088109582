import { gql } from "@apollo/client/core";
import { UserType } from "../../types/UserType";
import { useParsedMutation } from "../../queries/utils/useParsedMutation";
import { useParsedMutationReturnType } from "../../queries/utils/useParsedMutationReturnType";

const MUTATION = gql`
  mutation DeleteUserGroup($trial_id: Int!, $user_id: Int!) {
    delete_user_group(
      where: {
        _and: [
          { group: { trial_groups: { trial_id: { _eq: $trial_id } } } }
          { user_id: { _eq: $user_id } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

type Variables = {
  trial_id: number;
  user_id: number;
};

type Data = {
  delete_user_group: { affected_rows: number };
};

type Input = {
  user: UserType;
  trialId: number;
};

export function useDeleteUserGroup(): useParsedMutationReturnType<
  Input,
  number
> {
  return useParsedMutation<Input, number, Data, Variables>({
    mutation: MUTATION,
    parseVariables,
    parseData,
  });
}

function parseVariables({
  trialId: trial_id,
  user: { id: user_id },
}: Input): Variables {
  return {
    trial_id,
    user_id,
  };
}

function parseData(data: Data): number {
  return data.delete_user_group.affected_rows;
}
