import React, { FC, useState } from "react";
import styled from "styled-components";
import UploadButtons from "./UploadButtons";
import axios, { CancelTokenSource } from "axios";
import { TitleWrapper } from "./TitleWrapper";
import { UploadInProgressLabel } from "./UploadInProgressLabel";
import { SUCCESS } from "../../../common/types/StatusTypes";
import { getAllFiles } from "./fileUtils";
import { useAuth0 } from "@auth0/auth0-react";
import config from "../../../auth0/auth_config.json";

const DashedWrapper = styled.div<{ isDragged: boolean }>`
  display: flex;
  flex-direction: column;
  height: 230px;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  background: ${(props) =>
    `${
      props.isDragged ? props.theme.colors.actionPrimary.hover10 : ""
    } url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23333' stroke-width='3' stroke-dasharray='12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`};
`;

const MessageWrapper = styled.div`
  flex: 1;
  display: flex;
`;

const DragDropFileUpload: FC = () => {
  const [fileDragged, setFileDragged] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploadInProgress, setIsUploadInProgress] = useState<boolean>(false);

  const [cancellationSource, setCancellationSource] = useState<
    CancelTokenSource | undefined
  >(undefined);

  const { getAccessTokenSilently } = useAuth0();

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setFileDragged(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setFileDragged(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setFileDragged(true);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setFileDragged(false);

    const items = event.dataTransfer.items;

    if (items.length === 0) {
      console.log("No file selected!");
      return;
    }

    const files = await getAllFiles(items);
    await handleUpload(files);
  };

  const handleUploadProgress = (progressEvent: ProgressEvent) => {
    const { loaded, total } = progressEvent;
    const progress = loaded / total;
    console.debug(`Upload progress: ${progress}`);
    setUploadProgress(progress);
  };

  const handleUpload = async (files: File[]) => {
    if (files.length === 0) {
      console.warn("No files selected for upload");
      uploadCompleted();
      return;
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setCancellationSource(source);

    setIsUploadInProgress(true);
    await uploadFiles(
      files,
      handleUploadProgress,
      handleUploadCancelled,
      handleUploadError,
      source,
      getAccessTokenSilently
    );
    uploadCompleted();
  };

  const handleCancelUpload = () => {
    if (!cancellationSource) {
      return;
    }

    cancellationSource.cancel("Operation canceled by the user.");
  };

  const handleUploadCancelled = (message: string) => {
    console.warn("Request canceled", message);
    uploadCompleted();
  };

  const handleUploadError = (e: Error) => {
    console.error(`An error occurred: ${e.message}`, e);
    uploadCompleted();
  };

  const uploadCompleted = () => {
    setIsUploadInProgress(false);
    setCancellationSource(undefined);
    setUploadProgress(0);
  };

  return (
    <DashedWrapper
      isDragged={fileDragged}
      onDrop={(e) => handleDrop(e)}
      onDragOver={(e) => handleDragOver(e)}
      onDragEnter={(e) => handleDragEnter(e)}
      onDragLeave={(e) => handleDragLeave(e)}
    >
      <MessageWrapper>
        {fileDragged ? (
          <TitleWrapper>Upload</TitleWrapper>
        ) : isUploadInProgress ? (
          <UploadInProgressLabel
            progress={uploadProgress}
            state={SUCCESS}
            onCancel={handleCancelUpload}
          />
        ) : (
          <TitleWrapper>Drag files here</TitleWrapper>
        )}
      </MessageWrapper>
      <UploadButtons
        disabled={isUploadInProgress}
        onUploadFiles={handleUpload}
      />
    </DashedWrapper>
  );
};

export default DragDropFileUpload;

export async function uploadFiles(
  files: File[],
  onProgress: (e: ProgressEvent) => void,
  onCancelled: (message: string) => void,
  onError: (e: Error) => void,
  cancelToken: CancelTokenSource,
  getAuthToken: (options: any) => Promise<string>
): Promise<void> {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    formData.append("files", file, file.name);
  }

  const apiUrl = process.env.REACT_APP_CELERY_API_URL;
  const uploadUrl = apiUrl + "/upload/";

  const token = await getAuthToken(config);

  try {
    const result = await axios.post(uploadUrl, formData, {
      onUploadProgress: onProgress,
      cancelToken: cancelToken.token,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.debug(result.data);
  } catch (e) {
    if (axios.isCancel(e)) {
      onCancelled(e.message);
    } else {
      onError(e);
    }
  }
}
