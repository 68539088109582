import styled from "styled-components";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import React from "react";
import { OuterHeaderWrapper } from "./DialogContents";

const HeaderWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 12px;
`;

const HeaderLabelWrapper = styled.div<{ center: boolean }>`
  margin: ${(props) => (props.center ? "auto" : undefined)};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;

const HeaderCloseButtonWrapper = styled.div`
  cursor: pointer;
`;

export interface DialogHeaderProps {
  label: string;
  showCloseButton?: boolean;
  onClose?: () => void;
}

export function DialogHeader({
  label,
  showCloseButton,
  onClose,
}: DialogHeaderProps): JSX.Element {
  const handleClose = () => {
    onClose?.();
  };

  return (
    <OuterHeaderWrapper>
      <HeaderWrapper>
        <HeaderLabelWrapper center={!showCloseButton}>
          {label}
        </HeaderLabelWrapper>
        {showCloseButton && (
          <HeaderCloseButtonWrapper>
            <CloseRoundedIcon onClick={handleClose} />
          </HeaderCloseButtonWrapper>
        )}
      </HeaderWrapper>
    </OuterHeaderWrapper>
  );
}
