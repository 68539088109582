import { useHistory } from "react-router-dom";
import { useTrialId } from "./useTrialId";
import { getTrialRoute } from "../../common/utils/getTrialRoute";
import { TrialViewPageType } from "../types/TrialViewPageType";

type useGoToTrialViewPageReturn = {
  // eslint-disable-next-line no-unused-vars
  goToPage: (page: TrialViewPageType) => void;
};

export function useGoToTrialViewPage(): useGoToTrialViewPageReturn {
  const { push } = useHistory();
  const trialId = useTrialId();

  const goToPage = (page: TrialViewPageType) => {
    const trialRoute = getTrialRoute(trialId, page);
    push(trialRoute);
  };

  return { goToPage };
}
