import { RawTrialDetailsType } from "../../common/types/RawTrialDetailsType";
import { TrialType } from "../../common/types/TrialType";

export function parseTrialDetails(data: RawTrialDetailsType): TrialType[] {
  return data.trial.map((trial) => {
    const { trial_groups } = trial;
    const groupIds = trial_groups.map(({ group_id }) => group_id);
    return {
      ...trial,
      indicationName: trial.details.disease,
      investigationName: trial.details.ip,
      groupIds,
    };
  });
}
