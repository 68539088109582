import React from "react";
import { SvgIcon } from "../icons/SvgIcon";
import { ReactComponent as AddIcon } from "../../../assets/svgs/PlusOutlineFilled.svg";
import { SiteRow } from "./SiteRow";
import { useCreateSiteDialog } from "./Dialogs/useCreateSiteDialog";
import styled from "styled-components";
import CTAButton from "../buttons/CTAButton";
import { DialogContentInnerWrapper } from "../Dialog/DialogContentInnerWrapper";
import { SiteType } from "../../types/SiteType";
import { SiteActionCallbacksType } from "./SiteActionCallbacksType";
import { UserType } from "../../types/UserType";

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ActionButton = styled(CTAButton)`
  width: 114px;
`;

export interface EditTrialSitesProps {
  sites: SiteType[];
  actionCallbacks: SiteActionCallbacksType;
  allowEditSite: boolean;
  allowRemoveSite: boolean;
  allowCreateSite: boolean;
}

export function EditTrialSites({
  sites,
  actionCallbacks,
  allowEditSite,
  allowRemoveSite,
  allowCreateSite,
}: EditTrialSitesProps): JSX.Element {
  const { onCreated } = actionCallbacks ?? {
    onCreated: undefined,
  };

  if (!onCreated) {
    throw new Error("onCreated not defined in EditTrialSites");
  }

  const handleConfirmCreateSites = (sites: SiteType[]) => {
    onCreated(sites);
  };

  const [setCreateSiteDialogOpen, { dialog }] = useCreateSiteDialog(
    handleConfirmCreateSites
  );

  const handleClickCreateSite = () => {
    if (!allowCreateSite) {
      throw new Error(
        "handleClickCreateSite called by allowCreateSite is false"
      );
    }

    setCreateSiteDialogOpen(true);
  };

  return (
    <>
      {dialog}
      <DialogContentInnerWrapper>
        {allowCreateSite && (
          <ActionButtonWrapper>
            <ActionButton
              icon={<SvgIcon icon={AddIcon} size={16} />}
              text={"Create Site"}
              onClick={handleClickCreateSite}
            />
          </ActionButtonWrapper>
        )}
        <ListWrapper>
          {sites.map((site, index) => (
            <SiteRow
              site={site}
              actionCallbacks={actionCallbacks}
              key={index}
              allowEditSite={allowEditSite}
              allowRemoveSite={allowRemoveSite}
            />
          ))}
        </ListWrapper>
      </DialogContentInnerWrapper>
    </>
  );
}
