export function getTicksAtInterval(
  min: number,
  max: number,
  interval: number
): number[] {
  const roundToNearest = (value: number, interval: number) => {
    if (value > 0) return Math.ceil(value / interval) * interval;
    else if (value < 0) return Math.ceil(value / interval) * interval;
    else return value;
  };
  const lower = roundToNearest(min, interval);
  const upper = roundToNearest(max, interval);

  const numberOfTicks = 1 + (upper - lower) / interval;
  if (numberOfTicks <= 0) {
    return [];
  }
  return [...Array(numberOfTicks).keys()].map(
    (tick) => tick * interval + lower
  );
}
