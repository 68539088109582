const N = 3;
export const LOCAL_STORAGE_KEY = "recentTrialIds";

export const loadRecentTrials = (): number[] => {
  const recentTrialIds = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!recentTrialIds) {
    return [];
  }
  return JSON.parse(recentTrialIds);
};

export const addRecentTrial = (trialIdNumber: number): void => {
  const recentTrialIds = loadRecentTrials();

  if (recentTrialIds.length === 0) {
    recentTrialIds.push(trialIdNumber);
  } else if (recentTrialIds) {
    if (recentTrialIds[recentTrialIds.length - 1] !== trialIdNumber) {
      if (recentTrialIds.length === N) {
        recentTrialIds.shift();
        recentTrialIds.push(trialIdNumber);
      } else {
        recentTrialIds.push(trialIdNumber);
      }
      recentTrialIds.forEach((id, i) => {
        if (i !== recentTrialIds.length - 1) {
          if (id === recentTrialIds[recentTrialIds.length - 1]) {
            recentTrialIds.splice(i, 1);
          }
        }
      });
    }
  }
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(recentTrialIds));
};
