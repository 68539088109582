import React, { useEffect, useState } from "react";
import { useDialog, useDialogReturnType } from "../../Dialog/useDialog";
import styled from "styled-components";
import { OK_CANCEL } from "../../Dialog/DialogActionButtonsType";
import { DialogText } from "../../Dialog/DialogText";
import { DialogContentInnerWrapper } from "../../Dialog/DialogContentInnerWrapper";
import { UserType } from "../../../types/UserType";
import {
  ANNOTATE,
  MANAGER,
  USER,
  UserRoleType,
  VIEW_ANNOTATIONS,
} from "../../../types/UserRoleType";
import Select, { ValueType } from "react-select";
import { getNewUsersByEmail } from "../utils/getNewUsersByEmail";
import { DEFAULT_TENANT_ID } from "../DefaultTenantId";
import { RoleLabel } from "../RoleLabel";
import { getSelectStyle } from "../../input/getSelectStyle";
import { useTenantUsers } from "../../../../Settings/useTenantUsers";
import { formatRole } from "../utils/formatRole";
import { UserSelect } from "../UserSelect";

const Wrapper = styled(DialogContentInnerWrapper)`
  width: 372px;
  overflow: visible;
`;

type RoleSelectOptionType = {
  value: UserRoleType;
  label: string;
};

export function useCreateUserDialog(
  users: UserType[],
  onConfirmCreateUsers: (users: UserType[]) => void
): useDialogReturnType {
  const [selectedUsers, setSelectedUsers] = useState<UserType[]>([]);
  const [availableUsers, setAvailableUser] = useState<UserType[]>([]);

  const { data: tenantUsers, loading } = useTenantUsers(DEFAULT_TENANT_ID);

  useEffect(() => {
    if (!tenantUsers || loading) {
      setAvailableUser([]);
      return;
    }

    const newUsers = getNewUsersByEmail(tenantUsers, users);
    setAvailableUser(newUsers);
  }, [users, loading]);

  const initialRoles: UserRoleType[] = [USER];

  const [roles, setRoles] = useState<UserRoleType[]>(initialRoles);

  const handleRoleChanged = (value: ValueType<RoleSelectOptionType, true>) => {
    if (!value) {
      return;
    }

    const roles = value.map(({ value }) => value);

    setSelectedUsers(selectedUsers.map((user) => ({ ...user, roles: roles })));

    setRoles(roles);
  };

  const formatRoleOptionLabel = ({ value }: RoleSelectOptionType) => (
    <RoleLabel role={value} />
  );

  const label = "Add Team Member";

  const roleOptions: RoleSelectOptionType[] = [
    USER,
    MANAGER,
    ANNOTATE,
    VIEW_ANNOTATIONS,
  ].map((role) => formatRole(role as UserRoleType));

  const selectStyle = getSelectStyle<RoleSelectOptionType, true>();

  const content = (
    <Wrapper>
      <UserSelect
        users={availableUsers}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        isCreatable={true}
      />
      <Select
        styles={selectStyle}
        defaultValue={[roleOptions[0]]}
        isClearable={false}
        isSearchable={true}
        options={roleOptions}
        formatOptionLabel={formatRoleOptionLabel}
        onChange={handleRoleChanged}
        menuPortalTarget={document.body}
        isMulti
      />
      <DialogText>
        Once confirmed, the user will receive an email with details of how to
        reset your password.
      </DialogText>
    </Wrapper>
  );

  const handleOnDialogOpened = () => {
    setSelectedUsers([]);
    setRoles(initialRoles);
  };

  const handleConfirmCreateUser = () => {
    onConfirmCreateUsers(selectedUsers);
  };

  return useDialog({
    label,
    content,
    options: {
      showCloseButton: false,
      onOpened: handleOnDialogOpened,
      actionButtonOptions: {
        type: OK_CANCEL,
        okText: "Add",
        onOkOverride: handleConfirmCreateUser,
      },
    },
  });
}
