import React, { FC } from "react";
import { useTrialId } from "../../../Trial/hooks/useTrialId";
import handleApolloError from "../../../common/utils/handleApolloError";
import Loading from "../../../common/components/Loading";
import EnrolmentChart from "./EnrolmentChart";
import { useSubjectQuery } from "../../../Analysis/common/hooks/useSubjectQuery";
import { parseEnrollmentData } from "../utils/parseEnrolmentChartData";

export const EnrolmentChartContainer: FC = () => {
  const trialId = useTrialId();
  const { subjects, loading, error } = useSubjectQuery(trialId);

  if (loading) return <Loading />;
  if (error) handleApolloError(error);
  if (!subjects) throw new Error("Subject null with no apollo error");

  const processedData = parseEnrollmentData(subjects);
  return <EnrolmentChart enrolmentData={processedData} />;
};
