import React, { ReactElement, ReactText } from "react";
import { TooltipItemWrapper } from "../../common/components/TooltipItem";
import {
  TooltipWrapper,
  CustomTooltipProps,
} from "../../common/components/Tooltip";
import styled from "styled-components";
import { formatFollowUp } from "../../common/utils/formatFollowUp";
import { TooltipItem } from "../../common/components/TooltipItem";
import { formatMonthsRounded } from "../../common/utils/formatMonthsRounded";
import { formatTumorBurden } from "../../common/utils/formatTumorBurden";
import { sortTooltip } from "../utils/sortTooltip";
import {
  TUMOR_BURDEN,
  PREDICTED_SURVIVAL,
} from "../../common/utils/spellingConstants";
interface MedianToolTipProps extends CustomTooltipProps {
  maxIPRO: number;
  maxRECIST: number;
}

const TooltipHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.neutral.white80};
`;

const TooltipDivider = styled.div`
  height: 0;
  width: 100%;
  left: 8px;
  margin: 6px 0px;
  border: 0.5px solid ${(props) => props.theme.colors.neutral.white40};
`;

export function MedianSurvivalChartTooltip({
  active,
  payload,
  label,
  maxRECIST,
  maxIPRO,
}: MedianToolTipProps): ReactElement | null {
  if (!active || !payload || payload.length < 1) {
    return null;
  }
  const itemsToRender: any[] = payload.filter(
    (item: any) => item.type !== "none"
  );

  if (itemsToRender.length < 1) {
    return null;
  }
  const sortedItems = sortTooltip(itemsToRender, maxRECIST, maxIPRO);

  return (
    <TooltipWrapper>
      <TooltipItemWrapper>
        <TooltipHeaderWrapper>{formatFollowUp(label)}</TooltipHeaderWrapper>
      </TooltipItemWrapper>
      <TooltipDivider />
      {sortedItems.map((item, index) => {
        let formatValue = (value: ReactText) => String(value);
        if (item.name.includes(PREDICTED_SURVIVAL)) {
          formatValue = formatMonthsRounded;
        } else if (item.name.includes(TUMOR_BURDEN)) {
          formatValue = formatTumorBurden;
        }
        return (
          <TooltipItem key={index} data={item} formatValue={formatValue} />
        );
      })}
    </TooltipWrapper>
  );
}
