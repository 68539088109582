import { useParsedQueryReturnType } from "../../common/queries/utils/useParsedQueryReturnType";
import { useParsedQuery } from "../../common/queries/utils/useParsedQuery";
import { gql } from "@apollo/client/core";
import {
  AnatomicalStructureTreeNode,
  AnatomicalStructureType,
} from "../../Analysis/common/types/AnatomicalStructureType";
import {
  ANATOMICAL_STRUCTURES_FRAGMENT,
  AnatomicalStructuresFragmentType,
  parseAnatomicalStructuresFragment,
} from "./AnatomicalStructuresFragment";

const QUERY = gql`
  query GetEnabledAnatomicalStructures {
    anatomical_structures(
      where: { enabled_organs: { enabled: { _eq: true } } }
    ) {
      ...AnatomicalStructure
    }
  }
  ${ANATOMICAL_STRUCTURES_FRAGMENT}
`;

type Variables = undefined;

type Data = {
  anatomical_structures: AnatomicalStructuresFragmentType[];
};

export function useAnatomicalStructures(): useParsedQueryReturnType<
  undefined,
  AnatomicalStructureTreeNode[],
  Variables
> {
  return useParsedQuery<
    undefined,
    AnatomicalStructureTreeNode[],
    Data,
    Variables
  >({
    query: QUERY,
    input: undefined,
    parseVariables,
    parseData,
  });
}

function parseVariables(): Variables {
  return undefined;
}

function parseData(data: Data): AnatomicalStructureTreeNode[] {
  const output = data.anatomical_structures.map(
    parseAnatomicalStructuresFragment
  );
  return getAnatomicalStructuresTree(
    output.map((anatomicalStructure) => ({
      ...anatomicalStructure,
      children: [],
    }))
  );
}

export function getAnatomicalStructuresTree(
  anatomicalStructures: AnatomicalStructureTreeNode[]
): AnatomicalStructureTreeNode[] {
  const tree: AnatomicalStructureTreeNode[] = [];

  const sortedAnatomicalStructures = [...anatomicalStructures].sort((a, b) =>
    a.structure.localeCompare(b.structure)
  );

  sortedAnatomicalStructures.forEach((anatomicalStructure) => {
    const parentPath = getParentPath(anatomicalStructure);

    const parent = tree.find((anatomicalStructure) =>
      parentPath.includes(anatomicalStructure.structure)
    );

    if (parent) {
      addAnatomyToParent(anatomicalStructure, parent);
    } else {
      tree.push(anatomicalStructure);
    }
  });

  return tree;
}

function getParentPath(anatomicalStructure: AnatomicalStructureType): string {
  return anatomicalStructure.structure.substr(
    0,
    anatomicalStructure.structure.lastIndexOf(".")
  );
}

function addAnatomyToParent(
  anatomicalStructure: AnatomicalStructureTreeNode,
  parent: AnatomicalStructureTreeNode
): void {
  const { children } = parent;

  const parentPath = getParentPath(anatomicalStructure);

  if (parent.structure === parentPath) {
    parent.children.push(anatomicalStructure);
    return;
  }

  let nested = children.find((child) => parentPath.includes(child.structure));

  if (!nested) {
    throw new Error(`No parent found for path: ${parentPath}`);
  }

  while (nested?.structure !== parentPath) {
    nested =
      nested.children[
        nested.children.findIndex((res) =>
          anatomicalStructure.structure.includes(res.structure)
        )
      ];
  }

  nested.children.push(anatomicalStructure);
}
