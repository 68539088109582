import React from "react";
interface TextProps {
  text: string;
}
function Text({
  text,
}: TextProps): React.ReactElement<React.HTMLAttributes<HTMLParagraphElement>> {
  return <p>{text}</p>;
}
export default Text;
