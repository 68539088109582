import React from "react";
import { Label } from "../../../common/components/Label";
import { main } from "../../../common/theme/main";
import {
  actionTypeColors,
  actionTypeDisplayNames,
  ActionTypeType,
} from "../../types/ActionTypeType";

interface ActionTypeLabelProps {
  actionType: ActionTypeType;
  onClick?: () => void;
}

export function ActionTypeLabel({
  actionType,
  onClick,
}: ActionTypeLabelProps): JSX.Element {
  const {
    neutral: { white },
  } = main.colors;
  const label = actionTypeDisplayNames[actionType];
  const background = actionTypeColors[actionType];
  return (
    <Label background={background} color={white} onClick={onClick}>
      {label}
    </Label>
  );
}
