import { main } from "../../../common/theme/main";
import { DataType } from "../../ChangeFromBaseline/utils/parseMortalityBaselineChartData";
import { IPRO, RECIST } from "../../ChangeFromBaseline/components/Filter";
import {
  ARM_0_ALT_COLOR,
  ARM_0_COLOR,
  ARM_1_ALT_COLOR,
  ARM_1_COLOR,
  DASHED_STROKE_DASH_ARRAY,
  SOLID_STROKE_DASH_ARRAY,
  STROKE_WIDTH,
} from "./strokeSettings";

export const armColors: { [arm: number]: string } = {
  0: ARM_0_COLOR,
  1: ARM_1_COLOR,
};

export const altArmColors: { [arm: number]: string } = {
  0: ARM_0_ALT_COLOR,
  1: ARM_1_ALT_COLOR,
};

export const armFilterColors: {
  [arm: number]: Record<DataType, StrokeSettingsType>;
} = {
  0: {
    [RECIST]: {
      color: armColors[0],
      width: STROKE_WIDTH,
      dashArray: SOLID_STROKE_DASH_ARRAY,
    },
    [IPRO]: {
      color: main.colors.accent.accent1Light,
      width: STROKE_WIDTH,
      dashArray: DASHED_STROKE_DASH_ARRAY,
    },
  },
  1: {
    [RECIST]: {
      color: armColors[1],
      width: STROKE_WIDTH,
      dashArray: SOLID_STROKE_DASH_ARRAY,
    },
    [IPRO]: {
      color: main.colors.accent.accent2Light,
      width: STROKE_WIDTH,
      dashArray: DASHED_STROKE_DASH_ARRAY,
    },
  },
};

type StrokeSettingsType = {
  width: number;
  color: string;
  dashArray: string;
};
