import React from "react";
import { FC } from "react";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import styled from "styled-components";

const Styled = styled(ExpandIcon)`
  color: ${(props) => props.theme.colors.primary.inverted};
`;

export const ExpandMoreIcon: FC = () => <Styled />;
