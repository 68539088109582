import Loading from "./Loading";
import handleApolloError from "../utils/handleApolloError";
import React from "react";
import { UserRoleType } from "../types/UserRoleType";
import { useCan } from "./Can/useCan";

interface CurrentUserCanProps {
  yes?: JSX.Element;
  no?: JSX.Element;
  allowedRoles: UserRoleType[];
}

export function CurrentUserCan({
  yes,
  no,
  allowedRoles,
}: CurrentUserCanProps): JSX.Element | null {
  const { can, error, loading } = useCan(allowedRoles);

  if (loading) return <Loading />;
  if (error) handleApolloError(error);
  if (can === undefined)
    throw new Error("Can return value is null with no apollo error");

  return can ? yes ?? null : no ?? null;
}
