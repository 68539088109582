import React, { FC } from "react";
import { useWorklist } from "./useWorklist";
import handleApolloError from "../../../common/utils/handleApolloError";
import { FlexAltLoading } from "../../../common/components/AltLoading";
import styled from "styled-components";
import { COMPLETED, PENDING } from "../../types/CaseProgressType";
import { WorklistTableRowType } from "./WorklistTableRowType";
import { WorklistTable } from "./WorklistTable";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 24px;
`;

const ColumnWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const TableCard = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.actionPrimary.inverted};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  padding: 24px;
  gap: 12px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
`;

const IncompleteAssignmentsWrapper = styled(ColumnWrapper)`
  flex: 2;
`;

const CompleteAssignmentsWrapper = styled(ColumnWrapper)`
  flex: 1;
`;

export const Worklist: FC = () => {
  const { data, loading, error } = useWorklist({ pollInterval: 1000 });

  const { caseAssignments, unassignedCaseInstances } = data ?? {
    caseAssignments: [],
    unassignedCaseInstances: [],
  };

  const unassignedRows: WorklistTableRowType[] = unassignedCaseInstances.map(
    ({ case: { id: caseId, name, url }, actionType }) => ({
      caseId,
      name,
      progress: PENDING,
      actionType,
      url,
    })
  );

  const incompleteAssignmentRows = caseAssignments
    .filter(({ progress }) => progress !== COMPLETED)
    .map(({ case: { id: caseId, name, url }, progress, actionType }) => ({
      caseId,
      name,
      progress,
      actionType,
      url,
    }));

  const completeAssignmentRows = caseAssignments
    .filter(({ progress }) => progress === COMPLETED)
    .map(({ case: { id: caseId, name, url }, progress, actionType }) => ({
      caseId,
      name,
      progress,
      actionType,
      url,
    }));

  if (loading) return <FlexAltLoading />;
  if (error) handleApolloError(error);
  return (
    <Wrapper>
      <ColumnWrapper>
        <TableCard>
          Pending
          <WorklistTable
            rows={unassignedRows}
            enableSelect={false}
            minTableHeight={200}
          />
        </TableCard>
      </ColumnWrapper>
      <ColumnWrapper>
        <IncompleteAssignmentsWrapper>
          <TableCard>
            In Progress
            <WorklistTable
              rows={incompleteAssignmentRows}
              enableSelect={false}
              minTableHeight={150}
            />
          </TableCard>
        </IncompleteAssignmentsWrapper>
        <CompleteAssignmentsWrapper>
          <TableCard>
            Completed
            <WorklistTable
              rows={completeAssignmentRows}
              enableSelect={false}
              minTableHeight={100}
            />
          </TableCard>
        </CompleteAssignmentsWrapper>
      </ColumnWrapper>
    </Wrapper>
  );
};
