import { useLazyQuery } from "@apollo/client";
import { useMemo } from "react";
import { useParsedLazyQueryReturnType } from "./useParsedLazyQueryReturnType";
import {
  ParsedLazyQueryArgs,
  ParsedLazyQueryOptions,
} from "./ParsedLazyQueryArgs";

export function useParsedLazyQuery<TInput, TOutput, TData, TVariables>({
  query,
  options,
  parseVariables,
  parseData,
}: ParsedLazyQueryArgs<
  TInput,
  TOutput,
  TData,
  TVariables
>): useParsedLazyQueryReturnType<TInput, TOutput, TVariables> {
  const parsedOptions: ParsedLazyQueryOptions<TData, TVariables> = {
    ...options,
    query,
    onCompleted: (data: TData) => {
      if (!options) {
        return;
      }

      const output = parseData(data);
      options.onCompleted?.(output);
    },
  };

  const [execute, { loading, error, data }] = useLazyQuery<TData, TVariables>(
    query,
    parsedOptions
  );

  const parsedExecute = (input: TInput) => {
    const variables = parseVariables(input);
    return execute({ variables });
  };

  let output: TOutput | undefined;

  if (!loading && !error && data) {
    output = parseData(data);
  }

  const memoizedOutput = useMemo(() => output, [data, loading, error]);

  return [parsedExecute, { data: memoizedOutput, loading, error }];
}
