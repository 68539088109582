import React, { ReactElement } from "react";
import {
  Bar,
  BarChart,
  Cell,
  Legend as ReChartsLegend,
  ResponsiveContainer,
  Tooltip as ReChartsTooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import { Legend } from "../../common/components/Legend";
import { Payload } from "recharts/types/component/DefaultLegendContent";
import {
  COMPLETE_RESPONSE,
  DISPLAY_NAMES,
  PARTIAL_RESPONSE,
  PROGRESSIVE_DISEASE,
  STABLE_DISEASE,
  TumourResponseType,
} from "../../common/types/TumourResponseType";
import { getYAxisProps } from "../../common/utils/getYAxisProps";
import { getXAxisProps } from "../../common/utils/getXAxisProps";
import { PatientJourneyChartTooltip } from "./PatientJourneyChartTooltip";
import { formatDaysInMonths } from "../../common/utils/formatDaysInMonths";
import { SubjectResponseProgressionType } from "../types/SubjectResponseProgressionType";
import { useGoToPatientPage } from "../../../Trial/hooks/useGoToPatientPage";
import { followUpIds } from "../../common/utils/followUpIds";
import { tumourResponseColors } from "../../common/utils/tumourResponseColors";

interface PatientJourneyChartProps {
  onClickAction?: React.MouseEventHandler;
  subjectResponseProgressions: SubjectResponseProgressionType[];
}

const Wrapper = styled.div`
  flex: 1;
`;

const displayedResponseTypes: TumourResponseType[] = [
  PROGRESSIVE_DISEASE,
  STABLE_DISEASE,
  PARTIAL_RESPONSE,
  COMPLETE_RESPONSE,
];

const legendPayload: Payload[] = displayedResponseTypes.map((key) => {
  const response: TumourResponseType = key as TumourResponseType;
  const color: string = tumourResponseColors[response] ?? "black";
  return {
    type: "square",
    color,
    value: DISPLAY_NAMES[response],
  };
});

function PatientJourneyChart({
  subjectResponseProgressions,
}: PatientJourneyChartProps): React.ReactElement {
  const { goToPage } = useGoToPatientPage();

  const renderLegend = ({ payload }: any) => <Legend payload={payload} />;
  const renderToolTip = (props: any) => (
    <PatientJourneyChartTooltip {...props} />
  );

  const onClickSubject = (data: any) => {
    if (!data) {
      return;
    }

    const { activePayload } = data;
    if (!activePayload || activePayload.length < 1) {
      return;
    }

    const { payload: subjectResponseProgression } = activePayload[0];

    const { id } = subjectResponseProgression;
    goToPage(id);
  };

  const getValue = (
    data: SubjectResponseProgressionType,
    followUpOrder: number
  ): number => {
    const followUp = data.followUpResponses[followUpOrder];
    return followUp?.elapsedDays;
  };

  const getCell = (followUpOrder: number) => (
    subjectResponseProgression: SubjectResponseProgressionType
  ): ReactElement | null => {
    const { followUpResponses } = subjectResponseProgression;
    const followUp = followUpResponses[followUpOrder];
    if (!followUp) {
      return <Cell fill={"transparent"} key={followUpOrder} />;
    }
    const { response } = followUp;
    const color = tumourResponseColors[response];
    return <Cell fill={color} key={followUpOrder} />;
  };

  const xAxisProps = getXAxisProps();
  const yAxisProps = getYAxisProps(false);

  return (
    <Wrapper>
      <ResponsiveContainer height={700}>
        <BarChart
          margin={{ top: 0, right: 0, left: 30, bottom: -40 }}
          layout={"vertical"}
          data={subjectResponseProgressions}
          height={700}
          width={1000}
          onClick={onClickSubject}
          barGap={0}
          barCategoryGap={0}
        >
          <ReChartsTooltip content={renderToolTip} isAnimationActive={false} />
          <ReChartsLegend
            align="left"
            verticalAlign="top"
            height={48}
            content={renderLegend}
            payload={legendPayload}
          />
          <XAxis
            {...xAxisProps}
            type={"number"}
            tickFormatter={formatDaysInMonths}
          />
          <YAxis
            dataKey={"subjectId"}
            type={"category"}
            reversed={true}
            tick={false}
            {...yAxisProps}
          />
          {followUpIds.map((followUp) => {
            return (
              <Bar
                isAnimationActive={false}
                dataKey={(data) => getValue(data, followUp)}
                stackId={"x"}
                key={followUp.toString()}
                legendType={"square"}
              >
                {subjectResponseProgressions
                  .map(getCell(followUp))
                  .filter((cell) => cell !== null)}
              </Bar>
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </Wrapper>
  );
}

export default PatientJourneyChart;
