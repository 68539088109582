import React, { FC } from "react";
import TrialSelector from "./TrialSelector";
import { useQuery } from "@apollo/client";
import handleApolloError from "../../utils/handleApolloError";
import { getTrialBaseRoute } from "../../utils/getTrialRoute";
import { useHistory } from "react-router-dom";
import { useTrialId } from "../../../Trial/hooks/useTrialId";
import { GET_ALL_TRIALS_DETAILS } from "../../queries/GetAllTrialsDetails";
import { RawTrialDetailsType } from "../../types/RawTrialDetailsType";
import { parseTrialDetails } from "../../../Dashboard/utils/parseTrialDetails";

export const TrialSelectorContainer: FC = () => {
  const { push } = useHistory();
  const trialId = useTrialId();

  const { data, loading, error } = useQuery<RawTrialDetailsType>(
    GET_ALL_TRIALS_DETAILS
  );
  if (error) handleApolloError(error);

  const onTrialSelected = (trialId: number) => {
    const trialRoute = getTrialBaseRoute(trialId);
    push(trialRoute);
  };

  const trialDetails = data ? parseTrialDetails(data) : [];

  return (
    <TrialSelector
      trials={trialDetails}
      selectedTrialId={trialId}
      isDisabled={loading}
      isLoading={loading}
      onTrialSelected={onTrialSelected}
    />
  );
};
