import { ValueType } from "react-select";
import React from "react";
import {
  AnatomicalStructureOptionType,
  getAnatomicalStructureOption,
  getAnatomicalStructureOptions,
} from "../../../Annotate/components/AnatomicalStructureOptionType";
import {
  AnatomicalStructureTreeNode,
  AnatomicalStructureType,
} from "../../../Analysis/common/types/AnatomicalStructureType";
import { NamedProps } from "react-select/src/Select";
import { ADD, REMOVE, UpdateModeType } from "../../types/UpdateModeType";
import { AnatomicalStructureLabel } from "../../../Annotate/components/AnatomicalStructureLabel";
import { getSelectStyle } from "../input/getSelectStyle";
import { getChanged } from "../../types/ItemsUpdateType";
import CreatableSelect from "react-select/creatable";

interface AnatomicalStructuresSelectProps
  extends NamedProps<AnatomicalStructureOptionType, true> {
  anatomicalStructures?: AnatomicalStructureTreeNode[];
  selectedAnatomicalStructures: AnatomicalStructureType[];
  setSelectedAnatomicalStructures: (
    anatomicalStructures: AnatomicalStructureType[]
  ) => void;
  mode: UpdateModeType;
}

export function AnatomicalStructuresSelect({
  anatomicalStructures,
  selectedAnatomicalStructures,
  setSelectedAnatomicalStructures,
  mode,
  ...selectProps
}: AnatomicalStructuresSelectProps): JSX.Element {
  const options = anatomicalStructures
    ? getAnatomicalStructureOptions(anatomicalStructures)
    : [];

  const handleAnatomyChanged = (
    values: ValueType<AnatomicalStructureOptionType, true>
  ) => {
    const anatomicalStructure = values
      .map(({ value }) => value)
      .filter((a) => a) as AnatomicalStructureType[];
    setSelectedAnatomicalStructures(anatomicalStructure);
  };

  const formatOptionLabel = (option: AnatomicalStructureOptionType) => {
    const { value: anatomicalStructure } = option;

    //we have to ignore the following lines since this is how react-select new options work
    // @ts-ignore
    if (option.__isNew__) {
      // @ts-ignore
      return `Create label: ${anatomicalStructure as string}`;
    }

    return (
      <AnatomicalStructureLabel
        anatomicalStructure={anatomicalStructure}
        state={mode}
      />
    );
  };

  const formatCreateLabel = (input: string) => {
    return <>Create label: {input}</>;
  };

  const handleCreateOption = async (value: string) => {
    const newLabel: AnatomicalStructureType = {
      id: 0,
      name: value,
      structure: value,
    };

    setSelectedAnatomicalStructures([
      newLabel,
      ...selectedAnatomicalStructures,
    ]);
  };

  const { untouched, added, removed } = getChanged(
    selectedAnatomicalStructures,
    [],
    mode
  );
  const value = [
    ...untouched.map((a) => getAnatomicalStructureOption(a)),
    ...added.map((a) => getAnatomicalStructureOption(a, ADD)),
    ...removed.map((a) => getAnatomicalStructureOption(a, REMOVE)),
  ];

  const selectStyle = getSelectStyle<AnatomicalStructureOptionType, true>();

  return (
    <CreatableSelect
      autoFocus
      styles={selectStyle}
      isClearable={false}
      isSearchable={true}
      isLoading={!anatomicalStructures}
      placeholder={"Select or create labels..."}
      formatOptionLabel={formatOptionLabel}
      formatCreateLabel={formatCreateLabel}
      {...selectProps}
      isMulti={true}
      options={options}
      onChange={handleAnatomyChanged}
      value={value}
      menuPortalTarget={document.body}
      onCreateOption={handleCreateOption}
    />
  );
}
