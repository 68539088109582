import { UserType } from "../../../types/UserType";

export function formatUserTitleAndOrganization(user: UserType): string | null {
  const { title, organization } = user;

  if (!title && !organization) {
    return null;
  }

  return [title, organization].filter((text) => text).join(" - ");
}
