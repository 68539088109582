import { gql } from "@apollo/client/core";
import { LESION_FRAGMENT } from "../types/LesionFragment";
import { LesionFragmentType } from "../types/LesionFragmentType";
import { LesionType } from "../types/LesionType";
import { useParsedLazyQuery } from "../../common/queries/utils/useParsedLazyQuery";
import { useParsedLazyQueryReturnType } from "../../common/queries/utils/useParsedLazyQueryReturnType";
import { ParsedLazyQueryOptions } from "../../common/queries/utils/ParsedLazyQueryArgs";

const QUERY = gql`
  query GetCompletedLesions($study_ids: [Int!]!) {
    lesion(
      where: {
        _and: [
          {
            case: {
              series_cases: { series: { study_id: { _in: $study_ids } } }
            }
          }
          { lesion_statuses: { status: { _eq: "COMPLETED" } } }
        ]
      }
    ) {
      ...Lesion
    }
  }
  ${LESION_FRAGMENT}
`;

type Variables = {
  study_ids: number[];
};

type Data = {
  lesion: LesionFragmentType[];
};

export function useCompletedLesions(
  options: ParsedLazyQueryOptions<LesionType[], number[]>
): useParsedLazyQueryReturnType<number[], LesionType[], Variables> {
  return useParsedLazyQuery<number[], LesionType[], Data, Variables>({
    query: QUERY,
    options,
    parseVariables: (study_ids: number[]) => ({ study_ids }),
    parseData,
  });
}

function parseData(data: Data): LesionType[] {
  return data.lesion.map(
    ({ name, rois: roi_fragments, anatomical_structure }) => {
      const rois = roi_fragments.map(
        ({ roi_attribute: classification, ellipse, contours }) => {
          return { classification, contours, ellipse };
        }
      );
      return {
        name,
        location: anatomical_structure,
        rois,
      };
    }
  );
}
