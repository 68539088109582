import { TumourResponseType } from "../../types/TumourResponseType";
import { RawTumourResponse } from "../../rawTypes/RawTumourResponse";

export function parseTumourResponsesFromQueryResult(
  rawTumourResponse: RawTumourResponse
): TumourResponseType {
  const {
    tumour_response: { classification: classificationStr },
  } = rawTumourResponse;
  return (classificationStr as TumourResponseType) ?? null;
}
