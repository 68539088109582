import React, { FC } from "react";
import { Card } from "../../../common/components/cards/Card";
import { KaplanChartContainer } from "./KaplanChartContainer";
import { GraphCardProps } from "../../common/utils/GraphCardProps";

export const KaplanMeierCurveCard: FC<GraphCardProps> = ({
  onClickHeader,
}: GraphCardProps) => {
  return (
    <Card
      title={"Kaplan Meier"}
      content={<KaplanChartContainer />}
      onClickHeader={onClickHeader}
      downloadCSV={true}
    />
  );
};
