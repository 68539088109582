import { createSelector } from "@reduxjs/toolkit";
import {
  getTrialViewFilters,
  trialViewFiltersActions,
} from "nota-predict-web/src/common/store/trialViewFiltersSlice";
import { capitalize } from "nota-predict-web/src/common/utils/capitalize";
import React from "react";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterButton from "./FilterButton";
import FilterCategory from "./FilterCategory";
import { ButtonWrapper } from "./FilterInputWrapper";
import { TumourHistologyType } from "../../common/types/TumourHistologyType";

const getHistologyFilters = createSelector(
  [getTrialViewFilters],
  (trialViewFilters) => trialViewFilters.histology
);

export const HistologyFilters: FC = () => {
  const dispatch = useDispatch();
  const histologyFilters = useSelector(getHistologyFilters);

  const histologyOptions = Object.keys(histologyFilters).map((key, index) => {
    const histology = key as TumourHistologyType;

    const onFilterChanged = (checked: boolean) => {
      dispatch(
        trialViewFiltersActions.setHistology({
          ...histologyFilters,
          [histology]: checked,
        })
      );
    };

    const isChecked = histologyFilters[histology];

    return (
      <FilterButton
        key={index}
        text={capitalize(histology)}
        onClick={onFilterChanged}
        active={isChecked}
        width={40}
      />
    );
  });

  const options = (
    <>
      <ButtonWrapper>{histologyOptions}</ButtonWrapper>
    </>
  );

  return <FilterCategory name={"Histology"} options={options} />;
};
