import React, { ReactElement } from "react";
import styled from "styled-components";

interface FilterNumberInputProps {
  label?: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (number: number | null) => void;
  value?: number;
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const InputLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;

  align-self: stretch;
`;

const InputLabelText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;

  display: flex;
  align-items: center;

  color: ${(props) => props.theme.colors.neutral.black};

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
`;

const StyledInputNumber = styled.input`
  position: static;
  width: 88px;
  height: 40px;
  left: 0px;
  top: 24px;

  background: #ffffff;

  border: 1px solid #858c94;
  box-sizing: border-box;
  border-radius: 8px;

  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  text-align: center;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 4px 0px;
`;

const FilterNumberInput = ({
  value,
  onChange,
  label,
}: FilterNumberInputProps): ReactElement => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    const number = value !== "" ? parseInt(value) : null;
    onChange(number);
  };

  return (
    <InputWrapper>
      <InputLabelWrapper>
        <InputLabelText>{label}</InputLabelText>
      </InputLabelWrapper>
      <StyledInputNumber
        type="number"
        value={value ?? ""}
        onChange={handleOnChange}
        name={label}
      />
    </InputWrapper>
  );
};

export default FilterNumberInput;
