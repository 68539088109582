import { SubjectType } from "../../Analysis/common/types/SubjectType";
import { FOLLOW_UP_TEXT } from "../../Analysis/common/utils/FollowUpText";
import { getFormattedMMMDDYYYY } from "./dateFormatUtils/getFormattedDate";
import { getArmNameFromId } from "../../Analysis/common/utils/getArmNameFromId";
import { getAjccStageType } from "../../Analysis/common/types/AjccStageType";
import { sortFollowUps } from "../../Analysis/common/utils/sortFollowUps";
import { getFollowUpSurvivalPrediction } from "../../Analysis/common/utils/getFollowUpSurvivalPrediction";
import { getFollowUpTumourBurden } from "../../Analysis/common/utils/getFollowUpTumourBurden";
import { getFollowUpTumourResponse } from "../../Analysis/common/utils/getFollowUpTumourResponse";

export default function getCsvData(subjects: SubjectType[]): string[][] {
  const naString = "N/A";
  const csvData: string[][] = [];
  csvData.push([
    "Subject ID",
    "Date of Birth",
    "Date of Death",
    "Randomization Date",
    "Sex",
    "Smoking Status",
    "Trial Arm",
    "Mutation",
    "Histology",
    "AJCC Stage",
    "ECOG",
    `${FOLLOW_UP_TEXT} #`,
    `${FOLLOW_UP_TEXT} Date`,
    "Predicted Survival (Months)",
    "Tumor Burden (mm)",
    "Tumor Response Classification",
  ]);
  for (const subject of subjects) {
    const {
      subjectId,
      dateOfBirth,
      deathDate,
      randomizationDate,
      followUps,
      sex,
      smokingStatus,
      trialArm: { number: trialArmNumber },
      diagnosis: { ajccStage, histology, mutation },
      ecogPerformanceStatus,
    } = subject;

    const formattedDateOfBirth = dateOfBirth
      ? getFormattedMMMDDYYYY(dateOfBirth)
      : naString;
    const formattedDeathDate = deathDate
      ? getFormattedMMMDDYYYY(deathDate)
      : naString;
    const formattedRandomizationDate = randomizationDate
      ? getFormattedMMMDDYYYY(randomizationDate)
      : naString;

    const formattedSex = sex ?? naString;
    const formattedSmokingStatus = smokingStatus ?? naString;

    const trialArm = getArmNameFromId(trialArmNumber);

    const formattedAjccStage = ajccStage
      ? getAjccStageType(ajccStage)
      : naString;
    const formattedHistology = histology ?? naString;
    const formattedMutation = mutation ?? naString;
    const formattedEcog = ecogPerformanceStatus
      ? ecogPerformanceStatus.toString()
      : naString;

    const sortedFollowUps = sortFollowUps(followUps, true);
    for (const followUp of sortedFollowUps) {
      const { order, date } = followUp;
      const survivalPrediction = getFollowUpSurvivalPrediction(followUp);
      const formattedPrediction = survivalPrediction
        ? survivalPrediction.toString()
        : naString;
      const burden = getFollowUpTumourBurden(followUp);
      const formattedBurden = burden ? burden.toString() : naString;
      const response = getFollowUpTumourResponse(followUp);
      const formattedResponse = response ?? naString;
      const formattedDate = date ? getFormattedMMMDDYYYY(date) : naString;

      csvData.push([
        subjectId,
        formattedDateOfBirth,
        formattedDeathDate,
        formattedRandomizationDate,
        formattedSex,
        formattedSmokingStatus,
        trialArm,
        formattedMutation,
        formattedHistology,
        formattedAjccStage,
        formattedEcog,
        order.toString(),
        formattedDate,
        formattedPrediction,
        formattedBurden,
        formattedResponse,
      ]);
    }
  }
  return csvData;
}
