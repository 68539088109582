import {
  caseProgressDisplayNames,
  CaseProgressType,
} from "../../types/CaseProgressType";
import {
  actionTypeDisplayNames,
  ActionTypeType,
} from "../../types/ActionTypeType";
import { matchSorter } from "match-sorter";

export type WorklistTableRowType = {
  caseId: number;
  name: string;
  progress: CaseProgressType;
  actionType: ActionTypeType;
  url: string;
};

export function filterWorklistRow(
  row: WorklistTableRowType,
  query: string
): boolean {
  const { caseId, name, progress, actionType } = row;

  return [
    caseId,
    name,
    caseProgressDisplayNames[progress],
    actionTypeDisplayNames[actionType],
  ]
    .filter((field) => field)
    .some((field) => matchSorter([field], query).length > 0);
}
