import { gql } from "@apollo/client/core";
import { AnatomicalStructureType } from "../../Analysis/common/types/AnatomicalStructureType";
import { SeriesType } from "../../Analysis/common/types/SeriesType";
import { useParsedMutation } from "../../common/queries/utils/useParsedMutation";
import { useParsedMutationReturnType } from "../../common/queries/utils/useParsedMutationReturnType";
import { ADD, REMOVE, UpdateModeType } from "../../common/types/UpdateModeType";
import { CaseProgressType, PENDING } from "../types/CaseProgressType";
import { ActionTypeType, CONTOUR_ORGAN } from "../types/ActionTypeType";
import { UserType } from "../../common/types/UserType";

const MUTATION = gql`
  mutation InsertCases(
    $cases: [cases_insert_input!]!
    $series_required_anatomical_structures: [series_required_anatomical_structure_insert_input!]!
    $delete_series_ids: [Int!]!
    $delete_anatomical_structure_ids: [Int!]!
    $delete_case_assignment_user_ids: [Int!]!
  ) {
    insert_cases(
      objects: $cases
      on_conflict: { constraint: cases_name_key, update_columns: [name] }
    ) {
      affected_rows
    }
    delete_series_required_anatomical_structure(
      where: {
        anatomical_structure_id: { _in: $delete_anatomical_structure_ids }
        series_id: { _in: $delete_series_ids }
      }
    ) {
      affected_rows
    }
    delete_case_assignment(
      where: {
        user_id: { _in: $delete_case_assignment_user_ids }
        case: { series_cases: { series_id: { _in: $delete_series_ids } } }
      }
    ) {
      affected_rows
    }
    insert_series_required_anatomical_structure(
      objects: $series_required_anatomical_structures
      on_conflict: {
        constraint: series_required_anatomical_structure_pkey
        update_columns: []
      }
    ) {
      affected_rows
    }
  }
`;

type RawSeriesRequiredAnatomicalStructureVariables = {
  anatomical_structure_id: number;
  series_id: number;
};

type RawCasesVariables = {
  name: string;
  series_cases: {
    data: {
      series_id: number;
    }[];
    on_conflict: {
      constraint: string;
      update_columns: string[];
    };
  };
  case_instances: {
    data: {
      action_type: string;
      enabled: boolean;
      priority: string;
    }[];
    on_conflict: {
      constraint: string;
      update_columns: string[];
    };
  };
  case_assignments: {
    data: {
      user_id: number;
      progress: CaseProgressType;
      action_type: ActionTypeType;
    }[];
    on_conflict: {
      constraint: string;
      update_columns: string[];
    };
  };
};

type Variables = {
  cases: RawCasesVariables[];
  series_required_anatomical_structures: RawSeriesRequiredAnatomicalStructureVariables[];
  delete_series_ids: number[];
  delete_anatomical_structure_ids: number[];
  delete_case_assignment_user_ids: number[];
};

type Data = {
  insert_cases: { affected_rows: number };
  delete_series_required_anatomical_structure: { affected_rows: number };
  delete_case_assignment: { affected_rows: number };
  insert_series_required_anatomical_structure: { affected_rows: number };
};

type Input = {
  anatomicalStructures: AnatomicalStructureType[];
  users: UserType[];
  studySeries: SeriesType[][];
  mode: UpdateModeType;
};

export function useInsertCases(): useParsedMutationReturnType<
  Input,
  undefined
> {
  return useParsedMutation<Input, undefined, Data, Variables>({
    mutation: MUTATION,
    parseVariables,
    parseData: () => undefined,
  });
}

function parseVariables({
  anatomicalStructures,
  users,
  studySeries,
  mode,
}: Input): Variables {
  const series_required_anatomical_structures: RawSeriesRequiredAnatomicalStructureVariables[] = [];
  const cases: RawCasesVariables[] = [];

  const userIds = users.map(({ id }) => id);

  for (const series of studySeries) {
    const seriesIds = series.map(({ id }) => id);
    for (const series_id of seriesIds) {
      for (const { id: anatomical_structure_id } of anatomicalStructures) {
        series_required_anatomical_structures.push({
          series_id,
          anatomical_structure_id,
        });
      }
    }

    cases.push({
      name: `case_${seriesIds.join("_")}`,
      case_instances: {
        data: [
          {
            action_type: CONTOUR_ORGAN,
            enabled: true,
            priority: "MEDIUM",
          },
        ],
        on_conflict: {
          constraint: "action_type_case_id_unique",
          update_columns: ["enabled", "priority"],
        },
      },
      series_cases: {
        data: seriesIds.map((series_id) => ({ series_id })),
        on_conflict: {
          constraint: "series_case_pkey",
          update_columns: [],
        },
      },
      case_assignments: {
        data: userIds.map((id) => {
          return {
            user_id: id,
            action_type: "CONTOUR_ORGAN",
            progress: PENDING,
          };
        }),
        on_conflict: {
          constraint: "action_type_case_id_user_id_unique",
          update_columns: [],
        },
      },
    });
  }

  const delete_series_ids = studySeries
    .flatMap((seriesList) => seriesList)
    .map(({ id }) => id);

  const delete_anatomical_structure_ids = anatomicalStructures.map(
    ({ id }) => id
  );

  return {
    series_required_anatomical_structures:
      mode === ADD ? series_required_anatomical_structures : [],
    cases: mode === ADD ? cases : [],
    delete_series_ids: mode === REMOVE ? delete_series_ids : [],
    delete_anatomical_structure_ids:
      mode === REMOVE ? delete_anatomical_structure_ids : [],
    delete_case_assignment_user_ids: mode === REMOVE ? userIds : [],
  };
}
