import React from "react";
import styled from "styled-components";

const StyledTextInput = styled.input`
  border: 1px solid #dadee3;
  padding: 12px 40px;
  border-radius: 8px;
  background-image: url("data:image/svg+xml, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M15.5%2014h-.79l-.28-.27C15.41%2012.59%2016%2011.11%2016%209.5%2016%205.91%2013.09%203%209.5%203S3%205.91%203%209.5%205.91%2016%209.5%2016c1.61%200%203.09-.59%204.23-1.57l.27.28v.79l5%204.99L20.49%2019l-4.99-5zm-6%200C7.01%2014%205%2011.99%205%209.5S7.01%205%209.5%205%2014%207.01%2014%209.5%2011.99%2014%209.5%2014z%22%20fill%3D%22%236D7580%22%2F%3E%3C%2Fsvg%3E");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 13px;
`;

function SearchTextField(
  props: React.InputHTMLAttributes<HTMLInputElement>
): React.ReactElement<React.InputHTMLAttributes<HTMLInputElement>> {
  return <StyledTextInput type="text" placeholder={"Search"} {...props} />;
}
export default SearchTextField;
