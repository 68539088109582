import { UserType } from "../../types/UserType";
import { createUsers } from "./createUsers";
import { DEFAULT_TENANT_ID } from "./DefaultTenantId";
import { annotateClient } from "../../../apollo/client";

export async function createNewUsers(
  newUsers: UserType[]
): Promise<UserType[]> {
  const usersToBeCreated = newUsers.filter(({ id }) => id === 0);
  const existingUsers = newUsers.filter(({ id }) => id !== 0);

  const createdUsers =
    usersToBeCreated.length > 0
      ? await createUsers(usersToBeCreated, DEFAULT_TENANT_ID, annotateClient)
      : [];

  return [...createdUsers, ...existingUsers];
}
