import { RootState } from "../rootReducer";
import { TrialFilterSelectors } from "./TrialFilterSelectors";
import { EcogFilters } from "../../types/TrialViewFilterTypes";

export const defaultEcogState = {
  "0": true,
  "1": true,
};

export const clearEcogState = {
  "0": false,
  "1": false,
};
export const ecogFilters = new TrialFilterSelectors<EcogFilters>(
  defaultEcogState,
  clearEcogState,
  (state: RootState) => state.trialViewFilters.ecog
);
