import React from "react";
import {
  useDialog,
  useDialogReturnType,
} from "../../../common/components/Dialog/useDialog";
import { formatFollowUp } from "../../common/utils/formatFollowUp";
import styled from "styled-components";
import MortalityDistributionChartContainer from "../../MortalityDistribution/components/MortalityDistributionChartContainer";

const Wrapper = styled.div`
  width: 80vw;
  height: 80vh;
`;

export function useMortalityDistributionChartDialog(
  selectedFollowUp: number | null
): useDialogReturnType {
  const label = `Survival Distribution at ${formatFollowUp(
    selectedFollowUp || 0
  )}`;

  const content =
    selectedFollowUp !== null ? (
      <Wrapper>
        <MortalityDistributionChartContainer followUp={selectedFollowUp} />
      </Wrapper>
    ) : undefined;

  return useDialog({
    label,
    content,
    options: {
      showCloseButton: true,
    },
  });
}
