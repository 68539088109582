import React, { ReactElement } from "react";
import AllTrial from "./AllTrial";
import ItemSubtext2 from "../../common/components/text/ItemSubtext2";
import styled from "styled-components";
import { TrialType } from "../../common/types/TrialType";
interface AllTrialsProps {
  allTrials: TrialType[];
}
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-left: 16px;
  padding-right: 16px;
`;
const CommonHeaderDiv = styled.div`
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  color: #858c94;
  padding-bottom: 16px;
`;
const StyledTrialNameHeader = styled(CommonHeaderDiv)`
  flex-basis: 20%;
`;
const StyledIndicationHeader = styled(CommonHeaderDiv)`
  flex-basis: 20%;
`;
const StyledInvestigationHeader = styled(CommonHeaderDiv)`
  flex-basis: 20%;
`;
const StyledStatusHeader = styled(CommonHeaderDiv)`
  flex-basis: 15%;
`;
const StyledCompletionHeader = styled(CommonHeaderDiv)`
  flex-basis: 20%;
`;

const AllTrials = ({ allTrials }: AllTrialsProps): ReactElement => {
  return (
    <Wrapper>
      <StyledDiv>
        <StyledTrialNameHeader>
          <ItemSubtext2 text={"Trial name"} />
        </StyledTrialNameHeader>
        <StyledIndicationHeader>
          <ItemSubtext2 text={"Indication Name"} />
        </StyledIndicationHeader>
        <StyledInvestigationHeader>
          <ItemSubtext2 text={"Investigation Product Name"} />
        </StyledInvestigationHeader>
        <StyledStatusHeader>
          <ItemSubtext2 text={"Status"} />
        </StyledStatusHeader>
        <StyledCompletionHeader>
          <ItemSubtext2 text={"Estimated Completion"} />
        </StyledCompletionHeader>
      </StyledDiv>
      {allTrials.map(
        ({
          id,
          name,
          status,
          estimatedCompletion,
          indicationName,
          investigationName,
          sites,
          groupIds,
        }) => {
          return (
            <AllTrial
              id={id}
              key={id}
              name={name}
              status={status}
              estimatedCompletion={estimatedCompletion}
              indicationName={indicationName}
              investigationName={investigationName}
              sites={sites}
              groupIds={groupIds}
            />
          );
        }
      )}
    </Wrapper>
  );
};
export default AllTrials;
