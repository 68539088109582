import React, { FC } from "react";
import { RadiologyPanelContainer } from "./Radiology/components/RadiologyPanelContainer";
import { TrialContent } from "../Trial/TrialContent";
import { TopBar } from "../common/components/TopBar/TopBar";
import { Card } from "../common/components/cards/Card";
import { KaplanChartContainer } from "../Analysis/KaplanMeier/components/KaplanChartContainer";
import { useGoToTrialViewPage } from "../Trial/hooks/useGoToTrialViewPage";
import { ANALYSIS, DATA_MANAGEMENT } from "../common/routes/routePaths";
import { TrialDetailsCard } from "../common/components/TrialDetails/TrialDetailsCard";
import { OverviewIcon } from "../common/components/icons/OverviewIcon";
import { TitleTextWIcon } from "../common/components/TopBar/TitleTextWIcon";
import { EnrolmentCard } from "../DataManagement/EnrolmentChart/components/EnrolmentCard";
import DownloadReportButton from "../common/components/buttons/DownloadReportButton";

const OverviewTopBar: FC = () => {
  return (
    <TopBar
      titleContent={<TitleTextWIcon icon={<OverviewIcon />} title="Overview" />}
      buttons={[<DownloadReportButton text={"Download Report"} key={0} />]}
    />
  );
};

const Content: FC = () => {
  const { goToPage } = useGoToTrialViewPage();

  const handleClickDataManagement = () => {
    goToPage(DATA_MANAGEMENT);
  };

  const handleClickAnalysis = () => {
    goToPage(ANALYSIS);
  };

  return (
    <>
      <TrialDetailsCard />
      <EnrolmentCard title={"Data"} onClickHeader={handleClickDataManagement} />
      <Card
        title={"Analysis"}
        content={<KaplanChartContainer />}
        onClickHeader={handleClickAnalysis}
      />
      <Card title={"Radiology"} content={<RadiologyPanelContainer />} />
    </>
  );
};

export const Overview: FC = () => {
  return <TrialContent topBar={<OverviewTopBar />} mainContent={<Content />} />;
};
