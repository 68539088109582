import React, { FC } from "react";
import styled from "styled-components";
import TableHeaderText from "./text/TableHeaderText";
import { RadiologyCasesSummaryType } from "../../Overview/Radiology/utils/parseRadiologyCaseStatusTableData";
import { formatDecimalPercentage } from "../../Analysis/common/utils/formatDecimalPercentage";

export const TableWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.background.secondary};
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border: none;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  th {
    border: none;
    text-align: right;
    padding: 0 0 20px 0;
    :first-child {
      text-align: left;
    }
  }

  td {
    margin: 0;
    padding: 16px 0;
    text-align: right;
    box-shadow: inset 0px -1px 0px ${(props) => props.theme.colors.neutral.neutral7};
    font-size: 13px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.actionPrimary.default};
    :first-child {
      text-align: left;
    }
  }
`;

interface RadiologyCaseStatusTableProps {
  data: RadiologyCasesSummaryType[];
}

const RadiologyCaseStatusTable: FC<RadiologyCaseStatusTableProps> = ({
  data,
}: RadiologyCaseStatusTableProps) => {
  return (
    <TableWrapper>
      <StyledTable>
        <tbody>
          <tr>
            <TableHeaderText text={"Case Status (active) "} />
            <TableHeaderText text={"This Week"} />
            <TableHeaderText text={"Overall"} />
          </tr>
          {data.map((dataPerType, index) => {
            const {
              type,
              weekTotal,
              allTimeTotal,
              allCasesTotal,
              allCasesThisWeek,
            } = dataPerType;
            const thisWeekPercent =
              allCasesThisWeek !== 0 ? weekTotal / allCasesThisWeek : 0;
            const allCasesPercent =
              allCasesTotal !== 0 ? allTimeTotal / allCasesTotal : 0;

            const thisWeekText = `${weekTotal} (${formatDecimalPercentage(
              thisWeekPercent
            )})`;
            const overallText = `${allTimeTotal} (${formatDecimalPercentage(
              allCasesPercent
            )})`;

            return (
              <tr key={index}>
                <td>{type}</td>
                <td>{thisWeekText}</td>
                <td>{overallText}</td>
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    </TableWrapper>
  );
};

export default RadiologyCaseStatusTable;
