import { gql, useQuery } from "@apollo/client";
import { parseSubjectFromQueryResult } from "../utils/rawDataParsers/parseSubjectFromQueryResult";
import { SubjectQueryResultType } from "../types/SubjectQueryResultType";
import { RawSubjectType } from "../rawTypes/RawSubjectType";
import { ANATOMICAL_STRUCTURES_FRAGMENT } from "../../../Annotate/components/AnatomicalStructuresFragment";
import { useMemo } from "react";
import { CASE_ASSIGNMENT_WITH_USER_FRAGMENT } from "../../../Annotate/types/CaseAssignmentWithUserFragment";
import { CASE_INSTANCE_FRAGMENT } from "../../../Annotate/types/CaseInstanceFragment";

export const SUBJECT_FRAGMENT = gql`
  fragment Subject on patient {
    patient_id
    patient_dicom_id
    enrolments {
      randomization_date
    }
    patient_demographic {
      bmi
      dob
      ecog_performance_status
      ethnicity
      sex
    }
    patient_history {
      smoking_status
      death_date
    }
    patient_trial_arms {
      trial_arm {
        name
        arm_num
      }
    }
    follow_ups(order_by: { follow_up_order: desc }) {
      diagnoses {
        diagnosis_date
        stages {
          ajcc_stage
          m_stage
          n_stage
          t_stage
        }
        diagnosis_histology_maps {
          histology {
            result
          }
        }
        treatments {
          start_date
        }
        tumours {
          id
          tumour_mutations {
            mutation
          }
        }
      }
      follow_up_date
      follow_up_order
      id
      study_follow_up_maps {
        study {
          site_id
          study_id
          study_dicom_id
          study_date
          survival_prediction_jobs: study_celery_job_maps(
            order_by: { celery_job: { last_modified: desc } }
            where: { celery_job: { name: { _eq: SURVIVAL_PREDICTION } } }
            limit: 1
          ) {
            celery_job {
              id
              status
              last_modified
              created_at
            }
          }
          upload_jobs: study_celery_job_maps(
            order_by: { celery_job: { last_modified: desc } }
            where: { celery_job: { name: { _eq: DICOM_UPLOAD } } }
            limit: 1
          ) {
            celery_job {
              id
              status
              last_modified
              created_at
            }
          }
          series {
            series_instance_uid
            series_id
            modality
            survival_predictions {
              survival
            }
            series_required_anatomical_structures(
              distinct_on: anatomical_structure_id
            ) {
              anatomical_structure {
                ...AnatomicalStructure
              }
            }
            series_tumour_burden_maps {
              tumour_burden {
                burden
                tumour_id
                id
              }
            }
            series_tumour_response_maps {
              tumour_response {
                classification
                id
              }
            }
            series_cases(distinct_on: case_id) {
              case_id
              case {
                case_assignments(distinct_on: user_id) {
                  ...CaseAssignmentWithUser
                }
                case_instances {
                  ...CaseInstance
                }
              }
            }
          }
        }
      }
    }
    temp_demo_patients {
      patient_id
    }
    temp_demo_fixed_patients {
      patient_id
    }
    studies {
      series {
        series_cases {
          case_id
        }
      }
    }
  }
  ${ANATOMICAL_STRUCTURES_FRAGMENT}
  ${CASE_ASSIGNMENT_WITH_USER_FRAGMENT}
  ${CASE_INSTANCE_FRAGMENT}
`;

export const GET_SUBJECTS_QUERY = gql`
  query getSubjectData($trial_id: Int!) {
    patient(
      where: {
        patient_trial_arms: { trial_arm: { trial_id: { _eq: $trial_id } } }
      }
      order_by: {
        studies_aggregate: { max: { last_modified: desc_nulls_last } }
      }
    ) {
      ...Subject
    }
    temp_nota_annotation_page_url_base {
      url
    }
  }
  ${SUBJECT_FRAGMENT}
`;

export type RawSubjectsQueryType = {
  patient: RawSubjectType[];
  temp_nota_annotation_page_url_base: { url: string }[];
};

export type useSubjectsQueryVariables = { trial_id: number };

export function useSubjectQuery(trialId: number): SubjectQueryResultType {
  const { data, loading, error, refetch } = useQuery<
    RawSubjectsQueryType,
    useSubjectsQueryVariables
  >(GET_SUBJECTS_QUERY, {
    variables: { trial_id: trialId },
    pollInterval: 500,
  });

  const { patient: rawSubjects, temp_nota_annotation_page_url_base } = data || {
    patient: [],
    temp_nota_annotation_page_url_base: [],
  };
  const subjects = useMemo(() => {
    return rawSubjects.map((subject) =>
      parseSubjectFromQueryResult(subject, temp_nota_annotation_page_url_base)
    );
  }, [data, loading, error]);

  return {
    subjects,
    loading,
    error,
    refetch,
  };
}
