import { main } from "../../../common/theme/main";

export const ARM_0_COLOR = main.colors.accent.accent1;
export const ARM_1_COLOR = main.colors.accent.accent2;

export const ARM_0_ALT_COLOR: string = main.colors.accent.accent1Light;
export const ARM_1_ALT_COLOR: string = main.colors.accent.accent2Light;

export const STROKE_WIDTH = 2;
export const ALT_STROKE_WIDTH = 2.5;
export const AREA_OPACITY = 0.2;

export const DASHED_STROKE_DASH_ARRAY = "3 3";
export const SOLID_STROKE_DASH_ARRAY = "3 0";
export const REFERENCE_LINE_STOKE = main.colors.actionPrimary.active20;
export const REFERENCE_LINE_STROKE_DASH_ARRAY = "5 5";

export const BAR_RADIUS: [number, number, number, number] = [12, 12, 12, 12];
export const BAR_BACKGROUND_FILL = "#f1f2f3";
