import React, { ReactElement } from "react";
import styled from "styled-components";

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TitleText = styled.div`
  margin-left: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: ${(props) => props.theme.colors.neutral.black};
`;

interface TitleTextWIconProps {
  icon: ReactElement;
  title: string;
}

export function TitleTextWIcon({
  icon,
  title,
}: TitleTextWIconProps): ReactElement {
  return (
    <TitleContainer>
      {icon}
      <TitleText>{title}</TitleText>
    </TitleContainer>
  );
}
