import React, { useEffect, useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import clsx from "clsx";
import StepLabel from "@material-ui/core/StepLabel";
import {
  LUNG_SEGMENTATION,
  LUNG_SURVIVAL_PREDICTION,
  PREPROCESS_IMAGES,
  SKIN_SEGMENTATION,
  SurvivalPredictionTasks,
} from "./TaskNames";
import {
  makeStyles,
  StepConnector,
  StepIconProps,
  withStyles,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { main } from "../../../common/theme/main";
import styled from "styled-components";

interface ModelTaskProgressProps {
  state: SurvivalPredictionTasks | null;
  isDone: boolean | undefined;
}

type TaskStep = {
  task: SurvivalPredictionTasks;
  step: number;
  label: string;
};

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: main.colors.states.success,
    },
  },
  completed: {
    "& $line": {
      borderColor: main.colors.states.success,
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    width: "100%",
    alignItems: "center",
  },
  active: {
    color: main.colors.states.success,
  },
  circle: {
    width: 14,
    height: 14,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: main.colors.states.success,
    zIndex: 1,
    fontSize: 24,
  },
});

function CustomIcon(props: StepIconProps) {
  const classes = useStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

export function ModelTaskProgress({
  state,
  isDone,
}: ModelTaskProgressProps): JSX.Element {
  const [activeStep, setActiveStep] = useState<number>(-1);

  useEffect(() => {
    if (isDone) {
      setActiveStep(taskStepMapping.length);
      return;
    }

    if (isDone === undefined) {
      setActiveStep(-1);
      return;
    }

    const activeStep = taskStepMapping.find(
      (taskStep) => taskStep.task === state
    );
    setActiveStep(activeStep?.step ?? -1);
  }, [state, isDone]);

  return (
    <Wrapper>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<CustomConnector />}
      >
        {taskStepMapping.map((taskStep) => {
          const { label } = taskStep;
          return (
            <Step key={label}>
              <StepLabel StepIconComponent={CustomIcon}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Wrapper>
  );
}

const taskStepMapping: TaskStep[] = [
  {
    task: PREPROCESS_IMAGES,
    step: 0,
    label: "Preprocessing Images",
  },
  {
    task: SKIN_SEGMENTATION,
    step: 1,
    label: "Body Segmentation",
  },
  {
    task: LUNG_SEGMENTATION,
    step: 2,
    label: "Organ Segmentation",
  },
  {
    task: LUNG_SURVIVAL_PREDICTION,
    step: 3,
    label: "Survival Prediction",
  },
];
