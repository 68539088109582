import React from "react";
import { SvgIcon } from "../icons/SvgIcon";
import { ReactComponent as AddIcon } from "../../../assets/svgs/PlusOutlineFilled.svg";
import { UserRow } from "./UserRow";
import styled from "styled-components";
import CTAButton from "../buttons/CTAButton";
import { useCreateUserDialog } from "./Dialogs/useCreateUserDialog";
import { DialogContentInnerWrapper } from "../Dialog/DialogContentInnerWrapper";
import { UserType } from "../../types/UserType";
import { UserActionCallbacksType } from "./UserActionCallbacksType";

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ActionButton = styled(CTAButton)`
  width: 114px;
`;

export interface EditTrialUsersProps {
  users: UserType[];
  actionCallbacks: UserActionCallbacksType;
  allowEditUser: boolean;
  allowRemoveUser: boolean;
  allowCreateUser: boolean;
}

export function EditTrialUsers({
  users,
  actionCallbacks,
  allowEditUser,
  allowRemoveUser,
  allowCreateUser,
}: EditTrialUsersProps): JSX.Element {
  const { onCreated } = actionCallbacks ?? {
    onCreated: undefined,
  };

  if (!onCreated) {
    throw new Error("onCreated not defined in EditTrialUsers");
  }

  const handleConfirmCreateUsers = (users: UserType[]) => {
    onCreated(users);
  };

  const [
    setCreateUserDialogOpen,
    { dialog: createUserDialog },
  ] = useCreateUserDialog(users, handleConfirmCreateUsers);

  const handleClickCreateUser = () => {
    if (!allowCreateUser) {
      throw new Error(
        "handleClickCreateUser called by allowCreateUser is false"
      );
    }

    setCreateUserDialogOpen(true);
  };

  return (
    <>
      {createUserDialog}
      <DialogContentInnerWrapper>
        {allowCreateUser && (
          <ActionButtonWrapper>
            <ActionButton
              icon={<SvgIcon icon={AddIcon} size={16} />}
              text={"Add Team Member"}
              onClick={handleClickCreateUser}
            />
          </ActionButtonWrapper>
        )}
        <ListWrapper>
          {users.map((user, index) => (
            <UserRow
              user={user}
              key={index}
              actionCallbacks={actionCallbacks}
              allowRemoveUser={allowRemoveUser}
              allowEditUser={allowEditUser}
            />
          ))}
        </ListWrapper>
      </DialogContentInnerWrapper>
    </>
  );
}
