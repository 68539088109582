import React from "react";

type SiteManagementContextType = {
  allowCreateSite: boolean;
  allowRemoveSite: boolean;
  allowEditSite: boolean;
};

export const viewTrialSiteContext: SiteManagementContextType = {
  allowCreateSite: false,
  allowRemoveSite: false,
  allowEditSite: false,
};

export const manageTrialSiteContext: SiteManagementContextType = {
  allowCreateSite: true,
  allowRemoveSite: true,
  allowEditSite: true,
};

export const SiteManagementContext = React.createContext<SiteManagementContextType>(
  viewTrialSiteContext
);
