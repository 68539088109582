import { createSelector } from "@reduxjs/toolkit";
import React from "react";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterButton from "./FilterButton";
import FilterCategory from "./FilterCategory";
import { ButtonWrapper } from "./FilterInputWrapper";
import {
  getTrialViewFilters,
  trialViewFiltersActions,
} from "../../../common/store/trialViewFiltersSlice";
import { capitalize } from "../../../common/utils/capitalize";

const getEcogFilters = createSelector(
  [getTrialViewFilters],
  (trialViewFilters) => trialViewFilters.ecog
);

export const EcogFilters: FC = () => {
  const dispatch = useDispatch();
  const ecogFilters = useSelector(getEcogFilters);

  const ecogOptions = Object.keys(ecogFilters).map((filter, index) => {
    const ecog = parseInt(filter);

    const updateEcogFilters = (checked: boolean) => {
      dispatch(trialViewFiltersActions.toggleEcog(ecog));
    };

    const isChecked = ecogFilters[ecog];

    return (
      <FilterButton
        key={index}
        text={capitalize(filter)}
        onClick={updateEcogFilters}
        active={isChecked}
        width={50}
      />
    );
  });

  const options = (
    <>
      <ButtonWrapper>{ecogOptions}</ButtonWrapper>
    </>
  );

  return <FilterCategory name={"ECOG"} options={options} />;
};
