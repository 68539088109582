import { createSelector } from "@reduxjs/toolkit";
import React, { ReactElement } from "react";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterCategory from "./FilterCategory";
import FilterCheckbox from "./FilterCheckbox";
import { formatFollowUp } from "../../common/utils/formatFollowUp";
import {
  getTrialViewFilters,
  trialViewFiltersActions,
} from "../../../common/store/trialViewFiltersSlice";

export const selectTimepointsFilters = createSelector(
  [getTrialViewFilters],
  (trialViewFilters) => trialViewFilters.timepoints
);

export const TimepointsFilters: FC = () => {
  const dispatch = useDispatch();
  const timepointsFilters = useSelector(selectTimepointsFilters);

  const components: ReactElement[] = [];
  for (const key in timepointsFilters) {
    if (!Object.prototype.hasOwnProperty.call(timepointsFilters, key)) {
      continue;
    }

    const isChecked = timepointsFilters[key];
    const timepoint = parseInt(key);

    const onFilterChanged = (checked: boolean) => {
      dispatch(
        trialViewFiltersActions.setTimepoints({
          ...timepointsFilters,
          [timepoint]: checked,
        })
      );
    };

    components.push(
      <FilterCheckbox
        key={timepoint}
        label={formatFollowUp(timepoint)}
        checked={isChecked}
        onChange={onFilterChanged}
        name={timepoint.toString()}
      />
    );
  }

  return <FilterCategory name={"Timepoints"} options={components} />;
};
