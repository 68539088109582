import { createSelector } from "@reduxjs/toolkit";
import React from "react";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterSubtext from "../FilterSubtext";
import { NumberInputWrapper } from "../FilterInputWrapper";
import FilterNumberInput from "../FilterNumberInput";
import {
  getDemographicsFilters,
  trialViewFiltersActions,
} from "../../../../common/store/trialViewFiltersSlice";
import { capitalize } from "../../../../common/utils/capitalize";

const getAgeFilters = createSelector(
  [getDemographicsFilters],
  (demographicsFilters) => demographicsFilters.age
);

export const AgeFilters: FC = () => {
  const dispatch = useDispatch();
  const ageFilters = useSelector(getAgeFilters);

  const ageOptions = Object.entries(ageFilters).map(
    ([filter, value], index) => {
      const updateAgeFilters = (number: number | null) => {
        dispatch(
          trialViewFiltersActions.setAge({
            ...ageFilters,
            [filter]: number,
          })
        );
      };
      return (
        <FilterNumberInput
          key={index}
          label={capitalize(filter)}
          onChange={updateAgeFilters}
          value={value}
        />
      );
    }
  );

  const options = (
    <>
      <NumberInputWrapper>{ageOptions}</NumberInputWrapper>
    </>
  );

  return (
    <>
      <FilterSubtext text="Age" />
      {options}
    </>
  );
};
