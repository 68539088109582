import React from "react";
import { FC } from "react";
import BookIcon from "@material-ui/icons/Book";
import styled from "styled-components";

const Styled = styled(BookIcon)`
  color: ${(props) => props.theme.colors.primary.inverted};
`;

export const LogbookIcon: FC = () => <Styled />;
