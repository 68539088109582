import dayjs from "dayjs";
import { DEFAULT_TODAY_DATE } from "./DateConstants";

export function checkWithinWeekForDemo(date: string): boolean {
  return checkWithinWeek(date, DEFAULT_TODAY_DATE);
}

export function checkWithinWeek(dateA: string, dateB: string): boolean {
  return Math.abs(dayjs(dateA).diff(dayjs(dateB), "day")) <= 7;
}
