import React, { ReactElement } from "react";
import DisplayRadioButton from "../../common/components/DisplayRadioButton";
import styled from "styled-components";
import { TUMOR_SPELLING } from "../../../common/utils/spellingConstants";

export const RECIST = "RECIST";
export const IPRO = "IPRO";
export const BOTH = "BOTH";

const DISPLAY_NAMES = {
  RECIST: TUMOR_SPELLING + " Burden",
  IPRO: "Survival",
  BOTH: "Both",
};

export type FilterType = typeof RECIST | typeof IPRO | typeof BOTH;

interface FilterProps {
  activeFilter: string;
  // eslint-disable-next-line no-unused-vars
  onFilterSelected: (filter: FilterType) => void;
}

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 48px;
`;

export function Filter({
  onFilterSelected,
  activeFilter,
}: FilterProps): ReactElement {
  return (
    <Wrapper>
      {[RECIST, IPRO, BOTH].map((filter) => {
        return (
          <DisplayRadioButton
            active={activeFilter == filter}
            key={filter}
            text={DISPLAY_NAMES[filter as keyof typeof DISPLAY_NAMES]}
            onClick={() => onFilterSelected(filter as FilterType)}
          />
        );
      })}
    </Wrapper>
  );
}
