import React from "react";
import { FC } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import styled from "styled-components";

const Styled = styled(MoreHorizIcon)`
  color: ${(props) => props.theme.colors.primary.primary50};
`;

export const EllipsisIcon: FC = () => <Styled />;
