import React, { useContext } from "react";
import { SiteType } from "../../types/SiteType";
import { SiteActionCallbacksType } from "./SiteActionCallbacksType";
import { EditTrialSites } from "./EditTrialSites";
import { SiteManagementContext } from "./SiteManagementContext";

export interface ContextAwareEditTrialSitesProps {
  sites: SiteType[];
  actionCallbacks: SiteActionCallbacksType;
}

export function ContextAwareEditTrialSites({
  sites,
  actionCallbacks,
}: ContextAwareEditTrialSitesProps): JSX.Element {
  const { allowRemoveSite, allowEditSite, allowCreateSite } = useContext(
    SiteManagementContext
  );

  return (
    <EditTrialSites
      sites={sites}
      actionCallbacks={actionCallbacks}
      allowRemoveSite={allowRemoveSite}
      allowEditSite={allowEditSite}
      allowCreateSite={allowCreateSite}
    />
  );
}
