import React, { MouseEventHandler } from "react";
import PatientJourneyChart from "./PatientJourneyChart";
import { getPatientJourneySubjects } from "../utils/parsePatientJourneyData";
import { useTrialId } from "../../../Trial/hooks/useTrialId";
import Loading from "../../../common/components/Loading";
import handleApolloError from "../../../common/utils/handleApolloError";
import { useTumorClassificationFilteredSubjectsQuery } from "../../common/hooks/useTumorClassificationFilteredSubjectsQuery";

interface AnalysisOverviewPatientJourneyProps {
  onClickAction?: MouseEventHandler;
}
function AnalysisOverviewPatientJourney({
  onClickAction,
}: AnalysisOverviewPatientJourneyProps): React.ReactElement {
  const trialId = useTrialId();
  const {
    subjects,
    loading,
    error,
  } = useTumorClassificationFilteredSubjectsQuery(trialId);

  if (loading) return <Loading />;
  if (error) handleApolloError(error);
  if (!subjects) throw new Error("Subject null with no apollo error");

  const subjectResponseProgressions = getPatientJourneySubjects(subjects);

  return (
    <PatientJourneyChart
      subjectResponseProgressions={subjectResponseProgressions}
      onClickAction={onClickAction}
    />
  );
}

export default AnalysisOverviewPatientJourney;
