import { gql } from "@apollo/client/core";
import { UserType } from "../common/types/UserType";
import { UserRoleType } from "../common/types/UserRoleType";

export const USER_FRAGMENT = gql`
  fragment UserFragment on users {
    auth0Id: auth0_user_id
    id: user_id
    firstName: first_name
    lastName: last_name
    title
    organization
    email
    avatarImageSource: picture_url
    roles {
      role
    }
  }
`;

export type UserFragmentType = Omit<UserType, "roles"> & {
  roles: { role: UserRoleType }[];
};

export function parseUserFragment(userFragment: UserFragmentType): UserType {
  const { roles: rawRoles, ...other } = userFragment;
  const roles = rawRoles.map(({ role }) => role.toUpperCase() as UserRoleType);
  return {
    ...other,
    roles,
  };
}
