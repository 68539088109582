import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AppBranding from "./AppBranding";
import SearchTextField from "./input/SearchTextField";
import SideNavButton from "./buttons/SideNavButton";
import SideNavSubButton from "./buttons/SideNavSubButton";
import { TrialSelectorContainer } from "./TrialSelector/TrialSelectorContainer";
import { useGoToTrialViewPage } from "../../Trial/hooks/useGoToTrialViewPage";
import {
  ANALYSIS,
  DATA_MANAGEMENT,
  OVERVIEW,
  DASHBOARD,
  SETTINGS,
  ANNOTATE,
} from "../routes/routePaths";
import { useRouteMatch } from "react-router";
import { ClickableDiv } from "./divs/ClickableDiv";
import styled from "styled-components";
import { OverviewIcon } from "./icons/OverviewIcon";
import { DataManagementIcon } from "./icons/DataManagementIcon";
import { AnalysisIcon } from "./icons/AnalysisIcon";
import { ReactComponent as RadiologyIcon } from "../../assets/svgs/Lungs.svg";
import { LogbookIcon } from "./icons/LogbookIcon";
import {
  ANALYSIS_OVERVIEW,
  ANALYSIS_ALGORITHM,
  ANALYSIS_KAPLAN_DETAILS,
  ANALYSIS_SURVIVAL_DETAILS,
  ANALYSIS_MORTALITY_DETAILS,
  ANALYSIS_PATIENT_JOURNEY,
} from "../../Analysis/routes/routePaths";
import {
  DATA_MANAGEMENT_OVERVIEW,
  DATA_MANAGEMENT_UPLOAD,
} from "../../DataManagement/routes/routePaths";
import { ANNOTATE_WORKLIST } from "../../Annotate/routes/routePaths";
import { getActiveMainPage, getActiveSubPage } from "../utils/getActivePage";
import { SvgIcon } from "./icons/SvgIcon";
import { main } from "../theme/main";
import { SITES, TEAM_MEMBERS } from "../../Settings/routes/routePaths";
import { ReactComponent as SettingsIcon } from "../../assets/svgs/Settings.svg";
import { CurrentUserAvatar } from "./Avatar/CurrentUserAvatar";
import { CurrentUserCan } from "./CurrentUserCan";
import { ANNOTATE as ANNOTATE_ROLE } from "../types/UserRoleType";

const StyledTop = styled.div``;

const StyledBottom = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
  > div {
    margin: 0 10px;
    :first-child {
      margin-left: 0;
      img {
        margin: 0;
        border-radius: 50%;
        width: 32px;
        height: 32px;
      }
    }
    :last-child {
      margin-right: 0;
    }
  }
`;

const StyledFlexBox = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  overflow-x: hidden;
`;
const StyledLogo = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: ${(props) => props.theme.colors.neutral.white10};
`;

const StyledSearchBar = styled(SearchTextField)`
  background-image: url("data:image/svg+xml, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M15.5%2014h-.79l-.28-.27C15.41%2012.59%2016%2011.11%2016%209.5%2016%205.91%2013.09%203%209.5%203S3%205.91%203%209.5%205.91%2016%209.5%2016c1.61%200%203.09-.59%204.23-1.57l.27.28v.79l5%204.99L20.49%2019l-4.99-5zm-6%200C7.01%2014%205%2011.99%205%209.5S7.01%205%209.5%205%2014%207.01%2014%209.5%2011.99%2014%209.5%2014z%22%20fill%3D%22%23FFFFFF%22%2F%3E%3C%2Fsvg%3E");
  box-sizing: border-box;
  color: white;
  outline: none;
  font-family: "Inter", verdana, arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 12px;
  width: 216px;
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.neutral.white10};
  ::placeholder {
    color: white;
  }
  :focus {
    border: 1px solid ${(props) => props.theme.colors.neutral.white};
  }
`;

const SubMenu = styled.div<{ active: boolean }>`
  transition: 0.3s all;
  display: ${(props) => (props.active ? "flex" : "none")};
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 240px;
  padding: 12px;
`;

const SideNav = (): JSX.Element => {
  const { goToPage } = useGoToTrialViewPage();
  const [mainPage, setMainPage] = useState<string>("");
  const [subPage, setSubPage] = useState<string>("");
  const [analysisSubmenuOpen, setAnalysisSubmenuOpen] = useState<boolean>(
    false
  );
  const [annotateSubmenuOpen, setAnnotateSubmenuOpen] = useState<boolean>(
    false
  );
  const [settingsSubmenuOpen, setSettingsSubmenuOpen] = useState<boolean>(
    false
  );
  const [
    dataManagementSubmenuOpen,
    setDataManagementSubmenuOpen,
  ] = useState<boolean>(false);
  const { url } = useRouteMatch();
  const { push } = useHistory();
  const location = useLocation();
  useEffect(() => {
    const mainPage = getActiveMainPage();
    const subPage = getActiveSubPage();
    setMainPage(mainPage);
    setSubPage(subPage);
    setAnalysisSubmenuOpen(mainPage === ANALYSIS);
    setDataManagementSubmenuOpen(mainPage === DATA_MANAGEMENT);
    setAnnotateSubmenuOpen(mainPage === ANNOTATE);
    setSettingsSubmenuOpen(mainPage === SETTINGS);
  }, [location]);

  const onClickOverview = () => {
    goToPage(OVERVIEW);
  };
  const onClickDataManagement = () => {
    goToPage(DATA_MANAGEMENT);
  };
  const onClickAnalysis = () => {
    goToPage(ANALYSIS);
  };
  const onClickAnnotate = () => {
    goToPage(ANNOTATE);
  };
  const onClickSettings = () => {
    goToPage(SETTINGS);
  };

  const onClickHome = () => {
    push(DASHBOARD);
  };

  const onClickAnalysisOverview = () => {
    push(`${url}/${ANALYSIS}/${ANALYSIS_OVERVIEW}`);
  };

  const onClickAnalysisAlgorithm = () => {
    push(`${url}/${ANALYSIS}/${ANALYSIS_ALGORITHM}`);
  };

  const onClickAnalysisKaplan = () => {
    push(`${url}/${ANALYSIS}/${ANALYSIS_KAPLAN_DETAILS}`);
  };

  const onClickAnalysisMedian = () => {
    push(`${url}/${ANALYSIS}/${ANALYSIS_SURVIVAL_DETAILS}`);
  };

  const onClickAnalysisMortality = () => {
    push(`${url}/${ANALYSIS}/${ANALYSIS_MORTALITY_DETAILS}`);
  };

  const onClickAnalysisPatient = () => {
    push(`${url}/${ANALYSIS}/${ANALYSIS_PATIENT_JOURNEY}`);
  };

  const onClickDataManagementOverview = () => {
    push(`${url}/${DATA_MANAGEMENT}/${DATA_MANAGEMENT_OVERVIEW}`);
  };

  const onClickDataManagementUpload = () => {
    push(`${url}/${DATA_MANAGEMENT}/${DATA_MANAGEMENT_UPLOAD}`);
  };

  const onClickAnnotateWorklist = () => {
    push(`${url}/${ANNOTATE}/${ANNOTATE_WORKLIST}`);
  };

  const onClickTeamMembers = () => {
    push(`${url}/${SETTINGS}/${TEAM_MEMBERS}`);
  };

  const onClickSites = () => {
    push(`${url}/${SETTINGS}/${SITES}`);
  };

  const isCurrentPageActive = (page: string, pageType: string) => {
    return pageType === page;
  };
  return (
    <StyledFlexBox>
      <StyledTop>
        <Row>
          <ClickableDiv onClick={onClickHome}>
            <StyledLogo>
              <AppBranding />
            </StyledLogo>
          </ClickableDiv>
          <TrialSelectorContainer />
        </Row>
        <StyledSearchBar />
        <div>
          <SideNavButton
            text="Overview"
            icon={<OverviewIcon />}
            onClick={onClickOverview}
            active={isCurrentPageActive(OVERVIEW, mainPage)}
            disabled={false}
          />
        </div>
        <div>
          <SideNavButton
            text="Data"
            icon={<DataManagementIcon />}
            onClick={onClickDataManagement}
            active={isCurrentPageActive(DATA_MANAGEMENT, mainPage)}
            disabled={false}
          />
          <SubMenu active={dataManagementSubmenuOpen}>
            <SideNavSubButton
              text="Overview"
              active={isCurrentPageActive(DATA_MANAGEMENT_OVERVIEW, subPage)}
              onClick={onClickDataManagementOverview}
            />
            <SideNavSubButton
              text="Upload"
              active={isCurrentPageActive(DATA_MANAGEMENT_UPLOAD, subPage)}
              onClick={onClickDataManagementUpload}
            />
          </SubMenu>
        </div>
        <div>
          <SideNavButton
            text="Analysis"
            icon={<AnalysisIcon />}
            onClick={onClickAnalysis}
            active={isCurrentPageActive(ANALYSIS, mainPage)}
            disabled={false}
          />
          <SubMenu active={analysisSubmenuOpen}>
            <SideNavSubButton
              text="Overview"
              active={isCurrentPageActive(ANALYSIS_OVERVIEW, subPage)}
              onClick={onClickAnalysisOverview}
            />
            <SideNavSubButton
              text="Algorithm"
              active={isCurrentPageActive(ANALYSIS_ALGORITHM, subPage)}
              onClick={onClickAnalysisAlgorithm}
            />
            <SideNavSubButton
              text="Kaplan Meier"
              active={isCurrentPageActive(ANALYSIS_KAPLAN_DETAILS, subPage)}
              onClick={onClickAnalysisKaplan}
            />
            <SideNavSubButton
              text="Median"
              active={isCurrentPageActive(ANALYSIS_SURVIVAL_DETAILS, subPage)}
              onClick={onClickAnalysisMedian}
            />
            <SideNavSubButton
              text="Change from Baseline"
              active={isCurrentPageActive(ANALYSIS_MORTALITY_DETAILS, subPage)}
              onClick={onClickAnalysisMortality}
            />
            <SideNavSubButton
              text="Patient Journey"
              active={isCurrentPageActive(ANALYSIS_PATIENT_JOURNEY, subPage)}
              onClick={onClickAnalysisPatient}
            />
          </SubMenu>
        </div>
        <div>
          <SideNavButton
            text="Annotate"
            icon={
              <SvgIcon icon={RadiologyIcon} color={main.colors.neutral.white} />
            }
            onClick={onClickAnnotate}
            active={isCurrentPageActive(ANNOTATE, mainPage)}
            disabled={false}
          />

          <SubMenu active={annotateSubmenuOpen}>
            <CurrentUserCan
              allowedRoles={[ANNOTATE_ROLE]}
              yes={
                <SideNavSubButton
                  text="Worklist"
                  active={isCurrentPageActive(ANNOTATE_WORKLIST, subPage)}
                  onClick={onClickAnnotateWorklist}
                />
              }
            />
          </SubMenu>
        </div>
        <div>
          <SideNavButton
            text="Logbook"
            icon={<LogbookIcon />}
            active={false}
            disabled={false}
          />
        </div>
        <div>
          <SideNavButton
            text="Settings"
            icon={
              <SvgIcon
                icon={SettingsIcon}
                size={24}
                color={main.colors.neutral.white}
              />
            }
            onClick={onClickSettings}
            active={isCurrentPageActive(SETTINGS, mainPage)}
            disabled={false}
          />
          <SubMenu active={settingsSubmenuOpen}>
            <SideNavSubButton
              text="Team Members"
              active={isCurrentPageActive(TEAM_MEMBERS, subPage)}
              onClick={onClickTeamMembers}
            />
            <SideNavSubButton
              text="Sites"
              active={isCurrentPageActive(SITES, subPage)}
              onClick={onClickSites}
            />
          </SubMenu>
        </div>
      </StyledTop>
      <StyledBottom>
        <CurrentUserAvatar showProfileOnClick={true} />
      </StyledBottom>
    </StyledFlexBox>
  );
};

export default SideNav;
