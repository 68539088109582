import React, { ReactElement } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
`;
const Sideline = styled.div`
  width: 4px;
  height: 56px;
  border-radius: 0 4px 4px 0;
  left: 0;
  top: 0;
  background: ${(props) => props.theme.colors.secondary.secondary};
`;

const StyledSideNavButton = styled.button<{ active: boolean }>`
  width: 240px;
  height: 56px;
  outline: none;
  padding-inline-start: 24px;
  display: flex;
  align-items: center;
  border: none;
  transition: 0.2s all;
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.neutral.white20
      : props.active
      ? props.theme.colors.secondary.secondary
      : props.theme.colors.neutral.white};

  g {
    path {
      fill: ${(props) =>
        props.disabled
          ? props.theme.colors.neutral.white20
          : props.active
          ? props.theme.colors.secondary.secondary
          : props.theme.colors.neutral.white};
    }
  }

  background: ${(props) =>
    props.active
      ? props.theme.colors.actionPrimary.active
      : props.theme.colors.neutral.black};

  &:hover {
    background: ${(props) =>
      props.disabled
        ? props.theme.colors.neutral.black
        : props.theme.colors.neutral.white10};
    cursor: ${(props) => (props.disabled ? "initial" : "pointer")};
  }
`;

const NavText = styled.p`
  font-size: 12px;
  font-family: "Inter", verdana, arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.12em;
  line-height: 20px;
  margin: 0 0 0 16px;
  text-transform: uppercase;
`;
interface SideNavButtonProps {
  text: string;
  icon: ReactElement;
  onClick?: React.MouseEventHandler;
  active: boolean;
  disabled: boolean;
}

const SideNavButton = ({
  text,
  icon,
  onClick,
  active,
  disabled,
}: SideNavButtonProps): ReactElement => {
  return (
    <Wrapper>
      {active && <Sideline />}
      <StyledSideNavButton
        active={active}
        onClick={onClick}
        disabled={disabled}
      >
        {icon}
        <NavText>{text}</NavText>
      </StyledSideNavButton>
    </Wrapper>
  );
};

export default SideNavButton;
