import React from "react";
import { FC } from "react";
import styled from "styled-components";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";

const Styled = styled(SettingsRoundedIcon)`
  color: ${(props) => props.theme.colors.primary.inverted};
`;

export const SettingsIcon: FC = () => <Styled />;
