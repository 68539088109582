import React, { ReactElement } from "react";
import styled from "styled-components";
import { ReactComponent as AltisLogoIcon } from "../../assets/svgs/AltisLogo.svg";
import { SvgIcon } from "./icons/SvgIcon";
import { main } from "../theme/main";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 24px;
  height: 24px;
`;

export const NotaLogoSimple = (): ReactElement => {
  return (
    <Wrapper>
      <SvgIcon
        icon={AltisLogoIcon}
        color={main.colors.secondary.secondary}
        size={18}
      />
    </Wrapper>
  );
};
