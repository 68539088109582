import React from "react";
import { useGoToPatientPage } from "../../../Trial/hooks/useGoToPatientPage";
import { filterStudyTableRow, StudyTableRowType } from "./StudyTableRowType";
import { StudyTable, StudyTableBaseProps } from "./StudyTable";
import {
  ANALYSIS_STATUS_HEADER_ID,
  defaultColumnIds,
  QC_STATUS_HEADER_ID,
  StudyTableColumnType,
  UPLOAD_STATUS_HEADER_ID,
} from "./StudyTableColumns";

export interface LinkedStudyTableProps extends StudyTableBaseProps {
  hideAnalysisColumn: boolean;
}

export function LinkedStudyTable({
  subjects,
  hideAnalysisColumn,
  ...tableProps
}: LinkedStudyTableProps): React.ReactElement {
  const columnIds: StudyTableColumnType[] = [
    ...defaultColumnIds,
    UPLOAD_STATUS_HEADER_ID,
    QC_STATUS_HEADER_ID,
  ];

  if (!hideAnalysisColumn) {
    columnIds.push(ANALYSIS_STATUS_HEADER_ID);
  }

  const { goToPage } = useGoToPatientPage();

  const handleStudyClicked = (study: StudyTableRowType) => {
    const { subjectId } = study;
    goToPage(subjectId);
  };

  return (
    <StudyTable
      {...tableProps}
      subjects={subjects}
      columnIds={columnIds}
      onClickRow={handleStudyClicked}
      enableSelect={false}
      filterFn={filterStudyTableRow}
    />
  );
}
