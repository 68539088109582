import React from "react";
import styled from "styled-components";
import { AnatomicalStructureType } from "../../Analysis/common/types/AnatomicalStructureType";
import {
  updateModeColors,
  UpdateModeType,
} from "../../common/types/UpdateModeType";
import { main } from "../../common/theme/main";
import { InnerButtonWrapper } from "../../common/components/input/InputButton";
import { UpdateModeIcon } from "../../common/types/UpdateModeIcon";

const OuterWrapper = styled.div`
  display: inline-block;
`;

const Wrapper = styled.div<{ background: string }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 8px;
  background: ${(props) => props.background};
  border-radius: 4px;
`;

const Text = styled.div<{ color: string }>`
  font-family: Inter;
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  color: ${(props) => props.color};
`;

interface AnatomicalStructureLabelProps {
  anatomicalStructure: AnatomicalStructureType;
  state?: UpdateModeType;
  formatName?: (name: string) => JSX.Element;
}

export function AnatomicalStructureLabel({
  anatomicalStructure,
  state,
  formatName = (name: string) => <>{name}</>,
}: AnatomicalStructureLabelProps): JSX.Element {
  const { name } = anatomicalStructure;

  const color = state ? updateModeColors[state] : main.colors.neutral.neutral4;

  return (
    <OuterWrapper>
      <Wrapper background={color}>
        <InnerButtonWrapper>
          {state && (
            <UpdateModeIcon
              mode={state}
              color={main.colors.neutral.white}
              size={14}
            />
          )}
          <Text color={"white"}>{formatName(name)}</Text>
        </InnerButtonWrapper>
      </Wrapper>
    </OuterWrapper>
  );
}
