import {
  COMPLETE_RESPONSE,
  PARTIAL_RESPONSE,
  PROGRESSIVE_DISEASE,
  STABLE_DISEASE,
} from "../../../Analysis/common/types/TumourResponseType";
import { RootState } from "../rootReducer";
import { TrialFilterSelectors } from "./TrialFilterSelectors";
import { Recist1_1Filters } from "../../types/TrialViewFilterTypes";

export const defaultResponseState = {
  [PROGRESSIVE_DISEASE]: true,
  [STABLE_DISEASE]: true,
  [PARTIAL_RESPONSE]: true,
  [COMPLETE_RESPONSE]: true,
};

export const clearResponseState = {
  [PROGRESSIVE_DISEASE]: false,
  [STABLE_DISEASE]: false,
  [PARTIAL_RESPONSE]: false,
  [COMPLETE_RESPONSE]: false,
};

export const tumourBurdenFilters = new TrialFilterSelectors<Recist1_1Filters>(
  defaultResponseState,
  clearResponseState,
  (state: RootState) => state.trialViewFilters.recist1_1
);
