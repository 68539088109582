import React from "react";
import styled from "styled-components";
import { useDialog, useDialogReturnType } from "../../Dialog/useDialog";
import { OK_ONLY } from "../../Dialog/DialogActionButtonsType";
import {
  ContextAwareEditTrialSites,
  ContextAwareEditTrialSitesProps,
} from "../ContextAwareEditTrialSites";

const Wrapper = styled.div`
  width: 57vw;
  min-width: 800px;
`;

export function useEditTrialSitesDialog({
  sites,
  actionCallbacks,
}: ContextAwareEditTrialSitesProps): useDialogReturnType {
  const label = "Edit Sites";

  const content = (
    <Wrapper>
      <ContextAwareEditTrialSites
        sites={sites}
        actionCallbacks={actionCallbacks}
      />
    </Wrapper>
  );

  return useDialog({
    label,
    content,
    options: {
      showCloseButton: true,
      actionButtonOptions: {
        type: OK_ONLY,
      },
    },
  });
}
