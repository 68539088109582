import { Column } from "react-table";
import { CaseProgressLabel } from "./CaseProgressLabel";
import React from "react";
import { ActionTypeLabel } from "./ActionTypeLabel";
import { WorklistTableRowType } from "./WorklistTableRowType";
import { CaseProgressType } from "../../types/CaseProgressType";
import {
  actionTypeDisplayNames,
  ActionTypeType,
} from "../../types/ActionTypeType";

export const worklistTableColumns: Column<WorklistTableRowType>[] = [
  {
    id: "caseId",
    Header: "Case #",
    accessor: ({ caseId }) => caseId,
    showFilter: true,
  },
  {
    id: "name",
    Header: "Name",
    accessor: ({ name }) => name,
    filter: "fuzzyText",
    showFilter: true,
  },
  {
    id: "status",
    Header: "Status",
    accessor: ({ progress }) => progress,
    Cell: ({ value: progress }: { value: CaseProgressType }) => (
      <CaseProgressLabel progress={progress} />
    ),
  },
  {
    id: "task",
    Header: "Task",
    accessor: ({ actionType, url }) => ({ actionType, url }),
    Cell: ({
      value: { url, actionType },
    }: {
      value: { url: string; actionType: ActionTypeType };
    }) => {
      const handleOnClick = () => {
        const win = window.open(url, "_blank");
        win?.focus();
      };
      return (
        <ActionTypeLabel actionType={actionType} onClick={handleOnClick} />
      );
    },
    sortType: (
      {
        values: {
          task: { actionType: actionTypeA },
        },
      },
      {
        values: {
          task: { actionType: actionTypeB },
        },
      }
    ) =>
      actionTypeDisplayNames[actionTypeA as ActionTypeType].localeCompare(
        actionTypeDisplayNames[actionTypeB as ActionTypeType]
      ),
  },
];
