import React from "react";
import TrialStatus from "../../../Dashboard/components/TrialStatus";
import DetailGrid from "../DetailGrid";

const headers = [
  "Study Name",
  "Study Number",
  "Trial Status",
  "Investigational Product",
  "Phase",
  "Trial Arms",
  "Design",
  "Primary Endpoint(s)",
  "Secondary Endpoints(s)",
  "Expected Completion",
];

interface TrialDetailsPanelProps {
  details: string[];
}

function TrialDetailsPanel({
  details,
}: TrialDetailsPanelProps): React.ReactElement {
  const detailInfo = headers.map((header, i) => {
    return i == 2
      ? { title: header, value: <TrialStatus status={details[i]} /> }
      : { title: header, value: details[i] };
  });

  return <DetailGrid details={detailInfo} numColumns={5} />;
}

export default TrialDetailsPanel;
