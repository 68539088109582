import { ApolloError, gql, useQuery } from "@apollo/client";
import {
  RawSubjectsQueryType,
  SUBJECT_FRAGMENT,
} from "../../common/hooks/useSubjectQuery";
import { SubjectType } from "../../common/types/SubjectType";
import { parseSubjectFromQueryResult } from "../../common/utils/rawDataParsers/parseSubjectFromQueryResult";

const GET_SUBJECT_QUERY = gql`
  query GetSubjectFollowUps($patientID: Int!) {
    patient(where: { patient_id: { _eq: $patientID } }) {
      ...Subject
    }
    temp_recist_case_link(where: { id: { _eq: 1 } }) {
      case_link
    }
    temp_nota_annotation_page_url_base {
      url
    }
  }
  ${SUBJECT_FRAGMENT}
`;

type CaseLinkType = {
  case_link: string;
};

type UrlType = {
  url: string;
};

interface RawQueryType extends RawSubjectsQueryType {
  temp_recist_case_link: CaseLinkType[];
  temp_nota_annotation_page_url_base: UrlType[];
}

export type SubjectQueryResultType = {
  subject?: SubjectType;
  error?: ApolloError;
  loading: boolean;
  caseLinks: string[];
};

export function useSubjectFollowUps(patientID: number): SubjectQueryResultType {
  const { data, loading, error } = useQuery<RawQueryType>(GET_SUBJECT_QUERY, {
    variables: { patientID },
  });

  let subject: SubjectType | undefined = undefined;

  let caseLinks: string[] = [];

  if (!loading && !error && data) {
    const {
      patient: rawSubjects,
      temp_recist_case_link: rawCaseLinks,
      temp_nota_annotation_page_url_base: rawNotaAnnotationPageUrlBase,
    } = data;

    if (rawSubjects && rawSubjects.length > 0) {
      subject = parseSubjectFromQueryResult(
        rawSubjects[0],
        rawNotaAnnotationPageUrlBase
      );

      //use real case links if avaiable
      if (rawNotaAnnotationPageUrlBase.length > 0) {
        const { url } = rawNotaAnnotationPageUrlBase[0];
        const { caseIds } = subject;

        caseLinks = caseIds.map((caseId) => {
          return new URL(caseId.toString(), url).href;
        });
      }

      //revert to hardcoded case link
      if (caseLinks.length == 0) {
        caseLinks = rawCaseLinks.map((rawCaseLink) => rawCaseLink.case_link);
      }

      if (caseLinks.length === 0) {
        throw new Error(
          "Unable to create case links! Ensure that temp_recist_case_link exists"
        );
      }
    }
  }

  return { subject, caseLinks, loading, error };
}
