import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
`;

const ModelSuiteInfoPanel = () => {
  return (
    <Wrapper>
      <InfoWrapper>
        <InfoTextRow title={"Disease:"} detail={"Non-Small Cell Lung Cancer"} />
        <InfoTextRow title={"Endpoint:"} detail={"Survival"} />
        <InfoTextRow
          title={"Optimized for:"}
          detail={"Stage IIIB - IV (trained on 66,773 CT scans)"}
        />
        <InfoTextRow
          title={"Explainability:"}
          detail={
            "Left Lung, Right Lung, Heart, Upper Mediastinum, Liver, Adrenal Glands"
          }
        />
      </InfoWrapper>
    </Wrapper>
  );
};

export default ModelSuiteInfoPanel;

const InfoItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0 35px;
  height: 25px;

  width: 100%;
`;

const BoldInfoText = styled.span`
  font-weight: bold;
`;

const InfoText = styled.div`
  /* H4/Regular */
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`;

interface InfoTextRowProps {
  title: string;
  detail: string;
}

function InfoTextRow({ title, detail }: InfoTextRowProps): JSX.Element {
  return (
    <InfoItemWrapper>
      <InfoOutlinedIcon fontSize={"small"} />
      <InfoText>
        <BoldInfoText>{title}</BoldInfoText> {detail}
      </InfoText>
    </InfoItemWrapper>
  );
}
