import { gql } from "@apollo/client";

export const TRIAL_DETAILS_FRAGMENT = gql`
  fragment Trial on trial {
    id
    estimatedCompletion: estimated_completion
    startDate: start_date
    status
    name
    details
    sites {
      id
      name
    }
    trial_groups {
      group_id
    }
  }
`;
