import React, { ReactElement, MouseEventHandler } from "react";
import styled from "styled-components";

interface CTAButtonProps {
  text: string;
  icon?: ReactElement;
  onClick?: MouseEventHandler;
  blackBackground?: boolean;
}

const StyledButton = styled.button<{
  blackBackground?: boolean;
}>`
  border: 1px solid #1b1f20;
  box-sizing: border-box;
  border-radius: 6px;
  background: ${(props) =>
    props.blackBackground
      ? props.theme.colors.actionPrimary.default
      : "transparent"};
  padding: 6px 8px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TextWrapper = styled.div<{
  hasIcon: boolean | undefined;
  blackBackground: boolean | undefined;
}>`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) =>
    props.blackBackground
      ? props.theme.colors.actionPrimary.inverted
      : props.theme.colors.actionPrimary.default};
  margin-left: ${(props) => (props.hasIcon ? "6px" : "0px")};
`;

const CTAButton = ({
  text,
  icon,
  onClick,
  blackBackground,
}: CTAButtonProps): ReactElement => {
  return (
    <StyledButton onClick={onClick} blackBackground={blackBackground}>
      <Wrapper>
        {icon}
        <TextWrapper hasIcon={Boolean(icon)} blackBackground={blackBackground}>
          {text}
        </TextWrapper>
      </Wrapper>
    </StyledButton>
  );
};

export default CTAButton;
