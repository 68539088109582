import React, { FC, ReactElement } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
`;

const TitleContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  margin: 0 6px;
`;

interface TopBarProps {
  titleContent: ReactElement;
  buttons?: ReactElement[];
}

export const TopBar: FC<TopBarProps> = ({
  titleContent,
  buttons,
}: TopBarProps) => {
  //TODO there is a better way to do this via styling children components via: https://styled-components.com/docs/advanced#referring-to-other-components
  const buttonsWithMargins: ReactElement[] | undefined =
    buttons &&
    buttons.map((button, index) => (
      <ButtonWrapper key={index}>{button}</ButtonWrapper>
    ));
  return (
    <Wrapper>
      <TitleContentWrapper>{titleContent}</TitleContentWrapper>
      <ButtonsWrapper>{buttonsWithMargins}</ButtonsWrapper>
    </Wrapper>
  );
};
