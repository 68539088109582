import React from "react";
import styled from "styled-components";
interface FilterSubtextProps {
  text: string;
}

const StyledText = styled.p`
  position: static;
  height: 16px;
  left: 0px;
  top: 0px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;

  color: ${(props) => props.theme.colors.primary.primary90};
  opacity: 0.6;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
`;

function FilterSubtext({
  text,
}: FilterSubtextProps): React.ReactElement<
  React.LabelHTMLAttributes<HTMLLabelElement>
> {
  return <StyledText>{text}</StyledText>;
}

export default FilterSubtext;
