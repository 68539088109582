import React, { FC } from "react";
import MedianSurvivalDeltaChart from "./MedianSurvivalDeltaChart";
import handleApolloError from "../../../common/utils/handleApolloError";
import Loading from "../../../common/components/Loading";
import { useTrialId } from "../../../Trial/hooks/useTrialId";
import { useFilteredSubjectsQuery } from "../../common/hooks/useFilteredSubjectsQuery";
import { getMedianSurvivalSeries } from "../utils/getMedianSurvival";

export const SurvivalPanelContainer: FC = () => {
  const trialId = useTrialId();
  const { subjects, loading, error } = useFilteredSubjectsQuery(trialId);

  if (loading) return <Loading />;
  if (error) handleApolloError(error);
  if (!subjects) throw new Error("Subject null with no apollo error");

  const medianSurvivalSeries = getMedianSurvivalSeries(subjects);

  return (
    <MedianSurvivalDeltaChart medianSurvivalSeries={medianSurvivalSeries} />
  );
};
