import { useEffect } from "react";
import { useRef } from "react";
import { useLocation } from "react-router";

// Returns a ref to be passed into an element which needs scrolling to top on location change
export function useScrollToTop<T extends HTMLElement>(): React.RefObject<T> {
  const scrollableElementRef = useRef<T>(null);
  const location = useLocation();
  useEffect(() => {
    // Disable the no-non-null-assertion rule because TS doesn't know scrollableElementRef.current will be non-null here
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    scrollableElementRef.current!.scrollTo(0, 0);
  }, [location]);
  return scrollableElementRef;
}
