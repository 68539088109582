import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BlackCheckbox } from "./FilterCheckbox";

interface FilterHeaderButtonsProps {
  allSelected: boolean;
  noneSelected: boolean;
  onSelectAll: () => void;
  onClear: () => void;
}

export function FilterHeaderButtons({
  allSelected,
  noneSelected,
  onSelectAll,
  onClear,
}: FilterHeaderButtonsProps): JSX.Element {
  const [checked, setChecked] = useState<boolean | undefined>(false);
  const [isIndeterminate, setIsIndeterminate] = useState<boolean>(false);

  useEffect(() => {
    let check: boolean | undefined = undefined;
    if (allSelected) {
      check = true;
    } else if (noneSelected) {
      check = false;
    }

    setIsIndeterminate(check === undefined);
    if (checked !== check) {
      setChecked(check);
    }
  }, [allSelected, noneSelected]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      onSelectAll();
    } else {
      onClear();
    }
  };

  return (
    <BlackCheckbox
      checked={checked}
      onChange={handleChange}
      indeterminate={isIndeterminate}
      size={"small"}
      style={{ margin: "2px 0px", padding: "0px" }}
    />
  );
}
