import styled from "styled-components";

export const DialogText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  word-wrap: break-word;
  width: 320px;
`;
