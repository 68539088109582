import { RangeType } from "../../KaplanMeier/utils/generateKaplanMeierData";

export type SurvivalBinType = {
  id: number;
  range: RangeType;
  subjectsByArm: Record<number, any[]>;
};

export function formatBinRange(bin: SurvivalBinType): string {
  const {
    range: { min, max },
  } = bin;
  return `${Math.round(min)} to ${Math.round(max)} months`;
}
