import React, { ReactElement } from "react";
import { TooltipItemWrapper } from "../../common/components/TooltipItem";
import {
  TooltipWrapper,
  CustomTooltipProps,
} from "../../common/components/Tooltip";
import styled from "styled-components";
import { formatPercentage } from "../../common/utils/formatPercentage";
import { BOTH, FilterType, IPRO, RECIST } from "./Filter";

interface MortalityBaselineChartTooltipProps extends CustomTooltipProps {
  filter: FilterType;
}

const StyledItemName = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.neutral.white80};
`;

const StyledValue = styled.div`
  margin-left: 12px;
  color: ${(props) => props.theme.colors.neutral.white};
`;

const TooltipDivider = styled.div`
  height: 0;
  width: 166px;
  left: 8px;
  margin: 6px 0px;
  border: 0.5px solid ${(props) => props.theme.colors.neutral.white40};
`;

export function MortalityBaselineChartTooltip({
  active,
  payload,
  label,
  filter,
}: MortalityBaselineChartTooltipProps): ReactElement | null {
  if (!active || !payload || payload.length < 1) {
    return null;
  }
  const itemsToRender: any[] = payload.filter(
    (item: any) => item.type !== "none"
  );

  const dataPoint = itemsToRender[0].payload;
  const { subjectId, data } = dataPoint;

  const survival = data[IPRO];
  const burden = data[RECIST];

  const showBoth = filter === BOTH;
  const showBurden = filter === RECIST || showBoth;
  const showSurvival = filter === IPRO || showBoth;

  return (
    <TooltipWrapper>
      <TooltipItemWrapper>
        <StyledItemName>{`Subject ID`}</StyledItemName>
        <StyledValue>{subjectId}</StyledValue>
      </TooltipItemWrapper>
      <TooltipDivider />
      {showBurden && (
        <TooltipItemWrapper>
          <StyledItemName>{`Tumour Burden`}</StyledItemName>
          <StyledValue>{formatPercentage(burden)}</StyledValue>
        </TooltipItemWrapper>
      )}
      {showSurvival && (
        <TooltipItemWrapper>
          <StyledItemName>{`Survival`}</StyledItemName>
          <StyledValue>{formatPercentage(survival)}</StyledValue>
        </TooltipItemWrapper>
      )}
    </TooltipWrapper>
  );
}
