import React from "react";
import {
  caseProgressColors,
  caseProgressDisplayNames,
  CaseProgressType,
} from "../../types/CaseProgressType";
import { Label } from "../../../common/components/Label";
import { main } from "../../../common/theme/main";

interface CaseProgressLabelProps {
  progress: CaseProgressType;
}

export function CaseProgressLabel({
  progress,
}: CaseProgressLabelProps): JSX.Element {
  const {
    neutral: { white },
  } = main.colors;

  const background = caseProgressColors[progress];
  const text = caseProgressDisplayNames[progress];

  return (
    <Label background={background} color={white}>
      {text}
    </Label>
  );
}
