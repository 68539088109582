import React, { FC } from "react";
import { Card } from "../../../common/components/cards/Card";
import DragDropFileUpload from "./DragDropFileUpload";
import { useTrialId } from "../../../Trial/hooks/useTrialId";
import { useSubjectQuery } from "../../../Analysis/common/hooks/useSubjectQuery";
import Loading from "../../../common/components/Loading";
import handleApolloError from "../../../common/utils/handleApolloError";
import { StudyTableCard } from "../../../Analysis/common/components/StudyTableCard";

const Upload: FC = () => {
  const trialId = useTrialId();
  const { subjects, loading, error } = useSubjectQuery(trialId);

  if (loading) return <Loading />;
  if (error) handleApolloError(error);
  if (!subjects) throw new Error("Subject null with no apollo error");

  return (
    <>
      <Card noHead={true} content={<DragDropFileUpload />} />
      <StudyTableCard
        expand={true}
        hideAnalysisColumn={true}
        minTableHeight={200}
      />
    </>
  );
};

export default Upload;
