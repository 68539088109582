import React, { useState } from "react";
import {
  Legend as ReChartsLegend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as ReChartsTooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getYAxisProps } from "../../common/utils/getYAxisProps";
import styled from "styled-components";
import {
  getYAxisLabel,
  getYAxisLabelRight,
} from "../../common/utils/getYAxisLabel";
import { Legend } from "../../common/components/Legend";
import {
  DomainFilterType,
  getMaxValues,
  MedianSurvivalFollowUpType,
  MedianSurvivalSeriesType,
} from "../utils/getMedianSurvival";
import {
  BOTH,
  Filter,
  FilterType,
  IPRO,
  RECIST,
} from "../../ChangeFromBaseline/components/Filter";
import { DataType } from "../../ChangeFromBaseline/utils/parseMortalityBaselineChartData";
import { formatDataType } from "../../common/utils/formatDataType";
import { armFilterColors } from "../../common/utils/armColors";
import { FollowUpAxisTick } from "../../common/components/FollowUpAxisTick";
import { MedianSurvivalChartTooltip } from "./MedianSurvivalChartTooltip";
import { formatArmTitle } from "../../common/utils/formatArmTtitle";
import { useSelector } from "react-redux";
import { selectTimepointsFilters } from "../../ToolPanel/components/TimepointsFilters";
import { getXAxisProps } from "../../common/utils/getXAxisProps";
import { TUMOR_SPELLING } from "../../../common/utils/spellingConstants";
import { useFilteredTrialArmIds } from "../../common/hooks/useFilteredTrialArmIds";
import { useMortalityDistributionChartDialog } from "./useMortalityDistributionChartDialog";

const Wrapper = styled.div`
  flex: 1;
  position: relative;
`;

function getDeathTypes(filter: FilterType) {
  switch (filter) {
    case RECIST:
      return [RECIST];
    case IPRO:
      return [IPRO];
    case BOTH:
      return [RECIST, IPRO];
  }
}

interface MedianSurvivalDeltaChartProps {
  medianSurvivalSeries: MedianSurvivalSeriesType;
}

export default function MedianSurvivalDeltaChart({
  medianSurvivalSeries,
}: MedianSurvivalDeltaChartProps): React.ReactElement {
  const trialArmIds = useFilteredTrialArmIds();

  const [filter, setFilter] = useState<FilterType>(RECIST);
  const [selectedFollowUp, setSelectedFollowUp] = useState<number | null>(null);
  const timepointsFilters = useSelector(selectTimepointsFilters);

  const [setDialogOpen, { dialog }] = useMortalityDistributionChartDialog(
    selectedFollowUp
  );

  const deathTypes = getDeathTypes(filter);

  const showBoth = filter === BOTH;
  const showBurden = filter === RECIST || showBoth;
  const showSurvival = filter === IPRO || showBoth;

  const renderLegend = (props: any) => {
    return <Legend {...props} />;
  };

  const renderToolTip = (props: any) => {
    return (
      <MedianSurvivalChartTooltip
        {...props}
        maxIPRO={getDomain(IPRO)}
        maxRECIST={getDomain(RECIST)}
      />
    );
  };

  const handleClickLine = (data: any) => {
    if (!data) {
      return;
    }

    const { activePayload } = data;
    if (!activePayload) {
      return;
    }

    const firstPayload = activePayload.length > 0 ? activePayload[0] : null;
    if (!firstPayload) {
      return;
    }

    const {
      payload: { followUpOrder },
    } = firstPayload;

    handleFollowUpClicked(followUpOrder);
  };

  const handleFollowUpClicked = (followUpOrder: number) => {
    if (!timepointsFilters[followUpOrder]) {
      return;
    }

    setSelectedFollowUp(followUpOrder);
    openModal();
  };

  const openModal = () => {
    setDialogOpen(true);
  };

  const getData = (
    data: MedianSurvivalFollowUpType,
    arm: number,
    medianType: DataType
  ) => {
    const medianData = data.byArm[arm];
    if (!medianData) {
      return null;
    }
    return medianData[medianType];
  };

  const getDomain = (filter: DomainFilterType): number => {
    return Math.ceil(getMaxValues(medianSurvivalSeries)[filter]);
  };

  const xAxisProps = getXAxisProps();
  const yAxisProps = getYAxisProps(true);
  const burdenYAxisLabel = getYAxisLabel(TUMOR_SPELLING + " Burden (mm)");
  const survivalYAxisLabel = getYAxisLabelRight("Survival Months");

  return (
    <>
      {dialog}
      <Wrapper>
        <ResponsiveContainer height={540}>
          <LineChart
            onClick={handleClickLine}
            margin={{ top: 32, right: 20, left: 20, bottom: 40 }}
            data={medianSurvivalSeries}
          >
            <XAxis
              {...xAxisProps}
              dataKey="followUpOrder"
              padding={{ left: 10 }}
              tick={
                <FollowUpAxisTick
                  onClickFollowUp={handleFollowUpClicked}
                  useDisabledFollowUps={true}
                />
              }
            />
            <YAxis
              {...yAxisProps}
              yAxisId={IPRO}
              orientation={"right"}
              domain={[0, getDomain(IPRO)]}
            >
              {showSurvival && survivalYAxisLabel}
            </YAxis>
            <YAxis
              {...yAxisProps}
              yAxisId={RECIST}
              orientation={"left"}
              domain={[0, getDomain(RECIST)]}
            >
              {showBurden && burdenYAxisLabel}
            </YAxis>
            <ReChartsTooltip
              content={renderToolTip}
              isAnimationActive={false}
            />
            <ReChartsLegend
              align="left"
              verticalAlign="top"
              height={48}
              content={renderLegend}
            />

            {deathTypes.flatMap((medianType) =>
              trialArmIds.map((arm) => {
                const dataType = medianType as DataType;
                const { color, width, dashArray } = armFilterColors[arm][
                  dataType
                ];
                const name = formatArmTitle(arm, formatDataType(dataType));

                return (
                  <Line
                    isAnimationActive={false}
                    key={`${medianType}_${arm}`}
                    type="linear"
                    yAxisId={medianType}
                    dataKey={(data) => getData(data, arm, dataType)}
                    dot={false}
                    stroke={color}
                    strokeWidth={width}
                    strokeDasharray={dashArray}
                    name={name}
                  />
                );
              })
            )}
          </LineChart>
        </ResponsiveContainer>
        <Filter onFilterSelected={setFilter} activeFilter={filter} />
      </Wrapper>
    </>
  );
}
