import React from "react";
import styled from "styled-components";

const Wrapper = styled.div<{
  background?: string;
  color?: string;
  clickable?: boolean;
  size: number;
}>`
  border-radius: 50%;
  width: ${({ size }) => size + 2}px;
  height: ${({ size }) => size + 2}px;
  padding: 2px;

  background: ${(props) =>
    props.background ?? props.theme.colors.actionPrimary.default};
  color: ${(props) => props.color ?? props.theme.colors.actionPrimary.inverted};
  text-align: center;

  cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
  font: ${({ size }) => size}px Arial;
`;

interface CountIndicatorProps {
  count: number;
  color?: string;
  background?: string;
  formatCount?: (count: number) => string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  size?: number;
}

export function CountIndicator({
  count,
  background,
  color,
  formatCount = (count: number) => count.toString(),
  onClick,
  size = 12,
}: CountIndicatorProps): JSX.Element {
  return (
    <Wrapper
      background={background}
      color={color}
      onClick={onClick}
      clickable={!!onClick}
      size={size}
    >
      {formatCount(count)}
    </Wrapper>
  );
}
