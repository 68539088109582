import React from "react";
import { Area, ComposedChart, Line, ResponsiveContainer } from "recharts";
import {
  DeathType,
  KaplanMeierDataPoint,
  PREDICTED,
} from "../utils/generateKaplanMeierData";
import { KaplanMeierAttributesType } from "./kaplanMeierAttributes";
import { capitalizeFirstLetter } from "../../common/utils/captatlizeFirstLetter";

interface KaplanChartProps {
  data: KaplanMeierDataPoint[];
  attributes: KaplanMeierAttributesType;
}

export function TinyKaplanChart({
  data,
  attributes,
}: KaplanChartProps): React.ReactElement {
  const arms = Object.keys(attributes).map(Number);
  const types: DeathType[] = [PREDICTED];

  const getResult = (
    data: KaplanMeierDataPoint,
    arm: number,
    key: DeathType
  ) => {
    if (!data[arm]) {
      return null;
    }
    return data[arm][key];
  };

  const getValue = (
    data: KaplanMeierDataPoint,
    arm: number,
    key: DeathType
  ): number | null => {
    const result = getResult(data, arm, key);
    if (!result) {
      return null;
    }
    return result.value;
  };

  const getRange = (
    data: KaplanMeierDataPoint,
    arm: number,
    key: DeathType
  ): number[] | null => {
    const result = getResult(data, arm, key);
    if (!result) {
      return null;
    }
    const {
      range: { min, max },
    } = result;
    return [min, max];
  };

  return (
    <ResponsiveContainer>
      <ComposedChart
        data={data}
        style={{ cursor: "pointer" }}
        margin={{ top: 8, right: 8, left: 8, bottom: 8 }}
      >
        {types.flatMap((deathType) =>
          arms.map((arm) => {
            const { confidenceOpacity, confidenceColor } = attributes[arm][
              deathType
            ];
            return (
              <Area
                isAnimationActive={false}
                type="step"
                dataKey={(data) => getRange(data, arm, deathType)}
                name={`Arm: ${arm} (${deathType}MM)`}
                key={`${arm}_${deathType}MM_actual`}
                tooltipType={"none"}
                legendType={"none"}
                stroke={"none"}
                opacity={confidenceOpacity}
                fill={confidenceColor}
                dot={false}
                activeDot={false}
                yAxisId={0}
              />
            );
          })
        )}
        {types.flatMap((deathType) =>
          arms.map((arm) => {
            const { color, strokeWidth, dashArray } = attributes[arm][
              deathType
            ];
            const name = `${capitalizeFirstLetter(deathType)} Arm ${arm + 1}`;
            return (
              <Line
                isAnimationActive={false}
                type="step"
                dataKey={(data) => getValue(data, arm, deathType)}
                name={name}
                key={`${arm}_${deathType}_actual`}
                stroke={color}
                strokeWidth={strokeWidth}
                strokeDasharray={dashArray}
                dot={false}
                legendType={"plainline"}
                yAxisId={0}
              />
            );
          })
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
}
