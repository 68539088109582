import React, { FC } from "react";
import { FormProps } from "../../Dialog/FormProps";
import { useForm, Controller } from "react-hook-form";
import { Label } from "../../input/Label";
import { Input } from "../../input/Input";
import { InputButton } from "../../input/InputButton";
import Select, { ValueType } from "react-select";
import { getSelectStyle } from "../../input/getSelectStyle";
import FilterButton from "../../../../Analysis/ToolPanel/components/FilterButton";
import { main } from "../../../theme/main";
import { UserType } from "../../../types/UserType";
import { UserActionCallbacksType } from "../UserActionCallbacksType";
import { useResetPasswordDialog } from "./useResetPasswordDialog";
import { useRemoveUserFromTrialDialog } from "./useRemoveUserFromTrialDialog";
import {
  MANAGER,
  USER,
  UserRoleType,
  VIEW_ANNOTATIONS,
  ANNOTATE,
} from "../../../types/UserRoleType";
import { formatRole } from "../utils/formatRole";
import { RoleLabel } from "../RoleLabel";
import { ActionButtonsWrapper } from "../../Dialog/Form/ActionButtonsWrapper";
import { ButtonRowWrapper } from "../../Dialog/Form/ButtonRowWrapper";
import { Wrapper } from "../../Dialog/Form/Wrapper";

type RoleSelectOptionType = {
  value: UserRoleType;
  label: string;
};

interface EditUserFormProps {
  user: UserType;
  actionCallbacks?: UserActionCallbacksType;
  allowEditRole: boolean;
  allowRemoveUser: boolean;
  allowResetPassword: boolean;
}

export const EditUserForm: FC<FormProps<EditUserFormProps>> = ({
  props: {
    user,
    actionCallbacks,
    allowEditRole,
    allowRemoveUser,
    allowResetPassword,
  },
  onSubmit,
  onCancel,
}: FormProps<EditUserFormProps>) => {
  const {
    email,
    firstName = "",
    lastName = "",
    title = "",
    organization = "",
    roles,
  } = user;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<UserType>({
    defaultValues: {
      email,
      firstName,
      lastName,
      title,
      organization,
      roles,
    },
  });

  const { onRemoved, onPasswordReset, onEdited } = actionCallbacks ?? {
    onRemoved: undefined,
    onUserPasswordReset: undefined,
    onEdited: undefined,
  };

  if (allowResetPassword && !onPasswordReset) {
    throw new Error(
      "onPasswordReset is not defined but allowResetPassword is true"
    );
  }
  if (allowRemoveUser && !onRemoved) {
    throw new Error("onRemoved is not defined but allowRemoveUser is true");
  }

  const handleConfirmPasswordReset = (user: UserType) => {
    if (!allowResetPassword) {
      throw new Error(
        "handleConfirmPasswordReset called but allowResetPassword is true"
      );
    }
    onPasswordReset?.(user);
  };

  const handleConfirmRemoveUser = (user: UserType) => {
    if (!allowRemoveUser) {
      throw new Error(
        "handleConfirmRemoveUser called but allowRemoveUser is true"
      );
    }
    onRemoved?.(user);
  };

  const [
    setResetPasswordDialogOpen,
    { dialog: resetPasswordDialog },
  ] = useResetPasswordDialog(user, handleConfirmPasswordReset);

  const [
    setRemoveUserFromTrialDialogOpen,
    { dialog: removeUserFromTrialDialog },
  ] = useRemoveUserFromTrialDialog(user, handleConfirmRemoveUser);

  const handleClickResetPassword = () => {
    setResetPasswordDialogOpen(true);
  };

  const handleClickRemoveUser = () => {
    setRemoveUserFromTrialDialogOpen(true);
  };

  const handleRoleChanged = (
    value: ValueType<RoleSelectOptionType, true>
  ): UserRoleType[] | undefined => {
    if (!value) {
      return;
    }

    const roles = value.map(({ value }) => value);

    if (!roles.includes(USER)) {
      roles.push(USER);
    }
    return roles;
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmitForm = (editedUser: UserType) => {
    const edited = {
      ...user,
      ...editedUser,
    };
    onEdited?.(edited);
    onSubmit();
  };

  const roleOptions: RoleSelectOptionType[] = [
    USER,
    MANAGER,
    ANNOTATE,
    VIEW_ANNOTATIONS,
  ].map((role) => formatRole(role as UserRoleType));

  const roleValues = roles.map((role) => formatRole(role));

  const formatRoleOptionLabel = ({ value }: RoleSelectOptionType) => {
    return <RoleLabel role={value} />;
  };

  const selectStyle = getSelectStyle<RoleSelectOptionType, true>();

  return (
    <>
      {resetPasswordDialog}
      {removeUserFromTrialDialog}
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Wrapper>
          <div>
            <Label htmlFor={"email"}>Email</Label>
            <Input disabled type="text" id={"email"} {...register("email")} />
          </div>
          <div>
            <Label htmlFor={"firstName"}>First Name</Label>
            <Input
              type="text"
              id={"firstName"}
              autoFocus
              autoComplete={"new-password"}
              {...register("firstName")}
            />
          </div>
          <div>
            <Label htmlFor={"lastName"}>Last Name</Label>
            <Input
              error={errors.lastName}
              type="text"
              id={"lastName"}
              autoComplete={"new-password"}
              {...register("lastName")}
            />
          </div>
          <div>
            <Label htmlFor={"title"}>Title</Label>
            <Input type="text" id={"title"} {...register("title")} />
          </div>
          <div>
            <Label htmlFor={"organization"}>Organization</Label>
            <Input
              type="text"
              id={"organization"}
              autoComplete={"new-password"}
              {...register("organization")}
            />
          </div>
          {allowEditRole && (
            <Controller
              control={control}
              name="roles"
              render={({ field: { onChange, ref } }) => (
                <Select
                  styles={selectStyle}
                  isClearable={false}
                  isSearchable={true}
                  options={roleOptions}
                  defaultValue={roleValues}
                  formatOptionLabel={formatRoleOptionLabel}
                  menuPortalTarget={document.body}
                  onChange={(e) => onChange(handleRoleChanged(e))}
                  inputRef={ref}
                  isMulti
                />
              )}
            />
          )}
          {allowResetPassword && (
            <ButtonRowWrapper>
              Reset Password
              <FilterButton
                onClick={handleClickResetPassword}
                text={"Reset"}
                active={true}
                width={71}
              />
            </ButtonRowWrapper>
          )}
          {allowRemoveUser && (
            <ButtonRowWrapper>
              Remove User From Trial
              <FilterButton
                onClick={handleClickRemoveUser}
                text={"Remove"}
                active={true}
                width={71}
              />
            </ButtonRowWrapper>
          )}
        </Wrapper>
        <ActionButtonsWrapper>
          <InputButton type="submit" name="submit-button" value={"Confirm"} />
          <InputButton
            type="button"
            name="cancel-button"
            value={"Cancel"}
            background={main.colors.neutral.white}
            color={main.colors.neutral.black}
            onClick={handleCancel}
          />
        </ActionButtonsWrapper>
      </form>
    </>
  );
};
