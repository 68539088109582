import React, { FC } from "react";
import { Card } from "../../../common/components/cards/Card";
import { useTrialId } from "../../../Trial/hooks/useTrialId";
import { useFilteredSubjectsQuery } from "../hooks/useFilteredSubjectsQuery";
import Loading from "../../../common/components/Loading";
import handleApolloError from "../../../common/utils/handleApolloError";
import { LinkedStudyTable } from "../../../common/components/StudyTable/LinkedStudyTable";
import { TableOptions } from "../../../common/components/Table/Table";
import { StudyTableRowType } from "../../../common/components/StudyTable/StudyTableRowType";

interface StudyTableCardProps extends Partial<TableOptions<StudyTableRowType>> {
  expand?: boolean;
  hideAnalysisColumn?: boolean;
}

export const StudyTableCard: FC<StudyTableCardProps> = ({
  expand = false,
  hideAnalysisColumn = false,
  ...tableProps
}: StudyTableCardProps) => {
  const trialId = useTrialId();
  const { subjects, loading, error } = useFilteredSubjectsQuery(trialId);

  if (loading) return <Loading />;
  if (error) handleApolloError(error);
  if (!subjects) throw new Error("Subject null with no apollo error");

  const filteredSubjects = subjects.filter(
    ({ isTempDemoFixedPatient }) => !isTempDemoFixedPatient
  );

  return (
    <Card
      title={"Subjects"}
      expand={expand}
      content={
        <LinkedStudyTable
          {...tableProps}
          hideAnalysisColumn={hideAnalysisColumn}
          subjects={filteredSubjects}
        />
      }
    />
  );
};
