import dayjs from "dayjs";
import { DEFAULT_TODAY_DATE } from "../../../common/utils/dateFormatUtils/DateConstants";
import { SubjectType } from "../../common/types/SubjectType";
import { getLastFollowUpDate } from "./getLastFollowUpDate";
import { RTOGDetailDataType } from "../types/RTOGDetailDataType";

export const headers: { title: string; key: keyof RTOGDetailDataType }[] = [
  { title: "Subject ID", key: "subjectId" },
  { title: "Trial Arm", key: "trialArm" },
  { title: "Stage", key: "stage" },
  { title: "Age", key: "age" },
  { title: "Sex", key: "sex" },
  { title: "Ethnicity", key: "ethnicity" },
  { title: "BMI", key: "bmi" },
  { title: "Smoking", key: "smoking" },
  { title: "Trial Stage", key: "trialStage" },
  { title: "Read 1 Clone", key: "read1Clone" },
  { title: "Date", key: "date" },
  { title: "Since Baseline", key: "sinceBaseline" },
  { title: "Since Previous FU", key: "sincePreviousFollowup" },
  { title: "Histology", key: "histology" },
  { title: "Mutation", key: "mutation" },
];

export function parseRTOGDetailPanelContainerData(
  data: SubjectType
): RTOGDetailDataType {
  const notAvailable = "N/A";
  const diagnosis = data.diagnosis;
  const followUps = data.followUps;
  const lastFollowUpDate = getLastFollowUpDate(followUps)
    ? String(getLastFollowUpDate(followUps))
    : notAvailable;
  const isOnlyBaseline = followUps.length <= 1;

  return {
    subjectId: data.subjectId,
    trialArm: data.trialArm.name ? data.trialArm.name : notAvailable,
    stage: diagnosis.ajccStage ? diagnosis.ajccStage : notAvailable,
    age: data.dateOfBirth
      ? `${dayjs(DEFAULT_TODAY_DATE).diff(dayjs(data.dateOfBirth), "year")}`
      : notAvailable,

    sex: data.sex ? data.sex : notAvailable,
    ethnicity: data.ethnicity ? data.ethnicity : notAvailable,
    bmi: data.bmi ? String(data.bmi) : notAvailable,
    smoking: data.smokingStatus ? data.smokingStatus : notAvailable,

    trialStage: followUps.length ? `Follow up ${followUps.length}` : "Baseline",
    read1Clone: "iRECIST",
    date: diagnosis.diagnosisDate
      ? String(diagnosis.diagnosisDate)
      : notAvailable,
    sinceBaseline: diagnosis.diagnosisDate
      ? `${dayjs(DEFAULT_TODAY_DATE).diff(
          dayjs(diagnosis.diagnosisDate),
          "day"
        )} days`
      : notAvailable,

    sincePreviousFollowup:
      !isOnlyBaseline && lastFollowUpDate != notAvailable
        ? `${dayjs(DEFAULT_TODAY_DATE).diff(
            dayjs(lastFollowUpDate),
            "day"
          )} days`
        : notAvailable,
    histology: diagnosis.histology ? diagnosis.histology : notAvailable,
    mutation: diagnosis.mutation ? diagnosis.mutation : notAvailable,
  };
}
