import { gql } from "@apollo/client/core";
import { AnatomicalStructureType } from "../../Analysis/common/types/AnatomicalStructureType";

export const ANATOMICAL_STRUCTURES_FRAGMENT = gql`
  fragment AnatomicalStructure on anatomical_structures {
    id: anatomical_structures_id
    name: display_name
    structure
  }
`;

export type AnatomicalStructuresFragmentType = {
  id: number;
  name: string;
  structure: string;
};

export function parseAnatomicalStructuresFragment(
  fragment: AnatomicalStructuresFragmentType
): AnatomicalStructureType {
  return fragment;
}
