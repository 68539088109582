import React, { useEffect, useState } from "react";
import { SiteType } from "../common/types/SiteType";
import { useCurrentTrial } from "../common/queries/useCurrentTrial";
import Loading from "../common/components/Loading";
import handleApolloError from "../common/utils/handleApolloError";
import { Card } from "../common/components/cards/Card";
import { useInsertSites } from "./useInsertSites";
import { useUpdateSite } from "./useUpdateSite";
import { ContextAwareEditTrialSites } from "../common/components/SiteManagement/ContextAwareEditTrialSites";
import { useDeleteSite } from "./useDeleteSite";

export function SiteManagementCard(): JSX.Element {
  const [sites, setSites] = useState<SiteType[]>([]);

  const {
    data: trial,
    error,
    loading,
    refetch: refetchTrial,
  } = useCurrentTrial();

  const [
    insertSites,
    { error: insertSitesError, loading: insertSitesLoading },
  ] = useInsertSites();

  const [
    updateSite,
    { loading: updateSiteLoading, error: updateSiteError },
  ] = useUpdateSite();

  const [
    deleteSite,
    { error: deleteSiteError, loading: deleteSiteLoading },
  ] = useDeleteSite();

  useEffect(() => {
    if (!trial) {
      setSites([]);
      return;
    }

    const { sites } = trial;
    setSites(sites);
  }, [trial]);

  if (loading) return <Loading />;
  if (error) handleApolloError(error);
  if (insertSitesLoading) return <Loading />;
  if (insertSitesError) handleApolloError(insertSitesError);
  if (updateSiteLoading) return <Loading />;
  if (updateSiteError) handleApolloError(updateSiteError);
  if (deleteSiteLoading) return <Loading />;
  if (deleteSiteError) handleApolloError(deleteSiteError);

  const handleSitesCreated = async (sites: SiteType[]) => {
    if (!trial) {
      throw new Error("Trial is undefined in handleSiteCreated");
    }

    const { id: trialId } = trial;

    await insertSites({ trialId, sites });
    await refetchTrial();
  };

  const handleSiteRemoved = async (site: SiteType) => {
    await deleteSite(site);
    await refetchTrial();
  };

  const handleSiteEdited = async (site: SiteType) => {
    await updateSite(site);
    await refetchTrial();
  };

  return (
    <Card
      title={"Sites"}
      content={
        <ContextAwareEditTrialSites
          sites={sites}
          actionCallbacks={{
            onRemoved: handleSiteRemoved,
            onCreated: handleSitesCreated,
            onEdited: handleSiteEdited,
          }}
        />
      }
      noMenu={true}
    />
  );
}
