import { ListDisplay } from "./Dialogs/ListDisplay";
import React, { FC, useState } from "react";
import styled from "styled-components";
import { DialogContentInnerWrapper } from "../Dialog/DialogContentInnerWrapper";
import { SiteType } from "../../types/SiteType";
import { UserType } from "../../types/UserType";
import { NewTrialType } from "./NewTrialType";
import { InputBox } from "../input/InputBox";
import { DatePicker } from "../DatePicker";
import Select, { ValueType } from "react-select";
import { getSelectStyle } from "../input/getSelectStyle";
import { capitalizeFirstLetters } from "../UserManagement/utils/capitalizeFirstLetters";
import { FormProps } from "../Dialog/FormProps";
import { useForm, Controller } from "react-hook-form";
import { ActionButtonsWrapper } from "../Dialog/Form/ActionButtonsWrapper";
import { InputButton } from "../input/InputButton";
import { main } from "../../theme/main";
import { Label } from "../input/Label";
import { Input } from "../input/Input";
import { ErrorLabel } from "../input/ErrorLabel";
import { EMPTY_TRIAL } from "./EmptyTrial";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 819px;
  padding: 24px 0;
`;

const ColumnWrapper = styled(DialogContentInnerWrapper)`
  flex: 1;
  padding: 0 15px;
  height: 439px;
`;

interface CreateTrialProps {
  sites: SiteType[];
  onAddSite: () => void;
  onShowSites: () => void;
  users: UserType[];
  onAddUser: () => void;
  onShowUsers: () => void;
  onCreateTrial: (trial: NewTrialType) => void;
}

interface StatusOptions {
  label: string;
  value: string;
}

export const CreateTrialForm: FC<FormProps<CreateTrialProps>> = ({
  props: {
    sites,
    onAddSite,
    onShowSites,
    users,
    onAddUser,
    onShowUsers,
    onCreateTrial,
  },
  onSubmit,
  onCancel,
}: FormProps<CreateTrialProps>) => {
  const { estimatedCompletion: defaultEstimatedCompletion } = EMPTY_TRIAL;
  const [estimatedCompletionDate, setEstimatedCompletionDate] = useState<
    Date | undefined
  >(defaultEstimatedCompletion);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<NewTrialType>({
    defaultValues: EMPTY_TRIAL,
  });

  const handleStatusChanged = (
    value: ValueType<StatusOptions, false>
  ): string | undefined => {
    if (!value) {
      return;
    }

    const { value: status } = value;
    return status;
  };

  const handleEstimatedCompletionChanged = (estimatedCompletion?: Date) => {
    const date = estimatedCompletion ?? new Date("2023-01-01");
    setEstimatedCompletionDate(date);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmitForm = (newTrial: NewTrialType) => {
    const trial = {
      ...newTrial,
      estimatedCompletionDate,
    };
    onCreateTrial(trial);
    onSubmit();
  };

  const statusOptions = ["ACTIVE", "DRAFT", "DONE"].map((status) => ({
    label: capitalizeFirstLetters(status),
    value: status,
  }));

  const selectStyle = getSelectStyle<StatusOptions, false>();

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Wrapper>
        <ColumnWrapper>
          <div>
            <Label htmlFor={"trial-name"} required error={errors.name}>
              Trial Name
            </Label>
            <Input
              error={errors.name}
              type="text"
              id={"trial-name"}
              autoFocus
              autoComplete={"off"}
              {...register("name", { required: true })}
            />
            {errors.name && (
              <ErrorLabel>Trial name must be provided</ErrorLabel>
            )}
          </div>
          <div>
            <Label htmlFor={"indication"} required error={errors.indication}>
              Indication
            </Label>
            <Input
              error={errors.name}
              type="text"
              id={"indication"}
              autoComplete={"off"}
              {...register("indication", { required: true })}
            />
            {errors.indication && (
              <ErrorLabel>Indication must be provided</ErrorLabel>
            )}
          </div>
          <div>
            <Label
              htmlFor={"investigation-product"}
              required
              error={errors.investigationProduct}
            >
              Investigation Product
            </Label>
            <Input
              error={errors.investigationProduct}
              type="text"
              id={"investigationProduct"}
              autoComplete={"off"}
              {...register("investigationProduct", { required: true })}
            />
            {errors.investigationProduct && (
              <ErrorLabel>Investigation Product must be provided</ErrorLabel>
            )}
          </div>
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, ref } }) => (
              <Select
                styles={selectStyle}
                defaultValue={statusOptions[0]}
                isClearable={false}
                isSearchable={true}
                options={statusOptions}
                onChange={(e) => onChange(handleStatusChanged(e))}
                menuPortalTarget={document.body}
                inputRef={ref}
              />
            )}
          />
          <InputBox name={"Estimated Completion"} required={true}>
            <DatePicker
              date={estimatedCompletionDate}
              onDateChanged={handleEstimatedCompletionChanged}
            />
          </InputBox>
          <div>
            <Label htmlFor={"study-number"} required error={errors.studyNumber}>
              Study Number
            </Label>
            <Input
              error={errors.studyNumber}
              type="number"
              id={"study-number"}
              autoComplete={"off"}
              {...register("studyNumber", { required: true })}
            />
            {errors.studyNumber && (
              <ErrorLabel>Study number must be provided</ErrorLabel>
            )}
          </div>
        </ColumnWrapper>
        <ColumnWrapper>
          <div>
            <Label htmlFor={"phase"} required error={errors.phase}>
              Phase
            </Label>
            <Input
              error={errors.phase}
              type="text"
              id={"phase"}
              autoComplete={"off"}
              {...register("phase", { required: true })}
            />
            {errors.phase && <ErrorLabel>Phase must be provided</ErrorLabel>}
          </div>
          <div>
            <Label htmlFor={"design"} required error={errors.design}>
              Design
            </Label>
            <Input
              error={errors.design}
              type="text"
              id={"design"}
              autoComplete={"off"}
              {...register("design", { required: true })}
            />
            {errors.design && <ErrorLabel>Design must be provided</ErrorLabel>}
          </div>
          <div>
            <Label
              htmlFor={"primary-endpoints"}
              required
              error={errors.primaryEndpoints}
            >
              Primary Endpoint(s)
            </Label>
            <Input
              error={errors.primaryEndpoints}
              type="text"
              id={"primary-endpoints"}
              autoComplete={"off"}
              {...register("primaryEndpoints", { required: true })}
            />
            {errors.primaryEndpoints && (
              <ErrorLabel>Primary Endpoint(s) must be provided</ErrorLabel>
            )}
          </div>
          <div>
            <Label
              htmlFor={"secondary-endpoints"}
              required
              error={errors.secondaryEndpoints}
            >
              Secondary Endpoint(s)
            </Label>
            <Input
              error={errors.secondaryEndpoints}
              type="text"
              id={"secondary-endpoints"}
              autoComplete={"off"}
              {...register("secondaryEndpoints", { required: true })}
            />
            {errors.secondaryEndpoints && (
              <ErrorLabel>Secondary Endpoint(s) must be provided</ErrorLabel>
            )}
          </div>
          <ListDisplay
            label={"Sites"}
            count={sites.length}
            onClickAdd={onAddSite}
            onClickLabel={onShowSites}
          />
          <ListDisplay
            label={"Team Members"}
            count={users.length}
            onClickAdd={onAddUser}
            onClickLabel={onShowUsers}
          />
        </ColumnWrapper>
      </Wrapper>
      <ActionButtonsWrapper>
        <InputButton type="submit" name="submit-button" value={"Confirm"} />
        <InputButton
          type="button"
          name="cancel-button"
          value={"Cancel"}
          background={main.colors.neutral.white}
          color={main.colors.neutral.black}
          onClick={handleCancel}
        />
      </ActionButtonsWrapper>
    </form>
  );
};
