import React, { Dispatch, SetStateAction, useState } from "react";
import { UserType } from "../../types/UserType";

type UserManagementRulesType = {
  allowEditOtherUser: boolean;
  allowEditRole: boolean;
  allowResetPassword: boolean;
  allowRemoveUser: boolean;
  allowCreateUser: boolean;
};

export const createTrialUserContext: UserManagementRulesType = {
  allowEditOtherUser: false,
  allowEditRole: true,
  allowResetPassword: false,
  allowRemoveUser: true,
  allowCreateUser: true,
};

export const manageTrialUserContext: UserManagementRulesType = {
  allowEditOtherUser: true,
  allowEditRole: true,
  allowResetPassword: true,
  allowRemoveUser: true,
  allowCreateUser: true,
};

export const manageCurrentUserContext: UserManagementRulesType = {
  allowEditOtherUser: false,
  allowEditRole: false,
  allowResetPassword: true,
  allowRemoveUser: false,
  allowCreateUser: false,
};

type UserManagementContextType = {
  users: UserType[];
  setUsers: Dispatch<SetStateAction<UserType[]>>;
  rules: UserManagementRulesType;
};

export const UserManagementContext = React.createContext<UserManagementContextType>(
  {
    users: [],
    setUsers: (users: SetStateAction<UserType[]>) => undefined,
    rules: manageTrialUserContext,
  }
);

interface UserContextProps {
  children: JSX.Element;
  rules: UserManagementRulesType;
  users: UserType[];
  setUsers: Dispatch<SetStateAction<UserType[]>>;
}

export const UserManagementContextProvider = ({
  rules,
  children,
  users,
  setUsers,
}: UserContextProps) => {
  return (
    <UserManagementContext.Provider value={{ users, setUsers, rules }}>
      {children}
    </UserManagementContext.Provider>
  );
};
