import React, { ReactElement } from "react";
import RecentTrials from "./RecentTrials";
import Text from "../../common/components/text/Text";
import { useQuery } from "@apollo/client";
import handleApolloError from "../../common/utils/handleApolloError";
import { loadRecentTrials } from "../../Trial/utils/localStorage";
import { GET_TRIAL_DETAILS_BY_IDS } from "../../common/queries/GetTrialDetailsByIds";
import { RawTrialDetailsType } from "../../common/types/RawTrialDetailsType";
import { parseTrialDetails } from "../utils/parseTrialDetails";
import { AltLoading } from "../../common/components/AltLoading";
import { main } from "../../common/theme/main";

const RecentTrialsContainer = (): ReactElement => {
  const recentTrialIds = loadRecentTrials();
  if (recentTrialIds.length === 0) {
    return <Text text={"No Recent Trials"} />;
  }

  const { loading, data, error } = useQuery<RawTrialDetailsType>(
    GET_TRIAL_DETAILS_BY_IDS,
    {
      variables: { trialIds: recentTrialIds },
    }
  );
  if (loading || !data) {
    return <AltLoading />;
  }
  if (error) {
    handleApolloError(error);
  }

  const recentTrials = parseTrialDetails(data);

  return <RecentTrials recentTrials={recentTrials} />;
};

export default RecentTrialsContainer;
