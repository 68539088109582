import { main } from "../../common/theme/main";

export const NEW = "NEW";
export const ACTIVE = "ACTIVE";
export const PLANNING = "PLANNING";
export const SUSPENDED = "SUSPENDED";
export const ENROLLING = "ENROLLING";
export const COMPLETED = "COMPLETED";
export const TERMINATED = "TERMINATED";
export const DONE = "DONE";
export const DEFAULT = "DEFAULT";

interface colorConfigProps {
  fill: string;
  text: string;
  border: string;
}

export const colorConfig = {
  [NEW]: {
    fill: main.colors.secondary.secondary20,
    text: main.colors.secondary.secondary,
    border: "none",
  },
  [ACTIVE]: {
    fill: main.colors.actionSecondary.default,
    text: main.colors.actionPrimary.inverted,
    border: "none",
  },
  [PLANNING]: {
    fill: main.colors.states.successBg,
    text: main.colors.actionSecondary.default,
    border: main.colors.actionSecondary.default,
  },
  [SUSPENDED]: {
    fill: main.colors.accent.altAccent3,
    text: main.colors.accent.accent3,
    border: "none",
  },
  [ENROLLING]: {
    fill: main.colors.accent.altAccent4,
    text: main.colors.accent.accent4,
    border: "none",
  },
  [COMPLETED]: {
    fill: main.colors.neutral.neutral7,
    text: main.colors.neutral.neutral3,
    border: "none",
  },
  [TERMINATED]: {
    fill: main.colors.states.errorBg,
    text: main.colors.states.error,
    border: "none",
  },
  [DEFAULT]: {
    fill: main.colors.neutral.neutral7,
    text: main.colors.neutral.neutral3,
    border: "none",
  },
};

export function getColorFromStatus(status: string): colorConfigProps {
  switch (status) {
    case NEW:
      return colorConfig[NEW];
    case ACTIVE:
      return colorConfig[ACTIVE];
    case PLANNING:
      return colorConfig[PLANNING];
    case SUSPENDED:
      return colorConfig[SUSPENDED];
    case ENROLLING:
      return colorConfig[ENROLLING];
    case COMPLETED:
      return colorConfig[COMPLETED];
    case DONE:
      return colorConfig[COMPLETED];
    case TERMINATED:
      return colorConfig[TERMINATED];
    default:
      return colorConfig[DEFAULT];
  }
}
