import React, { useState } from "react";
import {
  Bar,
  BarChart,
  Cell,
  Legend as ReChartsLegend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  YAxis,
} from "recharts";
import { MortalityBaselineChartTooltip } from "./MortalityBaselineChartTooltip";
import { Payload } from "recharts/src/component/DefaultLegendContent";
import {
  DataType,
  SubjectMortalityVsBaselineType,
} from "../utils/parseMortalityBaselineChartData";
import styled from "styled-components";
import { BOTH, Filter, FilterType, IPRO, RECIST } from "./Filter";
import { Legend } from "../../common/components/Legend";
import { getYAxisProps } from "../../common/utils/getYAxisProps";
import {
  getYAxisLabel,
  getYAxisLabelRight,
} from "../../common/utils/getYAxisLabel";
import { armFilterColors } from "../../common/utils/armColors";
import {
  REFERENCE_LINE_STOKE,
  REFERENCE_LINE_STROKE_DASH_ARRAY,
} from "../../common/utils/strokeSettings";
import { formatArmId } from "../../common/utils/formatArmId";
import { useGoToPatientPage } from "../../../Trial/hooks/useGoToPatientPage";
import { TUMOR_SPELLING } from "../../../common/utils/spellingConstants";
import { useFilteredTrialArmIds } from "../../common/hooks/useFilteredTrialArmIds";
import { getTicksAtInterval } from "../../common/utils/getTicksAtInterval";
import { formatTicksEveryOther } from "../../common/utils/formatTicksEveryOther";

const Wrapper = styled.div`
  flex: 1;
  position: relative;
`;

interface MortalityBaselineChartProps {
  subjectsData: SubjectMortalityVsBaselineType[];
}

function MortalityBaselineChart({
  subjectsData,
}: MortalityBaselineChartProps): React.ReactElement {
  const trialArmIds = useFilteredTrialArmIds();
  const [filter, setFilter] = useState<FilterType>(RECIST);

  let sorted = [...subjectsData];
  const sortBy = filter === BOTH ? RECIST : (filter as DataType);
  sorted = sorted.sort((a, b) => (a.data[sortBy] < b.data[sortBy] ? 1 : -1));

  const allSurvivals = sorted.map((s) => s.data[IPRO]);
  const survivalMax = Math.ceil(Math.max(...allSurvivals));
  const survivalMin = Math.floor(Math.min(...allSurvivals));

  const allBurdens = sorted.map((s) => s.data[RECIST]);
  const burdenMax = Math.ceil(Math.max(...allBurdens));
  const burdenMin = Math.floor(Math.min(...allBurdens));

  const allMax = Math.max(burdenMax, survivalMax);
  const allMin = Math.min(burdenMin, survivalMin);

  const { goToPage } = useGoToPatientPage();

  const legendPayload: Payload[] = trialArmIds.map((trialArm) => {
    const { color } = armFilterColors[trialArm][RECIST];
    const name = formatArmId(trialArm);
    return {
      id: name,
      type: "square",
      value: name,
      color: color,
    };
  });

  const renderLegend = (props: any) => {
    return <Legend {...props} />;
  };

  const onClickPatient = (data: any) => {
    if (!data) {
      return;
    }

    const { activePayload } = data;
    if (!activePayload) {
      return;
    }

    const firstPayload = activePayload.length > 0 ? activePayload[0] : null;
    if (!firstPayload) {
      return;
    }

    const {
      payload: { id },
    } = firstPayload;

    goToPage(id);
  };

  const yAxisProps = getYAxisProps(true);

  const customCell = (filter: DataType) => {
    return sorted.map((entry, index) => {
      const {
        trialArm: { number },
      } = entry;
      const { color } = armFilterColors[number][filter];
      return <Cell key={index} fill={color} />;
    });
  };

  const renderToolTip = (props: any) => {
    return <MortalityBaselineChartTooltip {...props} filter={filter} />;
  };

  const showBoth = filter === BOTH;
  const showBurden = filter === RECIST || showBoth;
  const showSurvival = filter === IPRO || showBoth;

  const burdenYAxisLabel = getYAxisLabel(`% ${TUMOR_SPELLING} Burden Change`);
  const survivalYAxisLabel = getYAxisLabelRight("% Survival Change");

  const ticks = getTicksAtInterval(allMin, allMax, 25);
  const domain = [allMin, allMax];

  return (
    <Wrapper>
      <ResponsiveContainer height={423}>
        <BarChart
          onClick={onClickPatient}
          data={sorted}
          margin={{
            top: 0,
            right: 20,
            left: 20,
            bottom: 20,
          }}
          barGap={0}
          barCategoryGap={0}
        >
          <RechartsTooltip content={renderToolTip} />
          <RechartsTooltip />
          <ReChartsLegend
            payload={legendPayload}
            align="left"
            verticalAlign="top"
            height={48}
            content={renderLegend}
          />
          <YAxis
            {...yAxisProps}
            yAxisId={RECIST}
            orientation={"left"}
            tickFormatter={formatTicksEveryOther}
            ticks={showBurden ? ticks : []}
            type={"number"}
            domain={showBurden ? domain : [0, "auto"]}
          >
            {showBurden && burdenYAxisLabel}
          </YAxis>
          <YAxis
            {...yAxisProps}
            yAxisId={IPRO}
            type={"number"}
            orientation={"right"}
            tickFormatter={formatTicksEveryOther}
            ticks={showSurvival ? ticks : []}
            domain={showSurvival ? domain : [0, "auto"]}
          >
            {showSurvival && survivalYAxisLabel}
          </YAxis>

          {showBurden && (
            <ReferenceLine
              y={20}
              stroke={REFERENCE_LINE_STOKE}
              strokeDasharray={REFERENCE_LINE_STROKE_DASH_ARRAY}
              yAxisId={RECIST}
            />
          )}
          {showBurden && (
            <ReferenceLine
              y={-30}
              stroke={REFERENCE_LINE_STOKE}
              strokeDasharray={REFERENCE_LINE_STROKE_DASH_ARRAY}
              yAxisId={RECIST}
            />
          )}
          {showBurden && (
            <Bar
              dataKey={"data[RECIST]"}
              yAxisId={RECIST}
              name={"% Burden Change"}
              isAnimationActive={false}
            >
              {customCell(RECIST)}
            </Bar>
          )}
          {showSurvival && (
            <Bar
              dataKey={"data[IPRO]"}
              yAxisId={IPRO}
              name={"% Survival Change"}
              isAnimationActive={false}
            >
              {customCell(IPRO)}
            </Bar>
          )}
        </BarChart>
      </ResponsiveContainer>
      <Filter onFilterSelected={setFilter} activeFilter={filter} />
    </Wrapper>
  );
}

export default MortalityBaselineChart;
