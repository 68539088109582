import { SubjectType } from "../../common/types/SubjectType";
import { getFollowUpAtOrder } from "../../common/utils/getFollowUpAtOrder";
import { SurvivalBinType } from "./SurvivalBinType";
import { SurvivalHistogramType } from "./SurvivalHistogramType";

export function getSubjectSurvivalHistogram(
  subjects: SubjectType[],
  followUpOrder: number,
  binSize: number
): SurvivalHistogramType {
  const getSurvivalPrediction = (subject: SubjectType): number | null => {
    const { followUps } = subject;
    const followUp = getFollowUpAtOrder(followUps, followUpOrder);
    if (!followUp) {
      throw new Error("Subject follow up does not exist!");
    }

    const { survivalPredictions } = followUp;
    return survivalPredictions.length > 0 ? survivalPredictions[0] : null;
  };

  const allSurvivals: number[] = subjects
    .map(getSurvivalPrediction)
    .filter((prediction): prediction is number => prediction !== null);

  const minSurvival = Math.min(...allSurvivals);
  const maxSurvival = Math.max(...allSurvivals);

  const numberOfBins = (maxSurvival - minSurvival) / binSize;

  const histogram: SurvivalBinType[] = [];
  for (let i = 0; i < numberOfBins; i++) {
    const binMin = minSurvival + binSize * i;
    const binMax = minSurvival + binSize * (i + 1);
    histogram.push({
      id: i,
      range: {
        min: binMin,
        max: binMax,
      },
      subjectsByArm: {},
    });
  }

  for (const subject of subjects) {
    const {
      trialArm: { number },
    } = subject;

    const survivalPrediction = getSurvivalPrediction(subject);
    if (survivalPrediction === null) {
      continue;
    }

    let match = histogram.find((survivalBin) => {
      const {
        range: { min, max },
      } = survivalBin;
      return survivalPrediction >= min && survivalPrediction < max;
    });
    if (!match) {
      //there will be no match for max values, so they go in the last bin
      match = histogram[histogram.length - 1];
    }

    if (!match.subjectsByArm[number]) {
      match.subjectsByArm[number] = [];
    }

    match.subjectsByArm[number].push(subject);
  }
  return histogram;
}
