import React, { ReactElement } from "react";
import styled from "styled-components";

const IconButtonStyle = styled.button`
  border: none;
  cursor: pointer;
  background: transparent;
  padding: 4px;
`;

interface IconButtonProps {
  icon: ReactElement;
  onClick?: React.MouseEventHandler;
}

const IconButton = ({ icon, onClick }: IconButtonProps): ReactElement => {
  return <IconButtonStyle onClick={onClick}>{icon}</IconButtonStyle>;
};

export default IconButton;
