import { TableRowBase } from "../TableRowBase";
import { FilterProps, IdType, Row } from "react-table";
import React from "react";
import { Button, TextField } from "@material-ui/core";

function getMinMax<D extends TableRowBase>(
  rows: Row<D>[],
  id: IdType<D>
): [number, number] {
  let min = rows.length ? rows[0].values[id] : 0;
  let max = rows.length ? rows[0].values[id] : 0;
  rows.forEach((row) => {
    min = Math.min(row.values[id], min);
    max = Math.max(row.values[id], max);
  });
  return [min, max];
}

export function SliderColumnFilter<D extends TableRowBase>({
  column: { render, filterValue, setFilter, preFilteredRows, id },
}: FilterProps<D>): JSX.Element {
  const [min, max] = React.useMemo(() => getMinMax(preFilteredRows, id), [
    id,
    preFilteredRows,
  ]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
      }}
    >
      <TextField
        name={id}
        label={render("Header")}
        type="range"
        inputProps={{
          min,
          max,
        }}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <Button
        variant="outlined"
        style={{ width: 60, height: 36 }}
        onClick={() => setFilter(undefined)}
      >
        Off
      </Button>
    </div>
  );
}
