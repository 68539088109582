import React, { ReactElement } from "react";
import { TinyKaplanChartContainer } from "../../Analysis/KaplanMeier/components/TinyKaplanChartContainer";

interface TrialPreviewProps {
  trialId: number;
  status: string;
}

const TrialPreview = ({ trialId, status }: TrialPreviewProps): ReactElement => {
  return <TinyKaplanChartContainer trialId={trialId} />;
};

export default TrialPreview;
