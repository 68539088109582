import { DataType } from "../../ChangeFromBaseline/utils/parseMortalityBaselineChartData";
import { IPRO, RECIST } from "../../ChangeFromBaseline/components/Filter";
import { TUMOR_SPELLING } from "../../../common/utils/spellingConstants";

export function formatDataType(dataType: DataType): string {
  let formatted;
  switch (dataType) {
    case IPRO:
      formatted = "Predicted Survival";
      break;
    case RECIST:
      formatted = `${TUMOR_SPELLING} Burden`;
      break;
    default:
      throw new Error("DataType not supported in formatDataType");
  }
  return formatted;
}
