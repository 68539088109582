import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import { useScrollToTop } from "./hooks/useScrollToTop";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.background.main};
`;

const TopBarWrapper = styled.div`
  display: flex;
  height: 64px;
  background: ${(props) => props.theme.colors.actionPrimary.inverted};
  box-shadow: inset 0px -1px 0px #e4e4ed;
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  align-content: stretch;
`;

const SideBarWrapper = styled.div`
  width: 280px;
  display: flex;
  padding: 24px 12px 0 12px;
`;

const MainContentWrapper = styled.div`
  overflow-y: auto;
  flex: 1;
  overflow-x: auto;
  width: calc(100vw - 480px - 48px - 48px);
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`;

interface TrialContentProps {
  topBar: ReactElement;
  mainContent: ReactElement;
  sideBar?: ReactElement;
}

export const TrialContent: FC<TrialContentProps> = ({
  topBar,
  mainContent,
  sideBar,
}: TrialContentProps) => {
  const scrollRestoreRef = useScrollToTop<HTMLDivElement>();
  return (
    <Wrapper>
      <TopBarWrapper>{topBar}</TopBarWrapper>
      <ContentWrapper>
        <MainContentWrapper ref={scrollRestoreRef}>
          {mainContent}
        </MainContentWrapper>
        {sideBar && <SideBarWrapper>{sideBar}</SideBarWrapper>}
      </ContentWrapper>
    </Wrapper>
  );
};
