import { useSelector } from "react-redux";
import { useFilteredSubjectsQuery } from "./useFilteredSubjectsQuery";
import { SubjectQueryResultType } from "../types/SubjectQueryResultType";
import { SubjectType } from "../types/SubjectType";
import {
  FilterTypes,
  getTrialViewFilters,
} from "../../../common/store/trialViewFiltersSlice";
import {
  STABLE_DISEASE,
  TumourResponseType,
} from "../types/TumourResponseType";
import { getMostRecentFollowUp } from "../utils/getMostRecentFollowUp";
import { getFollowUpTumourResponse } from "../utils/getFollowUpTumourResponse";
import { FollowUpType } from "../types/FollowUpType";

function isMostRecentFollowUpStableDisease(followUps: FollowUpType[]): boolean {
  const mostRecentFollowUp = getMostRecentFollowUp(followUps);
  if (!mostRecentFollowUp) {
    return false;
  }

  const mostRecentTumourResponse = getFollowUpTumourResponse(
    mostRecentFollowUp
  );

  return mostRecentTumourResponse === STABLE_DISEASE;
}

function filter(subjects: SubjectType[], filters: FilterTypes): SubjectType[] {
  //if SD = false, it should ONLY exclude patient that has SD has most recent
  const recistFilters = filters.recist1_1;
  const enabledFilters = Object.keys(recistFilters)
    .map((key) => key as TumourResponseType)
    .filter((response) => recistFilters[response] === true);

  return subjects.filter((subject) => {
    const { followUps } = subject;
    const allResponse = [
      ...new Set(followUps.flatMap((followUp) => followUp.tumourResponses)),
    ];

    if (recistFilters[STABLE_DISEASE] === false) {
      const mostRecentIsStableDisease = isMostRecentFollowUpStableDisease(
        followUps
      );
      if (mostRecentIsStableDisease) {
        return false;
      }
      enabledFilters.push(STABLE_DISEASE);
    }

    return allResponse.every((response) => enabledFilters.includes(response));
  });
}

// NOTE: The subjects returned by this hook are also filtered by subject filters
export function useTumorClassificationFilteredSubjectsQuery(
  trialId: number
): SubjectQueryResultType {
  const { subjects, loading, error, refetch } = useFilteredSubjectsQuery(
    trialId
  );

  const filters = useSelector(getTrialViewFilters);
  let filteredSubjects;

  // NOTE: Assumes follow_ups are ordered by descending fu_order
  if (!loading && !error && subjects) {
    filteredSubjects = filter(subjects, filters);
  }

  return {
    subjects: filteredSubjects,
    loading,
    error,
    refetch,
  };
}
