import React from "react";
import styled from "styled-components";

const StyledH3 = styled.h3`
  font-size: 13px;
  font-weight: 600;
`;

interface Heading3Props {
  title: string;
}
function Heading3({
  title,
}: Heading3Props): React.ReactElement<
  React.HTMLAttributes<HTMLHeadingElement>
> {
  return <StyledH3>{title}</StyledH3>;
}

export default Heading3;
