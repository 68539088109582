import React from "react";
import {
  MANAGER,
  USER,
  ANNOTATE,
  VIEW_ANNOTATIONS,
  UserRoleType,
} from "../../types/UserRoleType";
import styled from "styled-components";
import { main } from "../../theme/main";
import { capitalizeFirstLetters } from "./utils/capitalizeFirstLetters";

const OuterWrapper = styled.div`
  display: inline-block;
`;

const Wrapper = styled.div<{ background: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  background: ${(props) => props.background};
  border-radius: 4px;
`;

const Text = styled.div<{ color: string }>`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: ${(props) => props.color};
`;

interface RoleLabelProps {
  role: UserRoleType;
}

const roleColors: Record<
  UserRoleType,
  { color: string; background: string }
> = {
  [USER]: {
    color: main.colors.accent.accent1,
    background: "rgba(236, 178, 242, 0.28)",
  },
  [MANAGER]: {
    color: "#ECB2F2",
    background: main.colors.accent.accent1,
  },
  [ANNOTATE]: {
    color: "#ECB2F2",
    background: main.colors.accent.accent1,
  },
  [VIEW_ANNOTATIONS]: {
    color: "#ECB2F2",
    background: main.colors.accent.accent1,
  },
};

export function RoleLabel({ role }: RoleLabelProps): JSX.Element {
  const text = capitalizeFirstLetters(role.split("_").join(" "));

  const { color, background } = roleColors[role];

  return (
    <OuterWrapper>
      <Wrapper background={background}>
        <Text color={color}>{text}</Text>
      </Wrapper>
    </OuterWrapper>
  );
}
