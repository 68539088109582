import { SubjectImageType } from "../types/SubjectImageType";
import ctImage from "../../../assets/images/ct-thumbnail.png";
import { FollowUpType } from "../../common/types/FollowUpType";
import { MISSING } from "../../common/types/TumourResponseType";

export function getSubjectImageFromFollowUp(
  followUp: FollowUpType
): SubjectImageType {
  const { date, order: followUpOrder, tumourResponses } = followUp;
  const response = tumourResponses.length > 0 ? tumourResponses[0] : MISSING;
  return {
    //TODO replace with DICOMweb query
    imageUrl: ctImage,
    followUpOrder,
    date: date,
    response,
    linkUrl: "TBD",
  };
}
