import {
  SMOKING_CURRENT,
  SMOKING_FORMER,
  SMOKING_NEVER,
} from "../../../Analysis/common/types/SmokingStatusType";
import { RootState } from "../rootReducer";
import { TrialFilterSelectors } from "./TrialFilterSelectors";
import { LifestyleFilters } from "../../types/LifestyleFilterTypes";

export const defaultLifestyleState = {
  bmi: {
    min: null,
    max: null,
  },
  smokingStatus: {
    [SMOKING_CURRENT]: true,
    [SMOKING_FORMER]: true,
    [SMOKING_NEVER]: true,
  },
};

export const clearLifestyleState = {
  bmi: {
    min: null,
    max: null,
  },
  smokingStatus: {
    [SMOKING_CURRENT]: false,
    [SMOKING_FORMER]: false,
    [SMOKING_NEVER]: false,
  },
};

export const lifestyleFilters = new TrialFilterSelectors<LifestyleFilters>(
  defaultLifestyleState,
  clearLifestyleState,
  (state: RootState) => state.trialViewFilters.lifestyle
);
