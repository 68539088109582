import React from "react";
import styled from "styled-components";
import { ReactComponent as TreeIcon } from "../../../assets/svgs/Tree.svg";
import CTAButton from "../../../common/components/buttons/CTAButton";
import { getFormattedLong } from "../../../common/utils/dateFormatUtils/getFormattedDateLong";
import { SvgIcon } from "../../../common/components/icons/SvgIcon";

const PaddingBottom = styled.div`
  padding-bottom: 8px;
`;

const BoldedItemSubtext2 = styled.div`
  font-weight: 600;
  color: ${(props) => props.theme.colors.neutral.neutral5};
  font-size: 11px;
`;

const PaddingBottomForLargeText = styled.div`
  padding-bottom: 3px;
`;

const StyledItemText = styled.div`
  color: ${(props) => props.theme.colors.neutral.black};
  font-size: 16px;
  font-weight: 600;
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const VerticalBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  margin-right: 8px;
  :first-child {
    margin-left: 16px;
  }
  :last-child {
    margin-right: 16px;
  }
`;

const CTAButtonWrapper = styled.div`
  padding-left: 8px;
  padding-right: 8px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  background: ${(props) => props.theme.colors.neutral.neutral8};
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;

const StyledItemSubtext = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.neutral.black};
  font-weight: 500;
`;

interface AlgorithmSummaryProps {
  algorithmName: string;
  createdOn: string | null;
  lastRun: string | null;
  // eslint-disable-next-line no-unused-vars
  onClickAlgorithms: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const MiddleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`;

function AlgorithmSummary({
  algorithmName,
  createdOn,
  lastRun,
  onClickAlgorithms,
}: AlgorithmSummaryProps): React.ReactElement {
  const lastRunString = lastRun ? getFormattedLong(lastRun) : "-";
  const createdOnString = createdOn ? getFormattedLong(createdOn) : "-";
  return (
    <RowWrapper>
      <IconWrapper>
        <SvgIcon icon={TreeIcon} size={16} />
      </IconWrapper>
      <MiddleWrapper>
        <VerticalBlock>
          <PaddingBottomForLargeText>
            <BoldedItemSubtext2>{"Algorithm"}</BoldedItemSubtext2>
          </PaddingBottomForLargeText>
          <StyledItemText>{algorithmName}</StyledItemText>
        </VerticalBlock>
        <VerticalBlock>
          <PaddingBottom>
            <BoldedItemSubtext2>{"Last Run"}</BoldedItemSubtext2>
          </PaddingBottom>
          <StyledItemSubtext>{lastRunString}</StyledItemSubtext>
        </VerticalBlock>
        <VerticalBlock>
          <PaddingBottom>
            <BoldedItemSubtext2>{"Created"}</BoldedItemSubtext2>
          </PaddingBottom>
          <StyledItemSubtext>{createdOnString}</StyledItemSubtext>
        </VerticalBlock>
      </MiddleWrapper>
      <ButtonsWrapper>
        <CTAButtonWrapper>
          <CTAButton text={"Edit"} onClick={onClickAlgorithms} />
        </CTAButtonWrapper>
        <CTAButtonWrapper>
          <CTAButton
            text={"Run Algorithm"}
            blackBackground={true}
            onClick={onClickAlgorithms}
          />
        </CTAButtonWrapper>
      </ButtonsWrapper>
    </RowWrapper>
  );
}

export default AlgorithmSummary;
