export const AJCC_IA = "IA";
export const AJCC_IB = "IB";
export const AJCC_IIA = "IIA";
export const AJCC_IIB = "IIB";
export const AJCC_IIIA = "IIIA";
export const AJCC_IIIB = "IIIB";
export const AJCC_IIIC = "IIIC";
export const AJCC_IVA = "IV"; //the db current only uses "4" so display it as "IV"
export const AJCC_IVB = "IVB";

export type AjccStageType =
  | typeof AJCC_IA
  | typeof AJCC_IB
  | typeof AJCC_IIA
  | typeof AJCC_IIB
  | typeof AJCC_IIIA
  | typeof AJCC_IIIB
  | typeof AJCC_IIIC
  | typeof AJCC_IVA
  | typeof AJCC_IVB;

export function getAjccStageType(ajccStageStr: string): AjccStageType {
  const dbNames: Record<string, AjccStageType> = {
    IA: AJCC_IA,
    IB: AJCC_IB,
    IIA: AJCC_IIA,
    IIB: AJCC_IIB,
    IIIA: AJCC_IIIA,
    IIIB: AJCC_IIIB,
    IIIC: AJCC_IIIC,
    IV: AJCC_IVA,
    IVA: AJCC_IVA,
    IVB: AJCC_IVB,
  };

  return dbNames[ajccStageStr] ?? null;
}
