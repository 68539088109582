import React, { createRef, FC, forwardRef, useCallback, useState } from "react";
import { Card } from "../../common/components/cards/Card";
import { useTrialId } from "../../Trial/hooks/useTrialId";
import { useSubjectQuery } from "../../Analysis/common/hooks/useSubjectQuery";
import handleApolloError from "../../common/utils/handleApolloError";
import {
  filterStudyTableRow,
  StudyTableRowType,
} from "../../common/components/StudyTable/StudyTableRowType";
import styled from "styled-components";
import {
  ANATOMICAL_STRUCTURES_HEADER_ID,
  ASSIGNED_USERS_HEADER_ID,
  CASE_IDS_HEADER_ID,
  defaultColumnIds,
  getColumns,
} from "../../common/components/StudyTable/StudyTableColumns";
import { AnatomicalStructureType } from "../../Analysis/common/types/AnatomicalStructureType";
import { AnatomicalStructuresSelect } from "../../common/components/StudyTable/AnatomicalStructuresSelect";
import { ADD, REMOVE, UpdateModeType } from "../../common/types/UpdateModeType";
import {
  InnerButtonWrapper,
  InputDiv,
} from "../../common/components/input/InputButton";
import { main } from "../../common/theme/main";
import {
  Table,
  TableControlsType,
  TableProps,
} from "../../common/components/Table/Table";
import { Column } from "react-table";
import { getStudyRowsFromSubjects } from "../../common/components/StudyTable/utils/getStudyRowsFromSubjects";
import { useEffect } from "react";
import { useInsertCases } from "./useInsertCases";
import { AltLoading } from "../../common/components/AltLoading";
import FilterCheckbox from "../../Analysis/ToolPanel/components/FilterCheckbox";
import { ExportLesionsButton } from "./ExportLesionsButton";
import { UserType } from "../../common/types/UserType";
import { useTrialUsers } from "../../Settings/useTrialUsers";
import { UserSelect } from "../../common/components/UserManagement/UserSelect";
import { insertAnatomicalStructures } from "../../common/components/StudyTable/insertAnatomicalStructures";
import { annotateClient } from "../../apollo/client";
import { useAnatomicalStructures } from "./useAnatomicalStructures";
import { StudyTableWithRef } from "../../common/components/StudyTable/StudyTableWithRef";

const ToolbarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  padding: 20px;
  gap: 12px;
`;

const SelectorsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const AnatomySelectorWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const ExpandWrapper = styled.div`
  flex: 1;
  padding: 2px;
`;

const CollapseWrapper = styled.div`
  flex: 0;
`;

const SelectLabelWrapper = styled.div`
  width: 42px;
  font-size: 14px;
  font-weight: 600;
`;

export const ManageAnnotations: FC = () => {
  const [rows, setRows] = useState<StudyTableRowType[]>([]);
  const [selectedStudyRows, setSelectedStudyRows] = useState<
    StudyTableRowType[]
  >([]);
  const [
    selectedAnatomicalStructures,
    setSelectedAnatomicalStructures,
  ] = useState<AnatomicalStructureType[]>([]);
  const [updateMode, setUpdateMode] = useState<UpdateModeType>(ADD);
  const [casesLoading, setCasesChangedLoading] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<UserType[]>([]);
  const [filter, setFilter] = useState<string>("");

  const trialId = useTrialId();
  const {
    data: trialUsers,
    loading: trialUsersLoading,
    refetch: refetchTrialUsers,
  } = useTrialUsers(trialId);

  const {
    subjects,
    loading: useSubjectsLoading,
    error: useSubjectsError,
    refetch: refetchSubjects,
  } = useSubjectQuery(trialId);

  const {
    data: anatomicalStructures,
    loading: useAnatomicalStructuresLoading,
    error: useAnatomicalStructuresError,
    refetch: refetchAnatomicalStructures,
  } = useAnatomicalStructures();

  const columns = React.useMemo<Column<StudyTableRowType>[]>(
    () =>
      getColumns({
        columnIds: [
          ...defaultColumnIds,
          ANATOMICAL_STRUCTURES_HEADER_ID,
          ASSIGNED_USERS_HEADER_ID,
          CASE_IDS_HEADER_ID,
        ],
      }),
    []
  );

  useEffect(() => {
    const rows = subjects
      ? getStudyRowsFromSubjects(
          subjects.filter((subject) => !subject.isTempDemoPatient)
        )
      : [];

    const selectedStudyIds = selectedStudyRows.map(({ studyId }) => studyId);
    const updatedRows = rows.map((row) => {
      const { studyId } = row;

      return selectedStudyIds.includes(studyId)
        ? ({
            ...row,
            changedAnatomicalStructures: {
              changed: selectedAnatomicalStructures,
              mode: updateMode,
            },
            changedUsers: {
              changed: selectedUsers,
              mode: updateMode,
            },
          } as StudyTableRowType)
        : row;
    });

    setRows(updatedRows);
  }, [
    subjects,
    selectedStudyRows,
    updateMode,
    selectedAnatomicalStructures,
    selectedUsers,
  ]);

  const [
    insertCases,
    { loading: useInsertCasesLoading, error: useInsertCasesError },
  ] = useInsertCases();

  const handleRowsSelected = (selectedRows: StudyTableRowType[]) => {
    setSelectedStudyRows(selectedRows);
  };

  const memoizedHandleRowsSelected = useCallback(handleRowsSelected, []);

  const tableRef = createRef<TableControlsType>();

  if (useSubjectsError) handleApolloError(useSubjectsError);
  if (useInsertCasesError) handleApolloError(useInsertCasesError);
  if (useAnatomicalStructuresError)
    handleApolloError(useAnatomicalStructuresError);

  if (!subjects) throw new Error("Subject null with no apollo error");

  const actionsDisabled =
    selectedStudyRows.length < 1 ||
    !(selectedUsers.length > 0 || selectedAnatomicalStructures.length > 0);

  const handleAddSelected = () => {
    setUpdateMode(ADD);
  };

  const handleRemoveSelected = () => {
    setUpdateMode(REMOVE);
  };

  const handleClear = () => {
    setSelectedAnatomicalStructures([]);
    setSelectedUsers([]);
    setFilter("");
    setUpdateMode(ADD);

    const { current: tableControls } = tableRef;
    tableControls?.clearSelectedRows();
    tableControls?.clearGlobalFilter();
  };

  const handleApply = async () => {
    if (actionsDisabled) {
      return;
    }

    let selectedLabels = selectedAnatomicalStructures;

    if (updateMode === ADD) {
      const newLabels = selectedAnatomicalStructures.filter(
        ({ id }) => id === 0
      );
      const existingLabels = selectedAnatomicalStructures.filter(
        ({ id }) => id !== 0
      );

      const createdLabels = await insertAnatomicalStructures(
        annotateClient,
        newLabels
      );

      await refetchAnatomicalStructures();

      selectedLabels = [...existingLabels, ...createdLabels];
      setSelectedAnatomicalStructures(selectedLabels);
    }

    const studySeries = selectedStudyRows.map(({ series }) => series);

    setCasesChangedLoading(true);
    await insertCases({
      anatomicalStructures: selectedLabels,
      users: selectedUsers,
      studySeries,
      mode: updateMode,
    });
    await refetchSubjects();
    setCasesChangedLoading(false);
  };

  return (
    <Card
      noHead
      expand={true}
      content={
        <>
          <ToolbarWrapper>
            <SelectorsWrapper>
              <AnatomySelectorWrapper>
                <SelectLabelWrapper>Labels</SelectLabelWrapper>
                <ExpandWrapper>
                  <AnatomicalStructuresSelect
                    anatomicalStructures={anatomicalStructures}
                    selectedAnatomicalStructures={selectedAnatomicalStructures}
                    setSelectedAnatomicalStructures={
                      setSelectedAnatomicalStructures
                    }
                    mode={updateMode}
                    isClearable={true}
                  />
                </ExpandWrapper>
              </AnatomySelectorWrapper>

              <AnatomySelectorWrapper>
                <SelectLabelWrapper>Users</SelectLabelWrapper>
                <ExpandWrapper>
                  <UserSelect
                    users={trialUsers ?? []}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    isCreatable={false}
                  />
                </ExpandWrapper>
              </AnatomySelectorWrapper>
            </SelectorsWrapper>

            <CollapseWrapper>
              <FilterCheckbox
                label={"Add"}
                name={"add"}
                checked={updateMode === ADD}
                onChange={handleAddSelected}
              />
              <FilterCheckbox
                label={"Remove"}
                name={"remove"}
                checked={updateMode === REMOVE}
                onChange={handleRemoveSelected}
              />
            </CollapseWrapper>
            <InputDiv
              onClick={handleClear}
              color={main.colors.neutral.white}
              background={main.colors.neutral.black}
              width={84}
            >
              <InnerButtonWrapper>{"Reset"}</InnerButtonWrapper>
            </InputDiv>
            <InputDiv
              onClick={handleApply}
              color={main.colors.neutral.white}
              background={main.colors.neutral.black}
              width={128}
            >
              <InnerButtonWrapper>
                {casesLoading && (
                  <AltLoading color={main.colors.neutral.white} />
                )}
                {`Apply (${selectedStudyRows.length})`}
              </InnerButtonWrapper>
            </InputDiv>
            <ExportLesionsButton studyRows={selectedStudyRows} />
          </ToolbarWrapper>

          <StudyTableWithRef
            ref={tableRef}
            columns={columns}
            data={rows}
            onRowsSelected={memoizedHandleRowsSelected}
            enableSelect={true}
            minTableHeight={200}
            filterFn={filterStudyTableRow}
          />
        </>
      }
    />
  );
};
