import { main } from "nota-predict-web/src/common/theme/main";

export type ActionTypeType =
  | typeof ADMIN
  | typeof BIOPSY_LINKING
  | typeof CONTOUR
  | typeof CONTOUR_ABDOMEN
  | typeof CONTOUR_KIDNEY
  | typeof CONTOUR_LIVER
  | typeof CONTOUR_LUNG
  | typeof CONTOUR_MEDIASTINUM
  | typeof CONTOUR_ORGAN
  | typeof DETECT
  | typeof QA
  | typeof QA_CONTOUR
  | typeof RECIST_1_1
  | typeof TRAINEE
  | typeof VIEWER;

export const ADMIN = "ADMIN";
export const BIOPSY_LINKING = "BIOPSY_LINKING";
export const CONTOUR = "CONTOUR";
export const CONTOUR_ABDOMEN = "CONTOUR_ABDOMEN";
export const CONTOUR_KIDNEY = "CONTOUR_KIDNEY";
export const CONTOUR_LIVER = "CONTOUR_LIVER";
export const CONTOUR_LUNG = "CONTOUR_LUNG";
export const CONTOUR_MEDIASTINUM = "CONTOUR_MEDIASTINUM";
export const CONTOUR_ORGAN = "CONTOUR_ORGAN";
export const DETECT = "DETECT";
export const QA = "QA";
export const QA_CONTOUR = "QA_CONTOUR";
export const RECIST_1_1 = "RECIST_1_1";
export const TRAINEE = "TRAINEE";
export const VIEWER = "VIEWER";

export const actionTypeDisplayNames: Record<ActionTypeType, string> = {
  [ADMIN]: "Admin",
  [BIOPSY_LINKING]: "Biopsy Linking",
  [CONTOUR]: "Segmentation",
  [CONTOUR_ABDOMEN]: "Abdomen Segmentation",
  [CONTOUR_KIDNEY]: "Kidney Segmentation",
  [CONTOUR_LIVER]: "Liver Segmentation",
  [CONTOUR_LUNG]: "Lung Segmentation",
  [CONTOUR_MEDIASTINUM]: "Mediastinum Segmentation",
  [CONTOUR_ORGAN]: "Organ Segmentation",
  [DETECT]: "Detect",
  [QA]: "QA",
  [QA_CONTOUR]: "QA-Contour",
  [RECIST_1_1]: "Recist 1.1",
  [TRAINEE]: "Train",
  [VIEWER]: "View",
};

const {
  accent: { accent1, accent2, accent3, accent4 },
} = main.colors;

export const actionTypeColors: Record<ActionTypeType, string> = {
  [ADMIN]: "#f0b000",
  [BIOPSY_LINKING]: "#009688",
  [CONTOUR]: accent3,
  [CONTOUR_ABDOMEN]: "#9c27b0",
  [CONTOUR_KIDNEY]: "#1ee595",
  [CONTOUR_LIVER]: "#1e88e5",
  [CONTOUR_LUNG]: "#2816f0",
  [CONTOUR_MEDIASTINUM]: "#32a852",
  [CONTOUR_ORGAN]: accent1,
  [DETECT]: accent4,
  [QA]: "#2196f3",
  [QA_CONTOUR]: "#1e88e5",
  [RECIST_1_1]: accent2,
  [TRAINEE]: "#616161",
  [VIEWER]: "#616161",
};
