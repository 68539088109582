import React, { FC } from "react";
import MenuTitle from "./MenuTitle";
import { TimepointsFilters } from "./TimepointsFilters";
import { TrialArmFilters } from "./TrialArmFilters";
import { TumourMutationsFilters } from "./TumourMutationsFilters";
import { EcogFilters } from "./EcogFilters";
import { Recist1_1Filters } from "./Recist1_1Filters";
import { DemographicsFilters } from "./DemographicsFilters/DemographicsFilters";
import { LifestyleFilters } from "./LifestyleFilters/LifestyleFilters";
import { HistologyFilters } from "./HistologyFilters";
import styled from "styled-components";
import { FilterIcon } from "../../../common/components/icons/FilterIcon";
import List from "@material-ui/core/List";
import { useDispatch, useSelector } from "react-redux";
import {
  allFiltersSelected,
  FilterTypes,
  getTrialViewFilters,
  noFiltersSelected,
  trialViewFiltersActions,
} from "../../../common/store/trialViewFiltersSlice";
import { StageFilters } from "./StageFilters";
import {
  TIME_POINT_SPELLING,
  TUMOR_SPELLING,
} from "../../../common/utils/spellingConstants";
import { CopyFilteredURLButton } from "./CopyFilteredURLButton";
import { FilterHeaderButtons } from "./FilterHeaderButtons";
import { FilterPanel } from "./FilterPanel";
import { trialArmFilters } from "../../../common/store/selectors/trialArmSelectors";
import { timePointFilters } from "../../../common/store/selectors/timePointSelectors";
import { tumourBurdenFilters } from "../../../common/store/selectors/tumourBurdenSelectors";
import { lifestyleFilters } from "../../../common/store/selectors/lifestyleSelectors";
import { ajccFilters } from "../../../common/store/selectors/ajccStageSelectors";
import { histologyFilters } from "../../../common/store/selectors/histologySelectors";
import { mutationFilters } from "../../../common/store/selectors/mutationSelectors";
import { ecogFilters } from "../../../common/store/selectors/egocSelectors";
import { demographicsFilters } from "../../../common/store/selectors/demographicsSelectors";

const StyledList = styled(List)`
  display: flex;
  width: 270px;
  flex-direction: column;
  justify-content: flex-start;
  background: ${(props) => props.theme.colors.actionPrimary.inverted};
`;

const SideBarContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  overflow-x: hidden;
  padding: 0 24px 24px 0;
  border-radius: 12px 12px 0 0;
  width: 270px;
  background: ${(props) => props.theme.colors.neutral.white};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
`;

const TitleWrapper = styled.div`
  display: flex;
`;

const TitleText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  font-family: "Inter", verdana, arial, sans-serif;
  margin-right: 8px;
`;

const TitleBarButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 6px;
`;

const ScrollSpace = styled.div`
  overflow-y: scroll;
  padding-right: 20px;
  overflow-x: hidden;
  width: 260px;
  &::-webkit-scrollbar {
    width: 6px;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    border-top: 8px solid ${(props) => props.theme.colors.background.secondary};
    background-clip: padding-box;
  }
`;

const ToolPanelContainer: FC = () => {
  const dispatch = useDispatch();

  const filters = useSelector(getTrialViewFilters);

  function handleFilterLoad(filters: FilterTypes) {
    dispatch(trialViewFiltersActions.setAllFilters(filters));
  }

  const clearAllFilters = () => {
    dispatch(trialViewFiltersActions.clearAll());
  };

  const selectAllFilters = () => {
    dispatch(trialViewFiltersActions.selectAll());
  };

  const allSelected = useSelector(allFiltersSelected);
  const noSelected = useSelector(noFiltersSelected);

  return (
    <SideBarContent>
      <ScrollSpace>
        <MenuTitle
          iconTitle={
            <TitleWrapper>
              <TitleText>{"Filters"}</TitleText>
              <FilterIcon />
              <CopyFilteredURLButton
                filters={filters}
                handleFilterLoad={handleFilterLoad}
              />
            </TitleWrapper>
          }
          buttons={
            <TitleBarButtonsWrapper>
              <FilterHeaderButtons
                onSelectAll={selectAllFilters}
                onClear={clearAllFilters}
                allSelected={allSelected}
                noneSelected={noSelected}
              />
            </TitleBarButtonsWrapper>
          }
        />
        <StyledList disablePadding>
          <FilterPanel
            title={"Trial Arm"}
            filterSelectors={trialArmFilters}
            selectAllAction={trialViewFiltersActions.resetTrialArms}
            clearAction={trialViewFiltersActions.clearTrialArms}
          >
            <TrialArmFilters />
          </FilterPanel>

          <FilterPanel
            title={TIME_POINT_SPELLING}
            filterSelectors={timePointFilters}
            selectAllAction={trialViewFiltersActions.resetTimepoints}
            clearAction={trialViewFiltersActions.clearTimepoints}
          >
            <TimepointsFilters />
          </FilterPanel>

          <FilterPanel
            title={TUMOR_SPELLING + " Burden"}
            filterSelectors={tumourBurdenFilters}
            selectAllAction={trialViewFiltersActions.resetRecist1_1}
            clearAction={trialViewFiltersActions.clearRecist1_1}
          >
            <Recist1_1Filters />
          </FilterPanel>

          <FilterPanel
            title={"AJCC Stage"}
            filterSelectors={ajccFilters}
            selectAllAction={trialViewFiltersActions.resetStage}
            clearAction={trialViewFiltersActions.clearStage}
          >
            <StageFilters />
          </FilterPanel>

          <FilterPanel
            title={"Histology"}
            filterSelectors={histologyFilters}
            selectAllAction={trialViewFiltersActions.resetHistology}
            clearAction={trialViewFiltersActions.clearHistology}
          >
            <HistologyFilters />
          </FilterPanel>

          <FilterPanel
            title={"Mutations"}
            filterSelectors={mutationFilters}
            selectAllAction={trialViewFiltersActions.resetTumourMutations}
            clearAction={trialViewFiltersActions.clearTumourMutations}
          >
            <TumourMutationsFilters />
          </FilterPanel>

          <FilterPanel
            title={"Demographics"}
            filterSelectors={demographicsFilters}
            selectAllAction={trialViewFiltersActions.resetDemographics}
            clearAction={trialViewFiltersActions.clearDemographics}
          >
            <DemographicsFilters />
          </FilterPanel>

          <FilterPanel
            title={"Lifestyle"}
            filterSelectors={lifestyleFilters}
            selectAllAction={trialViewFiltersActions.resetLifestyle}
            clearAction={trialViewFiltersActions.clearLifestyle}
          >
            <LifestyleFilters />
          </FilterPanel>

          <FilterPanel
            title={"ECOG"}
            filterSelectors={ecogFilters}
            selectAllAction={trialViewFiltersActions.resetEcog}
            clearAction={trialViewFiltersActions.clearEcog}
          >
            <EcogFilters />
          </FilterPanel>
        </StyledList>
      </ScrollSpace>
    </SideBarContent>
  );
};

export default ToolPanelContainer;
