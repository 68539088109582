import { UserType } from "../../types/UserType";
import { useAuth0 } from "@auth0/auth0-react";
import { gql } from "@apollo/client/core";
import {
  parseUserFragment,
  USER_FRAGMENT,
  UserFragmentType,
} from "../../../Settings/UserFragment";
import { useParsedQueryReturnType } from "../../queries/utils/useParsedQueryReturnType";
import { useParsedQuery } from "../../queries/utils/useParsedQuery";

const QUERY = gql`
  query GetUser($auth0_user_id: String) {
    users(where: { auth0_user_id: { _eq: $auth0_user_id } }) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

type Data = { users: UserFragmentType[] };
type Variables = { auth0_user_id: string };

export function useCurrentUser(): useParsedQueryReturnType<
  string,
  UserType,
  Variables
> {
  const {
    user: { sub: auth0_user_id },
  } = useAuth0();

  return useParsedQuery<string, UserType, Data, Variables>({
    query: QUERY,
    input: auth0_user_id,
    parseVariables,
    parseData,
  });
}

function parseVariables(auth0UserId: string): Variables {
  return { auth0_user_id: auth0UserId };
}

function parseData(data: Data): UserType {
  if (data.users.length !== 1) {
    throw new Error("No or more than one user returned with the same auth0 id");
  }
  return data.users.map(parseUserFragment)[0];
}
