import React, { ReactElement } from "react";
import styled from "styled-components";
import { useCurrentUser } from "../../common/components/UserManagement/useCurrentUser";
import Loading from "../../common/components/Loading";
import handleApolloError from "../../common/utils/handleApolloError";

const Wrapper = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  color: ${(props) => props.theme.colors.actionPrimary.inverted};
`;

const HelloText = (): ReactElement => {
  const { data: user, loading, error } = useCurrentUser();

  if (loading) return <Loading />;
  if (error) handleApolloError(error);
  if (!user) throw new Error("Current user null with no apollo error");

  const { firstName } = user;

  const displayName = firstName ? `, ${firstName}` : "";
  const text = `Hello${displayName}`;
  return <Wrapper>{text}</Wrapper>;
};

export default HelloText;
