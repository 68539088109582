import React, { ReactElement, MouseEventHandler } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Underline = styled.div`
  height: 2px;
  border-radius: 2px;
  background: ${(props) => props.theme.colors.actionPrimary.active};
`;

const StyledButton = styled.button<{ active: boolean; disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.neutral.neutral6
      : props.active
      ? props.theme.colors.actionPrimary.default
      : props.theme.colors.neutral.neutral3};
  cursor: pointer;
  border: none;
  background: none;
  :focus {
    outline: none;
  }
`;
interface DisplayRadioButtonProps {
  text: string;
  active: boolean;
  disabled?: boolean;
  onClick: MouseEventHandler;
}

const DisplayRadioButton = ({
  active,
  disabled,
  text,
  onClick,
}: DisplayRadioButtonProps): ReactElement => {
  return (
    <Wrapper>
      <StyledButton
        onClick={onClick}
        active={active}
        disabled={disabled === false}
      >
        {text}
      </StyledButton>
      {active && <Underline />}
    </Wrapper>
  );
};

export default DisplayRadioButton;
