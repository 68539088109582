import { SubjectQueryResultType } from "../types/SubjectQueryResultType";
import { getFollowUpAtOrder } from "../utils/getFollowUpAtOrder";
import { useFilteredSubjectsQuery } from "./useFilteredSubjectsQuery";

export function useFilteredSubjectsWithFollowUp(
  trialId: number,
  followUpOrder: number
): SubjectQueryResultType {
  const { subjects, loading, error, refetch } = useFilteredSubjectsQuery(
    trialId
  );

  let filteredSubjects;

  if (!loading && !error && subjects) {
    filteredSubjects = subjects
      .map((subject) => {
        const { followUps } = subject;
        const followUp = getFollowUpAtOrder(followUps, followUpOrder);
        subject.followUps = followUp ? [followUp] : [];
        return subject;
      })
      .filter((subject) => subject.followUps.length > 0);
  }

  return {
    subjects: filteredSubjects,
    loading,
    error,
    refetch,
  };
}
