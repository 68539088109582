import { UserType } from "../../../types/UserType";

export function getNewUsersByEmail(
  allUsers: UserType[],
  existingUsers: UserType[]
): UserType[] {
  return allUsers.filter(
    ({ email }) =>
      !existingUsers.some(
        ({ email: existingUserEmail }) => email === existingUserEmail
      )
  );
}
