import React from "react";
import { UserType } from "../../types/UserType";
import styled from "styled-components";
import { UserDetails } from "./UserDetails";
import { useResetPasswordDialog } from "./Dialogs/useResetPasswordDialog";
import { useAllTrials } from "../../queries/useAllTrials";
import Loading from "../Loading";
import handleApolloError from "../../utils/handleApolloError";
import TrialStatus from "../../../Dashboard/components/TrialStatus";
import FilterButton from "../../../Analysis/ToolPanel/components/FilterButton";
import { RoleLabel } from "./RoleLabel";
import { UserActionCallbacksType } from "./UserActionCallbacksType";
import { CurrentUserAvatar } from "../Avatar/CurrentUserAvatar";
import { getPrimaryRole } from "./utils/getPrimaryRole";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  gap: 18px;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
`;

const Text = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
`;

const TrialsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 10px;
  max-width: 300px;
`;

interface UserProfileProps {
  user: UserType;
  actionCallbacks: UserActionCallbacksType;
}

export function UserProfile({
  user,
  actionCallbacks,
}: UserProfileProps): JSX.Element {
  const { onPasswordReset } = actionCallbacks;
  if (!onPasswordReset) {
    throw new Error("onUserPasswordReset not defined in UserProfile");
  }

  const handleConfirmPasswordReset = (user: UserType) => {
    onPasswordReset(user);
  };

  const [
    setResetPasswordDialogOpen,
    { dialog: resetPasswordDialog },
  ] = useResetPasswordDialog(user, handleConfirmPasswordReset);

  const { trials, loading, error } = useAllTrials();

  if (loading) return <Loading />;
  if (error) handleApolloError(error);
  if (!trials) {
    throw new Error("Trials null");
  }

  const handleClickResetPassword = () => {
    setResetPasswordDialogOpen(true);
  };

  const { roles } = user;
  const primaryRole = getPrimaryRole(roles);

  return (
    <>
      {resetPasswordDialog}
      <Wrapper>
        <CurrentUserAvatar size={64} showProfileOnClick={false} />
        <UserDetails user={user} actionCallbacks={actionCallbacks} />
        <RowWrapper>
          <Text>Roles:</Text>
          {roles.map((role, index) => {
            return <RoleLabel key={index} role={role} />;
          })}
        </RowWrapper>
        <RowWrapper>
          <Text>Trials:</Text>
          <TrialsWrapper>
            {trials.map(({ name, status }, index) => (
              <TrialStatus key={index} status={status} labelOverride={name} />
            ))}
          </TrialsWrapper>
        </RowWrapper>
        <FilterButton
          onClick={handleClickResetPassword}
          width={128}
          active={true}
          text={"Reset Password"}
        />
      </Wrapper>
    </>
  );
}
