import React, { FC } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import {
  ANALYSIS,
  ANNOTATE,
  DATA_MANAGEMENT,
  OVERVIEW,
  SETTINGS,
} from "../common/routes/routePaths";
import { DataManagement } from "../DataManagement/DataManagement";
import { Analysis } from "../Analysis/Analysis";
import { Overview } from "../Overview/Overview";
import { Settings } from "../Settings/Settings";
import { Annotate } from "../Annotate/Annotate";

export const TrialRouter: FC = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/${OVERVIEW}`}>
        <Overview />
      </Route>
      <Route path={`${url}/${DATA_MANAGEMENT}`}>
        <DataManagement />
      </Route>
      <Route path={`${url}/${ANALYSIS}`}>
        <Analysis />
      </Route>
      <Route path={`${url}/${ANNOTATE}`}>
        <Annotate />
      </Route>
      <Route path={`${url}/${SETTINGS}`}>
        <Settings />
      </Route>
      <Redirect to={`${url}/${OVERVIEW}`} />
    </Switch>
  );
};
