import { gql, useQuery } from "@apollo/client";
import { ApolloError } from "@apollo/client/errors";
import {
  ModifiedRunJobProp,
  parseAlgorithmsToRunJobs,
  RawTrialAlgorithmsType,
} from "../utils/parseAlgorithms";

export const GET_ALGORITHMS = gql`
  query GetAlgorithms($trial_id: Int!, $model_name: String!) {
    model(where: { name: { _eq: $model_name } }) {
      name
      created_at
      survival_predictions_aggregate(
        where: {
          series: {
            study: {
              patient: {
                patient_trial_arms: {
                  trial_arm: { trial_id: { _eq: $trial_id } }
                }
              }
            }
          }
        }
      ) {
        aggregate {
          max {
            last_modified
          }
          count
        }
      }
    }
  }
`;

const useAlgorithms = (
  trialId: number
): {
  error?: ApolloError;
  loading: boolean;
  runJobs: ModifiedRunJobProp[];
} => {
  const { data, loading, error } = useQuery<RawTrialAlgorithmsType>(
    GET_ALGORITHMS,
    {
      variables: {
        trial_id: trialId,
        model_name: "Non-Small Cell Lung Cancer",
      },
    }
  );
  let runJobs: ModifiedRunJobProp[] = [];
  if (!loading && !error) runJobs = data ? parseAlgorithmsToRunJobs(data) : [];
  return { runJobs, loading, error };
};
export default useAlgorithms;
