import { gql } from "@apollo/client/core";
import { SiteType } from "../common/types/SiteType";
import { useParsedMutation } from "../common/queries/utils/useParsedMutation";
import { useParsedMutationReturnType } from "../common/queries/utils/useParsedMutationReturnType";

const MUTATION = gql`
  mutation InsertSites($sites: [site_insert_input!]!) {
    insert_site(
      objects: $sites
      on_conflict: { constraint: sites_name_trial_id_key, update_columns: name }
    ) {
      affected_rows
    }
  }
`;

type Input = {
  trialId: number;
  sites: SiteType[];
};

type Variables = {
  sites: {
    name: string;
    trial_id: number;
  }[];
};

type Data = {
  insert_site: { affected_rows: number };
};

export function useInsertSites(): useParsedMutationReturnType<Input, number> {
  return useParsedMutation<Input, number, Data, Variables>({
    mutation: MUTATION,
    parseVariables,
    parseData,
  });
}

function parseVariables({ trialId: trial_id, sites }: Input): Variables {
  return {
    sites: sites.map(({ name }) => {
      return {
        trial_id,
        name,
      };
    }),
  };
}

function parseData(data: Data): number {
  return data.insert_site.affected_rows;
}
