import React from "react";
import StatusIndicator from "./StatusIndicator";
import { getStatusColorType } from "../types/StatusColorTypes";
import { StatusIndicationType } from "../types/StatusIndicationType";

function StateStatusIndicator({
  status,
  state,
  isBusy,
}: StatusIndicationType): React.ReactElement<
  React.ProgressHTMLAttributes<HTMLProgressElement>
> {
  const color = getStatusColorType(state);
  return <StatusIndicator status={status} color={color} isBusy={isBusy} />;
}

export default StateStatusIndicator;
