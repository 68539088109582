import React, { FC } from "react";
import { Card } from "../../../common/components/cards/Card";
import { GraphCardProps } from "../../common/utils/GraphCardProps";
import { SurvivalPanelContainer } from "./SurvivalPanelContainer";

export const MedianSurvivalPredictionCard: FC<GraphCardProps> = ({
  onClickHeader,
}: GraphCardProps) => {
  return (
    <Card
      title={"Median"}
      content={<SurvivalPanelContainer />}
      onClickHeader={onClickHeader}
      downloadCSV={true}
    />
  );
};
