import { FollowUpType } from "../../common/types/FollowUpType";
import { getFollowUpInMonths } from "../../common/utils/getFollowUpInMonths";

export const getPredictedSurvival = (followUp: FollowUpType) => {
  const { survivalPredictions, order } = followUp;
  if (survivalPredictions.length < 1) {
    return null;
  }
  const followUpTime = getFollowUpInMonths(order);
  return survivalPredictions[0] + followUpTime;
};
