import { Label } from "recharts";
import React from "react";
import { Props } from "recharts/types/component/Label";
import { TEXT_OPACITY } from "./textOpacity";

const axisLabelProps: Props = {
  angle: 270,
  style: { textAnchor: "middle" },
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "11px",
  opacity: TEXT_OPACITY,
};

export const getYAxisLabel = (title?: string) => {
  return (
    <Label {...axisLabelProps} position="left">
      {title}
    </Label>
  );
};

export const getYAxisLabelRight = (title?: string) => {
  return (
    <Label {...axisLabelProps} position="right">
      {title}
    </Label>
  );
};
