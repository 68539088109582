import React from "react";
import styled from "styled-components";
import { UserType } from "../../types/UserType";
import { formatUserFullName } from "./utils/formatUserFullName";
import { ManagementRow } from "../ManagementRow";
import { useEditUserDialog } from "./Dialogs/useEditUserDialog";
import { useRemoveUserFromTrialDialog } from "./Dialogs/useRemoveUserFromTrialDialog";
import { Avatar } from "../Avatar/Avatar";
import { RoleLabel } from "./RoleLabel";
import { UserActionCallbacksType } from "./UserActionCallbacksType";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 6px;
`;

const ItemWrapper = styled.div`
  flex: 1;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 6px;
  gap: 3px;
`;

const RolesWrapper = styled(FlexWrapper)`
  justify-content: center;
`;

const Text = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.neutral.black};
  min-width: 125px;
`;

interface UserRowProps {
  user: UserType;
  actionCallbacks: UserActionCallbacksType;
  allowEditUser: boolean;
  allowRemoveUser: boolean;
}

export function UserRow({
  user,
  actionCallbacks,
  allowEditUser,
  allowRemoveUser,
}: UserRowProps): JSX.Element {
  const { onRemoved } = actionCallbacks ?? {
    onRemoved: undefined,
  };

  const handleConfirmRemoveUser = (user: UserType) => {
    if (!allowRemoveUser) {
      throw new Error(
        "handleConfirmRemoveUser called but allowRemoveUser is true"
      );
    }
    onRemoved?.(user);
  };

  const [
    setShowRemoveUserDialog,
    { dialog: removeUserDialog },
  ] = useRemoveUserFromTrialDialog(user, handleConfirmRemoveUser);

  const [setShowEditUserDialog, { dialog: editUserDialog }] = useEditUserDialog(
    user,
    actionCallbacks
  );

  const handleClickEditUser = () => {
    setShowEditUserDialog(true);
  };

  const handleClickRemoveUser = () => {
    setShowRemoveUserDialog(true);
  };

  const { title, organization, roles, email } = user;
  const displayName = formatUserFullName(user) ?? email;
  return (
    <>
      {editUserDialog}
      {removeUserDialog}
      <ManagementRow<UserType>
        object={user}
        onEdit={allowEditUser ? handleClickEditUser : undefined}
        onDelete={allowRemoveUser ? handleClickRemoveUser : undefined}
        content={
          <Wrapper>
            <ItemWrapper>
              <FlexWrapper>
                <Avatar user={user} />
                <Text>{displayName}</Text>
              </FlexWrapper>
            </ItemWrapper>
            <ItemWrapper>
              <Text>{title}</Text>
            </ItemWrapper>
            <ItemWrapper>
              <Text>{organization}</Text>
            </ItemWrapper>
            <ItemWrapper>
              <RolesWrapper>
                {roles.map((role, index) => {
                  return <RoleLabel key={index} role={role} />;
                })}
              </RolesWrapper>
            </ItemWrapper>
          </Wrapper>
        }
      />
    </>
  );
}
