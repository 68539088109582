import React from "react";
import styled from "styled-components";
import ClearIcon from "@material-ui/icons/Clear";
import { MuiIcon } from "./icons/MuiIcon";
import { SvgIcon } from "./icons/SvgIcon";
import { ReactComponent as EditIcon } from "../../assets/svgs/Edit.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.colors.background.secondary};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 3px 8px;
  min-height: 34px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  cursor: pointer;
`;

interface ManagementRowProps<T> {
  content: JSX.Element;
  object: T;
  onEdit?: (object: T) => void;
  onDelete?: (object: T) => void;
}

export function ManagementRow<T>({
  content,
  object,
  onEdit,
  onDelete,
}: ManagementRowProps<T>): JSX.Element {
  const handleOnClickEdit = () => {
    if (!onEdit) {
      throw new Error("handleOnClickEdit should not be callable");
    }
    onEdit(object);
  };

  const handleOnClickDelete = () => {
    if (!onDelete) {
      throw new Error("handleOnClickDelete should not be callable");
    }

    onDelete(object);
  };

  return (
    <Wrapper>
      {content}
      <ButtonsWrapper>
        {onEdit && (
          <ButtonWrapper onClick={handleOnClickEdit}>
            <SvgIcon icon={EditIcon} size={16} />
          </ButtonWrapper>
        )}
        {onDelete && (
          <ButtonWrapper onClick={handleOnClickDelete}>
            <MuiIcon icon={ClearIcon} size={16} />
          </ButtonWrapper>
        )}
      </ButtonsWrapper>
    </Wrapper>
  );
}
