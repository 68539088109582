import { SubjectType } from "../../common/types/SubjectType";
import { DataType } from "../../ChangeFromBaseline/utils/parseMortalityBaselineChartData";
import { IPRO, RECIST } from "../../ChangeFromBaseline/components/Filter";
import { getMostRecentFollowUp } from "../../common/utils/getMostRecentFollowUp";
import { getFollowUpSurvivalPrediction } from "../../common/utils/getFollowUpSurvivalPrediction";
import { getFollowUpTumourBurden } from "../../common/utils/getFollowUpTumourBurden";
import { followUpIds } from "../../common/utils/followUpIds";
import { trialArmIds } from "../../common/utils/trialArmIds";
import { getFollowUpInMonths } from "../../common/utils/getFollowUpInMonths";
import { getFollowUpAtOrder } from "../../common/utils/getFollowUpAtOrder";

export type MedianSurvivalSeriesType = MedianSurvivalFollowUpType[];

export type MedianSurvivalFollowUpType = {
  followUpOrder: number;
  byArm: { [arm: number]: MedianData };
};

export type MedianData = Record<DataType, number | null>;

export type DomainFilterType = typeof RECIST | typeof IPRO;
export type maxValuesType = {
  [IPRO]: number;
  [RECIST]: number;
};

export function getMedianSurvivalSeries(
  subjects: SubjectType[]
): MedianSurvivalSeriesType {
  const byFollowUp: {
    [followUp: number]: { [arm: number]: Record<DataType, number[]> };
  } = {};

  //prefill the data structure
  for (const followUpId of followUpIds) {
    if (!byFollowUp[followUpId]) {
      byFollowUp[followUpId] = {};
    }

    for (const trialArmId of trialArmIds) {
      if (!byFollowUp[followUpId][trialArmId]) {
        byFollowUp[followUpId][trialArmId] = {
          RECIST: [],
          IPRO: [],
        };
      }
    }
  }

  for (const subject of subjects) {
    const {
      trialArm: { number: arm },
      followUps,
    } = subject;

    //get last follow up and add it to bucket
    //iterate forward to max number of followups, subtract time and add any residuals > 0
    const mostRecentFollowUp = getMostRecentFollowUp(followUps);
    if (!mostRecentFollowUp) {
      continue;
    }

    const mostRecentSurvivalPrediction = getFollowUpSurvivalPrediction(
      mostRecentFollowUp
    );
    if (mostRecentSurvivalPrediction === null) {
      continue;
    }

    const doCumulative = true;
    if (doCumulative) {
      const { order: mostRecentFollowUpId } = mostRecentFollowUp;
      for (const followUpId of [...followUpIds].reverse()) {
        if (mostRecentFollowUpId < followUpId) {
          const difference = followUpId - mostRecentFollowUpId;
          const differenceMonths = getFollowUpInMonths(difference);
          const survivalPredictionAtFollowUp =
            mostRecentSurvivalPrediction - differenceMonths;
          if (survivalPredictionAtFollowUp > 0) {
            byFollowUp[followUpId][arm][IPRO].push(
              survivalPredictionAtFollowUp
            );
          } else {
            byFollowUp[followUpId][arm][IPRO].push(0);
          }
        } else if (mostRecentFollowUpId === followUpId) {
          byFollowUp[followUpId][arm][IPRO].push(mostRecentSurvivalPrediction);
        } else {
          const followUp = getFollowUpAtOrder(followUps, followUpId);
          const survivalPrediction = followUp
            ? getFollowUpSurvivalPrediction(followUp)
            : null;
          if (survivalPrediction !== null) {
            byFollowUp[followUpId][arm][IPRO].push(survivalPrediction);
          }
        }
      }
    } else {
      for (const followUp of followUps) {
        const { order } = followUp;
        const survivalPrediction = getFollowUpSurvivalPrediction(followUp);
        if (survivalPrediction !== null) {
          byFollowUp[order][arm][IPRO].push(survivalPrediction);
        }
      }
    }

    for (const followUp of followUps) {
      const { order } = followUp;

      const tumourBurden = getFollowUpTumourBurden(followUp);
      if (tumourBurden !== null) {
        byFollowUp[order][arm][RECIST].push(tumourBurden);
      }
    }
  }

  //convert into series type suitable for plotting
  const series: MedianSurvivalSeriesType = [];

  for (const followUpOrderKey in byFollowUp) {
    if (!Object.prototype.hasOwnProperty.call(byFollowUp, followUpOrderKey)) {
      continue;
    }

    const followUpOrder = Number(followUpOrderKey);
    const median: { [arm: number]: MedianData } = {};
    const byArm = byFollowUp[followUpOrder];
    for (const arm in byArm) {
      if (!Object.prototype.hasOwnProperty.call(byArm, arm)) {
        continue;
      }

      const followUpTime = getFollowUpInMonths(followUpOrder);
      const predictions = byArm[arm][IPRO];
      const burdens = byArm[arm][RECIST];
      const mediaPrediction = getMedian(predictions);
      median[arm] = {
        [IPRO]:
          mediaPrediction !== null ? mediaPrediction + followUpTime : null,
        [RECIST]: getMedian(burdens),
      };
    }

    series.push({
      followUpOrder,
      byArm: median,
    });
  }

  return series;
}

export function getMaxValues(
  medianSurvivalSeries: MedianSurvivalSeriesType
): maxValuesType {
  let maxIPRO = 0;
  let maxRECIST = 0;
  for (const followUp of medianSurvivalSeries) {
    const { byArm } = followUp;
    for (const arm in byArm) {
      if (!Object.prototype.hasOwnProperty.call(byArm, arm)) {
        continue;
      }
      const survival = byArm[arm].IPRO === null ? 0 : Number(byArm[arm].IPRO);
      const burden = byArm[arm].RECIST === null ? 0 : Number(byArm[arm].RECIST);
      maxIPRO = Math.max(maxIPRO, survival);
      maxRECIST = Math.max(maxRECIST, burden);
    }
  }

  return {
    [IPRO]: maxIPRO,
    [RECIST]: maxRECIST,
  };
}

function getMedian(values: number[]): number | null {
  if (values.length === 0) {
    return null;
  }

  values.sort((a, b) => a - b);

  const half = Math.floor(values.length / 2);

  if (values.length % 2) {
    return values[half];
  }

  return (values[half - 1] + values[half]) / 2.0;
}
