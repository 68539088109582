import { RawPatientHistory } from "../../rawTypes/RawPatientHistory";
import { getSmokingStatus } from "../../types/SmokingStatusType";
import { PatientHistoryType } from "../../types/PatientHistoryType";

export function parsePatientHistoryFromQueryResults(
  rawPatientHistory: RawPatientHistory
): PatientHistoryType {
  if (!rawPatientHistory) {
    return {
      smokingStatus: null,
      deathDate: null,
    };
  }

  const {
    smoking_status: smokingStatusStr,
    death_date: deathDateStr,
  } = rawPatientHistory;
  const smokingStatus =
    smokingStatusStr !== null ? getSmokingStatus(smokingStatusStr) : null;

  const deathDate = deathDateStr ? new Date(deathDateStr) : null;

  return { smokingStatus, deathDate };
}
