import React from "react";
import { BarChart, XAxis, Bar, ResponsiveContainer } from "recharts";
import Text from "../../../common/components/text/Text";

import styled from "styled-components";
import {
  BAR_BACKGROUND_FILL,
  BAR_RADIUS,
} from "../../../Analysis/common/utils/strokeSettings";

const Wrapper = styled.div`
  flex: 1;
`;

const StyledLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
`;

const StyledLabelHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 4px;
`;

const StyledLabelContentsWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
`;

const Spacer = styled.div`
  height: 16px;
  border: 0.5px solid ${(props) => props.theme.colors.neutral.black};
  margin: 0 8px;
  transform: scaleX(0.5);
`;

export interface EnrolmentDataProps {
  name: string;
  success: number;
  total: number;
  percentage: number;
}
interface EnrolmentChartProps {
  enrolmentData: EnrolmentDataProps[];
}

function EnrolmentChart({
  enrolmentData,
}: EnrolmentChartProps): React.ReactElement {
  if (enrolmentData.length === 0) {
    return <Text text={"Not available"} />;
  }
  const formatXAxis = ({ x, y, payload }: any): React.ReactElement => {
    const { name, percentage, success, total } = enrolmentData[payload.index];

    // Rounded percentage to the nearest hundredth, for display purposes
    const roundedPercentage = Math.round(percentage * 100) / 100;

    const width = 96;
    const height = 36;
    return (
      <svg x={x - width / 2} y={y} width={width} height={height} fill="#666">
        <foreignObject width={width} height={height}>
          <StyledLabelWrapper // @ts-ignore
            xmlns="http://www.w3.org/1999/xhtml"
          >
            <StyledLabelHeaderWrapper>{name}</StyledLabelHeaderWrapper>
            <StyledLabelContentsWrapper>
              <div>{`${success}/${total}`}</div>
              <Spacer />
              <div>{`${roundedPercentage}%`}</div>
            </StyledLabelContentsWrapper>
          </StyledLabelWrapper>
        </foreignObject>
      </svg>
    );
  };
  return (
    <Wrapper>
      <ResponsiveContainer height={350}>
        <BarChart
          data={enrolmentData}
          margin={{ top: 0, right: 0, left: 0, bottom: 20 }}
        >
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            tick={formatXAxis}
            interval={0}
          />
          <Bar
            dataKey="percentage"
            fill={"#A06DFA"}
            background={{ fill: BAR_BACKGROUND_FILL, radius: BAR_RADIUS }}
            radius={BAR_RADIUS}
            isAnimationActive={false}
          />
        </BarChart>
      </ResponsiveContainer>
    </Wrapper>
  );
}

export default EnrolmentChart;
