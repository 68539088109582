import React from "react";
import { UserType } from "../../types/UserType";
import { Avatar } from "../Avatar/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";

interface UsersListProps {
  users: UserType[];
}

export function UsersList({ users }: UsersListProps): JSX.Element {
  const sorted = [...users].sort(({ id: idA }, { id: idB }) => idA - idB);
  return (
    <AvatarGroup max={4} spacing={"small"}>
      {sorted.map((user, index) => (
        <Avatar key={index} user={user} size={18} />
      ))}
    </AvatarGroup>
  );
}
