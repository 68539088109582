import { JobType } from "../../../../Analysis/common/types/JobType";
import {
  ERROR,
  INFO,
  StatusType,
  SUCCESS as SUCCESS_STATUS,
  WARNING,
} from "../../../types/StatusTypes";
import {
  FAILED,
  JobStatusType,
  PENDING,
  STARTED,
  SUCCESS,
} from "../../../types/JobStatusType";
import { SubjectType } from "../../../../Analysis/common/types/SubjectType";
import { StatusIndicationType } from "../../../types/StatusIndicationType";
import { StudyTableRowType } from "../StudyTableRowType";
import { AnatomicalStructureType } from "../../../../Analysis/common/types/AnatomicalStructureType";
import { CaseAssignmentType } from "../../../../Annotate/types/CaseAssignmentType";
import { UserType } from "../../../types/UserType";
import { CaseInstanceType } from "../../../../Annotate/types/CaseInstanceType";
import { CONTOUR_ORGAN } from "../../../../Annotate/types/ActionTypeType";

export function getStudyRowsFromSubjects(
  subjects: SubjectType[]
): StudyTableRowType[] {
  const studies: StudyTableRowType[] = [];

  subjects.forEach((subject) => {
    const {
      id,
      subjectId: subjectDicomId,
      followUps,
      isTempDemoPatient,
      notaUrl,
    } = subject;

    followUps.forEach((followUp) => {
      const { studies: fuStudies } = followUp;

      fuStudies.forEach((fuStudy) => {
        const {
          studyId,
          studyDicomId,
          studyDate,
          series,
          survivalPredictionJobs,
          uploadJobs,
        } = fuStudy;

        const uploadState = getStateFromJobs(uploadJobs, isTempDemoPatient);
        const qcState = uploadState;
        const analysisState = getStateFromJobs(
          survivalPredictionJobs,
          isTempDemoPatient
        );

        const uploadStatus = getStatusIndicationType(uploadState);
        const qcStatus = getStatusIndicationType(qcState);
        const analysisStatus = getStatusIndicationType(analysisState);

        const requiredAnatomicalStructures: AnatomicalStructureType[] = [];

        const caseAssignments: CaseAssignmentType[] = [];
        const caseInstances: CaseInstanceType[] = [];
        const assignedUsers: UserType[] = [];

        series.forEach((s) => {
          const {
            requiredAnatomicalStructures: seriesStructures,
            caseAssignments: seriesCaseAssignmentsWithUser,
            caseInstances: seriesCaseInstances,
          } = s;
          for (const seriesStructure of seriesStructures) {
            const { id } = seriesStructure;
            if (
              !requiredAnatomicalStructures.some(
                (structure) => structure.id === id
              )
            ) {
              requiredAnatomicalStructures.push(seriesStructure);
            }
          }

          const filteredSeriesCaseAssignments = seriesCaseAssignmentsWithUser.filter(
            ({ actionType }) => actionType === CONTOUR_ORGAN
          );

          const seriesCaseAssignments: CaseAssignmentType[] = filteredSeriesCaseAssignments.map(
            ({ user, ...other }) => other
          );

          for (const caseAssignment of seriesCaseAssignments) {
            if (!caseAssignments.some(({ id }) => id === caseAssignment.id)) {
              caseAssignments.push(caseAssignment);
            }
          }

          for (const caseInstance of seriesCaseInstances) {
            if (!caseInstances.some(({ id }) => id === caseInstance.id)) {
              caseInstances.push(caseInstance);
            }
          }

          const seriesAssignedUsers: UserType[] = filteredSeriesCaseAssignments.map(
            ({ user }) => user
          );

          for (const assignedUser of seriesAssignedUsers) {
            const { id: assignedUserId } = assignedUser;
            if (!assignedUsers.some(({ id }) => id === assignedUserId)) {
              assignedUsers.push(assignedUser);
            }
          }
        });

        const study: StudyTableRowType = {
          rawSubject: subject,
          subjectId: id,
          subjectDicomId,
          studyId,
          studyDicomId: studyDicomId !== "" ? studyDicomId : "N/A",
          studyDate: studyDate?.toISOString().split("T")[0] ?? "N/A",
          series,
          uploadStatus,
          qcStatus,
          analysisStatus,
          requiredAnatomicalStructures,
          caseAssignments,
          caseInstances,
          assignedUsers,
          notaUrl,
        };

        studies.push(study);
      });
    });
  });

  return studies;
}

function getStateFromJobs(
  jobs: JobType[],
  isTempDemoPatient: boolean
): StatusType {
  if (jobs.length === 0) {
    return isTempDemoPatient ? SUCCESS_STATUS : WARNING;
  }

  const map: Record<JobStatusType, StatusType> = {
    [FAILED]: ERROR,
    [PENDING]: WARNING,
    [STARTED]: INFO,
    [SUCCESS]: SUCCESS_STATUS,
  };

  const job = jobs[0];
  return map[job.status];
}

function isBusy(status: StatusType): boolean {
  return status !== SUCCESS_STATUS && status !== ERROR;
}

function getText(status: StatusType): string {
  const values: Record<StatusType, string> = {
    [SUCCESS_STATUS]: "Completed",
    [INFO]: "In Progress",
    [WARNING]: "Pending",
    [ERROR]: "Review",
  };
  return values[status];
}

function getStatusIndicationType(status: StatusType): StatusIndicationType {
  return {
    status: getText(status),
    state: status,
    isBusy: isBusy(status),
  };
}
