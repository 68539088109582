import React from "react";
import {
  Area,
  ComposedChart,
  Legend as ReChartsLegend,
  Line,
  ResponsiveContainer,
  Tooltip as ReChartsTooltip,
  XAxis,
  YAxis,
  ReferenceLine,
} from "recharts";
import styled from "styled-components";
import { Legend } from "../../common/components/Legend";
import { KaplanChartTooltip } from "./KaplanChartTooltip";
import { getXAxisProps } from "../../common/utils/getXAxisProps";
import { getYAxisProps } from "../../common/utils/getYAxisProps";
import { getYAxisLabel } from "../../common/utils/getYAxisLabel";
import {
  DeathType,
  KaplanMeierDataPoint,
  PREDICTED,
} from "../utils/generateKaplanMeierData";
import { KaplanMeierAttributesType } from "./kaplanMeierAttributes";
import {
  REFERENCE_LINE_STOKE,
  REFERENCE_LINE_STROKE_DASH_ARRAY,
} from "../../common/utils/strokeSettings";
import { formatDecimalPercentage } from "../../common/utils/formatDecimalPercentage";
import { formatDaysInMonths } from "../../common/utils/formatDaysInMonths";
import { formatArmId } from "../../common/utils/formatArmId";
import { useFilteredTrialArmIds } from "../../common/hooks/useFilteredTrialArmIds";
import { getTicksAtInterval } from "../../common/utils/getTicksAtInterval";
import { NUMBER_OF_DAYS_IN_A_MONTH } from "../../common/utils/numberOfDaysInMonth";

const Wrapper = styled.div`
  flex: 1;
`;

interface KaplanChartProps {
  data: KaplanMeierDataPoint[];
  attributes: KaplanMeierAttributesType;
}

function KaplanChart({
  data,
  attributes,
}: KaplanChartProps): React.ReactElement {
  const trialArmIds = useFilteredTrialArmIds();
  const types: DeathType[] = [PREDICTED];

  const getResult = (
    data: KaplanMeierDataPoint,
    arm: number,
    key: DeathType
  ) => {
    if (!data[arm]) {
      return null;
    }
    return data[arm][key];
  };

  const getValue = (
    data: KaplanMeierDataPoint,
    arm: number,
    key: DeathType
  ): number | null => {
    const result = getResult(data, arm, key);
    if (!result) {
      return null;
    }
    return result.value;
  };

  const getRange = (
    data: KaplanMeierDataPoint,
    arm: number,
    key: DeathType
  ): number[] | null => {
    const result = getResult(data, arm, key);
    if (!result) {
      return null;
    }
    const {
      range: { min, max },
    } = result;
    return [min, max];
  };

  const renderToolTip = (props: any) => {
    return (
      <KaplanChartTooltip
        formatLabel={formatDaysInMonths}
        formatValue={formatDecimalPercentage}
        {...props}
      />
    );
  };

  const renderLegend = (props: any) => {
    return <Legend {...props} />;
  };

  const xAxisProps = getXAxisProps("Time");
  const yAxisProps = getYAxisProps(true);
  const yAxisLabel = getYAxisLabel("Survival (%)");

  const getXValue = (data: KaplanMeierDataPoint) => {
    return data.x;
  };

  const getFirstOverHalf = (
    data: KaplanMeierDataPoint[],
    arm: number,
    key: DeathType
  ) => {
    for (let i = 0; i < data.length; i++) {
      const value = getValue(data[i], arm, key);
      if (value !== null && value <= 0.5) {
        return getXValue(data[i]);
      }
    }
  };

  const formatYAxisTicks = (value: any) => {
    return `${value * 100}`;
  };

  const minDays = Math.min(...data.map((point) => point.x));
  const maxDays = Math.max(...data.map((point) => point.x));

  const ticks = getTicksAtInterval(
    minDays,
    maxDays,
    6 * NUMBER_OF_DAYS_IN_A_MONTH
  );
  const domain = [minDays, maxDays];

  return (
    <Wrapper>
      <ResponsiveContainer height={500}>
        <ComposedChart
          margin={{ top: 0, right: 0, left: 20, bottom: 0 }}
          data={data}
        >
          <XAxis
            {...xAxisProps}
            dataKey="x"
            type={"number"}
            padding={{ left: 10 }}
            ticks={ticks}
            tickFormatter={formatDaysInMonths}
            domain={domain}
          />
          <YAxis
            {...yAxisProps}
            type={"number"}
            tickFormatter={formatYAxisTicks}
          >
            {yAxisLabel}
          </YAxis>
          <ReferenceLine
            y={0.5}
            stroke={REFERENCE_LINE_STOKE}
            strokeDasharray={REFERENCE_LINE_STROKE_DASH_ARRAY}
          />
          <ReChartsTooltip content={renderToolTip} isAnimationActive={false} />
          <ReChartsLegend
            align="left"
            verticalAlign="top"
            height={48}
            content={renderLegend}
          />
          {types.flatMap((deathType) =>
            trialArmIds.map((arm) => {
              const { confidenceOpacity, confidenceColor } = attributes[arm][
                deathType
              ];
              return (
                <Area
                  isAnimationActive={false}
                  type="stepAfter"
                  dataKey={(data) => getRange(data, arm, deathType)}
                  name={`confidence_${arm}_${deathType}`}
                  key={`${arm}_${deathType}MM_actual`}
                  tooltipType={"none"}
                  legendType={"none"}
                  stroke={"none"}
                  opacity={confidenceOpacity}
                  fill={confidenceColor}
                  dot={false}
                  activeDot={false}
                  yAxisId={0}
                />
              );
            })
          )}
          {types.flatMap((deathType) =>
            trialArmIds.map((arm) => {
              const { color, strokeWidth, dashArray } = attributes[arm][
                deathType
              ];
              const name = formatArmId(arm);
              return (
                <Line
                  isAnimationActive={false}
                  type="stepAfter"
                  dataKey={(data) => getValue(data, arm, deathType)}
                  name={name}
                  key={`${arm}_${deathType}_actual`}
                  stroke={color}
                  strokeWidth={strokeWidth}
                  strokeDasharray={dashArray}
                  dot={false}
                  legendType={"plainline"}
                  yAxisId={0}
                />
              );
            })
          )}
          {trialArmIds.map((arm, index) => (
            <ReferenceLine
              key={index}
              x={getFirstOverHalf(data, arm, PREDICTED)}
              stroke={REFERENCE_LINE_STOKE}
              strokeDasharray={REFERENCE_LINE_STROKE_DASH_ARRAY}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </Wrapper>
  );
}

export default KaplanChart;
