import { Redirect, Route, Router, Switch, useRouteMatch } from "react-router";
import { useHistory } from "react-router-dom";
import React, { FC } from "react";
import { history } from "../common/store/history";
import { CurrentUserCan } from "../common/components/CurrentUserCan";
import {
  MANAGER,
  ANNOTATE as ANNOTATE_ROLE,
} from "../common/types/UserRoleType";
import { TrialContent } from "../Trial/TrialContent";
import CTAButton from "../common/components/buttons/CTAButton";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import { ManageAnnotations } from "./components/ManageAnnotations";
import {
  ANNOTATE_MANAGE_ANNOTATIONS,
  ANNOTATE_WORKLIST,
} from "./routes/routePaths";
import { TopBar } from "../common/components/TopBar/TopBar";
import { TitleTextWIcon } from "../common/components/TopBar/TitleTextWIcon";
import { Worklist } from "./components/Worklist/Worklist";
import { ReactComponent as RadiologyIcon } from "../assets/svgs/Lungs.svg";
import { main } from "../common/theme/main";
import { SvgIcon } from "../common/components/icons/SvgIcon";
import { DATA_MANAGEMENT_UPLOAD } from "../DataManagement/routes/routePaths";
import { ANNOTATE, DATA_MANAGEMENT } from "../common/routes/routePaths";
import { DataManagementIcon } from "../common/components/icons/DataManagementIcon";

export const Annotate: FC = () => {
  const { path } = useRouteMatch();
  const { push } = useHistory();

  const handleUploadClicked = () => {
    const dataManagementRootUrl = path.replace(ANNOTATE, DATA_MANAGEMENT);
    push(`${dataManagementRootUrl}/${DATA_MANAGEMENT_UPLOAD}`);
  };

  const handleManageClicked = () => {
    push(`${path}/${ANNOTATE_MANAGE_ANNOTATIONS}`);
  };

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/${ANNOTATE_MANAGE_ANNOTATIONS}`} />
        </Route>
        <Route path={`${path}/${ANNOTATE_MANAGE_ANNOTATIONS}`}>
          <CurrentUserCan
            allowedRoles={[MANAGER]}
            yes={
              <TrialContent
                topBar={
                  <TopBar
                    titleContent={
                      <TitleTextWIcon
                        icon={
                          <SvgIcon
                            icon={RadiologyIcon}
                            color={main.colors.neutral.black}
                          />
                        }
                        title={"Annotate - Manage"}
                      />
                    }
                    buttons={[
                      <CTAButton
                        key={0}
                        icon={<CloudUploadOutlinedIcon />}
                        text={"Upload now"}
                        onClick={handleUploadClicked}
                      />,
                    ]}
                  />
                }
                mainContent={<ManageAnnotations />}
              />
            }
            no={<Redirect to={`${path}/${ANNOTATE_WORKLIST}`} />}
          />
        </Route>
        <Route path={`${path}/${ANNOTATE_WORKLIST}`}>
          <CurrentUserCan
            allowedRoles={[ANNOTATE_ROLE]}
            yes={
              <TrialContent
                topBar={
                  <TopBar
                    titleContent={
                      <TitleTextWIcon
                        icon={
                          <SvgIcon
                            icon={RadiologyIcon}
                            color={main.colors.neutral.black}
                          />
                        }
                        title={"Annotate - Worklist"}
                      />
                    }
                    buttons={[
                      <CurrentUserCan
                        key={0}
                        allowedRoles={[MANAGER]}
                        yes={
                          <CTAButton
                            icon={<DataManagementIcon />}
                            text={"Manage"}
                            onClick={handleManageClicked}
                          />
                        }
                      />,
                      <CTAButton
                        key={1}
                        icon={<CloudUploadOutlinedIcon />}
                        text={"Upload now"}
                        onClick={handleUploadClicked}
                      />,
                    ]}
                  />
                }
                mainContent={<Worklist />}
              />
            }
            no={<Redirect to={`${path}/${ANNOTATE_WORKLIST}`} />}
          />
        </Route>
      </Switch>
    </Router>
  );
};
