import { TableRowBase } from "../Table/TableRowBase";
import { StatusIndicationType } from "../../types/StatusIndicationType";
import { SubjectType } from "../../../Analysis/common/types/SubjectType";
import { AnatomicalStructureType } from "../../../Analysis/common/types/AnatomicalStructureType";
import { SeriesType } from "../../../Analysis/common/types/SeriesType";
import { AnatomicalStructuresUpdateType } from "../../../Annotate/components/AnatomicalStructuresUpdateType";
import { UserType } from "../../types/UserType";
import { UserUpdateType } from "../../types/UserUpdateType";
import { matchSorter } from "match-sorter";
import { CaseAssignmentType } from "../../../Annotate/types/CaseAssignmentType";
import { CaseInstanceType } from "../../../Annotate/types/CaseInstanceType";

export type StudyTableRowType = TableRowBase & {
  rawSubject: SubjectType;
  subjectId: number;
  subjectDicomId: string;
  studyId: number;
  studyDicomId: string;
  studyDate: string;
  series: SeriesType[];
  uploadStatus: StatusIndicationType;
  qcStatus: StatusIndicationType;
  analysisStatus: StatusIndicationType;
  requiredAnatomicalStructures: AnatomicalStructureType[];
  changedAnatomicalStructures?: AnatomicalStructuresUpdateType;
  caseAssignments: CaseAssignmentType[];
  caseInstances: CaseInstanceType[];
  assignedUsers: UserType[];
  changedUsers?: UserUpdateType;
  notaUrl: string;
};

export function filterStudyTableRow(
  row: StudyTableRowType,
  query: string
): boolean {
  const {
    subjectDicomId,
    studyDicomId,
    requiredAnatomicalStructures,
    assignedUsers,
  } = row;

  const anatomicalStructureNames = requiredAnatomicalStructures.map(
    ({ name }) => name
  );

  const userDetails = assignedUsers.flatMap(
    ({ firstName, lastName, organization, title, email }) => [
      firstName,
      lastName,
      organization,
      title,
      email,
    ]
  );

  return [
    subjectDicomId,
    studyDicomId,
    ...anatomicalStructureNames,
    ...userDetails,
  ]
    .filter((field) => field)
    .some((field) => matchSorter([field], query).length > 0);
}
