import { useRef } from "react";
import { CSVLink } from "react-csv";
import { useTrialId } from "../../Trial/hooks/useTrialId";
import { useSubjectQuery } from "../../Analysis/common/hooks/useSubjectQuery";
import handleApolloError from "./handleApolloError";
import getCsvData from "./getCsvData";

interface getCsvLinkProp {
  onClose?: () => void;
}

export default function getCsvLink({ onClose }: getCsvLinkProp): any {
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const trialId = useTrialId();
  const { subjects, loading, error } = useSubjectQuery(trialId);

  if (loading) return { loading };
  if (error) handleApolloError(error);
  if (!subjects) throw new Error("Subject null with no apollo error");

  const csvData = getCsvData(subjects);

  const handleDownload = () => {
    if (csvLinkRef.current) (csvLinkRef.current as any).link.click();
    if (onClose) onClose();
  };

  return {
    csvData,
    csvLinkRef,
    handleDownload,
  };
}
