import { SubjectType } from "../../types/SubjectType";
import { parseFollowUpFromQueryResult } from "./parseFollowUpFromQueryResult";
import { RawSubjectType } from "../../rawTypes/RawSubjectType";
import { parsePatientDemographicFromQueryResults } from "./parsePatientDemographicFromQueryResults";
import { parsePatientHistoryFromQueryResults } from "./parsePatientHistoryFromQueryResults";
import { DiagnosisType } from "../../types/DiagnosisType";
import { FollowUpType } from "../../types/FollowUpType";
import { TrialArmType } from "../../types/TrialArmType";
import { TreatmentType } from "../../types/TreatmentType";
import { parsePatientCaseIds } from "./parsePatientCaseIds";
import {
  SMOKING_NEVER,
  SmokingStatusType,
} from "../../types/SmokingStatusType";
import { ETHNICITY_CAUCASIAN, EthnicityType } from "../../types/EthnicityType";
import { MUTATION_EGFR } from "../../types/TumourMutationType";
import { AJCC_IVA } from "../../types/AjccStageType";
import { HISTOLOGY_NOS } from "../../types/TumourHistologyType";

// Generating from ID ensures consistent QCs per user
function generateQCFromId(id: number): number {
  // Generate an integer in the range [1,4]; given ids [1,2,3,4,5,6,7,8...], would generate [1,2,3,4,1,2,3,4,...]
  return (id % 4) + 1;
}

export function parseSubjectFromQueryResult(
  rawSubject: RawSubjectType,
  rawNotaUrls: { url: string }[]
): SubjectType {
  const {
    patient_id: id,
    patient_dicom_id: subjectId,
    enrolments: rawEnrolments,
    follow_ups: rawFollowUps,
    patient_demographic: rawPatientDemographic,
    patient_history: rawPatientHistory,
    patient_trial_arms: rawPatientTrialArm,
    temp_demo_patients,
    temp_demo_fixed_patients,
    studies,
  } = rawSubject;

  const {
    bmi,
    dob,
    ecogPerformanceStatus,
    ethnicity,
    sex,
  } = parsePatientDemographicFromQueryResults(rawPatientDemographic);

  const notaUrls = rawNotaUrls.map(({ url }) => url);
  if (notaUrls.length !== 1) {
    throw new Error(
      "One or more urls set at temp_nota_annotation_page_url_base"
    );
  }

  const notaUrl = notaUrls[0];

  const dateOfBirth = dob ? new Date(dob) : null;

  const { smokingStatus, deathDate } = parsePatientHistoryFromQueryResults(
    rawPatientHistory
  );

  const randomizationDate =
    rawEnrolments.length > 0
      ? new Date(rawEnrolments[0].randomization_date)
      : null;

  const trialArm: TrialArmType =
    rawPatientTrialArm.length > 0
      ? {
          name: rawPatientTrialArm[0].trial_arm.name,
          number: rawPatientTrialArm[0].trial_arm.arm_num,
        }
      : ({} as TrialArmType);

  const followUpOutput = rawFollowUps.map(parseFollowUpFromQueryResult);

  const intermediateDiagnosis = followUpOutput
    .filter((fu) => fu.diagnoses.length > 0)
    .map((fu) => fu.diagnoses[0]);

  const treatments: TreatmentType[] = intermediateDiagnosis
    .filter((d) => d.treatments.length > 0)
    .flatMap((d) => {
      return { startDate: d.treatments[0].startDate };
    });

  const diagnoses: DiagnosisType[] = intermediateDiagnosis.flatMap((d) => {
    return {
      ajccStage: d.ajccStage,
      histology: d.histology,
      mutation: d.mutation,
      tStage: d.tStage,
      nStage: d.nStage,
      mStage: d.mStage,
      diagnosisDate: d.diagnosisDate,
    };
  });

  const diagnosis =
    diagnoses.length > 0
      ? diagnoses[0]
      : {
          ajccStage: null,
          histology: null,
          mutation: null,
          tStage: null,
          nStage: null,
          mStage: null,
          diagnosisDate: null,
        };

  const followUps: FollowUpType[] = followUpOutput.map((fu) => {
    return {
      order: fu.order,
      date: fu.date,
      tumourBurdens: fu.tumourBurdens,
      tumourResponses: fu.tumourResponses,
      survivalPredictions: fu.survivalPredictions,
      studies: fu.studies,
    };
  });

  //TODO get qc from db
  const qcStatus = generateQCFromId(id);

  const isTempDemoPatient = temp_demo_patients.length > 0;
  const isTempDemoFixedPatient = temp_demo_fixed_patients.length > 0;

  const caseIds = parsePatientCaseIds(studies);

  const demoAttributes: {
    diagnosis: DiagnosisType;
    bmi: number;
    smokingStatus: SmokingStatusType;
    ethnicity: EthnicityType;
    ecogPerformanceStatus: number | null;
    sex: string;
  } = {
    diagnosis: {
      mutation: MUTATION_EGFR,
      ajccStage: AJCC_IVA,
      diagnosisDate: null,
      mStage: null,
      nStage: null,
      tStage: null,
      histology: HISTOLOGY_NOS,
    },
    bmi: 24.2,
    smokingStatus: SMOKING_NEVER,
    ethnicity: ETHNICITY_CAUCASIAN,
    ecogPerformanceStatus: 1,
    sex: "Female",
  };

  return {
    id,
    subjectId,
    bmi: isTempDemoPatient ? bmi : demoAttributes.bmi,
    dateOfBirth,
    deathDate,
    diagnosis: isTempDemoPatient ? diagnosis : demoAttributes.diagnosis,
    ecogPerformanceStatus: isTempDemoPatient
      ? ecogPerformanceStatus
      : demoAttributes.ecogPerformanceStatus,
    ethnicity: isTempDemoPatient ? ethnicity : demoAttributes.ethnicity,
    followUps,
    randomizationDate,
    sex: isTempDemoPatient ? sex : demoAttributes.sex,
    smokingStatus: isTempDemoPatient
      ? smokingStatus
      : demoAttributes.smokingStatus,
    trialArm,
    treatments,
    qcStatus,
    isTempDemoPatient,
    isTempDemoFixedPatient,
    caseIds,
    notaUrl,
  };
}
