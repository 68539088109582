import { RawTrialDetailsType } from "../../types/RawTrialDetailsType";

const parseTrialDetailsData = (data: RawTrialDetailsType): string[] => {
  const { trial } = data;
  const { name, details, status } = trial[0];
  const parsedData: string[] = [];
  parsedData.push(name);
  parsedData.push(details.study_no);
  parsedData.push(status);
  parsedData.push(details.ip);
  parsedData.push(details.phase);
  parsedData.push(details.arms);
  parsedData.push(details.design);
  parsedData.push(details.primary_endpoints);
  parsedData.push(details.secondary_endpoints);
  parsedData.push(details.target_end_date);

  return parsedData;
};

export default parseTrialDetailsData;
