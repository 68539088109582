import { gql } from "@apollo/client/core";
import { MutationTuple } from "@apollo/client/react/types/types";
import { GET_ALL_TRIALS_DETAILS } from "../../queries/GetAllTrialsDetails";
import { NewTrialType } from "./NewTrialType";
import { getFormattedMMMDDYYYY } from "../../utils/dateFormatUtils/getFormattedDate";
import { SiteType } from "../../types/SiteType";
import { UserType } from "../../types/UserType";
import { useMutation } from "@apollo/client";

const MUTATION = gql`
  mutation InsertTrial($trial: trial_insert_input!) {
    insert_trial_one(object: $trial) {
      id
    }
  }
`;

type Variables = {
  trial: {
    name: string;
    status: string;
    estimated_completion?: string;
    start_date: string;
    created_by: number;
    details: {
      study_no?: number;
      ip?: string; //investigationName
      disease?: string; //indicationName
      phase?: string;
      arms?: number;
      design?: string;
      primary_endpoints?: string;
      secondary_endpoints?: string;
      target_end_date: string;
    };
    trial_arms: {
      data: { arm_num: number; name: string }[];
    };
    trial_user_settings: {
      data: {
        settings: { automaticallyProcessNewData: boolean };
      };
    };
    trial_groups: {
      data: {
        group: {
          data: {
            name: string;
            user_groups: {
              data: {
                user_id: number;
              }[];
            };
          };
        };
      };
    };
    sites: {
      data: {
        name: string;
      }[];
    };
  };
};

type Data = {
  id: number;
};

export function useInsertTrial(): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(MUTATION, {
    refetchQueries: [{ query: GET_ALL_TRIALS_DETAILS }],
  });
}

export function getInsertTrialVariables(
  trial: NewTrialType,
  sites: SiteType[],
  users: UserType[],
  createdByUserId: number
): Variables {
  const {
    name,
    status,
    estimatedCompletion,
    studyNumber: study_no,
    startDate,
    investigationProduct: ip,
    indication: disease,
    phase,
    arms,
    design,
    primaryEndpoints: primary_endpoints,
    secondaryEndpoints: secondary_endpoints,
    targetEndDate,
  } = trial;

  const siteVariables = sites.map(({ name }) => {
    return {
      name,
    };
  });

  const groupName = `${name}-group`;
  const groupVariables = {
    data: {
      name: groupName,
      user_groups: {
        data: users.map(({ id: user_id }) => {
          return {
            user_id,
          };
        }),
      },
    },
  };

  return {
    trial: {
      name,
      status,
      estimated_completion: estimatedCompletion.toUTCString(),
      start_date: startDate.toUTCString(),
      created_by: createdByUserId,
      details: {
        study_no,
        ip,
        disease,
        phase,
        arms,
        design,
        primary_endpoints,
        secondary_endpoints,
        target_end_date: getFormattedMMMDDYYYY(targetEndDate),
      },
      trial_arms: {
        data: [
          { arm_num: 0, name: "A" },
          { arm_num: 1, name: "B" },
        ],
      },
      trial_user_settings: {
        data: {
          settings: { automaticallyProcessNewData: true },
        },
      },
      trial_groups: {
        data: {
          group: groupVariables,
        },
      },
      sites: { data: siteVariables },
    },
  };
}
