import { checkWithinWeekForDemo } from "../../../common/utils/dateFormatUtils/checkWithinWeek";

type RawUploadReportsType = {
  uploadJobReport: {
    contents: { [key: string]: Record<string, string> }[];
  }[];
};

interface UploadReportsType {
  type: string;
  weekTotal: number;
  allTimeTotal: number;
}

const parseDataIngestionData = (
  data: RawUploadReportsType
): UploadReportsType[] => {
  const { uploadJobReport } = data;
  const COMPLETE_STRING = "COMPLETE";
  const DATE_SUFFIX = "_date";
  const TYPES: { [key: string]: string } = {
    upload: "Upload",
    deid: "De-ID",
    qc: "QC",
  };
  const processedData: UploadReportsType[] = [];

  for (const typeKey in TYPES) {
    processedData.push({
      type: TYPES[typeKey],
      weekTotal: 0,
      allTimeTotal: 0,
    });
  }

  uploadJobReport.forEach((uploadJob) => {
    const { contents } = uploadJob;

    contents.forEach((content) => {
      for (const contentKey in content) {
        const subContent = content[contentKey];
        Object.keys(TYPES).forEach((typeKey, index) => {
          if (subContent[`${typeKey}`] === COMPLETE_STRING) {
            if (
              checkWithinWeekForDemo(subContent[`${typeKey}${DATE_SUFFIX}`])
            ) {
              processedData[index].weekTotal += 1;
            }
            processedData[index].allTimeTotal += 1;
          }
        });
      }
    });
  });
  return processedData;
};

export default parseDataIngestionData;
