import {
  TUMOR_BURDEN,
  PREDICTED_SURVIVAL,
} from "../../common/utils/spellingConstants";

export function sortTooltip(
  tooltipData: any,
  tumorScale: number,
  survivalScale: number
) {
  for (const item of tooltipData) {
    if (item.name.includes(TUMOR_BURDEN)) {
      item.scaledValue = item.value / tumorScale;
    } else if (item.name.includes(PREDICTED_SURVIVAL)) {
      item.scaledValue = item.value / survivalScale;
    }
  }
  return [...tooltipData].sort(sortByScaledValue);
}

function sortByScaledValue(a: any, b: any): number {
  if (a.scaledValue > b.scaledValue) return -1;
  else return 1;
}
