export const SUCCESS = "SUCCESS";
export const INFO = "INFO";
export const WARNING = "WARNING";
export const ERROR = "ERROR";

export type StatusType =
  | typeof SUCCESS
  | typeof INFO
  | typeof WARNING
  | typeof ERROR;
