import dayjs, { Dayjs } from "dayjs";
import { main } from "../../../common/theme/main";
import { getMonthDateTime } from "../../../common/utils/dateFormatUtils/getMonthDateTime";
import { RawSubjectsQueryType } from "../../../Analysis/common/hooks/useSubjectQuery";
import { RawTrialDetailsType } from "../../../common/types/RawTrialDetailsType";
import { RawFollowUp } from "../../../Analysis/common/rawTypes/RawFollowUp";
import { DEFAULT_TODAY_DATE } from "../../../common/utils/dateFormatUtils/DateConstants";

export const colorConfig = {
  TARGET: main.colors.accent.accent4,
  ESTIMATE: main.colors.accent.accent2,
  CURRENT: main.colors.accent.accent3,
};

export interface ParsedTrialCompletionBurndownDataProps {
  date: Date;
  current: number | null | undefined;
  estimated?: number | null;
  target?: number;
}

interface CompletionDateProps {
  currentDates: Date[];
  estimatedDates: Date[];
}

const getMonthsBetween = (
  projectStartDate: Date,
  expectedEndDate: Date
): number => {
  const start = dayjs(projectStartDate);
  const end = dayjs(expectedEndDate);
  return end.diff(start, "months");
};

const getTumourResponseFromFollowUp = (followUp: RawFollowUp): string => {
  // TODO This assumes only one series/study/response
  return followUp.study_follow_up_maps[0].study.series[0]
    .series_tumour_response_maps.length > 0
    ? followUp.study_follow_up_maps[0].study.series[0]
        .series_tumour_response_maps[0].tumour_response.classification
    : "";
};

const addTargetLineData = (
  parsedData: ParsedTrialCompletionBurndownDataProps[],
  data: RawSubjectsQueryType,
  monthsBetweenStartAndEnd: number
) => {
  let interval: number;
  if (parsedData[0]) {
    interval = data.patient.length / monthsBetweenStartAndEnd;
  }

  parsedData.forEach((dataPoint, i) => {
    if (i === 0) {
      dataPoint.target = data.patient.length;
    } else if (i === parsedData.length - 1) {
      dataPoint.target = 0;
    } else {
      dataPoint.target = (parsedData[i - 1].target ?? 0) - interval;
    }
  });
};

const getChartDates = (
  startDate: Date,
  monthsBetweenInTrial: number
): Date[] => {
  const chartDates: Date[] = [];

  for (let months = 0; months <= monthsBetweenInTrial; months++) {
    const newDate = dayjs(startDate)
      .add(months, "months")
      .add(1, "day")
      .set("hours", 0);
    chartDates.push(newDate.toDate());
  }
  return chartDates;
};

const getEstimatedCompletedSubjectsThisMonth = (
  currentDate: Dayjs,
  estimatedCompletionDates: Date[]
): number => {
  let estimatedCompletedSubjectsThisMonth = 0;
  estimatedCompletionDates.forEach((date) => {
    if (dayjs(date).isSame(currentDate, "month")) {
      estimatedCompletedSubjectsThisMonth += 1;
    }
  });
  return estimatedCompletedSubjectsThisMonth;
};

const getEstimatedSubjectsLine = (
  chartDates: Date[],
  estimatedCompletionDates: Date[],
  currentSubjectCount: number
): (number | null)[] => {
  const todayDate = new Date(DEFAULT_TODAY_DATE);
  const estimatedSubjectsLine: (number | null)[] = [null];
  for (let i = 0; i < chartDates.length; i++) {
    if (dayjs(todayDate).isSame(chartDates[i], "month")) {
      estimatedSubjectsLine.push(currentSubjectCount);
    } else if (chartDates[i] > todayDate) {
      const completedSubjectsThisMonth = getEstimatedCompletedSubjectsThisMonth(
        dayjs(chartDates[i]),
        estimatedCompletionDates
      );
      estimatedSubjectsLine.push(
        estimatedSubjectsLine[i]! - completedSubjectsThisMonth
      );
    } else {
      estimatedSubjectsLine.push(null);
    }
  }
  return estimatedSubjectsLine;
};

const getCurrentSubjectsLine = (
  chartDates: Date[],
  currentCompletionDates: Date[],
  currentSubjectCount: number
): (number | null)[] => {
  const todayDate = new Date(DEFAULT_TODAY_DATE);
  const currentSubjectsLine: (number | null)[] = [currentSubjectCount];
  for (let i = 0; i < chartDates.length; i++) {
    if (chartDates[i] <= todayDate) {
      const completedSubjectsThisMonth = getEstimatedCompletedSubjectsThisMonth(
        dayjs(chartDates[i]),
        currentCompletionDates
      );
      currentSubjectsLine.push(
        currentSubjectsLine[i]! - completedSubjectsThisMonth
      );
    } else {
      currentSubjectsLine.push(null);
    }
  }
  return currentSubjectsLine;
};

const getAllSubjectCompletionDates = (
  subjectsData: RawSubjectsQueryType
): CompletionDateProps => {
  let currentDates: Date[] = [];
  let estimatedDates: Date[] = [];
  subjectsData.patient.forEach((subject) => {
    for (let i = 0; i < subject.follow_ups.length; i++) {
      if (
        subject.follow_ups[i].follow_up_order === 7 ||
        getTumourResponseFromFollowUp(subject.follow_ups[i]) === "PD"
      ) {
        currentDates.push(dayjs(subject.follow_ups[i].follow_up_date).toDate());
        break;
      } else if (
        subject.follow_ups[i].follow_up_order ===
        subject.follow_ups.length - 1
      ) {
        const daysFromEstimatedCompletion = 42 * (7 - i);
        const lastFollowUpDate = dayjs(subject.follow_ups[i].follow_up_date)
          .add(daysFromEstimatedCompletion, "days")
          .toDate();
        estimatedDates.push(lastFollowUpDate);
        break;
      }
    }
  });
  currentDates.sort((a, b) => a.getTime() - b.getTime());
  estimatedDates.sort((a, b) => a.getTime() - b.getTime());
  return { currentDates, estimatedDates };
};

export function parseTrialsCompletionBurndownData(
  subjectsData: RawSubjectsQueryType,
  trialData: RawTrialDetailsType
): ParsedTrialCompletionBurndownDataProps[] {
  const trial = trialData.trial[0];
  const startDate = new Date(trial.startDate);
  const monthsBetween = getMonthsBetween(startDate, trial.estimatedCompletion);
  const chartDates: Date[] = getChartDates(startDate, monthsBetween);

  const { currentDates, estimatedDates } = getAllSubjectCompletionDates(
    subjectsData
  );
  const currentSubjectsLine: (number | null)[] = getCurrentSubjectsLine(
    chartDates,
    currentDates,
    subjectsData.patient.length
  );
  const currentSubjectCount = Math.min(
    ...currentSubjectsLine.filter((x): x is number => x !== null)
  );
  const estimatedSubjectsLine: (number | null)[] = getEstimatedSubjectsLine(
    chartDates,
    estimatedDates,
    currentSubjectCount
  );
  const parsedData = chartDates.map((date, i) => {
    return {
      date: date,
      current: currentSubjectsLine[i],
      estimated: estimatedSubjectsLine[i],
    };
  });
  addTargetLineData(parsedData, subjectsData, monthsBetween);
  return parsedData;
}

export const trialBurndownTickFormater = (
  tick: number,
  data: ParsedTrialCompletionBurndownDataProps[]
): string => {
  if (data[0].target) {
    return `${(tick / data[0].target) * 100}`;
  }
  return "0 %";
};

export const getTrialBurndownTicks = (
  data: ParsedTrialCompletionBurndownDataProps[]
): number[] => {
  let interval = 0;
  const ticks: number[] = [];
  if (data[0].target) {
    ticks.push(0);
    interval = data[0].target * 0.25;
    for (let i = 1; i < 5; i++) {
      ticks.push(ticks[i - 1] + interval);
    }
  }
  return ticks;
};

export const formatBurndownLabel = (date: string): string => {
  return getMonthDateTime(new Date(date));
};

export const formatBurndownValue = (value: number): string => {
  return Math.ceil(value).toString();
};
