import { ApolloError, useQuery } from "@apollo/client";
import { TrialType } from "../types/TrialType";
import { RawTrialDetailsType } from "../types/RawTrialDetailsType";
import { GET_ALL_TRIALS_DETAILS } from "./GetAllTrialsDetails";
import { parseTrialDetails } from "../../Dashboard/utils/parseTrialDetails";

type useAllTrialsResultType = {
  trials?: TrialType[];
  error?: ApolloError;
  loading: boolean;
};

export function useAllTrials(): useAllTrialsResultType {
  const { data, loading, error } = useQuery<RawTrialDetailsType>(
    GET_ALL_TRIALS_DETAILS
  );

  let trials;

  if (!loading && !error && data) {
    trials = parseTrialDetails(data);
  }

  return {
    trials,
    loading,
    error,
  };
}
