import React, { ReactElement, useEffect, useState } from "react";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import store from "./common/store/store";
import { Router } from "react-router";
import { history } from "./common/store/history";
import PageContainer from "./common/components/containers/PageContainer";
import { useAuth0 } from "@auth0/auth0-react";
import config from "./auth0/auth_config.json";
import { setTokenCallback, annotateClient } from "./apollo/client";
import { ThemeProvider } from "styled-components";
import { main } from "./common/theme/main";
import Modal from "react-modal";
import PageTitleWrapper from "./common/components/PageTitleWrapper";

//register react-modal to app
Modal.setAppElement("#root");

const App = (): ReactElement => {
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    loginWithRedirect,
  } = useAuth0();
  const [appReady, setAppReady] = useState<boolean>(false);

  const getToken = async () => {
    let token;
    try {
      token = await getAccessTokenSilently(config);
    } catch (e) {
      console.warn("Failed to get token silently, trying with popup");
      token = await getAccessTokenWithPopup(config);
    }
    return token;
  };

  useEffect(() => {
    if (isAuthenticated) {
      setTokenCallback(getToken);
      setAppReady(true);
    }
  }, [isAuthenticated]);

  if (!isAuthenticated && !isLoading) {
    loginWithRedirect().then(() => undefined);
  }

  return (
    <Provider store={store}>
      <Router history={history}>
        <ApolloProvider client={annotateClient}>
          <ThemeProvider theme={main}>
            <PageTitleWrapper>
              <PageContainer isAppReady={appReady} />
            </PageTitleWrapper>
          </ThemeProvider>
        </ApolloProvider>
      </Router>
    </Provider>
  );
};

export default App;
