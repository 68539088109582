import { LifestyleFilters } from "../../../../common/types/LifestyleFilterTypes";
import { DemographicsFilters } from "../../../../common/types/DemographicsFilterTypes";
import { ageFromDOB } from "../../../../common/utils/dateFormatUtils/ageFromDOB";
import {
  AjccStageFilters,
  HistologyFilters,
  TrialArmFilters,
  TumourMutationsFilters,
} from "../../../../common/types/TrialViewFilterTypes";
import { FilterTypes } from "../../../../common/store/trialViewFiltersSlice";
import { SubjectType } from "../../types/SubjectType";
import { TumourHistologyType } from "../../types/TumourHistologyType";
import { AjccStageType } from "../../types/AjccStageType";
import { TumourMutationType } from "../../types/TumourMutationType";
import { SmokingStatusType } from "../../types/SmokingStatusType";
import { EthnicityType } from "../../types/EthnicityType";

function lifestyleIsEnabled(
  bmi: number | null,
  smokingStatus: SmokingStatusType | null,
  lifestyleFilters: LifestyleFilters
) {
  // Subject is in the enabled age range
  const bmiIsEnabled =
    bmi === null ||
    valueInFilterRange(bmi, lifestyleFilters.bmi.min, lifestyleFilters.bmi.max);

  // Subject is in an enabled sex
  const smokingStatusIsEnabled =
    smokingStatus === null || lifestyleFilters.smokingStatus[smokingStatus];

  return bmiIsEnabled && smokingStatusIsEnabled;
}

function valueInFilterRange(
  value: number,
  min: number | null,
  max: number | null
) {
  const minSatisfied = min === null ? true : min <= value;
  const maxSatisfied = max === null ? true : value <= max;
  return minSatisfied && maxSatisfied;
}

function demographicIsEnabled(
  dob: Date | null,
  sex: string | null,
  ethnicity: EthnicityType | null,
  demographicsFilters: DemographicsFilters
) {
  let ageIsEnabled;

  if (dob !== null) {
    const age = ageFromDOB(dob);
    // Subject is in the enabled age range
    ageIsEnabled = valueInFilterRange(
      age,
      demographicsFilters.age.min,
      demographicsFilters.age.max
    );
  } else {
    ageIsEnabled = true;
  }

  // Subject is in an enabled sex
  const sexIsEnabled =
    sex === null ||
    demographicsFilters.sex[
      sex.toLowerCase() as keyof DemographicsFilters["sex"]
    ];

  // Subject is in an enabled ethnicity
  const ethnicityIsEnabled =
    ethnicity === null || demographicsFilters.ethnicity[ethnicity];

  return ageIsEnabled && sexIsEnabled && ethnicityIsEnabled;
}

function mutationIsEnabled(
  mutation: TumourMutationType | null,
  tumourMutationFilters: TumourMutationsFilters
): boolean {
  if (mutation === null) {
    return true;
  }

  //TODO we shouldn't use partial types, since things can slip through the cracks
  // however we currently are to accommodate the buttons mapping
  // since we are using a partial type, its possible the filter is undefined, so lets include it
  return tumourMutationFilters[mutation] ?? true;
}

function histologyIsEnabled(
  histology: TumourHistologyType | null,
  histologyFilters: HistologyFilters
) {
  if (histology === null) {
    return true;
  }

  //TODO we shouldn't use partial types, since things can slip through the cracks
  // however we currently are to accommodate the buttons mapping
  // since we are using a partial type, its possible the filter is undefined, so lets include it
  return histologyFilters[histology] ?? true;
}

function ajccStageIsEnabled(
  ajccStage: AjccStageType | null,
  ajccStageFilters: AjccStageFilters
) {
  if (ajccStage === null) {
    return true;
  }

  //TODO we shouldn't use partial types, since things can slip through the cracks
  // however we currently are to accommodate the buttons mapping
  // since we are using a partial type, its possible the filter is undefined, so lets include it
  return ajccStageFilters[ajccStage] ?? true;
}

// Given some filters, create a "filter function" which returns true if the passed subject passes the filters, and false otherwise
export const applySubjectLevelFilters = (filters: FilterTypes) => ({
  dateOfBirth,
  sex,
  bmi,
  smokingStatus,
  ethnicity,
  trialArm,
  diagnosis,
  ecogPerformanceStatus,
}: SubjectType): boolean => {
  const { ajccStage, histology, mutation } = diagnosis;
  const { name: trialArmName } = trialArm;

  const isInEnabledTrialArm =
    filters.trialArm[trialArmName as keyof TrialArmFilters];

  const isInEnabledAjccStage = ajccStageIsEnabled(
    ajccStage,
    filters.stage.ajccStage
  );
  const isInEnabledHistology = histologyIsEnabled(histology, filters.histology);

  const isInEnabledMutation = mutationIsEnabled(
    mutation,
    filters.tumourMutations
  );

  const isInEnabledDemographic = demographicIsEnabled(
    dateOfBirth,
    sex,
    ethnicity,
    filters.demographics
  );

  const isInEnabledLifestyle = lifestyleIsEnabled(
    bmi,
    smokingStatus,
    filters.lifestyle
  );

  const isInEnabledEcogGroup =
    ecogPerformanceStatus === null || filters.ecog[ecogPerformanceStatus];

  return (
    isInEnabledTrialArm &&
    isInEnabledAjccStage &&
    isInEnabledHistology &&
    isInEnabledMutation &&
    isInEnabledDemographic &&
    isInEnabledLifestyle &&
    isInEnabledEcogGroup
  );
};
