import { RawSeriesType } from "../../rawTypes/RawSeriesType";
import { SeriesType } from "../../types/SeriesType";
import { parseTumourBurdenFromQueryResult } from "./parseTumourBurdenFromQueryResult";
import { parseTumourResponsesFromQueryResult } from "./parseTumourResponsesFromQueryResult";
import { parseAnatomicalStructuresFragment } from "../../../../Annotate/components/AnatomicalStructuresFragment";
import { parseUserFragment } from "../../../../Settings/UserFragment";
import { CaseAssignmentType } from "../../../../Annotate/types/CaseAssignmentType";
import { CaseAssignmentWithUserType } from "../../../../Annotate/types/CaseAssignmentWithUserType";
import { CaseInstanceType } from "../../../../Annotate/types/CaseInstanceType";

export function parseSeriesFromQueryResult(
  rawSeries: RawSeriesType
): SeriesType {
  const {
    series_id: id,
    survival_predictions: rawSurvivalPrediction,
    series_tumour_burden_maps: rawSeriesTumourBurden,
    series_tumour_response_maps: rawSeriesTumourResponse,
    series_required_anatomical_structures: rawRequiredAnatomicalStructures,
    series_cases: rawSeriesCases,
  } = rawSeries;

  const survivalPredictions = rawSurvivalPrediction
    .map((s) => s.survival)
    .filter((s) => s !== null);

  const tumourBurdens = rawSeriesTumourBurden.map(
    parseTumourBurdenFromQueryResult
  );
  const tumourResponses = rawSeriesTumourResponse.map(
    parseTumourResponsesFromQueryResult
  );

  const requiredAnatomicalStructures = rawRequiredAnatomicalStructures.map(
    (item) => {
      const { anatomical_structure } = item;
      return parseAnatomicalStructuresFragment(anatomical_structure);
    }
  );

  const caseAssignments: CaseAssignmentWithUserType[] = rawSeriesCases
    .flatMap(({ case: { case_assignments } }) => case_assignments)
    .map(({ user, ...other }) => ({
      ...other,
      user: parseUserFragment(user),
    }));

  const caseInstances: CaseInstanceType[] = rawSeriesCases.flatMap(
    ({ case: { case_instances } }) => case_instances
  );

  return {
    id,
    tumourBurdens,
    tumourResponses,
    survivalPredictions,
    requiredAnatomicalStructures,
    caseAssignments,
    caseInstances,
  };
}
