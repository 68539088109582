import React, { FC, useState } from "react";
import { useDialogReturnType } from "../../Dialog/useDialog";
import { useCreateUserDialog } from "../../UserManagement/Dialogs/useCreateUserDialog";
import { useCreateSiteDialog } from "../../SiteManagement/Dialogs/useCreateSiteDialog";
import { useEditTrialSitesDialog } from "../../SiteManagement/Dialogs/useEditTrialSitesDialog";
import { useEditTrialUsersDialog } from "../../UserManagement/Dialogs/useEditTrialUsersDialog";
import { SiteType } from "../../../types/SiteType";
import { getNextId } from "../../../utils/getNextId";
import { replaceAt } from "../../../utils/replaceAt";
import { UserType } from "../../../types/UserType";
import {
  createTrialUserContext,
  UserManagementContextProvider,
} from "../../UserManagement/UserManagementContext";
import { CreateTrialForm } from "../CreateTrialForm";
import { NewTrialType } from "../NewTrialType";
import {
  manageTrialSiteContext,
  SiteManagementContext,
} from "../../SiteManagement/SiteManagementContext";
import { getInsertTrialVariables, useInsertTrial } from "../useInsertTrial";
import handleApolloError from "../../../utils/handleApolloError";
import { useCurrentUser } from "../../UserManagement/useCurrentUser";
import { createNewUsers } from "../../UserManagement/createNewUsers";
import { useFormDialog } from "../../Dialog/useFormDialog";
import { FormProps } from "../../Dialog/FormProps";
import Loading from "../../Loading";

export function useCreateTrialDialog(): useDialogReturnType {
  return useFormDialog({
    children: FormWrapper,
    label: "Create Trial",
    showCloseButton: true,
    props: {},
  });
}

const FormWrapper: FC<FormProps<unknown>> = ({
  onSubmit,
  onCancel,
}: FormProps<unknown>) => {
  const { data: user } = useCurrentUser();

  const [sites, setSites] = useState<SiteType[]>([]);
  const [users, setUsers] = useState<UserType[]>([]);

  const handleSitesCreated = (newSites: SiteType[]) => {
    const newSitesWithId = newSites.map((site) => {
      const { name } = site;
      const id = getNextId(sites.map((site) => site.id));
      return { id, name };
    });

    setSites([...sites, ...newSitesWithId]);
  };

  const handleSiteRemoved = (site: SiteType) => {
    setSites(sites.filter((existingSite) => existingSite.id !== site.id));
  };

  const handleSiteEdited = (site: SiteType) => {
    const existingIndex = sites.findIndex(
      (existingSite) => existingSite.id === site.id
    );
    setSites(replaceAt(sites, existingIndex, site));
  };

  const handleUsersCreated = async (newUsers: UserType[]) => {
    const allUsers = await createNewUsers(newUsers);

    setUsers([...users, ...allUsers]);
  };

  const handleUserRemoved = (user: UserType) => {
    setUsers(users.filter((existingUser) => existingUser.id !== user.id));
  };

  const handleUserEdited = (user: UserType) => {
    const existingIndex = users.findIndex(
      (existingUser) => existingUser.id === user.id
    );
    setUsers(replaceAt(users, existingIndex, user));
  };

  const [
    setShowCreateUserDialog,
    { dialog: createUserDialog },
  ] = useCreateUserDialog(users, handleUsersCreated);
  const [
    setShowCreateSiteDialog,
    { dialog: createSiteDialog },
  ] = useCreateSiteDialog(handleSitesCreated);
  const [
    setShowEditSitesDialog,
    { dialog: editSitesDialog },
  ] = useEditTrialSitesDialog({
    sites,
    actionCallbacks: {
      onCreated: handleSitesCreated,
      onRemoved: handleSiteRemoved,
      onEdited: handleSiteEdited,
    },
  });
  const [
    setShowEditUsersDialog,
    { dialog: editUsersDialog },
  ] = useEditTrialUsersDialog({
    actionCallbacks: {
      onCreated: handleUsersCreated,
      onRemoved: handleUserRemoved,
      onEdited: handleUserEdited,
    },
  });

  const [insertTrial, { loading, error }] = useInsertTrial();

  const handleShowSites = () => {
    setShowEditSitesDialog(true);
  };

  const handleAddSite = () => {
    setShowCreateSiteDialog(true);
  };

  const handleShowUsers = () => {
    setShowEditUsersDialog(true);
  };

  const handleAddUser = () => {
    setShowCreateUserDialog(true);
  };

  const handleCreateTrial = async (trial: NewTrialType) => {
    if (!user) {
      throw new Error("User is null in handleCreateTrial");
    }

    const { id: createdByUserId } = user;
    const variables = getInsertTrialVariables(
      trial,
      sites,
      users,
      createdByUserId
    );
    await insertTrial({ variables });
  };

  if (error) handleApolloError(error);
  if (loading) return <Loading />;

  return (
    <UserManagementContextProvider
      rules={createTrialUserContext}
      users={users}
      setUsers={setUsers}
    >
      <SiteManagementContext.Provider value={manageTrialSiteContext}>
        {createUserDialog}
        {createSiteDialog}
        {editSitesDialog}
        {editUsersDialog}
        <CreateTrialForm
          props={{
            sites,
            onAddSite: handleAddSite,
            onShowSites: handleShowSites,
            users,
            onAddUser: handleAddUser,
            onShowUsers: handleShowUsers,
            onCreateTrial: handleCreateTrial,
          }}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </SiteManagementContext.Provider>
    </UserManagementContextProvider>
  );
};
