import React from "react";
import styled from "styled-components";

interface TextInputProps {
  name: string;
  required?: boolean;
  children: React.ReactElement<React.InputHTMLAttributes<HTMLInputElement>>;
}

const InputWrapper = styled.div`
  color: black;
  font-family: Inter;
  font-size: 13px;
  width: 100%;
  padding-top: 8px;
  position: relative;
`;

const FormTitle = styled.span`
  position: absolute;
  background-color: white;
  top: 0px;
  left: 20px;
  padding-left: 4px;
  padding-right: 4px;
`;

const Required = styled.span`
  color: red;
`;

const ChildrenWrapper = styled.div`
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #858c94;
  &:focus-within {
    border: 1px solid black;
  }
`;

export function InputBox({
  name,
  required,
  children,
}: TextInputProps): JSX.Element {
  return (
    <InputWrapper>
      <FormTitle>
        {name}
        {required && <Required>*</Required>}
      </FormTitle>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </InputWrapper>
  );
}
