import styled from "styled-components";
import { SvgIcon } from "../icons/SvgIcon";
import { ReactComponent as ErrorIcon } from "../../../assets/svgs/Error.svg";
import { main } from "../../theme/main";
import React from "react";

const ErrorLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px 6px 16px;
  background: #feecec;
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.states.error};
  gap: 4px;
`;

interface ErrorLabelProps {
  children: string;
}

export function ErrorLabel({ children }: ErrorLabelProps): JSX.Element {
  return (
    <ErrorLabelWrapper>
      <SvgIcon icon={ErrorIcon} size={16} color={main.colors.states.error} />
      {children}
    </ErrorLabelWrapper>
  );
}
