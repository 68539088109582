import React from "react";
import { useDialog, useDialogReturnType } from "../../Dialog/useDialog";
import { UserType } from "../../../types/UserType";
import { OK_CANCEL } from "../../Dialog/DialogActionButtonsType";
import { UserDetails } from "../UserDetails";
import { RED_BUTTON_OVERRIDES } from "../../Dialog/RedButtonOverrides";
import { DialogText } from "../../Dialog/DialogText";
import { DialogContentInnerWrapper } from "../../Dialog/DialogContentInnerWrapper";

export function useRemoveUserFromTrialDialog(
  user: UserType,
  onConfirmRemoveUser: (user: UserType) => void
): useDialogReturnType {
  const label = "Remove Team Member From Trial";

  const content = (
    <DialogContentInnerWrapper>
      <UserDetails user={user} />
      <DialogText>
        This will remove the user from the trial. All data created by this user
        will remain, however they will no longer be able to access this trial.
      </DialogText>
    </DialogContentInnerWrapper>
  );

  const handleConfirmRemoveUser = () => {
    onConfirmRemoveUser(user);
  };

  return useDialog({
    label,
    content,
    options: {
      showCloseButton: false,
      actionButtonOptions: {
        type: OK_CANCEL,
        okText: "Remove",
        onOkOverride: handleConfirmRemoveUser,
        okButtonOverrides: RED_BUTTON_OVERRIDES,
      },
    },
  });
}
