import { FollowUpType } from "../types/FollowUpType";

export function getFollowUpTumourBurden(followUp: FollowUpType): number | null {
  const { tumourBurdens } = followUp;
  const firstTumourBurden = tumourBurdens.length > 0 ? tumourBurdens[0] : null;
  if (!firstTumourBurden) {
    return null;
  }
  return firstTumourBurden.burden;
}
