import { getTrialViewFilters } from "nota-predict-web/src/common/store/trialViewFiltersSlice";
import { useSelector } from "react-redux";
import { useSubjectQuery } from "./useSubjectQuery";
import { applySubjectLevelFilters } from "../utils/filters/applySubjectLevelFilters";
import { SubjectQueryResultType } from "../types/SubjectQueryResultType";

export function useFilteredSubjectsQuery(
  trialId: number
): SubjectQueryResultType {
  const { subjects, loading, error, refetch } = useSubjectQuery(trialId);
  const filters = useSelector(getTrialViewFilters);

  let filteredSubjects;

  if (!loading && !error && subjects) {
    // filteredSubjects = useMemo(
    //   () => subjects.filter(applySubjectLevelFilters(filters)),
    //   [subjects]
    // );
    filteredSubjects = subjects.filter(applySubjectLevelFilters(filters));
  }

  return {
    subjects: filteredSubjects,
    loading,
    error,
    refetch,
  };
}
