import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { main } from "../../../common/theme/main";
import { Checkbox, CheckboxProps, withStyles } from "@material-ui/core";

export const BlackCheckbox = withStyles({
  root: {
    color: main.colors.neutral.black,
    boxSizing: "border-box",
  },
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

interface FilterCheckboxProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  name: string;
}

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: default;
`;

const CheckboxText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;

  display: flex;
  align-items: center;

  color: ${(props) => props.theme.colors.neutral.black};

  margin: 0 8px;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

const FilterCheckbox = ({
  label,
  name,
  checked,
  onChange,
}: FilterCheckboxProps): ReactElement => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    if (checked === isChecked) {
      return;
    }
    setIsChecked(checked);
  }, [checked]);

  const handleClickLabel = () => {
    const newState = !isChecked;
    setIsChecked(newState);
    onChange(newState);
  };

  const handleClickCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = (event.target as HTMLInputElement).checked;
    setIsChecked(checked);
    onChange(checked);
  };

  return (
    <CheckboxWrapper>
      <BlackCheckbox
        checked={checked}
        onChange={handleClickCheckbox}
        disableRipple
        name={name}
        size={"small"}
        style={{ margin: "2px 0px", padding: "0px" }}
      />
      <CheckboxText onClick={handleClickLabel}>{label}</CheckboxText>
    </CheckboxWrapper>
  );
};

export default FilterCheckbox;
