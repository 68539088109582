import React from "react";
import { FC } from "react";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import styled from "styled-components";

const Styled = styled(GetAppRoundedIcon)`
  color: ${(props) => props.theme.colors.primary.default};
`;

export const DownloadIcon: FC = () => <Styled />;
