import { RawDiagnosisType } from "../../rawTypes/RawDiagnosisType";
import { getTumourHistologyType } from "../../types/TumourHistologyType";
import { getAjccStageType } from "../../types/AjccStageType";
import { getTumourMutationType } from "../../types/TumourMutationType";
import { IntermediateDiagnosisType } from "../../types/IntermediateDiagnosisType";
import { RawTumourType } from "../../rawTypes/RawTumourType";

export function parseDiagnosisFromQueryResult(
  rawDiagnoses: RawDiagnosisType
): IntermediateDiagnosisType {
  // TODO We assume (1) that there is only 1 diagnosis, only 1 tumour and only 1 histology
  const {
    diagnosis_histology_maps: rawDiagnosisHistology,
    stages: rawStages,
    treatments: rawTreatments,
    tumours: rawTumours,
    diagnosis_date: diagnosisDate,
  } = rawDiagnoses;

  const stage =
    rawStages.length > 0
      ? rawStages[0]
      : {
          ajcc_stage: null,
          t_stage: null,
          m_stage: null,
          n_stage: null,
        };

  const {
    ajcc_stage: rawAjccStage,
    t_stage: tStage,
    m_stage: mStage,
    n_stage: nStage,
  } = stage;

  const ajccStage =
    rawAjccStage !== null ? getAjccStageType(rawAjccStage) : null;

  const histologyStr =
    rawDiagnosisHistology.length != 0
      ? rawDiagnosisHistology[0].histology.result
      : null;
  const histology =
    histologyStr !== null ? getTumourHistologyType(histologyStr) : null;

  const treatments = rawTreatments.flatMap((rawTreatment) => {
    return { startDate: rawTreatment.start_date };
  });

  const { tumour_mutations: mutations } =
    rawTumours.length > 0 ? rawTumours[0] : { tumour_mutations: [] };

  const { mutation: rawMutation } =
    mutations.length > 0 ? mutations[0] : { mutation: null };

  const mutation =
    rawMutation !== null ? getTumourMutationType(rawMutation) : null;

  return {
    mutation,
    histology,
    ajccStage,
    tStage,
    nStage,
    mStage,
    diagnosisDate,
    treatments,
  };
}
