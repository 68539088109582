import React from "react";
import {
  ParsedTrialCompletionBurndownDataProps,
  trialBurndownTickFormater,
  getTrialBurndownTicks,
  colorConfig,
  formatBurndownLabel,
} from "../utils/parseTrialsCompletionBurndown";
import {
  Legend as ReChartsLegend,
  LineChart,
  Tooltip as ReChartTooltip,
  XAxis,
  YAxis,
  Line,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";
import { getXAxisProps } from "../../../Analysis/common/utils/getXAxisProps";
import styled from "styled-components";
import { Legend } from "../../../Analysis/common/components/Legend";
import { Tooltip } from "../../../Analysis/common/components/Tooltip";
import {
  DASHED_STROKE_DASH_ARRAY,
  STROKE_WIDTH,
} from "../../../Analysis/common/utils/strokeSettings";
import { formatPercentage } from "../../../Analysis/common/utils/formatPercentage";
import { getYAxisLabel } from "../../../Analysis/common/utils/getYAxisLabel";
import { getYAxisProps } from "../../../Analysis/common/utils/getYAxisProps";

const Wrapper = styled.div`
  flex: 1;
`;

interface TrialCompletionBurndownProps {
  data: ParsedTrialCompletionBurndownDataProps[];
}

function TrialCompletionBurndown({
  data,
}: TrialCompletionBurndownProps): React.ReactElement {
  const xAxisProps = getXAxisProps();
  const yAxisLabel = getYAxisLabel("Percent");
  const yAxisProps = getYAxisProps(true);

  const renderCustomizedLegend = ({ payload }: any) => (
    <Legend payload={payload} />
  );

  const renderTooltip = (props: any) => {
    return (
      <Tooltip
        {...props}
        formatLabel={formatBurndownLabel}
        formatValue={formatPercentage}
      />
    );
  };

  return (
    <Wrapper>
      <ResponsiveContainer height={500}>
        <LineChart
          data={data}
          margin={{ top: 0, right: 0, left: 20, bottom: -40 }}
        >
          <XAxis
            dataKey={"date"}
            tickFormatter={(tick) => dayjs(tick).format("YYYY")}
            interval={11}
            {...xAxisProps}
          />
          <YAxis
            {...yAxisProps}
            type={"number"}
            ticks={getTrialBurndownTicks(data)}
            tickFormatter={(tick) => trialBurndownTickFormater(tick, data)}
          >
            {yAxisLabel}
          </YAxis>
          <ReChartTooltip isAnimationActive={false} content={renderTooltip} />
          <ReChartsLegend
            align="left"
            verticalAlign="top"
            height={48}
            content={renderCustomizedLegend}
          />
          <Line
            type={"linear"}
            dataKey={"target"}
            stroke={colorConfig.TARGET}
            dot={false}
            strokeDasharray={DASHED_STROKE_DASH_ARRAY}
            legendType={"plainline"}
            isAnimationActive={false}
            name={"Target"}
            strokeWidth={STROKE_WIDTH}
          />
          <Line
            type={"step"}
            dataKey={"estimated"}
            dot={false}
            stroke={colorConfig.ESTIMATE}
            legendType={"plainline"}
            isAnimationActive={false}
            name={"Estimated"}
            strokeWidth={STROKE_WIDTH}
          />
          <Line
            type={"step"}
            dataKey={"current"}
            stroke={colorConfig.CURRENT}
            dot={false}
            legendType={"plainline"}
            isAnimationActive={false}
            name={"Current"}
            strokeWidth={STROKE_WIDTH}
          />
        </LineChart>
      </ResponsiveContainer>
    </Wrapper>
  );
}
export default TrialCompletionBurndown;
