import { createSelector } from "@reduxjs/toolkit";
import {
  getLifestyleFilters,
  trialViewFiltersActions,
} from "nota-predict-web/src/common/store/trialViewFiltersSlice";
import { SmokingStatusFilters as SSF } from "nota-predict-web/src/common/types/LifestyleFilterTypes";
import { capitalize } from "nota-predict-web/src/common/utils/capitalize";
import React, { MouseEventHandler } from "react";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterButton from "../FilterButton";
import FilterSubtext from "../FilterSubtext";
import { ButtonWrapper } from "../FilterInputWrapper";
import { SmokingStatusType } from "../../../common/types/SmokingStatusType";

const getSmokingStatusFilters = createSelector(
  [getLifestyleFilters],
  (lifestyleFilters) => lifestyleFilters.smokingStatus
);

export const SmokingStatusFilters: FC = () => {
  const dispatch = useDispatch();
  const smokingStatusFilters = useSelector(getSmokingStatusFilters);

  const smokingOptions = Object.keys(smokingStatusFilters).map(
    (filter, index) => {
      const smokingStatus = filter as SmokingStatusType;

      const updateSmokingStatusFilters = (checked: boolean) => {
        dispatch(
          trialViewFiltersActions.toggleSmokingStatus(filter as keyof SSF)
        );
      };

      const isChecked = smokingStatusFilters[smokingStatus];

      return (
        <FilterButton
          key={index}
          text={capitalize(filter)}
          onClick={updateSmokingStatusFilters}
          active={isChecked}
          width={60}
        />
      );
    }
  );

  const options = (
    <>
      <ButtonWrapper>{smokingOptions}</ButtonWrapper>
    </>
  );

  return (
    <>
      <FilterSubtext text="Smoking Status" />
      {options}
    </>
  );
};
