import React, { FC } from "react";
import { Card } from "../../../common/components/cards/Card";
import { GraphCardProps } from "../../common/utils/GraphCardProps";
import MortalityBaselineChartContainer from "./MortalityBaselineChartContainer";

export const ChangeInMortalityVsBaselineCard: FC<GraphCardProps> = ({
  onClickHeader,
}: GraphCardProps) => {
  return (
    <Card
      title={"Change from Baseline"}
      content={<MortalityBaselineChartContainer />}
      onClickHeader={onClickHeader}
      downloadCSV={true}
    />
  );
};
