import {
  AnatomicalStructureTreeNode,
  AnatomicalStructureType,
} from "../../Analysis/common/types/AnatomicalStructureType";
import { UpdateModeType } from "../../common/types/UpdateModeType";

export type AnatomicalStructureOptionType = {
  label: string;
  value: AnatomicalStructureType;
  state?: UpdateModeType;
};

export function getAnatomicalStructureOption(
  anatomicalStructure: AnatomicalStructureType,
  state?: UpdateModeType
): AnatomicalStructureOptionType {
  const { name } = anatomicalStructure;
  return {
    label: name,
    value: anatomicalStructure,
    state,
  };
}

export function getAnatomicalStructureOptions(
  anatomicalStructures: AnatomicalStructureTreeNode[],
  state?: UpdateModeType
): AnatomicalStructureOptionType[] {
  const options: AnatomicalStructureOptionType[] = [];

  for (const anatomicalStructure of anatomicalStructures) {
    const { name, children } = anatomicalStructure;

    if (children.length === 0) {
      const option = getAnatomicalStructureOption(anatomicalStructure, state);
      options.push(option);
    } else {
      const other = getAnatomicalStructureOptions(children);
      options.push(...other);
    }
  }

  return options;
}
