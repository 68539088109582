import React, { FC, useRef } from "react";
import MediumFilledButton from "../../../common/components/buttons/MediumFilledButton";
import { InvertedFolderIcon } from "../../../common/components/icons/InvertedFolderIcon";
import { InvertedUploadIcon } from "../../../common/components/icons/InvertedUploadIcon";
import styled from "styled-components";

const ButtonRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 50px;
  padding: 26px;
`;

interface UploadButtonsProps {
  onUploadFiles: (files: File[]) => Promise<void>;
  disabled: boolean;
}

const UploadButtons: FC<UploadButtonsProps> = ({
  disabled,
  onUploadFiles,
}: UploadButtonsProps) => {
  const inputFile = useRef(null);
  const inputFolder = useRef(null);

  const handleClickUploadFolder = () => {
    // @ts-ignore
    inputFolder.current.click();
  };

  const handleClickUploadFiles = () => {
    // @ts-ignore
    inputFile.current.click();
  };

  const handleFilesSelected = async (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    // @ts-ignore
    const files = event.target.files;

    await onUploadFiles(files);

    // @ts-ignore
    event.target.value = null;
  };

  return (
    <ButtonRowWrapper>
      <input
        type="file"
        id="file"
        multiple
        ref={inputFile}
        style={{ display: "none" }}
        onChange={handleFilesSelected}
      />
      <input
        type="file"
        id="folder"
        // @ts-ignore
        directory=""
        webkitdirectory=""
        multiple
        ref={inputFolder}
        style={{ display: "none" }}
        onChange={handleFilesSelected}
      />
      <MediumFilledButton
        text={"Upload Folder"}
        icon={<InvertedFolderIcon />}
        onClick={handleClickUploadFolder}
        disabled={disabled}
      />
      <MediumFilledButton
        text={"Upload File(s)"}
        icon={<InvertedUploadIcon />}
        onClick={handleClickUploadFiles}
        disabled={disabled}
      />
    </ButtonRowWrapper>
  );
};

export default UploadButtons;
