import React, { FC } from "react";
import { useContext } from "react";
import Loader from "react-loader-spinner";
import styled, { ThemeContext } from "styled-components";

const LoadingBackground = styled.div`
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
`;

interface LoadingProps {
  timeout?: number;
  width?: number;
  height?: number;
}

const Loading: FC<LoadingProps> = ({
  timeout,
  width,
  height,
}: LoadingProps) => {
  const theme = useContext(ThemeContext);

  const actualTimeout = timeout === undefined ? 3000 : timeout;
  const actualWidth = width === undefined ? 100 : width;
  const actualHeight = height === undefined ? 100 : height;

  return (
    <LoadingBackground>
      <Loader
        type="Rings"
        color={theme.colors.neutral.black}
        height={actualWidth}
        width={actualHeight}
        timeout={actualTimeout} //3 secs
      />
    </LoadingBackground>
  );
};

export default Loading;
