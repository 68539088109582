import styled from "styled-components";
import { updateModeColors, UpdateModeType } from "../../types/UpdateModeType";
import { UpdateModeIcon } from "../../types/UpdateModeIcon";
import { main } from "../../theme/main";
import React, { ReactNode } from "react";

const Wrapper = styled.div<{ background: string }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 4px;
  background: ${(props) => props.background};
  border-radius: 4px;
  gap: 8px;
`;

interface AddRemoveLabelProps {
  state: UpdateModeType;
  children: ReactNode;
}

export function AddRemoveLabel({
  state,
  children,
}: AddRemoveLabelProps): JSX.Element {
  const color = updateModeColors[state];

  return (
    <Wrapper background={color}>
      <UpdateModeIcon
        mode={state}
        color={main.colors.neutral.white}
        size={14}
      />
      {children}
    </Wrapper>
  );
}
