const MAIN_PAGE_INDEX = 3;
const SUB_PAGE_INDEX = 4;

export const getActiveSubPage = () => {
  const splitPaths = window.location.pathname.split("/");
  let subPage = "";
  if (splitPaths.length > SUB_PAGE_INDEX) subPage = splitPaths[SUB_PAGE_INDEX];
  return subPage;
};

export const getActiveMainPage = () => {
  const splitPaths = window.location.pathname.split("/");
  return splitPaths[MAIN_PAGE_INDEX];
};
