import React, { FC, ReactElement } from "react";
import { TopBar } from "../common/components/TopBar/TopBar";
import { TitleTextWIcon } from "../common/components/TopBar/TitleTextWIcon";
import { DataManagementIcon } from "../common/components/icons/DataManagementIcon";

interface DataManagementTopBarProps {
  buttons?: ReactElement[];
  title?: string;
  subTitle?: string;
}

export const DataManagementTopBar: FC<DataManagementTopBarProps> = ({
  buttons,
  title = "Data",
  subTitle,
}: DataManagementTopBarProps) => {
  const displayTitle = [title, subTitle].filter((str) => str).join(" - ");

  return (
    <TopBar
      titleContent={
        <TitleTextWIcon icon={<DataManagementIcon />} title={displayTitle} />
      }
      buttons={buttons}
    />
  );
};
