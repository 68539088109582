import { XAxisProps } from "recharts";
import { ALT_TEXT_OPACITY, TEXT_OPACITY } from "./textOpacity";

export function getXAxisProps(label?: string): XAxisProps {
  return {
    height: 70,
    allowDuplicatedCategory: false,
    axisLine: {
      strokeWidth: "0.5px",
      opacity: TEXT_OPACITY,
    },
    tickLine: {
      strokeWidth: "0.5px",
      opacity: TEXT_OPACITY,
    },
    tick: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "11px",
      opacity: ALT_TEXT_OPACITY,
    },
    label: {
      value: label,
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "11px",
      lineHeight: "16px",
      opacity: TEXT_OPACITY,
    },
  };
}
