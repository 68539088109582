import { gql } from "@apollo/client";
import { TRIAL_DETAILS_FRAGMENT } from "./TrialDetailsFragment";

export const GET_TRIAL_DETAILS_BY_IDS = gql`
  query getRecentTrial($trialIds: [Int!]!) {
    trial(where: { id: { _in: $trialIds } }) {
      ...Trial
    }
  }
  ${TRIAL_DETAILS_FRAGMENT}
`;
