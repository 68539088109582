export function getNextId(ids: number[]): number {
  const n = ids.length;

  const set = new Set<number>();
  for (const id of ids) {
    if (id > 0) {
      set.add(id);
    }
  }

  for (let i = 1; i <= n + 1; i++) {
    if (!set.has(i)) {
      return i;
    }
  }

  throw new Error("No unique number could be found in getNextId");
}
