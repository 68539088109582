import React, {
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useSortBy,
  useRowSelect,
  Column,
  useGlobalFilter,
  Row,
  IdType,
  FilterProps,
  ColumnInstance,
} from "react-table";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { IndeterminateCheckbox } from "./IndeterminateCheckbox";
import { TableRowBase } from "./TableRowBase";
import { FilterPage } from "./FilterPage";
import { FilterChipBar } from "./FilterChipBar";
import { IconButton, TextField } from "@material-ui/core";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import SearchTextField from "../input/SearchTextField";
import { fuzzyTextFilter } from "./Filters/fuzzyFilter";
import { numericTextFilter } from "./Filters/numericFilter";

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;

  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.neutral.neutral3};
`;

const Wrapper = styled.div<{ minHeight: number }>`
  height: 100%;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  min-height: ${({ minHeight }) => minHeight}px;
`;

const TableWrapper = styled.div`
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const BodyWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const TableRow = styled.div<{ clickable?: boolean; isHeader: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
  width: 100%;

  background: ${({ theme, isHeader }) =>
    isHeader ? theme.colors.background.secondary : "unset"};

  height: 35px;
`;

const TableData = styled.div<{
  width?: number | string;
  isHeader: boolean;
  flex?: number;
}>`
  text-align: left;

  padding: 0.4rem;

  ${({ isHeader }) =>
    isHeader
      ? `:last-child {
    margin-right: 6px;
  }`
      : undefined}

  flex: ${({ flex }) => flex ?? 1};
  overflow: hidden;
`;

const FilterRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  min-height: 40px;
`;

const FilterItemWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
`;

const filterTypes = {
  fuzzyText: fuzzyTextFilter,
  numeric: numericTextFilter,
};

const defaultColumn = {
  Filter: DefaultColumnFilter,
  width: undefined, // width is used for both the flex-basis and flex-grow
  showFilter: false,
};

export interface TableOptions<D extends TableRowBase> {
  onClickRow?: (row: D) => void;
  onRowsSelected?: (rows: D[]) => void;
  enableSelect: boolean;
  minTableHeight?: number;
  showFilterBar?: boolean;
  filterFn?: (row: D, query: string) => boolean;
}

export interface TableProps<D extends TableRowBase> extends TableOptions<D> {
  columns: Column<D>[];
  data: D[];
  forwardedRef?: Ref<TableControlsType>;
}

export type TableControlsType = {
  clearSelectedRows: () => void;
  clearGlobalFilter: () => void;
};

export function Table<D extends TableRowBase>({
  columns,
  data,
  onClickRow,
  onRowsSelected,
  enableSelect,
  minTableHeight = 450,
  showFilterBar = true,
  filterFn = () => true,
  forwardedRef,
}: TableProps<D>): JSX.Element {
  const globalFilterFunction = useCallback(
    // This is Typescript if you're using JS remove the types (e.g. :string)
    (rows: Row<D>[], ids: IdType<D>[], query: string) => {
      return rows.filter((row) => {
        if (row.isSelected) {
          return true;
        }
        return filterFn(row.original, query);
      });
    },
    [filterFn]
  );

  const table = useTable(
    {
      columns,
      data,
      filterTypes,
      defaultColumn,
      autoResetSelectedRows: false,
      globalFilter: globalFilterFunction,
      autoResetGlobalFilter: false,
      initialState: { globalFilter: "" },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      if (!enableSelect) {
        return;
      }

      // @ts-ignore
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "_selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          // eslint-disable-next-line react/prop-types,react/display-name
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          //@ts-ignore
          // eslint-disable-next-line react/prop-types,react/display-name
          Cell: ({ row }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
          flex: 0.2,
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { selectedRowIds },
    setGlobalFilter,
  } = table;

  useEffect(() => {
    onRowsSelected?.(selectedFlatRows.map((row) => row.original));
  }, [selectedRowIds, onRowsSelected]);

  const handleClickRow = (row: D) => {
    if (!onClickRow) {
      return;
    }

    onClickRow(row);
  };

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <TableRow
          isHeader={false}
          {...row.getRowProps({
            style,
          })}
          clickable={onClickRow !== undefined}
          onClick={() => handleClickRow(row.original)}
        >
          {row.cells.map((cell) => {
            const { width, flex } = cell.column;
            return (
              <TableData
                {...cell.getCellProps()}
                flex={flex}
                isHeader={false}
                width={width}
              >
                {cell.render("Cell")}
              </TableData>
            );
          })}
        </TableRow>
      );
    },
    [prepareRow, rows, selectedRowIds]
  );

  const [anchorEl, setAnchorEl] = React.useState<Element | undefined>(
    undefined
  );

  const [globalFilterQuery, setGlobalFilterQuery] = useState<string>("");

  useImperativeHandle(
    forwardedRef,
    () => ({
      clearSelectedRows: () => toggleAllRowsSelected(false),
      clearGlobalFilter: () => setGlobalFilterQuery(""),
    }),
    [forwardedRef]
  );

  useEffect(() => {
    setGlobalFilter(globalFilterQuery); // Set the Global Filter to the filter prop.
  }, [globalFilterQuery, setGlobalFilter]);

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setGlobalFilterQuery(value);
  };

  const handleFilterClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const filterOpen = Boolean(anchorEl);
  const showFilterPage = false;

  return (
    <OuterWrapper>
      {showFilterBar && (
        <FilterRow>
          <SearchTextField
            onChange={handleFilterChange}
            value={globalFilterQuery}
          />
          {showFilterPage && (
            <FilterItemWrapper>
              <IconButton size={"small"} onClick={handleFilterClick}>
                <FilterListRoundedIcon />
              </IconButton>
              <FilterPage
                instance={table}
                onClose={handleClose}
                show={filterOpen}
                anchorEl={anchorEl}
              />
              <FilterChipBar instance={table} />
            </FilterItemWrapper>
          )}
        </FilterRow>
      )}
      <Wrapper minHeight={minTableHeight}>
        <TableWrapper {...getTableProps()}>
          {headerGroups.map((headerGroup) => {
            return (
              <TableRow isHeader={true} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const { flex, width } = column;
                  return (
                    <TableData
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      flex={flex}
                      isHeader={true}
                      width={width}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "  ⬇"
                            : "  ⬆"
                          : ""}
                      </span>
                    </TableData>
                  );
                })}
              </TableRow>
            );
          })}
          <BodyWrapper {...getTableBodyProps()}>
            <AutoSizer>
              {({ height, width }) => {
                return (
                  <FixedSizeList
                    height={height}
                    width={width}
                    itemCount={rows.length}
                    itemSize={35}
                  >
                    {RenderRow}
                  </FixedSizeList>
                );
              }}
            </AutoSizer>
          </BodyWrapper>
        </TableWrapper>
      </Wrapper>
    </OuterWrapper>
  );
}

const findFirstColumn = <T extends Record<string, unknown>>(
  columns: Array<ColumnInstance<T>>
): ColumnInstance<T> =>
  columns[0].columns ? findFirstColumn(columns[0].columns) : columns[0];

function DefaultColumnFilter<T extends Record<string, unknown>>({
  columns,
  column,
}: FilterProps<T>) {
  const { id, filterValue, setFilter, render } = column;
  const [value, setValue] = React.useState(filterValue || "");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  // ensure that reset loads the new value
  useEffect(() => {
    setValue(filterValue || "");
  }, [filterValue]);

  const isFirstColumn = findFirstColumn(columns) === column;
  return (
    <TextField
      name={id}
      label={render("Header")}
      InputLabelProps={{ htmlFor: id }}
      value={value}
      autoFocus={isFirstColumn}
      variant="standard"
      onChange={handleChange}
      onBlur={(e) => {
        setFilter(e.target.value || undefined);
      }}
    />
  );
}
