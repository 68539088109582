import React, { PropsWithChildren, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { ClickableDiv } from "../../common/components/divs/ClickableDiv";
import { getTrialBaseRoute } from "../../common/utils/getTrialRoute";
import styled from "styled-components";

interface ClickableTrialWrapperProps {
  trialId: number;
}

const Wrapper = styled(ClickableDiv)`
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #dadee3;
  overflow: hidden;
  cursor: pointer;
`;

export const ClickableTrialWrapper = ({
  trialId,
  children,
}: PropsWithChildren<ClickableTrialWrapperProps>): ReactElement => {
  const history = useHistory();
  const onClickRecentTrial = () => {
    const trialRoute = getTrialBaseRoute(trialId);
    history.push(trialRoute);
  };
  return <Wrapper onClick={onClickRecentTrial}>{children}</Wrapper>;
};
