import { FollowUpType } from "../types/FollowUpType";
import { TumourResponseType } from "../types/TumourResponseType";

export function getFollowUpTumourResponse(
  followUp: FollowUpType
): TumourResponseType | null {
  const { tumourResponses } = followUp;
  const firstTumourResponse =
    tumourResponses.length > 0 ? tumourResponses[0] : null;
  if (!firstTumourResponse) {
    return null;
  }
  return firstTumourResponse;
}
