import { AnatomicalStructureLabel } from "./AnatomicalStructureLabel";
import React from "react";
import styled from "styled-components";
import { AnatomicalStructureOptionType } from "./AnatomicalStructureOptionType";
import { CountIndicator } from "../../common/components/TrialManagement/Dialogs/CountIndicator";
import {
  ADD,
  REMOVE,
  updateModeColors,
  UpdateModeType,
} from "../../common/types/UpdateModeType";
import { main } from "../../common/theme/main";
import { Popover } from "@material-ui/core";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  gap: 6px;
  padding: 12px;
  font-size: 14px;
  font-weight: 600;
`;

interface AnatomicalStructuresListProps {
  anatomicalStructures: AnatomicalStructureOptionType[];
}

export function AnatomicalStructuresList({
  anatomicalStructures,
}: AnatomicalStructuresListProps): JSX.Element {
  if (anatomicalStructures.length === 0) {
    return <>N/A</>;
  }

  const sorted = [
    ...anatomicalStructures,
  ].sort(({ value: { name: nameA } }, { value: { name: nameB } }) =>
    nameA.localeCompare(nameB)
  );

  const existing = sorted.filter(({ state }) => !state);
  const added = sorted.filter(({ state }) => state === ADD);
  const removed = sorted.filter(({ state }) => state === REMOVE);

  return (
    <Wrapper>
      <AnatomicalStructuresSubList options={existing} maxItems={1} />
      <AnatomicalStructuresSubList options={added} maxItems={0} state={ADD} />
      <AnatomicalStructuresSubList
        options={removed}
        maxItems={0}
        state={REMOVE}
      />
    </Wrapper>
  );
}

interface AnatomicalStructuresSubListProps {
  options: AnatomicalStructureOptionType[];
  maxItems: number;
  state?: UpdateModeType;
}

function AnatomicalStructuresSubList({
  options,
  maxItems,
  state,
}: AnatomicalStructuresSubListProps): JSX.Element {
  const color = state ? updateModeColors[state] : main.colors.neutral.neutral3;
  const [anchorEl, setAnchorEl] = React.useState<Element | undefined>(
    undefined
  );

  const overflowCount = options.length - maxItems;

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleClickMore = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  function formatName(name: string) {
    return (
      <Wrapper>
        {name}
        {overflowCount > 0 && (
          <CountIndicator
            size={10}
            onClick={handleClickMore}
            count={overflowCount}
            background={color}
            formatCount={formatCount}
          />
        )}
      </Wrapper>
    );
  }

  function formatCount(count: number) {
    const operator = state ? (state === REMOVE ? "-" : "+") : "+";
    return `${operator}${count}`;
  }

  const popoverOpen = Boolean(anchorEl);

  const labels = options
    .slice(0, maxItems)
    .map(({ value: anatomicalStructure, state }, index) => (
      <AnatomicalStructureLabel
        key={index}
        anatomicalStructure={anatomicalStructure}
        state={state}
        formatName={formatName}
      />
    ));

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        onClose={handleClose}
        open={popoverOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ListWrapper>
          Labels
          {options.map(({ value, state }, index) => (
            <AnatomicalStructureLabel
              key={index}
              anatomicalStructure={value}
              state={state}
            />
          ))}
        </ListWrapper>
      </Popover>
      {labels}
      {overflowCount > 0 && maxItems === 0 && (
        <CountIndicator
          onClick={handleClickMore}
          count={overflowCount}
          background={color}
          formatCount={formatCount}
        />
      )}
    </>
  );
}
