import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { NOT_ESTIMATABLE } from "../../common/types/TumourResponseType";
import { SubjectResponseProgressionType } from "../types/SubjectResponseProgressionType";
import { SubjectType } from "../../common/types/SubjectType";
import { getMostRecentFollowUp } from "../../common/utils/getMostRecentFollowUp";
import { getDeltaDateTimeInDays } from "../../../common/utils/dateFormatUtils/getDeltaDateTimeInDays";
import { FollowUpResponseType } from "../types/FollowUpResponseType";
import { getBaselineFollowUp } from "../../common/utils/getBaselineFollowUp";

dayjs.extend(relativeTime);

export interface ParsedPatientDataProps {
  subject_id: string;
  [key: number]: any;
  total_days: number;
}

export function getPatientJourneySubjects(
  subjects: SubjectType[]
): SubjectResponseProgressionType[] {
  const subjectResponseProgressions: SubjectResponseProgressionType[] = [];

  for (const subject of subjects) {
    const { id, subjectId, followUps, randomizationDate } = subject;

    const baselineFollowUp = getBaselineFollowUp(followUps);
    const mostRecentFollowUp = getMostRecentFollowUp(followUps);
    if (!mostRecentFollowUp || !baselineFollowUp) {
      continue;
    }

    const {
      survivalPredictions,
      tumourResponses,
      order: lastFollowUp,
      date: mostRecentFollowUpDate,
    } = mostRecentFollowUp;

    const { date: baselineFollowUpDate } = baselineFollowUp;
    if (!baselineFollowUpDate || !mostRecentFollowUpDate) {
      continue;
    }

    const daysSinceRandomization = randomizationDate
      ? getDeltaDateTimeInDays(mostRecentFollowUpDate, randomizationDate)
      : null;

    const daysSinceBaseline = getDeltaDateTimeInDays(
      mostRecentFollowUpDate,
      baselineFollowUpDate
    );

    const lastResponseClassification =
      tumourResponses.length > 0 ? tumourResponses[0] : null;

    const lastSurvivalPrediction =
      survivalPredictions.length > 0 ? survivalPredictions[0] : null;

    const orderedFollowUps = [...followUps].sort((a, b) => a.order - b.order);
    const followUpResponses: FollowUpResponseType = {};
    for (let i = 0; i < orderedFollowUps.length; i++) {
      const followUp = orderedFollowUps[i];
      const previousFollowUp = orderedFollowUps[i - 1];
      const previousFollowUpDate = previousFollowUp?.date;

      const {
        order,
        date,
        tumourResponses: followUpTumourResponses,
      } = followUp;

      if (!date) {
        continue;
      }

      const elapsedDays =
        previousFollowUpDate != null
          ? getDeltaDateTimeInDays(previousFollowUpDate, date)
          : 0;

      const firstResponseClassification =
        followUpTumourResponses.length > 0
          ? followUpTumourResponses[0]
          : NOT_ESTIMATABLE;

      followUpResponses[order] = {
        response: firstResponseClassification,
        elapsedDays,
      };
    }

    subjectResponseProgressions.push({
      id,
      subjectId,
      daysSinceRandomization,
      daysSinceBaseline,
      lastFollowUp,
      lastResponseClassification,
      lastSurvivalPrediction,
      followUpResponses,
    });
  }

  subjectResponseProgressions.sort(
    (a, b) => a.daysSinceBaseline - b.daysSinceBaseline
  );

  return subjectResponseProgressions;
}
