import { gql } from "@apollo/client/core";
import { UserType } from "../common/types/UserType";
import {
  parseUserFragment,
  USER_FRAGMENT,
  UserFragmentType,
} from "./UserFragment";
import { useParsedQueryReturnType } from "../common/queries/utils/useParsedQueryReturnType";
import { useParsedQuery } from "../common/queries/utils/useParsedQuery";

const QUERY = gql`
  query GetTrailUsers($trial_id: Int!) {
    users(
      where: {
        user_groups: {
          group: { trial_groups: { trial_id: { _eq: $trial_id } } }
        }
      }
      distinct_on: auth0_user_id
    ) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

type Variables = {
  trial_id: number;
};

type Data = {
  users: UserFragmentType[];
};

export function useTrialUsers(
  trialId: number
): useParsedQueryReturnType<number, UserType[], Variables> {
  return useParsedQuery<number, UserType[], Data, Variables>({
    query: QUERY,
    input: trialId,
    parseVariables,
    parseData,
  });
}

function parseVariables(trialId: number): Variables {
  return { trial_id: trialId };
}

function parseData(data: Data): UserType[] {
  return data.users.map(parseUserFragment);
}
