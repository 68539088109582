import { ApolloError } from "@apollo/client";
import { TrialType } from "../types/TrialType";
import { RawTrialDetailsType } from "../types/RawTrialDetailsType";
import { GET_ALL_TRIALS_DETAILS } from "./GetAllTrialsDetails";
import { parseTrialDetails } from "../../Dashboard/utils/parseTrialDetails";
import { useTrialId } from "../../Trial/hooks/useTrialId";
import { useParsedQuery } from "./utils/useParsedQuery";
import { useParsedQueryReturnType } from "./utils/useParsedQueryReturnType";

type Data = RawTrialDetailsType;
type Variables = undefined;
type Output = TrialType | undefined;

export function useCurrentTrial(): useParsedQueryReturnType<
  number,
  Output,
  Variables
> {
  const trialId = useTrialId();

  return useParsedQuery<number, Output, Data, Variables>({
    query: GET_ALL_TRIALS_DETAILS,
    input: trialId,
    parseVariables: () => undefined,
    parseData,
  });
}

function parseData(data: Data, trialId: number): Output {
  return parseTrialDetails(data).find((trial) => trial.id === trialId);
}
