import React from "react";
import { FC } from "react";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";
import styled from "styled-components";

const Styled = styled(FilterListRoundedIcon)`
  color: ${(props) => props.theme.colors.primary.inverted};
`;

export const FilterIcon: FC = () => <Styled />;
