import React from "react";
import { useLocation } from "react-router";

interface PageTitleWrapperProps {
  children: React.ReactElement;
}

function PageTitleWrapper({ children }: PageTitleWrapperProps) {
  const { pathname } = useLocation();
  let title = "Nota - Dashboard";
  if (pathname !== "/" && pathname.length > 1) {
    const pathnameSplit = pathname.split("/");
    if (pathnameSplit.length >= 3) {
      const location =
        pathnameSplit[2].charAt(0).toUpperCase() + pathnameSplit[2].slice(1);
      title = `Nota - ${location}`;
    } else {
      title = `Nota`;
    }
  } else if (pathname.length < 1) {
    title = "Nota";
  }

  document.title = title;

  return children;
}

export default PageTitleWrapper;
