import React, { FC } from "react";
import clearTrialId from "../common/store/trialIdSlice";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import SearchTextField from "../common/components/input/SearchTextField";
import Button from "../common/components/buttons/Button";
import HelloText from "./components/HelloText";
import { NotificationIcon } from "../common/components/icons/NotificationIcon";
import { SettingsIcon } from "../common/components/icons/SettingsIcon";
import RecentTrialsContainer from "./components/RecentTrialsContainer";
import AllTrialsContainer from "./components/AllTrialsContainer";
import { NotaLogo } from "../common/components/NotaLogo";
import { TasksIcon } from "../common/components/icons/TasksIcon";
import { useCreateTrialDialog } from "../common/components/TrialManagement/Dialogs/useCreateTrialDialog";
import { CurrentUserAvatar } from "../common/components/Avatar/CurrentUserAvatar";
import { CurrentUserCan } from "../common/components/CurrentUserCan";
import { MANAGER } from "../common/types/UserRoleType";

const Wrapper = styled.div`
  overflow: hidden;
  background: ${(props) => props.theme.colors.background.main};
  position: relative;
`;

const PanelWrapper = styled.div`
  position: relative;
  padding: 64px 320px 24px 320px;
  @media (min-width: 300px) {
    padding: 64px 50px 24px 50px;
  }
  @media (min-width: 1000px) {
    padding: 64px 200px 24px 200px;
  }
  @media (min-width: 1300px) {
    padding: 64px 320px 24px 320px;
  }
  overflow-y: auto;
`;

const ActionPanelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 48px;
  margin-bottom: 24px;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  background: ${(props) => props.theme.colors.actionPrimary.inverted};
`;

const StyledButton = styled(Button)`
  width: 207px;
  border-radius: 8px;
  background: #ff965b;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: white;
  cursor: pointer;
`;

const HeaderWrapper = styled.div`
  margin: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
`;

const CardHeaderText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 24px;
`;

const CardContentWrapper = styled.div`
  margin-bottom: 40px;
`;

const EllipseTop = styled.div`
  position: absolute;
  width: 100%;
  height: 500px;
  background: ${(prop) => prop.theme.colors.neutral.black};
`;

const Ellipse = styled.div`
  position: absolute;
  left: -15%;
  width: 130%;
  top: 350px;
  height: 300px;
  border-radius: 50%;
  background: ${(prop) => prop.theme.colors.neutral.black};
`;

const StyledSearchField = styled(SearchTextField)`
  background-size: 24px;
`;

const Dashboard: FC = () => {
  const dispatch = useDispatch();
  dispatch(clearTrialId);

  const [
    setShowCreateTrialDialog,
    { dialog: createTrialDialog },
  ] = useCreateTrialDialog();

  const handleCreateTrial = () => {
    setShowCreateTrialDialog(true);
  };

  return (
    <>
      {createTrialDialog}
      <Wrapper>
        <EllipseTop />
        <Ellipse />
        <PanelWrapper>
          <NotaLogo />
          <HeaderWrapper>
            <HelloText />
            <ButtonsWrapper>
              <NotificationIcon />
              <TasksIcon />
              <SettingsIcon />
              <CurrentUserAvatar showProfileOnClick={true} />
            </ButtonsWrapper>
          </HeaderWrapper>
          <ActionPanelWrapper>
            <StyledSearchField size={40} />
            <CurrentUserCan
              allowedRoles={[MANAGER]}
              yes={
                <StyledButton
                  content={"Create a new trial"}
                  onClick={handleCreateTrial}
                />
              }
            />
          </ActionPanelWrapper>
          <CardWrapper>
            <CardHeaderText>{"Recent Trials"}</CardHeaderText>
            <RecentTrialsContainer />
            <CardContentWrapper />
            <CardHeaderText>{"All Trials"}</CardHeaderText>
            <AllTrialsContainer />
          </CardWrapper>
        </PanelWrapper>
      </Wrapper>
    </>
  );
};

export default Dashboard;
