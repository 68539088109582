import React from "react";
import { formatFollowUpShort } from "../utils/formatFollowUpShort";
import styled from "styled-components";
import { formatMonthsRounded } from "../utils/formatMonthsRounded";
import { getFollowUpInMonths } from "../utils/getFollowUpInMonths";
import { useSelector } from "react-redux";
import { selectTimepointsFilters } from "../../ToolPanel/components/TimepointsFilters";
import FilterButton from "../../ToolPanel/components/FilterButton";
import { TEXT_OPACITY } from "../utils/textOpacity";

const StyledLabelContentsWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  opacity: ${() => TEXT_OPACITY};
`;

const StyledLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  color: ${(props) => props.theme.colors.neutral.black};
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
`;

const ButtonWrapper = styled.div<{ withTopMargin: boolean; disabled: boolean }>`
  margin-top: ${(props) => (props.withTopMargin ? "20px" : "4px")};
  ${(props) => (props.disabled ? "div { cursor: default; }" : "")}
`;

interface FollowUpAxisTickProps {
  x?: any;
  y?: any;
  payload?: any;
  useDisabledFollowUps: boolean;
  onClickFollowUp?: (followUp: number) => void;
}

export const FollowUpAxisTick = ({
  x,
  y,
  payload,
  useDisabledFollowUps,
  onClickFollowUp,
}: FollowUpAxisTickProps): React.ReactElement => {
  const timepointsFilters = useSelector(selectTimepointsFilters);

  const width = 176;
  const height = 55;

  const { value: followUp } = payload;
  const showLabel = followUp % 2 == 0;
  const disabled = useDisabledFollowUps && !timepointsFilters[followUp];

  const handleFollowUpClick = () => {
    if (!onClickFollowUp) {
      return;
    }

    onClickFollowUp(followUp);
  };

  const tickLabel = formatMonthsRounded(getFollowUpInMonths(followUp));

  return (
    <svg x={x - 50.52 / 2} y={y} width={width} height={height} fill="#666">
      <foreignObject width={width} height={height}>
        <StyledLabelWrapper // @ts-ignore
          xmlns="http://www.w3.org/1999/xhtml"
        >
          {showLabel && (
            <StyledLabelContentsWrapper>{tickLabel}</StyledLabelContentsWrapper>
          )}
          <ButtonWrapper withTopMargin={!showLabel} disabled={disabled}>
            <FilterButton
              onClick={handleFollowUpClick}
              width={32}
              active={!disabled}
              text={formatFollowUpShort(followUp)}
            />
          </ButtonWrapper>
        </StyledLabelWrapper>
      </foreignObject>
    </svg>
  );
};
