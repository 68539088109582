import React, { FC } from "react";
import styled from "styled-components";
import { SubjectImage } from "./SubjectImage";
import { ProgressionChip } from "./ProgressionChip";
import { FollowUpType } from "../../common/types/FollowUpType";
import { getSubjectImageFromFollowUp } from "../utils/getSubjectImageFromFollowUp";
import { MISSING } from "../../common/types/TumourResponseType";
import { formatFollowUp } from "../../common/utils/formatFollowUp";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.actionPrimary.inverted};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  margin: 0 4px;
`;

const ResponseWrapper = styled.div`
  position: absolute;
  top: 40px;
  left: 4px;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 6px 0;
  flex: 1;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.neutral.black};
`;

interface SubjectImagePanelProps {
  followUp: FollowUpType;
  maxImageWidth: number;
  maxImageHeight: number;
  caseLink?: string;
}

export const SubjectImagePanel: FC<SubjectImagePanelProps> = ({
  followUp,
  maxImageWidth,
  maxImageHeight,
  caseLink,
}: SubjectImagePanelProps) => {
  const image = getSubjectImageFromFollowUp(followUp);
  const { imageUrl, followUpOrder, response } = image;

  const label = formatFollowUp(followUpOrder);

  const handleClick = () => {
    if (!caseLink) {
      return;
    }
    const win = window.open(caseLink, "_blank");
    win?.focus();
  };

  return (
    <Wrapper onClick={handleClick}>
      <ResponseWrapper>
        {response !== MISSING && <ProgressionChip response={response} />}
      </ResponseWrapper>
      <SubjectImage
        source={imageUrl}
        maxWidth={maxImageWidth}
        maxHeight={maxImageHeight}
        alt={"ct-image"}
      />
      <LabelWrapper>{label}</LabelWrapper>
    </Wrapper>
  );
};
