import React, { ReactElement } from "react";
import RecentTrial from "./RecentTrial";
import styled from "styled-components";
import { TrialType } from "../../common/types/TrialType";

const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  padding-bottom: 10px;
  overflow-x: scroll;
`;

interface RecentTrialsProps {
  recentTrials: TrialType[];
}

const RecentTrials = ({ recentTrials }: RecentTrialsProps): ReactElement => {
  return (
    <Wrapper>
      {recentTrials
        .slice(0, 3)
        .map(
          ({
            id,
            name,
            status,
            estimatedCompletion,
            indicationName,
            investigationName,
            sites,
            groupIds,
          }) => {
            return (
              <RecentTrial
                id={id}
                key={id}
                name={name}
                status={status}
                estimatedCompletion={estimatedCompletion}
                indicationName={indicationName}
                investigationName={investigationName}
                sites={sites}
                groupIds={groupIds}
              />
            );
          }
        )}
    </Wrapper>
  );
};

export default RecentTrials;
