import React from "react";
import { Column } from "react-table";
import { Table, TableOptions } from "../../../common/components/Table/Table";
import {
  filterWorklistRow,
  WorklistTableRowType,
} from "./WorklistTableRowType";
import { worklistTableColumns } from "./WorklistTableColumns";

interface WorklistTableProps extends TableOptions<WorklistTableRowType> {
  rows: WorklistTableRowType[];
}

export function WorklistTable({
  rows,
  ...tableProps
}: WorklistTableProps): React.ReactElement {
  const memoizedColumns = React.useMemo<Column<WorklistTableRowType>[]>(
    () => worklistTableColumns,
    []
  );
  const memoizedRows = React.useMemo<WorklistTableRowType[]>(() => rows, [
    rows,
  ]);

  return (
    <Table
      {...tableProps}
      columns={memoizedColumns}
      data={memoizedRows}
      filterFn={filterWorklistRow}
    />
  );
}
