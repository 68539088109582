import React from "react";
import { IconProps } from "./IconProps";
import styled from "styled-components";
import { getPixelsFromSize } from "./utils/getPixelsFromSize";
import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from "./IconDefaults";

const SvgIconWrapper = styled.div<{ size: string; color: string }>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};

  path {
    fill: ${(props) => props.color || props.theme.colors.neutral.black};
  }
`;

interface SvgIconProps extends IconProps {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export function SvgIcon({
  size = DEFAULT_ICON_SIZE,
  color = DEFAULT_ICON_COLOR,
  icon: Icon,
}: SvgIconProps): JSX.Element {
  const pixelSize = getPixelsFromSize(size);
  return (
    <SvgIconWrapper size={pixelSize} color={color}>
      <Icon />
    </SvgIconWrapper>
  );
}
