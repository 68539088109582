import { RawStudyType } from "../../rawTypes/RawStudyType";
import { StudyType } from "../../types/StudyType";
import { parseSeriesFromQueryResult } from "./parseSeriesFromQueryResult";
import { parseStudyJob } from "./parseStudyJob";

export function parseStudyFromQueryResult(rawStudy: RawStudyType): StudyType {
  const {
    series: rawSeries,
    study_id: studyId,
    study_dicom_id: studyDicomId,
    study_date: rawStudyDate,
    survival_prediction_jobs: rawSurvivalPredictionJobs,
    upload_jobs: rawUploadJobs,
  } = rawStudy;

  const series = rawSeries.map(parseSeriesFromQueryResult);
  // TODO: here we assume there is only one series per study so we can flatMap it
  const tumourBurdens = series.flatMap((s) => {
    return s.tumourBurdens;
  });
  const tumourResponses = series.flatMap((s) => {
    return s.tumourResponses;
  });
  const survivalPredictions = series.flatMap((s) => {
    return s.survivalPredictions;
  });

  const studyDate = rawStudyDate ? new Date(rawStudyDate) : null;

  const survivalPredictionJobs = rawSurvivalPredictionJobs.map(parseStudyJob);
  const uploadJobs = rawUploadJobs.map(parseStudyJob);

  return {
    tumourBurdens,
    tumourResponses,
    survivalPredictions,
    series,
    studyId,
    studyDicomId,
    studyDate,
    survivalPredictionJobs,
    uploadJobs,
  };
}
