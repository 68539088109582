import { main } from "../../common/theme/main";

export const PENDING = "PENDING";
export const INPROGRESS = "INPROGRESS";
export const COMPLETED = "COMPLETED";

export type CaseProgressType =
  | typeof PENDING
  | typeof INPROGRESS
  | typeof COMPLETED;

const {
  states: { success, warning, info },
} = main.colors;

export const caseProgressColors: Record<CaseProgressType, string> = {
  [COMPLETED]: success,
  [INPROGRESS]: warning,
  [PENDING]: info,
};

export const caseProgressDisplayNames: Record<CaseProgressType, string> = {
  [COMPLETED]: "Completed",
  [INPROGRESS]: "In Progress",
  [PENDING]: "New",
};
