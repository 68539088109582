import React from "react";
import { FC } from "react";
import BarChartIcon from "@material-ui/icons/BarChart";
import styled from "styled-components";

const Styled = styled(BarChartIcon)`
  color: ${(props) => props.theme.colors.primary.inverted};
`;

export const AnalysisIcon: FC = () => <Styled />;
