export const main = {
  colors: {
    actionPrimary: {
      default: "#1B1F20",
      hover: "#393E3F",
      active: "#000000",
      disabled: "rgba(0, 0, 0, 0.5)",
      hover10: "rgba(57, 62, 63, 0.1)",
      active20: "rgba(0, 0, 0, 0.2)",
      inverted: "#FFFFFF",
    },
    actionSecondary: {
      default: "rgba(25, 171, 79, 1)",
    },
    primary: {
      primary: "#FFFFFF",
      primary90: "rgba(27, 31, 32, 0.9)",
      primary80: "rgba(27, 31, 32, 0.8)",
      primary70: "rgba(27, 31, 32, 0.7)",
      primary60: "rgba(27, 31, 32, 0.6)",
      primary50: "rgba(27, 31, 32, 0.5)",
      primary40: "rgba(27, 31, 32, 0.4)",
      primary30: "rgba(27, 31, 32, 0.3)",
      primary20: "rgba(27, 31, 32, 0.2)",
      primary10: "rgba(0, 0, 0, 0.22)",
    },
    secondary: {
      secondary: "#FF965B",
      secondary90: "rgba(255, 150, 91, 0.9)",
      secondary80: "rgba(255, 150, 91, 0.8)",
      secondary70: "rgba(255, 150, 91, 0.7)",
      secondary60: "rgba(255, 150, 91, 0.6)",
      secondary50: "rgba(255, 150, 91, 0.5)",
      secondary40: "rgba(255, 150, 91, 0.4)",
      secondary30: "rgba(255, 150, 91, 0.3)",
      secondary20: "rgba(255, 150, 91, 0.2)",
      secondary10: "rgba(255, 150, 91, 0.1)",
    },
    neutral: {
      black: "#1B1F20",
      white: "#FFFFFF",
      white90: "rgba(255, 255, 255, 0.9)",
      white80: "rgba(255, 255, 255, 0.8)",
      white70: "rgba(255, 255, 255, 0.7)",
      white60: "rgba(255, 255, 255, 0.6)",
      white50: "rgba(255, 255, 255, 0.5)",
      white40: "rgba(255, 255, 255, 0.4)",
      white30: "rgba(255, 255, 255, 0.3)",
      white20: "rgba(255, 255, 255, 0.2)",
      white10: "rgba(255, 255, 255, 0.1)",
      neutral1: "#FFFFFF",
      neutral2: "#394452",
      neutral3: "#545D69",
      neutral4: "#6D7580",
      neutral5: "#858C94",
      neutral6: "#A5ABB3",
      neutral7: "#DADEE3",
      neutral8: "#EBEEF2",
      neutral9: "#FFFFFF",
    },
    background: {
      main: "#F0F0F0",
      secondary: "#f8f8f8",
    },
    accent: {
      accent1: "#A06DFA",
      accent1Light: "#cbadff",
      accent2: "#2CCDCA",
      accent2Light: "#64f5f2",
      accent3: "#6613F6",
      accent4: "#209795",
      accent4Light: "rgba(32, 151, 149, 0.5)",
      accent5: "#CCB0FC",
      accent6: "#ABEDEC",
      altAccent1: "rgba(236, 178, 242, 0.28)",
      altAccent2: "#FFFFFF",
      altAccent3: "rgba(102, 19, 246, 0.23)",
      altAccent4: "rgba(32, 151, 149, 0.1)",
      altAccent5: "rgba(124, 198, 214, 0.15)",
      altAccent6: "#FFFFFF",
    },
    states: {
      success: "#11C182",
      warning: "#FBCC26",
      error: "#F64141",
      info: "#2E5AAC",
      successBg: "#E8F9F3",
      warningBg: "#FFFAEA",
      errorBg: "#FEECEC",
      infoBg: "#EEF2FA",
    },
  },
};
