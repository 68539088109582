import { gql } from "@apollo/client/core";
import { USER_FRAGMENT } from "../../Settings/UserFragment";
import { CASE_ASSIGNMENT_FRAGMENT } from "./CaseAssignmentFragment";

export const CASE_ASSIGNMENT_WITH_USER_FRAGMENT = gql`
  fragment CaseAssignmentWithUser on case_assignment {
    ...CaseAssignment
    user {
      ...UserFragment
    }
  }
  ${CASE_ASSIGNMENT_FRAGMENT}
  ${USER_FRAGMENT}
`;
