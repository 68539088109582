import React, { useContext } from "react";
import { useDialogReturnType } from "../../Dialog/useDialog";
import { UserType } from "../../../types/UserType";
import { UserActionCallbacksType } from "../UserActionCallbacksType";
import { UserManagementContext } from "../UserManagementContext";
import { useFormDialog } from "../../Dialog/useFormDialog";
import { EditUserForm } from "./EditUserForm";

export function useEditUserDialog(
  user: UserType,
  actionCallbacks?: UserActionCallbacksType
): useDialogReturnType {
  const {
    rules: { allowEditRole, allowRemoveUser, allowResetPassword },
  } = useContext(UserManagementContext);

  return useFormDialog({
    children: EditUserForm,
    label: "Edit Team Member",
    showCloseButton: true,
    props: {
      user,
      actionCallbacks,
      allowEditRole,
      allowRemoveUser,
      allowResetPassword,
    },
  });
}
