import { gql } from "@apollo/client/core";
import { ANATOMICAL_STRUCTURES_FRAGMENT } from "../components/AnatomicalStructuresFragment";

export const LESION_FRAGMENT = gql`
  fragment Lesion on lesion {
    anatomical_structure {
      ...AnatomicalStructure
    }
    name
    rois {
      roi_attribute {
        measurable
        tumourClassification: tumour_classification
      }
      contours {
        version
        tool_data
      }
      ellipse {
        data
      }
    }
  }
  ${ANATOMICAL_STRUCTURES_FRAGMENT}
`;
