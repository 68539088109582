import React, { FC, ReactElement } from "react";
import { DialogWrapper } from "./DialogWrapper";
import { FormProps } from "./FormProps";
import { BaseDialogProps } from "./BaseDialogProps";
import { DialogHeader, DialogHeaderProps } from "./DialogHeader";
import { ContentsWrapper, Wrapper } from "./DialogContents";

interface Props<T> extends FormDialogProps<T>, BaseDialogProps {}

export interface FormDialogProps<T> extends Omit<DialogHeaderProps, "onClose"> {
  children: FC<FormProps<T>>;
  props: T;
}

export function FormDialog<T>({
  children: Form,
  props,
  open,
  setOpen,
  label,
  showCloseButton,
}: Props<T>): ReactElement {
  const handleSubmit = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <DialogWrapper setOpen={setOpen} open={open}>
      <Wrapper>
        <DialogHeader
          label={label}
          showCloseButton={showCloseButton}
          onClose={handleCancel}
        />

        <ContentsWrapper>
          <Form props={props} onSubmit={handleSubmit} onCancel={handleCancel} />
        </ContentsWrapper>
      </Wrapper>
    </DialogWrapper>
  );
}
