import { gql } from "@apollo/client/core";
import {
  ANATOMICAL_STRUCTURES_FRAGMENT,
  AnatomicalStructuresFragmentType,
  parseAnatomicalStructuresFragment,
} from "../../../Annotate/components/AnatomicalStructuresFragment";
import { AnatomicalStructureType } from "../../../Analysis/common/types/AnatomicalStructureType";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { parsedMutation } from "../../queries/utils/parsedMutation";

const MUTATION = gql`
  mutation InsertAnatomicalStructure(
    $objects: [anatomical_structures_insert_input!]!
  ) {
    insert_anatomical_structures(
      objects: $objects
      on_conflict: {
        constraint: anatomical_structures_structure_key
        update_columns: [display_name]
      }
    ) {
      returning {
        ...AnatomicalStructure
      }
    }
  }
  ${ANATOMICAL_STRUCTURES_FRAGMENT}
`;

type Input = AnatomicalStructureType[];

type Variables = {
  objects: {
    display_name: string;
    structure: string;
    enabled_organs: {
      data: { enabled: boolean };
      on_conflict: { constraint: string; update_columns: string[] };
    };
    lesion_colors: {
      data: {
        color: number;
        type: string;
      };
    };
  }[];
};

type Data = {
  insert_anatomical_structures: {
    returning: AnatomicalStructuresFragmentType[];
  };
};

export async function insertAnatomicalStructures(
  client: ApolloClient<NormalizedCacheObject>,
  input: Input
): Promise<AnatomicalStructureType[]> {
  return await parsedMutation<
    Input,
    AnatomicalStructureType[],
    Data,
    Variables
  >({
    client,
    mutation: MUTATION,
    input,
    parseVariables: (input) => ({
      objects: input.map(({ name: display_name, structure }) => ({
        display_name,
        structure: structure.split(" ").join("_"),
        enabled_organs: {
          data: { enabled: true },
          on_conflict: {
            constraint: "enabled_organs_pkey",
            update_columns: ["enabled"],
          },
        },
        lesion_colors: {
          data: { color: 4, type: "ORGAN" },
        },
      })),
    }),
    parseData: ({ insert_anatomical_structures: { returning } }) =>
      returning.map(parseAnatomicalStructuresFragment),
  });
}
