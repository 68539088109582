export const ETHNICITY_BLACK = "Black";
export const ETHNICITY_ASIAN = "Asian";
export const ETHNICITY_CAUCASIAN = "Caucasian";
export const ETHNICITY_HISPANIC = "Hispanic";
export const ETHNICITY_MULTI_ETHNIC = "Multi-Ethnic";

export type EthnicityType =
  | typeof ETHNICITY_BLACK
  | typeof ETHNICITY_ASIAN
  | typeof ETHNICITY_CAUCASIAN
  | typeof ETHNICITY_HISPANIC
  | typeof ETHNICITY_MULTI_ETHNIC;

export function getEthnicityType(ethnicityStr: string): EthnicityType | null {
  const dbNames: Record<string, EthnicityType> = {
    "African American": ETHNICITY_BLACK,
    Asian: ETHNICITY_ASIAN,
    Caucasian: ETHNICITY_CAUCASIAN,
    Hispanic: ETHNICITY_HISPANIC,
    "Multi-Ethnic": ETHNICITY_MULTI_ETHNIC,
  };

  return dbNames[ethnicityStr] ?? null;
}
