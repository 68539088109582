import { useHistory } from "react-router-dom";
import { ANALYSIS_PATIENT_JOURNEY } from "../../Analysis/routes/routePaths";
import { useRouteMatch } from "react-router";
import { ANALYSIS } from "../../common/routes/routePaths";

type useGoToPatientType = {
  // eslint-disable-next-line no-unused-vars
  goToPage: (subjectId: number) => void;
};

export function useGoToPatientPage(): useGoToPatientType {
  const { push } = useHistory();
  const { url } = useRouteMatch();

  const goToPage = (subjectId: number) => {
    const urlPaths = url.split("/").slice(0, 3);
    if (urlPaths[2] !== ANALYSIS) urlPaths[2] = ANALYSIS;

    const urlWithTrialId = urlPaths.join("/");
    push(`${urlWithTrialId}/${subjectId}/${ANALYSIS_PATIENT_JOURNEY}`);
  };

  return { goToPage };
}
