import React, { ReactElement } from "react";
import { TrialType } from "../../types/TrialType";
import Select from "react-select";
import styled from "styled-components";

const StyledTrialSelector = styled(Select)`
  width: 144px;
  outline: none;
  background: ${(props) => props.theme.colors.neutral.black};
  color: white;
  > div:first-child {
    border: 1px solid ${(props) => props.theme.colors.neutral.white10};
  }
  > div {
    min-height: 48px;
    background: ${(props) => props.theme.colors.neutral.black};
    > div > div {
      color: white;
      font-weight: 600;
    }
  }
`;

interface SelectProps {
  isDisabled: boolean;
  isLoading: boolean;
}

interface TrialSelectorProps extends SelectProps {
  trials: TrialType[];
  selectedTrialId: number | null;
  // eslint-disable-next-line no-unused-vars
  onTrialSelected: (trialId: number) => void;
}

type OptionType = { label: string; value: number };

const TrialSelector = ({
  trials,
  selectedTrialId,
  isDisabled,
  isLoading,
  onTrialSelected,
}: TrialSelectorProps): ReactElement => {
  const options = trials.map((trial) => {
    const { id, name } = trial;
    return { value: id, label: name };
  });

  const trialOption = options.find(
    (option) => option.value === selectedTrialId
  );
  if (selectedTrialId != null && !trialOption && !isLoading) {
    throw new Error("Trial does not exist in TrialSelector");
  }

  const handleChange = (selected: OptionType | OptionType[] | null) => {
    if (Array.isArray(selected) || selected == null) {
      throw new Error("Multi/no selection options should not be allowed");
    }

    const { value } = selected;
    onTrialSelected(value);
  };

  return (
    <StyledTrialSelector
      options={options}
      isDisabled={isDisabled || selectedTrialId == null}
      isLoading={isLoading}
      placeholder={null}
      value={trialOption}
      onChange={handleChange}
    />
  );
};

export default TrialSelector;
