import React, { FC } from "react";
import { Card } from "../../../common/components/cards/Card";
import { GraphCardProps } from "../utils/GraphCardProps";
import RTOGDetailPanelContainer from "../../Longitudinal/components/RTOGDetailPanelContainer";

export const SubjectDetailsCard: FC<GraphCardProps> = ({
  onClickHeader,
}: GraphCardProps) => {
  return (
    <Card
      title={"Patient Details"}
      content={<RTOGDetailPanelContainer />}
      onClickHeader={onClickHeader}
    />
  );
};
