import React from "react";
import { FC } from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";
import styled from "styled-components";

const Styled = styled(NotificationsIcon)`
  color: ${(props) => props.theme.colors.primary.inverted};
`;

export const NotificationIcon: FC = () => <Styled />;
