import { RootState } from "../rootReducer";
import { TrialFilterSelectors } from "./TrialFilterSelectors";
import { TrialArmFilters } from "../../types/TrialViewFilterTypes";

export const defaultTrialArmsState = {
  A: true,
  B: true,
};

export const clearTrialArmsState = {
  A: false,
  B: false,
};

export const trialArmFilters = new TrialFilterSelectors<TrialArmFilters>(
  defaultTrialArmsState,
  clearTrialArmsState,
  (state: RootState) => state.trialViewFilters.trialArm
);
