import { MANAGER, USER, UserRoleType } from "../../../types/UserRoleType";

export function getPrimaryRole(roles: UserRoleType[]): UserRoleType {
  if (roles.includes(MANAGER)) {
    return MANAGER;
  }
  if (roles.includes(USER)) {
    return USER;
  }

  //TODO for now, we assume all users are a USER, so just return it even if they aren't
  return USER;
}
