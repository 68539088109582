import { FollowUpType } from "../types/FollowUpType";

export function sortFollowUps(
  followUps: FollowUpType[],
  reverse = false
): FollowUpType[] {
  return [...followUps].sort((a, b) =>
    reverse ? a.order - b.order : b.order - a.order
  );
}
