import React from "react";
import {
  ImagingDeviationDataProps,
  legendPayloadGenerator,
  getColorFromKey,
} from "../utils/parseImagingDeviationData";

import {
  BarChart,
  XAxis,
  YAxis,
  Legend as ReChartsLegend,
  Bar,
  Cell,
  ResponsiveContainer,
} from "recharts";
import Text from "../../../common/components/text/Text";
import styled from "styled-components";
import { Legend } from "../../../Analysis/common/components/Legend";
import { getXAxisProps } from "../../../Analysis/common/utils/getXAxisProps";
import { getYAxisProps } from "../../../Analysis/common/utils/getYAxisProps";
import { getYAxisLabel } from "../../../Analysis/common/utils/getYAxisLabel";

const Wrapper = styled.div`
  flex: 1;
`;

interface ImagingChartProps {
  data: ImagingDeviationDataProps[];
}

function ImagingChart({ data }: ImagingChartProps): React.ReactElement {
  if (data.length === 0) {
    return <Text text={"No data in DB"} />;
  }

  const renderCustomizedLegend = ({ payload }: any) => (
    <Legend payload={payload} />
  );

  const xAxisProps = getXAxisProps();
  const yAxisProps = getYAxisProps(true);
  const yAxisLabel = getYAxisLabel("Deviations (% of all Imaging Studies)");

  return (
    <Wrapper>
      <ResponsiveContainer height={500}>
        <BarChart
          margin={{ top: 0, right: 0, left: 20, bottom: -40 }}
          data={data}
        >
          <XAxis
            {...xAxisProps}
            dataKey={"siteId"}
            tickFormatter={(tick) => `Site ${tick}`}
            axisLine={false}
          />
          <YAxis
            {...yAxisProps}
            dataKey={"totalCount"}
            domain={[0, (dataMax: number) => Math.ceil(dataMax)]}
          >
            {yAxisLabel}
          </YAxis>
          <ReChartsLegend
            align="left"
            verticalAlign="top"
            height={48}
            content={renderCustomizedLegend}
            payload={legendPayloadGenerator()}
          />
          {Object.keys(data[0]).map((key, index) => {
            if (key !== "siteId") {
              return (
                <Bar
                  isAnimationActive={false}
                  dataKey={(val) => val[key].count}
                  stackId={"x"}
                  barSize={50}
                  key={index}
                  radius={
                    key == "d"
                      ? [6, 6, 0, 0]
                      : key == "a"
                      ? [0, 0, 6, 6]
                      : [0, 0, 0, 0]
                  }
                >
                  {data.map(() => {
                    return <Cell key={key} fill={getColorFromKey(key)} />;
                  })}
                </Bar>
              );
            }
          })}
        </BarChart>
      </ResponsiveContainer>
    </Wrapper>
  );
}

export default ImagingChart;
