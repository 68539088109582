import { RawFollowUp } from "../../rawTypes/RawFollowUp";
import { parseDiagnosisFromQueryResult } from "./parseDiagnosisFromQueryResult";
import { parseStudyFromQueryResult } from "./parseStudyFromQueryResult";
import { IntermediateFollowUpType } from "../../types/IntermediateFollowUpType";
import { StudyType } from "../../types/StudyType";

export function parseFollowUpFromQueryResult(
  rawFollowUp: RawFollowUp
): IntermediateFollowUpType {
  const {
    follow_up_date: followUpDateStr,
    follow_up_order: order,
    diagnoses: rawDiagnosis,
    study_follow_up_maps: studyFollowUps,
  } = rawFollowUp;

  const studies = studyFollowUps
    .map((studyFollowUp) => studyFollowUp.study)
    .map(parseStudyFromQueryResult);
  // TODO: here we assume there is only one study per follow_up so we can flatMap it which essentially grabs
  // the only study
  const tumourBurdens = studies.flatMap((s) => {
    return s.tumourBurdens;
  });
  const tumourResponses = studies.flatMap((s) => {
    return s.tumourResponses;
  });
  const survivalPredictions = studies.flatMap((s) => {
    return s.survivalPredictions;
  });

  const diagnoses = rawDiagnosis.map(parseDiagnosisFromQueryResult);

  const date = followUpDateStr ? new Date(followUpDateStr) : null;

  return {
    order,
    date,
    tumourBurdens,
    tumourResponses,
    survivalPredictions,
    diagnoses,
    studies,
  };
}
