import { Dialog } from "./Dialog";
import React, { useEffect, useMemo, useState } from "react";
import { ContentDialogProps } from "./ContentDialogProps";

export type useDialogReturnType = [
  (open: boolean) => void,
  {
    dialog: JSX.Element;
  }
];

export function useDialog({
  label,
  content,
  options,
}: ContentDialogProps): useDialogReturnType {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const { onOpened } = options;
    if (onOpened && open) {
      onOpened();
    }
  }, [open]);

  const dialog = useMemo(() => {
    return (
      <Dialog
        open={open}
        setOpen={setOpen}
        label={label}
        content={content}
        options={options}
      />
    );
  }, [open, label, content, options]);

  return [setOpen, { dialog }];
}
