import React from "react";
import { getInitials, UserType } from "../../../types/UserType";
import { stringToColor } from "./stringToColor";
import styled from "styled-components";
import { getPixelsFromSize } from "../../icons/utils/getPixelsFromSize";

const Text = styled.div<{ fontSize: string }>`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => props.fontSize};
  line-height: 20px;
`;

export function stringAvatar(
  user: UserType,
  size: number
): {
  style: { backgroundColor: string; width: string; height: string };
  children: JSX.Element;
} {
  const initials = getInitials(user);
  const fontSize = getPixelsFromSize(Math.round(size * 0.4));
  const sizePixels = getPixelsFromSize(size);
  const color = stringToColor(initials);

  return {
    style: {
      backgroundColor: color,
      width: sizePixels,
      height: sizePixels,
    },
    children: <Text fontSize={fontSize}>{initials}</Text>,
  };
}
