import React, { FC } from "react";
import SideNav from "../common/components/SideNav";
import styled from "styled-components";
import { captureTrialIdParam } from "./utils/captureTrialIdParam";
import { TrialRouter } from "./TrialRouter";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  width: 100%;
  overflow: hidden;
`;

const SideNavWrapper = styled.div`
  height: 100%;
  width: 240px;
  background: ${(props) => props.theme.colors.neutral.black};
`;

const ContentWrapper = styled.div`
  width: calc(100% - 240px);
`;

export const Trial: FC = () => {
  captureTrialIdParam();

  return (
    <Wrapper>
      <SideNavWrapper>
        <SideNav />
      </SideNavWrapper>
      <ContentWrapper>
        <TrialRouter />
      </ContentWrapper>
    </Wrapper>
  );
};
