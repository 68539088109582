import axios from "axios";

export async function deleteNonDemoPatients(token: string): Promise<void> {
  const apiUrl = process.env.REACT_APP_CELERY_API_URL;
  const deletePatientUrl = apiUrl + "/delete-demo-data/";

  const result = await axios.get(deletePatientUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log("Delete patients response:", result.data);
}
