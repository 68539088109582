export const LUNG_SURVIVAL_PREDICTION = "perform_lung_survival_prediction_task";
export const SKIN_SEGMENTATION = "apply_skin_mask_task";
export const LUNG_SEGMENTATION = "apply_lung_box_task";
export const PREPROCESS_IMAGES = "preprocess_images_task";

export type SurvivalPredictionTasks =
  | typeof LUNG_SURVIVAL_PREDICTION
  | typeof SKIN_SEGMENTATION
  | typeof LUNG_SEGMENTATION
  | typeof PREPROCESS_IMAGES;
