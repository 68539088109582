import React from "react";
import { getColumns, StudyTableColumnType } from "./StudyTableColumns";
import { getStudyRowsFromSubjects } from "./utils/getStudyRowsFromSubjects";
import { Column } from "react-table";
import { StudyTableRowType } from "./StudyTableRowType";
import { Table, TableOptions } from "../Table/Table";
import { SubjectType } from "../../../Analysis/common/types/SubjectType";

export interface StudyTableBaseProps {
  subjects: SubjectType[];
}

interface StudyTableProps
  extends StudyTableBaseProps,
    TableOptions<StudyTableRowType> {
  columnIds: StudyTableColumnType[];
}

export function StudyTable(props: StudyTableProps): React.ReactElement {
  const { subjects, columnIds, ...tableProps } = props;

  const memoizedColumns = React.useMemo<Column<StudyTableRowType>[]>(
    () => getColumns({ columnIds }),
    [columnIds]
  );
  const memoizedRows = React.useMemo<StudyTableRowType[]>(
    () => getStudyRowsFromSubjects(subjects),
    [subjects]
  );

  return (
    <Table {...tableProps} columns={memoizedColumns} data={memoizedRows} />
  );
}
