import styled from "styled-components";
import Badge from "@material-ui/core/Badge";
import { default as AvatarMui } from "@material-ui/core/Avatar";
import React, { ReactNode } from "react";
import { stringAvatar } from "./utils/stringAvatar";
import { UserType } from "../../types/UserType";

const Wrapper = styled.div<{ clickable: boolean }>`
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
`;

const StyledBadge = styled.div`
  width: 10px;
  height: 10px;
  color: red;
  background-color: ${(props) => props.theme.colors.actionSecondary.default};
  border-radius: 5px;
`;

interface AvatarProps {
  user: UserType;
  onDoubleClick?: () => void;
  onClick?: () => void;
  size?: number;
  badgeContent?: ReactNode;
}

export function Avatar({
  user,
  onDoubleClick,
  onClick,
  size = 32,
  badgeContent,
}: AvatarProps): JSX.Element {
  const { avatarImageSource } = user;
  const handleOnClick = () => {
    onClick?.();
  };

  const handleOnDoubleClick = () => {
    onDoubleClick?.();
  };

  return (
    <Wrapper clickable={onClick !== undefined}>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent={badgeContent}
        onClick={handleOnClick}
      >
        <AvatarMui
          src={avatarImageSource}
          onDoubleClick={handleOnDoubleClick}
          onClick={handleOnClick}
          {...stringAvatar(user, size)}
        />
      </Badge>
    </Wrapper>
  );
}
