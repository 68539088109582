import React, { FC } from "react";
import { useRouteMatch } from "react-router";
import { useHistory } from "react-router-dom";
import {
  ANALYSIS_ALGORITHM,
  ANALYSIS_KAPLAN_DETAILS,
  ANALYSIS_MORTALITY_DETAILS,
  ANALYSIS_OVERVIEW,
  ANALYSIS_PATIENT_JOURNEY,
  ANALYSIS_SURVIVAL_DETAILS,
} from "./routes/routePaths";
import { TrialDetailsCard } from "../common/components/TrialDetails/TrialDetailsCard";
import { AlgorithmSummaryCard } from "./Algorithm/components/AlgorithmSummaryCard";
import { KaplanMeierCurveCard } from "./KaplanMeier/components/KaplanMeierCurveCard";
import { MedianSurvivalPredictionCard } from "./MedianSurvival/components/MedianSurvivalPredictionCard";
import { ChangeInMortalityVsBaselineCard } from "./ChangeFromBaseline/components/ChangeInMortalityVsBaselineCard";
import { PatientJourneyCard } from "./PatientJourney/components/PatientJourneyCard";
import { StudyTableCard } from "./common/components/StudyTableCard";

export const AnalysisOverview: FC = () => {
  const { url } = useRouteMatch();
  const { push } = useHistory();

  const handleClickAlgorithms = () => {
    const path = url.replace(ANALYSIS_OVERVIEW, ANALYSIS_ALGORITHM);
    push(path);
  };

  const handleClickKaplanChart = () => {
    const path = url.replace(ANALYSIS_OVERVIEW, ANALYSIS_KAPLAN_DETAILS);
    push(path);
  };

  const handleClickMedianSurvivalPrediction = () => {
    const path = url.replace(ANALYSIS_OVERVIEW, ANALYSIS_SURVIVAL_DETAILS);
    push(path);
  };

  const handleClickMortalityBaseline = () => {
    const path = url.replace(ANALYSIS_OVERVIEW, ANALYSIS_MORTALITY_DETAILS);
    push(path);
  };

  const handleClickPatientJourney = () => {
    const path = url.replace(ANALYSIS_OVERVIEW, ANALYSIS_PATIENT_JOURNEY);
    push(path);
  };

  return (
    <>
      <TrialDetailsCard />
      <AlgorithmSummaryCard onClickAlgorithms={handleClickAlgorithms} />
      <KaplanMeierCurveCard onClickHeader={handleClickKaplanChart} />
      <MedianSurvivalPredictionCard
        onClickHeader={handleClickMedianSurvivalPrediction}
      />
      <ChangeInMortalityVsBaselineCard
        onClickHeader={handleClickMortalityBaseline}
      />
      <PatientJourneyCard onClickHeader={handleClickPatientJourney} />
      <StudyTableCard />
    </>
  );
};
