import PatientJourneyTumourDetails from "./PatientJourneyTumourDetails";
import React, { FC } from "react";
import Loading from "../../../common/components/Loading";
import handleApolloError from "../../../common/utils/handleApolloError";
import { useSubjectFollowUps } from "../utils/useSubjectFollowUps";
import { captureSubjectIdParam } from "../../../Trial/utils/captureSubjectIdParam";
import { MissingPatientPanel } from "./MissingPatientPanel";

const PatientJourneyTumourDetailsContainer: FC = () => {
  const subjectId = captureSubjectIdParam();

  const { subject, caseLinks, loading, error } = useSubjectFollowUps(subjectId);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    handleApolloError(error);
  }
  if (!subject) {
    return <MissingPatientPanel />;
  }

  const caseLink = caseLinks.length > 0 ? caseLinks[0] : undefined;

  return <PatientJourneyTumourDetails subject={subject} caseLink={caseLink} />;
};

export default PatientJourneyTumourDetailsContainer;
