import { OVERVIEW } from "../routes/routePaths";
import { TrialViewPageType } from "../../Trial/types/TrialViewPageType";

export function getTrialRoute(
  trialId: number,
  pageName: TrialViewPageType
): string {
  return `/${trialId}/${pageName}`;
}

export function getTrialBaseRoute(trialId: number): string {
  return getTrialRoute(trialId, OVERVIEW);
}
