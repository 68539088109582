import { gql } from "@apollo/client/core";
import { CASE_INSTANCE_FRAGMENT } from "../../types/CaseInstanceFragment";
import { CASE_ASSIGNMENT_FRAGMENT } from "../../types/CaseAssignmentFragment";
import { CaseInstanceFragmentType } from "../../types/CaseInstanceFragmentType";
import { CaseAssignmentFragmentType } from "../../types/CaseAssignmentFragmentType";
import { useParsedQuery } from "../../../common/queries/utils/useParsedQuery";
import { useCurrentUser } from "../../../common/components/UserManagement/useCurrentUser";
import { CaseInstanceType } from "../../types/CaseInstanceType";
import { CaseAssignmentType } from "../../types/CaseAssignmentType";
import { useParsedQueryReturnType } from "../../../common/queries/utils/useParsedQueryReturnType";
import { ParsedQueryOptions } from "../../../common/queries/utils/ParsedQueryArgs";

const QUERY = gql`
  query GetWorklist($user_id: Int = 0) {
    unassignedCaseInstances: case_instance(
      where: {
        _and: [
          { _not: { case: { case_assignments: {} } } }
          { enabled: { _eq: true } }
          { _not: { case: { case_flag_logs: { resolved: { _eq: false } } } } }
          {
            _or: [
              { case: { series_cases: { series: { study: { patient: {} } } } } }
              {
                case: {
                  series_cases: {
                    series: { study: { patient: { temp_demo_patients: {} } } }
                  }
                }
              }
            ]
          }
        ]
      }
    ) {
      ...CaseInstance
    }
    caseAssignments: case_assignment(where: { user_id: { _eq: $user_id } }) {
      ...CaseAssignment
    }
    temp_nota_annotation_page_url_base {
      url
    }
  }

  ${CASE_INSTANCE_FRAGMENT}
  ${CASE_ASSIGNMENT_FRAGMENT}
`;

type Variables = { user_id?: number };

type Data = {
  unassignedCaseInstances: CaseInstanceFragmentType[];
  caseAssignments: CaseAssignmentFragmentType[];
  temp_nota_annotation_page_url_base: { url: string }[];
};

type Output = {
  unassignedCaseInstances: CaseInstanceType[];
  caseAssignments: CaseAssignmentType[];
};

export function useWorklist(
  options?: ParsedQueryOptions<Data, Variables>
): useParsedQueryReturnType<number | undefined, Output, Variables> {
  const { data: user } = useCurrentUser();

  const { id } = user ?? { id: undefined };

  return useParsedQuery<number | undefined, Output, Data, Variables>({
    query: QUERY,
    input: id,
    options,
    parseVariables: (id) => ({ user_id: id }),
    parseData: parseData,
  });
}

function parseData(data: Data): Output {
  const {
    caseAssignments: rawCaseAssignments,
    unassignedCaseInstances: rawUnassignedCaseInstances,
    temp_nota_annotation_page_url_base: notaUrls,
  } = data;

  if (notaUrls.length !== 1) {
    throw new Error(
      "One or more urls set at temp_nota_annotation_page_url_base"
    );
  }

  const notaUrl = notaUrls[0].url;

  const caseAssignments = rawCaseAssignments.map((caseAssignment) => {
    const {
      case: { id },
    } = caseAssignment;

    return {
      ...caseAssignment,
      case: {
        ...caseAssignment.case,
        url: `${notaUrl}${id}`,
      },
    };
  });

  const unassignedCaseInstances = rawUnassignedCaseInstances.map(
    (caseInstance) => {
      const {
        case: { id },
      } = caseInstance;

      return {
        ...caseInstance,
        case: {
          ...caseInstance.case,
          url: `${notaUrl}${id}`,
        },
      };
    }
  );

  return {
    unassignedCaseInstances,
    caseAssignments,
  };
}
