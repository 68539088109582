import React from "react";
import DataIngestionProgress from "./DataIngestionProgress";
import { gql, useQuery } from "@apollo/client";
import { useTrialId } from "../../../Trial/hooks/useTrialId";
import handleApolloError from "../../../common/utils/handleApolloError";
import Loading from "../../../common/components/Loading";
import parseDataIngestionData from "../utils/parseDataIngestionData";
import RadiologyPanel from "../../../Overview/Radiology/components/RadiologyPanel";

const GET_UPLOAD_REPORTS = gql`
  query GetUploadReports($trialId: Int!) {
    uploadJobReport: upload_job_report(
      where: { upload_job: { trial_id: { _eq: $trialId } } }
    ) {
      contents
    }
  }
`;

function DataIngestionProgressContainer(): React.ReactElement {
  const trialId = useTrialId();
  const { data, loading, error } = useQuery(GET_UPLOAD_REPORTS, {
    variables: { trialId },
  });

  if (loading) return <Loading />;
  if (error) handleApolloError(error);

  const criteria = "RECIST 1.1";
  const followUp = "Double central review with adjudication";

  const processedData = parseDataIngestionData(data);
  return (
    <RadiologyPanel criteria={criteria} followUp={followUp}>
      <DataIngestionProgress dataInfo={processedData} />
    </RadiologyPanel>
  );
}

export default DataIngestionProgressContainer;
