import { MUTATION_EGFR } from "../../../Analysis/common/types/TumourMutationType";
import { RootState } from "../rootReducer";
import { TrialFilterSelectors } from "./TrialFilterSelectors";
import { TumourMutationsFilters } from "../../types/TrialViewFilterTypes";

export const defaultMutationsState = {
  [MUTATION_EGFR]: true,
};

export const clearMutationsState = {
  [MUTATION_EGFR]: false,
};

export const mutationFilters = new TrialFilterSelectors<TumourMutationsFilters>(
  defaultMutationsState,
  clearMutationsState,
  (state: RootState) => state.trialViewFilters.tumourMutations
);
