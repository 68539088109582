export const USER = "USER";
export const MANAGER = "MANAGER";
export const ANNOTATE = "ANNOTATE";
export const VIEW_ANNOTATIONS = "VIEW_ANNOTATIONS";

export type UserRoleType =
  | typeof USER
  | typeof MANAGER
  | typeof ANNOTATE
  | typeof VIEW_ANNOTATIONS;
