import { NewTrialType } from "./NewTrialType";

export const EMPTY_TRIAL: NewTrialType = {
  name: "",
  status: "ACTIVE",
  startDate: new Date(),
  arms: 2,
  targetEndDate: new Date("2023-01-01"),
  estimatedCompletion: new Date("2023-01-01"),
};
