import { ADD, REMOVE, UpdateModeType } from "./UpdateModeType";

export type ItemsUpdateType<D> = {
  changed: D[];
  mode: UpdateModeType;
};

export function getChanged<D extends { id: number; state?: UpdateModeType }>(
  existing: D[],
  changed: D[],
  state?: UpdateModeType
): { untouched: D[]; added: D[]; removed: D[] } {
  const addedValues = state === ADD ? changed : [];
  const removedValues = state === REMOVE ? changed : [];

  if (!state) {
    return { untouched: existing, added: [], removed: [] };
  }

  const untouched = existing.filter(
    ({ id: removedId }) => !removedValues.some(({ id }) => id === removedId)
  );

  const added = addedValues.filter(
    ({ id: addedId }) => !existing.some(({ id }) => id === addedId)
  );

  const removed = removedValues.filter(({ id: removedId }) =>
    existing.some(({ id }) => id === removedId)
  );

  return { untouched, added, removed };
}
