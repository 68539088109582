import React from "react";
import MortalityBaselineChart from "./MortalityBaselineChart";
import parseMortalityBaselineChartData from "../utils/parseMortalityBaselineChartData";
import Loading from "../../../common/components/Loading";
import handleApolloError from "../../../common/utils/handleApolloError";
import { useTrialId } from "../../../Trial/hooks/useTrialId";
import { useTimepointFilteredSubjectsQuery } from "../../common/hooks/useTimepointFilteredSubjectsQuery";

function MortalityBaselineChartContainer(): React.ReactElement {
  const trialId = useTrialId();
  const { subjects, loading, error } = useTimepointFilteredSubjectsQuery(
    trialId
  );

  if (loading) return <Loading />;
  if (error) handleApolloError(error);
  if (!subjects) throw new Error("Subject null with no apollo error");

  const subjectsData = parseMortalityBaselineChartData(subjects);

  return <MortalityBaselineChart subjectsData={subjectsData} />;
}

export default MortalityBaselineChartContainer;
