import React, { FC } from "react";
import { Redirect, Route, Router, Switch, useRouteMatch } from "react-router";
import { history } from "../common/store/history";
import CTAButton from "../common/components/buttons/CTAButton";
import { TrialContent } from "../Trial/TrialContent";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import Overview from "./Overview/components/Overview";
import Upload from "./Upload/components/Upload";
import {
  DATA_MANAGEMENT_OVERVIEW,
  DATA_MANAGEMENT_UPLOAD,
} from "./routes/routePaths";
import DownloadReportButton from "../common/components/buttons/DownloadReportButton";
import { useHistory } from "react-router-dom";
import { DataManagementTopBar } from "./DataManagementTopBar";
import { DataManagementIcon } from "../common/components/icons/DataManagementIcon";
import { ANNOTATE, DATA_MANAGEMENT } from "../common/routes/routePaths";
import { ANNOTATE_MANAGE_ANNOTATIONS } from "../Annotate/routes/routePaths";

export const DataManagement: FC = () => {
  const { path } = useRouteMatch();
  const { push } = useHistory();

  const handleUploadClicked = () => {
    push(`${path}/${DATA_MANAGEMENT_UPLOAD}`);
  };

  const handleManageAnnotationsClicked = () => {
    const annotateRootUrl = path.replace(DATA_MANAGEMENT, ANNOTATE);
    push(`${annotateRootUrl}/${ANNOTATE_MANAGE_ANNOTATIONS}`);
  };

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/${DATA_MANAGEMENT_OVERVIEW}`} />
        </Route>
        <Route path={`${path}/${DATA_MANAGEMENT_OVERVIEW}`}>
          <TrialContent
            topBar={
              <DataManagementTopBar
                subTitle={"Overview"}
                buttons={[
                  <CTAButton
                    key={0}
                    icon={<CloudUploadOutlinedIcon />}
                    text={"Upload now"}
                    onClick={handleUploadClicked}
                  />,
                  <DownloadReportButton text={"Generate Report"} key={1} />,
                ]}
              />
            }
            mainContent={<Overview />}
          />
        </Route>
        <Route path={`${path}/${DATA_MANAGEMENT_UPLOAD}`}>
          <TrialContent
            topBar={
              <DataManagementTopBar
                subTitle={"Upload"}
                buttons={[
                  <CTAButton
                    key={0}
                    text={"Manage Annotations"}
                    icon={<DataManagementIcon />}
                    onClick={handleManageAnnotationsClicked}
                  />,
                ]}
              />
            }
            mainContent={<Upload />}
          />
        </Route>
      </Switch>
    </Router>
  );
};
