import { gql } from "@apollo/client/core";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { UserType } from "../../types/UserType";
import { parsedMutation } from "../../queries/utils/parsedMutation";
import { TrialType } from "../../types/TrialType";

const MUTATION = gql`
  mutation InsertUserGroups($user_groups: [user_group_insert_input!]!) {
    insert_user_group(
      objects: $user_groups
      on_conflict: { constraint: user_group_pkey, update_columns: [] }
    ) {
      affected_rows
    }
  }
`;

type Input = {
  users: UserType[];
  trial: TrialType;
};

type Data = { insert_user_group: { affected_rows: number } };
type Variables = {
  user_groups: {
    user_id: number;
    group_id: number;
  }[];
};

export async function insertUsersIntoTrial(
  users: UserType[],
  trial: TrialType,
  client: ApolloClient<NormalizedCacheObject>
): Promise<UserType[]> {
  return await parsedMutation<Input, UserType[], Data, Variables>({
    mutation: MUTATION,
    client,
    input: { users, trial },
    parseVariables,
    parseData,
  });
}

function parseVariables(input: Input): Variables {
  const {
    users,
    trial: { groupIds },
  } = input;

  const userGroups: {
    user_id: number;
    group_id: number;
  }[] = [];
  for (const { id: user_id } of users) {
    for (const group_id of groupIds) {
      userGroups.push({
        user_id,
        group_id,
      });
    }
  }

  return { user_groups: userGroups };
}

function parseData(data: Data, input: Input): UserType[] {
  const { users } = input;
  return users;
}
