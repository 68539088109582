import React from "react";
import { UserType } from "../../types/UserType";
import { Avatar } from "../Avatar/Avatar";
import { Label } from "../Label";
import { main } from "../../theme/main";
import { formatUserFullName } from "./utils/formatUserFullName";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

interface UserLabelProps {
  user: UserType;
}
export function UserLabel({ user }: UserLabelProps): JSX.Element {
  const {
    neutral: { white: color, neutral5 },
    states: { success },
  } = main.colors;

  const { id, email } = user;
  const displayName = formatUserFullName(user) ?? email;

  const isNewUser = id === 0;

  const background = !isNewUser ? neutral5 : success;

  return (
    <Label background={background} color={color}>
      <Wrapper>
        {!isNewUser && <Avatar user={user} size={18} />}
        {displayName}
      </Wrapper>
    </Label>
  );
}
