import React, { ReactElement } from "react";
import { TooltipItemWrapper } from "../../common/components/TooltipItem";
import {
  TooltipWrapper,
  CustomTooltipProps,
} from "../../common/components/Tooltip";
import styled from "styled-components";
import { formatFollowUpShort } from "../../common/utils/formatFollowUpShort";
import { SubjectResponseProgressionType } from "../types/SubjectResponseProgressionType";
import { formatDays } from "../../common/utils/formatDays";

const StyledItemName = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.neutral.white80};
`;

const StyledValue = styled.div`
  margin-left: 12px;
  color: ${(props) => props.theme.colors.neutral.white};
`;

const TooltipDivider = styled.div`
  height: 0;
  width: 100%;
  left: 8px;
  margin: 6px 0px;
  border: 0.5px solid ${(props) => props.theme.colors.neutral.white40};
`;

export function PatientJourneyChartTooltip({
  active,
  payload,
  label,
}: CustomTooltipProps): ReactElement | null {
  if (!active || !payload || payload.length < 1) {
    return null;
  }
  const itemsToRender: any[] = payload.filter(
    (item: any) => item.type !== "none"
  );

  if (itemsToRender.length < 1) {
    return null;
  }

  const { payload: subjectPayload } = itemsToRender[0];
  const subject = subjectPayload as SubjectResponseProgressionType;

  const {
    subjectId,
    daysSinceRandomization,
    lastFollowUp,
    lastResponseClassification,
    lastSurvivalPrediction,
  } = subject;

  const daysSinceRandomizationText =
    daysSinceRandomization !== null
      ? formatDays(daysSinceRandomization)
      : "N/A";

  const lastFollowUpText = formatFollowUpShort(lastFollowUp);
  const lastSurvivalPredictionText = `${lastSurvivalPrediction} months`;

  return (
    <TooltipWrapper>
      <TooltipItemWrapper>
        <StyledItemName>{`Subject ID`}</StyledItemName>
        <StyledValue>{subjectId}</StyledValue>
      </TooltipItemWrapper>
      <TooltipDivider />
      <TooltipItemWrapper>
        <StyledItemName>{"Days Since Randomization"}</StyledItemName>
        <StyledValue>{daysSinceRandomizationText}</StyledValue>
      </TooltipItemWrapper>
      <TooltipItemWrapper>
        <StyledItemName>{"Last Imaging Time Point"}</StyledItemName>
        <StyledValue>{lastFollowUpText}</StyledValue>
      </TooltipItemWrapper>
      <TooltipItemWrapper>
        <StyledItemName>{"Last Response Classification"}</StyledItemName>
        <StyledValue>{lastResponseClassification}</StyledValue>
      </TooltipItemWrapper>
      <TooltipItemWrapper>
        <StyledItemName>{"Last Survival Prediction"}</StyledItemName>
        <StyledValue>{lastSurvivalPredictionText}</StyledValue>
      </TooltipItemWrapper>
    </TooltipWrapper>
  );
}
