import {
  InnerButtonWrapper,
  InputDiv,
} from "../../common/components/input/InputButton";
import { main } from "../../common/theme/main";
import { AltLoading } from "../../common/components/AltLoading";
import React from "react";
import { useCompletedLesions } from "./useCompletedLesions";
import handleApolloError from "../../common/utils/handleApolloError";
import { StudyTableRowType } from "../../common/components/StudyTable/StudyTableRowType";
import { LesionType } from "../types/LesionType";

interface ExportLesionsButtonProps {
  studyRows: StudyTableRowType[];
}

export function ExportLesionsButton({
  studyRows,
}: ExportLesionsButtonProps): JSX.Element {
  const handleExportCompleted = async (lesions: LesionType[]) => {
    console.log("lesions", lesions);
    const json = JSON.stringify(lesions, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = "lesions.json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [
    executeExport,
    { loading: useExportLoading, error: useExportError },
  ] = useCompletedLesions({
    onCompleted: handleExportCompleted,
  });

  if (useExportError) handleApolloError(useExportError);

  const handleExport = async () => {
    await executeExport(studyRows.map(({ studyId }) => studyId));
  };

  return (
    <InputDiv
      onClick={handleExport}
      color={main.colors.neutral.white}
      background={main.colors.neutral.black}
      width={128}
    >
      <InnerButtonWrapper>
        {useExportLoading && <AltLoading color={main.colors.neutral.white} />}
        {"Export"}
      </InnerButtonWrapper>
    </InputDiv>
  );
}
