export const PENDING = "PENDING";
export const STARTED = "STARTED";
export const FAILED = "FAILED";
export const SUCCESS = "SUCCESS";

export type JobStatusType =
  | typeof PENDING
  | typeof STARTED
  | typeof FAILED
  | typeof SUCCESS;
