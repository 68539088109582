import {
  AJCC_IIIB,
  AJCC_IIIC,
  AJCC_IVA,
} from "../../../Analysis/common/types/AjccStageType";
import { RootState } from "../rootReducer";
import { StageFilters } from "../../types/TrialViewFilterTypes";
import { TrialFilterSelectors } from "./TrialFilterSelectors";

export const defaultStageState: StageFilters = {
  ajccStage: {
    [AJCC_IIIB]: true,
    [AJCC_IIIC]: true,
    [AJCC_IVA]: true,
  },
  tStages: {
    "0": true,
    "1a": true,
    "1b": true,
    "1c": true,
    "2a": true,
    "2b": true,
    "3": true,
    "4": true,
  },
  nStages: {
    "0": true,
    "1": true,
    "2": true,
    "3": true,
    "4": true,
  },
  mStages: {
    "0": true,
    "1a": true,
    "1b": true,
    "1c": true,
  },
};

export const clearStageState: StageFilters = {
  ajccStage: {
    [AJCC_IIIB]: false,
    [AJCC_IIIC]: false,
    [AJCC_IVA]: false,
  },
  tStages: {
    "0": false,
    "1a": false,
    "1b": false,
    "1c": false,
    "2a": false,
    "2b": false,
    "3": false,
    "4": false,
  },
  nStages: {
    "0": false,
    "1": false,
    "2": false,
    "3": false,
    "4": false,
  },
  mStages: {
    "0": false,
    "1a": false,
    "1b": false,
    "1c": false,
  },
};

export const ajccFilters = new TrialFilterSelectors<StageFilters>(
  defaultStageState,
  clearStageState,
  (state: RootState) => state.trialViewFilters.stage
);
