import React, { FC } from "react";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import { main } from "../theme/main";

interface AltLoadingProps {
  color?: string;
  size?: number;
  thickness?: number;
}

export const AltLoading: FC<AltLoadingProps> = ({
  color = main.colors.actionPrimary.hover,
  size = 16,
  thickness = 8,
}: AltLoadingProps) => {
  return (
    <CircularProgress size={size} thickness={thickness} style={{ color }} />
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const FlexAltLoading: FC<AltLoadingProps> = (props: AltLoadingProps) => {
  return (
    <Wrapper>
      <AltLoading {...props} />
    </Wrapper>
  );
};
