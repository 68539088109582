import React, { FC } from "react";
import styled from "styled-components";
import { useDialogReturnType } from "../../Dialog/useDialog";
import { DialogContentInnerWrapper } from "../../Dialog/DialogContentInnerWrapper";
import { SiteType } from "../../../types/SiteType";
import { SiteActionCallbacksType } from "../SiteActionCallbacksType";
import { FormProps } from "../../Dialog/FormProps";
import { useForm } from "react-hook-form";
import { Label } from "../../input/Label";
import { Input } from "../../input/Input";
import { ErrorLabel } from "../../input/ErrorLabel";
import { ActionButtonsWrapper } from "../../Dialog/Form/ActionButtonsWrapper";
import { InputButton } from "../../input/InputButton";
import { main } from "../../../theme/main";
import { useFormDialog } from "../../Dialog/useFormDialog";

const Wrapper = styled(DialogContentInnerWrapper)`
  width: 320px;
`;

export function useEditSiteDialog(
  site: SiteType,
  actionCallbacks?: SiteActionCallbacksType
): useDialogReturnType {
  return useFormDialog({
    children: EditSiteForm,
    label: "Edit Site",
    showCloseButton: true,
    props: {
      actionCallbacks,
      site,
    },
  });
}

interface EditSiteFormProps {
  actionCallbacks?: SiteActionCallbacksType;
  site: SiteType;
}

const EditSiteForm: FC<FormProps<EditSiteFormProps>> = ({
  props: { site, actionCallbacks },
  onSubmit,
  onCancel,
}: FormProps<EditSiteFormProps>) => {
  const { onEdited } = actionCallbacks ?? { onEdited: undefined };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SiteType>({ defaultValues: site });

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmitForm = (site: SiteType) => {
    if (!onEdited) {
      throw new Error("onEdited not defined in handleSubmitForm");
    }

    onEdited(site);
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <Wrapper>
        <div>
          <Label htmlFor={"site-name"} required error={errors.name}>
            Site Name
          </Label>
          <Input
            error={errors.name}
            type="text"
            id={"site-name"}
            autoFocus
            autoComplete={"off"}
            {...register("name", { required: true })}
          />
          {errors.name && <ErrorLabel>Site Name must be provided</ErrorLabel>}
        </div>
      </Wrapper>

      <ActionButtonsWrapper>
        <InputButton type="submit" name="submit-button" value={"Confirm"} />
        <InputButton
          type="button"
          name="cancel-button"
          value={"Cancel"}
          background={main.colors.neutral.white}
          color={main.colors.neutral.black}
          onClick={handleCancel}
        />
      </ActionButtonsWrapper>
    </form>
  );
};
