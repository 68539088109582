import { main } from "../../theme/main";
import { CSSObject } from "@emotion/serialize";
import { StylesConfig } from "react-select";

const {
  colors: {
    actionPrimary: { active: black },
    primary: { primary20: hover, primary30: active, primary40: selected },
  },
} = main;

export function getSelectStyle<
  OptionType,
  IsMulti extends boolean
>(): StylesConfig<OptionType, IsMulti> {
  return {
    menuPortal: (provided: CSSObject) => ({ ...provided, zIndex: 9999 }),
    control: (styles: CSSObject) => ({
      ...styles,
      borderWidth: "0",
      boxShadow: "none",
      fontSize: "12px",
    }),
    container: (styles: CSSObject) => ({
      ...styles,
      boxSizing: "border-box",
      padding: "1px",
      border: "1px solid #858c94",
      borderRadius: "8px",
      "&:hover": {
        borderColor: "black",
      },
      minWidth: 181,
    }),
    menu: (styles: CSSObject) => ({
      ...styles,
      fontSize: "12px",
      color: "black",
    }),
    input: (styles: CSSObject) => ({
      ...styles,
      fontSize: "12px",
      color: black,
    }),
    singleValue: (styles: CSSObject) => ({
      ...styles,
      fontSize: "12px",
      color: black,
    }),
    option: (
      styles: CSSObject,
      { isDisabled, isFocused, isSelected }: any
    ) => ({
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? selected
        : isFocused
        ? hover
        : undefined,
      color: black,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? active
            : active
          : undefined,
      },
    }),
  };
}
