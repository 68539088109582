import { useParams } from "react-router";
import { setTrialId } from "../../common/store/trialIdSlice";
import { useDispatch } from "react-redux";
import { addRecentTrial } from "./localStorage";

interface RouteProps {
  trialId: string;
}

export function captureTrialIdParam(): void {
  const dispatch = useDispatch();

  const { trialId } = useParams<RouteProps>();
  const trialIdNumber = Number(trialId) || null;

  if (!trialIdNumber) {
    throw new Error("No trial found in route.");
  }
  addRecentTrial(trialIdNumber);

  dispatch(setTrialId(trialIdNumber));
}
