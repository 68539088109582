import styled from "styled-components";

export const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 12px 0 0 0;
  gap: 12px;
  width: 100%;
`;
