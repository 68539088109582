import { SubjectType } from "../../common/types/SubjectType";
import { getMostRecentFollowUp } from "../../common/utils/getMostRecentFollowUp";
import { getBaselineFollowUp } from "../../common/utils/getBaselineFollowUp";
import { IPRO, RECIST } from "../components/Filter";
import { TrialArmType } from "../../common/types/TrialArmType";
import { getFollowUpSurvivalPrediction } from "../../common/utils/getFollowUpSurvivalPrediction";
import { getFollowUpTumourBurden } from "../../common/utils/getFollowUpTumourBurden";
import { getFollowUpInMonths } from "../../common/utils/getFollowUpInMonths";

export type DataType = typeof RECIST | typeof IPRO;

export type SubjectMortalityVsBaselineType = {
  id: number;
  subjectId: string;
  trialArm: TrialArmType;
  data: Record<DataType, number>;
};

const ParseMortalityBaselineChartData = (
  subjects: SubjectType[]
): SubjectMortalityVsBaselineType[] => {
  const subjectsData: SubjectMortalityVsBaselineType[] = [];

  for (const subject of subjects) {
    const { id, subjectId, trialArm, followUps } = subject;

    if (followUps.length < 2) {
      continue;
    }

    const baselineFollowUp = getBaselineFollowUp(followUps);
    const mostRecentFollowUp = getMostRecentFollowUp(followUps);
    if (baselineFollowUp === mostRecentFollowUp) {
      throw new Error(
        "Error in change calculation. Baseline = Most Recent Follow Up"
      );
    }

    const baselineSurvival = baselineFollowUp
      ? getFollowUpSurvivalPrediction(baselineFollowUp)
      : null;
    const baselineBurden = baselineFollowUp
      ? getFollowUpTumourBurden(baselineFollowUp)
      : null;

    const recentSurvival = mostRecentFollowUp
      ? getFollowUpSurvivalPrediction(mostRecentFollowUp)
      : null;
    const recentBurden = mostRecentFollowUp
      ? getFollowUpTumourBurden(mostRecentFollowUp)
      : null;

    if (
      baselineFollowUp === null ||
      mostRecentFollowUp === null ||
      baselineSurvival === null ||
      baselineBurden === null ||
      recentSurvival === null ||
      recentBurden === null
    ) {
      continue;
    }

    const { order: mostRecentFollowUpOrder } = mostRecentFollowUp;
    const mostRecentFollowUpTime = getFollowUpInMonths(mostRecentFollowUpOrder);

    const survivalChange =
      100 *
      ((recentSurvival + mostRecentFollowUpTime - baselineSurvival) /
        Math.abs(baselineSurvival));
    const burdenChange =
      100 * ((recentBurden - baselineBurden) / Math.abs(baselineBurden));

    subjectsData.push({
      id,
      subjectId,
      trialArm,
      data: {
        [RECIST]: burdenChange,
        [IPRO]: survivalChange,
      },
    });
  }

  return subjectsData;
};

export default ParseMortalityBaselineChartData;
