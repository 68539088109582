import React, { forwardRef } from "react";
import { Table, TableControlsType, TableProps } from "../Table/Table";
import { StudyTableRowType } from "./StudyTableRowType";

export const StudyTableWithRef = forwardRef<
  TableControlsType,
  TableProps<StudyTableRowType>
>((props, ref) => {
  return <Table {...props} forwardedRef={ref} />;
});
StudyTableWithRef.displayName = "StudyTableWithRef";
