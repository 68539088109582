import { useSelector } from "react-redux";
import { getTrialViewFilters } from "../../../common/store/trialViewFiltersSlice";
import { TrialArmFilters } from "../../../common/types/TrialViewFilterTypes";

export function useFilteredTrialArmIds(): number[] {
  const filters = useSelector(getTrialViewFilters);

  const trialArmsIds: number[] = [];

  let count = 0;
  const trialArmFilter = filters.trialArm;
  for (const trialArmFilterKey in trialArmFilter) {
    if (
      !Object.prototype.hasOwnProperty.call(trialArmFilter, trialArmFilterKey)
    ) {
      continue;
    }

    if (trialArmFilter[trialArmFilterKey as keyof TrialArmFilters]) {
      trialArmsIds.push(count);
    }
    count++;
  }

  return trialArmsIds;
}
