import React from "react";
import { FC } from "react";
import DashboardIcon from "@material-ui/icons/Dashboard";
import styled from "styled-components";

const Styled = styled(DashboardIcon)`
  color: ${(props) => props.theme.colors.primary.inverted};
`;

export const OverviewIcon: FC = () => <Styled />;
