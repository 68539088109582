import React, { ReactElement } from "react";
import styled from "styled-components";

export const TooltipItemWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 4px;

  font-style: normal;
  font-size: 11px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.neutral.white80};
`;

const TooltipCircle = styled.div`
  height: 12px;
  width: 12px;
  margin-right: 4px;
  background-color: ${(props: { backgroundColor: string }) =>
    props.backgroundColor};
  border-radius: 50%;
  display: inline-block;
`;

export const TooltipItemLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TooltipValue = styled.div`
  margin-left: 12px;
`;

interface TooltipItemProps {
  data: any;
  // eslint-disable-next-line no-unused-vars
  formatValue: (value: string | number) => string;
}

export function TooltipItem({
  data,
  formatValue,
}: TooltipItemProps): ReactElement {
  const { name, color, value } = data;

  return (
    <TooltipItemWrapper>
      <TooltipItemLabelWrapper>
        <TooltipCircle backgroundColor={color} />
        {name}
      </TooltipItemLabelWrapper>
      <TooltipValue>{formatValue(value)}</TooltipValue>
    </TooltipItemWrapper>
  );
}
