import React, { FC, useState } from "react";
import { Redirect, Route, Router, Switch, useRouteMatch } from "react-router";
import { history } from "../common/store/history";
import { SITES, TEAM_MEMBERS } from "./routes/routePaths";
import { TrialContent } from "../Trial/TrialContent";
import { TitleTextWIcon } from "../common/components/TopBar/TitleTextWIcon";
import { TopBar } from "../common/components/TopBar/TopBar";
import { SvgIcon } from "../common/components/icons/SvgIcon";
import { ReactComponent as SettingsIcon } from "../assets/svgs/Settings.svg";
import {
  manageCurrentUserContext,
  manageTrialUserContext,
  UserManagementContextProvider,
} from "../common/components/UserManagement/UserManagementContext";
import { UserManagementCard } from "./UserManagementCard";
import { SiteManagementCard } from "./SiteManagementCard";
import { useCan } from "../common/components/Can/useCan";
import { MANAGER } from "../common/types/UserRoleType";
import {
  manageTrialSiteContext,
  SiteManagementContext,
  viewTrialSiteContext,
} from "../common/components/SiteManagement/SiteManagementContext";
import { UserType } from "../common/types/UserType";

export const Settings: FC = () => {
  const [users, setUsers] = useState<UserType[]>([]);
  const { path } = useRouteMatch();

  const { can: isManager } = useCan([MANAGER]);

  const userManagementContext = isManager
    ? manageTrialUserContext
    : manageCurrentUserContext;

  const siteManagementContext = isManager
    ? manageTrialSiteContext
    : viewTrialSiteContext;

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/${TEAM_MEMBERS}`} />
        </Route>
        <Route path={`${path}/${TEAM_MEMBERS}`}>
          <TrialContent
            topBar={<SettingsTopBar />}
            mainContent={
              <UserManagementContextProvider
                rules={userManagementContext}
                users={users}
                setUsers={setUsers}
              >
                <UserManagementCard />
              </UserManagementContextProvider>
            }
          />
        </Route>
        <Route path={`${path}/${SITES}`}>
          <TrialContent
            topBar={<SettingsTopBar />}
            mainContent={
              <SiteManagementContext.Provider value={siteManagementContext}>
                <SiteManagementCard />
              </SiteManagementContext.Provider>
            }
          />
        </Route>
        <Redirect to={path} />
      </Switch>
    </Router>
  );
};

const SettingsTopBar: FC = () => {
  return (
    <TopBar
      titleContent={
        <TitleTextWIcon
          icon={<SvgIcon icon={SettingsIcon} size={24} />}
          title="Settings"
        />
      }
    />
  );
};
