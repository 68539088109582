import React, { ReactElement } from "react";
import TrialStatus from "./TrialStatus";
import ItemText from "../../common/components/text/ItemTextSize16";
import ItemSubtext from "../../common/components/text/ItemSubtext";
import { getMonthDateTime } from "../../common/utils/dateFormatUtils/getMonthDateTime";
import { ClickableTrialWrapper } from "./ClickableTrialWrapper";
import { TrialType } from "../../common/types/TrialType";
import styled from "styled-components";
const Wrapper = styled.div`
  margin-bottom: 14px;
  font-style: normal;
`;
const CommonCellDiv = styled.div`
  display: flex;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
`;
const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
`;
const StyledTrialName = styled(CommonCellDiv)`
  flex-basis: 20%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1b1f20;
`;
const StyledInvestigation = styled(CommonCellDiv)`
  flex-basis: 20%;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #1b1f20;
`;
const StyledCompletion = styled(CommonCellDiv)`
  flex-basis: 20%;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #1b1f20;
`;
const StyledStatus = styled(CommonCellDiv)`
  flex-basis: 15%;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #1b1f20;
`;
const StyledIndication = styled(CommonCellDiv)`
  flex-basis: 20%;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #1b1f20;
`;
const AllTrial = ({
  id,
  name,
  status,
  estimatedCompletion,
  investigationName,
  indicationName,
}: TrialType): ReactElement => {
  return (
    <Wrapper>
      <ClickableTrialWrapper trialId={id}>
        <StyledDiv>
          <StyledTrialName>
            <ItemText text={name} />
          </StyledTrialName>
          <StyledIndication>
            <ItemSubtext text={indicationName} />
          </StyledIndication>
          <StyledInvestigation>
            <ItemSubtext text={investigationName} />
          </StyledInvestigation>
          <StyledStatus>
            <TrialStatus status={status} />
          </StyledStatus>
          <StyledCompletion>
            <ItemSubtext text={getMonthDateTime(estimatedCompletion)} />
          </StyledCompletion>
        </StyledDiv>
      </ClickableTrialWrapper>
    </Wrapper>
  );
};
export default AllTrial;
