import React, { FC } from "react";
import { Redirect, Route, Router, Switch, useRouteMatch } from "react-router";
import { history } from "../common/store/history";
import AnalysisAlgorithm from "./Algorithm/components/AnalysisAlgorithm";
import {
  ANALYSIS_ALGORITHM,
  ANALYSIS_KAPLAN_DETAILS,
  ANALYSIS_MORTALITY_DETAILS,
  ANALYSIS_OVERVIEW,
  ANALYSIS_PATIENT_JOURNEY,
  ANALYSIS_SURVIVAL_DETAILS,
} from "./routes/routePaths";
import { TrialContent } from "../Trial/TrialContent";
import { TopBar } from "../common/components/TopBar/TopBar";
import ToolPanelContainer from "./ToolPanel/components/ToolPanelContainer";
import { TopBarBack } from "../common/components/TopBar/TopBarBack";
import { useGoToTrialViewPage } from "../Trial/hooks/useGoToTrialViewPage";
import { ANALYSIS } from "../common/routes/routePaths";
import { KaplanMeierCurveCard } from "./KaplanMeier/components/KaplanMeierCurveCard";
import { MedianSurvivalPredictionCard } from "./MedianSurvival/components/MedianSurvivalPredictionCard";
import { ChangeInMortalityVsBaselineCard } from "./ChangeFromBaseline/components/ChangeInMortalityVsBaselineCard";
import { PatientJourneyCard } from "./PatientJourney/components/PatientJourneyCard";
import { StudyTableCard } from "./common/components/StudyTableCard";
import { SubjectDetailsCard } from "./common/components/SubjectDetailsCard";
import { PatientLongitudinalGraphCard } from "./Longitudinal/components/PatientLongitudinalGraphCard";
import { AnalysisIcon } from "../common/components/icons/AnalysisIcon";
import { TitleTextWIcon } from "../common/components/TopBar/TitleTextWIcon";
import DownloadReportButton from "../common/components/buttons/DownloadReportButton";
import { AnalysisOverview } from "./AnalysisOverview";

const topBarButtons = [
  <DownloadReportButton text={"Download Report"} key={1} />,
];

const AnalysisOverviewTopBar: FC = () => {
  return (
    <TopBar
      titleContent={<TitleTextWIcon icon={<AnalysisIcon />} title="Analysis" />}
      buttons={topBarButtons}
    />
  );
};

const AnalysisBackTopBar: FC = () => {
  const { goToPage } = useGoToTrialViewPage();

  const handleClickBack = () => {
    goToPage(ANALYSIS);
  };

  return <TopBarBack onClickBack={handleClickBack} buttons={topBarButtons} />;
};

const AlgorithmsBackTopBar: FC = () => {
  const { goToPage } = useGoToTrialViewPage();

  const handleClickBack = () => {
    goToPage(ANALYSIS);
  };

  return <TopBarBack onClickBack={handleClickBack} />;
};

export const Analysis: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/${ANALYSIS_OVERVIEW}`} />
        </Route>
        <Route path={`${path}/${ANALYSIS_OVERVIEW}`}>
          <TrialContent
            topBar={<AnalysisOverviewTopBar />}
            mainContent={<AnalysisOverview />}
            sideBar={<ToolPanelContainer />}
          />
        </Route>
        <Route path={`${path}/${ANALYSIS_ALGORITHM}`}>
          <TrialContent
            topBar={<AlgorithmsBackTopBar />}
            mainContent={<AnalysisAlgorithm />}
          />
        </Route>
        <Route path={`${path}/${ANALYSIS_KAPLAN_DETAILS}`}>
          <TrialContent
            topBar={<AnalysisBackTopBar />}
            mainContent={
              <>
                <KaplanMeierCurveCard />
                <StudyTableCard />
              </>
            }
            sideBar={<ToolPanelContainer />}
          />
        </Route>
        <Route path={`${path}/${ANALYSIS_SURVIVAL_DETAILS}`}>
          <TrialContent
            topBar={<AnalysisBackTopBar />}
            mainContent={
              <>
                <MedianSurvivalPredictionCard />
                <StudyTableCard />
              </>
            }
            sideBar={<ToolPanelContainer />}
          />
        </Route>
        <Route path={`${path}/${ANALYSIS_MORTALITY_DETAILS}`}>
          <TrialContent
            topBar={<AnalysisBackTopBar />}
            mainContent={
              <>
                <ChangeInMortalityVsBaselineCard />
                <StudyTableCard />
              </>
            }
            sideBar={<ToolPanelContainer />}
          />
        </Route>
        <Route path={`${path}/${ANALYSIS_PATIENT_JOURNEY}`}>
          <TrialContent
            topBar={<AnalysisBackTopBar />}
            mainContent={
              <>
                <PatientJourneyCard />
                <StudyTableCard />
              </>
            }
            sideBar={<ToolPanelContainer />}
          />
        </Route>
        <Route path={`${path}/:subjectId/${ANALYSIS_PATIENT_JOURNEY}`}>
          <TrialContent
            topBar={<AnalysisBackTopBar />}
            mainContent={
              <>
                <SubjectDetailsCard />
                <PatientLongitudinalGraphCard />
              </>
            }
          />
        </Route>
        <Redirect to={path} />
      </Switch>
    </Router>
  );
};
