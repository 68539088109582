export const PROGRESSIVE_DISEASE = "PD";
export const STABLE_DISEASE = "SD";
export const COMPLETE_RESPONSE = "CR";
export const PARTIAL_RESPONSE = "PR";
export const NOT_ESTIMATABLE = "NE";
export const MISSING = "N/A";

export type TumourResponseType =
  | typeof PROGRESSIVE_DISEASE
  | typeof STABLE_DISEASE
  | typeof COMPLETE_RESPONSE
  | typeof PARTIAL_RESPONSE
  | typeof NOT_ESTIMATABLE
  | typeof MISSING;

export const DISPLAY_NAMES: Record<TumourResponseType, string> = {
  [MISSING]: "Missing",
  [COMPLETE_RESPONSE]: "Complete Response",
  [NOT_ESTIMATABLE]: "Not Estimatable",
  [PROGRESSIVE_DISEASE]: "Progressive Disease",
  [PARTIAL_RESPONSE]: "Partial Response",
  [STABLE_DISEASE]: "Stable Disease",
};
