import React, { FC } from "react";
import { Card } from "../../../common/components/cards/Card";
import { GraphCardProps } from "../../common/utils/GraphCardProps";
import AnalysisOverviewPatientJourney from "./AnalysisOverviewPatientJourney";

export const PatientJourneyCard: FC<GraphCardProps> = ({
  onClickHeader,
}: GraphCardProps) => {
  return (
    <Card
      title={"Patient Journey"}
      content={<AnalysisOverviewPatientJourney />}
      onClickHeader={onClickHeader}
    />
  );
};
