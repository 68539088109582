import React, { useMemo, useState } from "react";
import { FormDialog, FormDialogProps } from "./FormDialog";
import { useDialogReturnType } from "./useDialog";

export function useFormDialog<T>({
  children: Form,
  props,
  label,
  showCloseButton,
}: FormDialogProps<T>): useDialogReturnType {
  const [open, setOpen] = useState<boolean>(false);

  const dialog = useMemo(() => {
    return (
      <FormDialog
        open={open}
        setOpen={setOpen}
        props={props}
        label={label}
        showCloseButton={showCloseButton}
      >
        {({ props, onSubmit, onCancel }) => (
          <Form props={props} onSubmit={onSubmit} onCancel={onCancel} />
        )}
      </FormDialog>
    );
  }, [open, Form]);

  return [setOpen, { dialog }];
}
