export type RawTrialAlgorithmsType = {
  model: {
    name: string;
    created_at: string;
    survival_predictions_aggregate: {
      aggregate: {
        max: {
          last_modified: string | null;
        };
      };
    };
  }[];
};

export interface ModifiedRunJobProp {
  name: string;
  createdOn: string;
  lastRun: string | null;
}

export function parseAlgorithmsToRunJobs(
  data: RawTrialAlgorithmsType
): ModifiedRunJobProp[] {
  const { model } = data;
  return model.map((algorithm) => {
    const {
      name,
      created_at: createdOn,
      survival_predictions_aggregate: {
        aggregate: {
          max: { last_modified: lastRun },
        },
      },
    } = algorithm;

    return {
      name,
      createdOn,
      lastRun,
    };
  });
}
