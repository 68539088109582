import { main } from "../theme/main";

export const ADD = "ADD";
export const REMOVE = "REMOVE";

export type UpdateModeType = typeof ADD | typeof REMOVE;

export const updateModeColors: Record<UpdateModeType, string> = {
  [ADD]: main.colors.states.success,
  [REMOVE]: main.colors.states.error,
};
