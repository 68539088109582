import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EcogFilters,
  HistologyFilters,
  Recist1_1Filters,
  StageFilters,
  TimepointsFilters,
  TrialArmFilters,
  TumourMutationsFilters,
} from "../types/TrialViewFilterTypes";
import {
  BmiFilters,
  LifestyleFilters,
  SmokingStatusFilters,
} from "../types/LifestyleFilterTypes";
import {
  AgeFilters,
  DemographicsFilters,
  EthnicityFilters,
  SexFilters,
} from "../types/DemographicsFilterTypes";
import { RootState } from "./rootReducer";
import {
  clearTrialArmsState,
  defaultTrialArmsState,
} from "./selectors/trialArmSelectors";
import {
  clearDemographicsState,
  defaultDemographicsState,
} from "./selectors/demographicsSelectors";
import {
  clearTimepointsState,
  defaultTimepointsState,
} from "./selectors/timePointSelectors";
import {
  clearResponseState,
  defaultResponseState,
} from "./selectors/tumourBurdenSelectors";
import {
  clearLifestyleState,
  defaultLifestyleState,
} from "./selectors/lifestyleSelectors";
import {
  clearStageState,
  defaultStageState,
} from "./selectors/ajccStageSelectors";
import {
  clearHistologyState,
  defaultHistologyState,
} from "./selectors/histologySelectors";
import {
  clearMutationsState,
  defaultMutationsState,
} from "./selectors/mutationSelectors";
import { clearEcogState, defaultEcogState } from "./selectors/egocSelectors";

export interface FilterTypes {
  timepoints: TimepointsFilters;
  trialArm: TrialArmFilters;
  demographics: DemographicsFilters;
  histology: HistologyFilters;
  tumourMutations: TumourMutationsFilters;
  lifestyle: LifestyleFilters;
  ecog: EcogFilters;
  recist1_1: Recist1_1Filters;
  stage: StageFilters;
  currentTrial: number | null;
}

const initialState: FilterTypes = {
  timepoints: defaultTimepointsState,
  trialArm: defaultTrialArmsState,
  demographics: defaultDemographicsState,
  histology: defaultHistologyState,
  tumourMutations: defaultMutationsState,
  lifestyle: defaultLifestyleState,
  ecog: defaultEcogState,
  recist1_1: defaultResponseState,
  stage: defaultStageState,
  currentTrial: null,
};

const clearState: FilterTypes = {
  timepoints: clearTimepointsState,
  trialArm: clearTrialArmsState,
  demographics: clearDemographicsState,
  histology: clearHistologyState,
  tumourMutations: clearMutationsState,
  lifestyle: clearLifestyleState,
  ecog: clearEcogState,
  recist1_1: clearResponseState,
  stage: clearStageState,
  currentTrial: null,
};

export const trialViewFiltersSlice = createSlice({
  name: "trialViewFilters",
  initialState,
  reducers: {
    setTimepoints(state, action: PayloadAction<TimepointsFilters>) {
      state.timepoints = action.payload;
    },
    resetTimepoints(state) {
      state.timepoints = defaultTimepointsState;
    },
    clearTimepoints(state) {
      state.timepoints = clearTimepointsState;
    },
    setTrialArm(state, action: PayloadAction<TrialArmFilters>) {
      state.trialArm = action.payload;
    },
    resetTrialArms(state) {
      state.trialArm = defaultTrialArmsState;
    },
    clearTrialArms(state) {
      state.trialArm = clearTrialArmsState;
    },
    setSex(state, action: PayloadAction<SexFilters>) {
      state.demographics.sex = action.payload;
    },
    toggleSex(state, action: PayloadAction<keyof SexFilters>) {
      state.demographics.sex[action.payload] = !state.demographics.sex[
        action.payload
      ];
    },
    setAge(state, action: PayloadAction<AgeFilters>) {
      state.demographics.age = action.payload;
    },
    setHistology(state, action: PayloadAction<HistologyFilters>) {
      state.histology = action.payload;
    },
    resetHistology(state) {
      state.histology = defaultHistologyState;
    },
    clearHistology(state) {
      state.histology = clearHistologyState;
    },
    setTumourMutations(state, action: PayloadAction<TumourMutationsFilters>) {
      state.tumourMutations = action.payload;
    },
    resetTumourMutations(state) {
      state.tumourMutations = defaultMutationsState;
    },
    clearTumourMutations(state) {
      state.tumourMutations = clearMutationsState;
    },
    toggleTumourMutation(
      state,
      action: PayloadAction<keyof TumourMutationsFilters>
    ) {
      state.tumourMutations[action.payload] = !state.tumourMutations[
        action.payload
      ];
    },
    setSmokingStatus(state, action: PayloadAction<SmokingStatusFilters>) {
      state.lifestyle.smokingStatus = action.payload;
    },
    toggleSmokingStatus(
      state,
      action: PayloadAction<keyof SmokingStatusFilters>
    ) {
      state.lifestyle.smokingStatus[action.payload] = !state.lifestyle
        .smokingStatus[action.payload];
    },
    setEthnicity(state, action: PayloadAction<EthnicityFilters>) {
      state.demographics.ethnicity = action.payload;
    },
    toggleEthnicity(state, action: PayloadAction<keyof EthnicityFilters>) {
      state.demographics.ethnicity[action.payload] = !state.demographics
        .ethnicity[action.payload];
    },
    setEcog(state, action: PayloadAction<EcogFilters>) {
      state.ecog = action.payload;
    },
    toggleEcog(state, action: PayloadAction<number>) {
      state.ecog[action.payload] = !state.ecog[action.payload];
    },
    setBmi(state, action: PayloadAction<BmiFilters>) {
      state.lifestyle.bmi = action.payload;
    },
    setRecist1_1(state, action: PayloadAction<Recist1_1Filters>) {
      state.recist1_1 = action.payload;
    },
    resetRecist1_1(state) {
      state.recist1_1 = defaultResponseState;
    },
    clearRecist1_1(state) {
      state.recist1_1 = clearResponseState;
    },
    setStage(state, action: PayloadAction<StageFilters>) {
      state.stage = action.payload;
    },
    resetStage(state) {
      state.stage = defaultStageState;
    },
    clearStage(state) {
      state.stage = clearStageState;
    },
    setAjccStage(state, action: PayloadAction<StageFilters["ajccStage"]>) {
      state.stage.ajccStage = action.payload;
    },
    toggleTStage(state, action: PayloadAction<keyof StageFilters["tStages"]>) {
      state.stage.tStages[action.payload] = !state.stage.tStages[
        action.payload
      ];
    },
    setCurrentTrial(state, { payload: trialId }: PayloadAction<number>) {
      state.currentTrial = trialId;
    },
    setAllFilters(state, action: PayloadAction<FilterTypes>) {
      return action.payload;
    },
    resetDemographics(state) {
      state.demographics = defaultDemographicsState;
    },
    clearDemographics(state) {
      state.demographics = clearDemographicsState;
    },
    resetLifestyle(state) {
      state.lifestyle = defaultLifestyleState;
    },
    clearLifestyle(state) {
      state.lifestyle = clearLifestyleState;
    },
    resetEcog(state) {
      state.ecog = defaultEcogState;
    },
    clearEcog(state) {
      state.ecog = clearEcogState;
    },
    selectAll() {
      return initialState;
    },
    clearAll() {
      return clearState;
    },
  },
});

export const trialViewFiltersActions = trialViewFiltersSlice.actions;

// Graphs using filters may want to create their own selectors, rather than using the filters object directly;
// i.e., using reselect's createSelector (also included in Redux). This will allow the component to re-render only for relevant filter changes.
export const getTrialViewFilters = (state: RootState): FilterTypes =>
  state.trialViewFilters;

export const getDemographicsFilters = createSelector(
  [getTrialViewFilters],
  (trialViewFilters) => trialViewFilters.demographics
);

export const getLifestyleFilters = createSelector(
  [getTrialViewFilters],
  (trialViewFilters) => trialViewFilters.lifestyle
);

const allSelected = (state: RootState): boolean =>
  JSON.stringify(state.trialViewFilters) === JSON.stringify(initialState);

const noneSelected = (state: RootState): boolean =>
  JSON.stringify(state.trialViewFilters) === JSON.stringify(clearState);

export const allFiltersSelected = createSelector(
  [allSelected],
  (allSelected) => allSelected
);

export const noFiltersSelected = createSelector(
  [noneSelected],
  (noSelected) => noSelected
);
