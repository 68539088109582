import React, { FC } from "react";
import TrialDetailsPanel from "./TrialDetailsPanel";
import { useTrialId } from "../../../Trial/hooks/useTrialId";
import { useQuery } from "@apollo/client";
import Loading from "../Loading";
import handleApolloError from "../../utils/handleApolloError";
import { GET_TRIAL_DETAILS_BY_IDS } from "../../queries/GetTrialDetailsByIds";
import { RawTrialDetailsType } from "../../types/RawTrialDetailsType";
import parseTrialDetailsData from "./parseTrialDetailsData";

export const TrialDetailsPanelContainer: FC = () => {
  const trialId = useTrialId();
  const { data, loading, error } = useQuery<RawTrialDetailsType>(
    GET_TRIAL_DETAILS_BY_IDS,
    {
      variables: { trialIds: [trialId] },
    }
  );

  if (loading || !data) return <Loading />;
  if (error) handleApolloError(error);
  const trialDetails = parseTrialDetailsData(data);
  return <TrialDetailsPanel details={trialDetails} />;
};
