import React from "react";
import { ManagementRow } from "../ManagementRow";
import { SiteType } from "../../types/SiteType";
import { useRemoveSiteFromTrialDialog } from "./Dialogs/useRemoveSiteFromTrialDialog";
import { useEditSiteDialog } from "./Dialogs/useEditSiteDialog";
import { SiteLabel } from "./SiteLabel";
import { SiteActionCallbacksType } from "./SiteActionCallbacksType";

interface SiteRowProps {
  site: SiteType;
  actionCallbacks: SiteActionCallbacksType;
  allowEditSite: boolean;
  allowRemoveSite: boolean;
}

export function SiteRow({
  site,
  actionCallbacks,
  allowEditSite,
  allowRemoveSite,
}: SiteRowProps): JSX.Element {
  const { onRemoved } = actionCallbacks ?? {
    onRemoved: undefined,
  };

  const handleConfirmRemoveSite = (site: SiteType) => {
    if (!allowRemoveSite) {
      throw new Error(
        "handleConfirmRemoveSite called but allowRemoveSite is true"
      );
    }

    onRemoved?.(site);
  };

  const [
    setShowRemoveSiteDialog,
    { dialog: removeSiteDialog },
  ] = useRemoveSiteFromTrialDialog(site, handleConfirmRemoveSite);

  const [setShowEditSiteDialog, { dialog: editSiteDialog }] = useEditSiteDialog(
    site,
    actionCallbacks
  );

  const handleClickEditSite = () => {
    setShowEditSiteDialog(true);
  };

  const handleClickRemoveSite = () => {
    setShowRemoveSiteDialog(true);
  };

  const { name } = site;
  return (
    <>
      {removeSiteDialog}
      {editSiteDialog}
      <ManagementRow<SiteType>
        object={site}
        onEdit={allowEditSite ? handleClickEditSite : undefined}
        onDelete={allowRemoveSite ? handleClickRemoveSite : undefined}
        content={<SiteLabel name={name} />}
      />
    </>
  );
}
