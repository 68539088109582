import React, { FC } from "react";
import KaplanChart from "./KaplanChart";
import Loading from "../../../common/components/Loading";
import handleApolloError from "../../../common/utils/handleApolloError";
import { useTrialId } from "../../../Trial/hooks/useTrialId";
import { useTimepointFilteredSubjectsQuery } from "../../common/hooks/useTimepointFilteredSubjectsQuery";
import {
  generateKaplanMeierData,
  KaplanMeierDataPoint,
} from "../utils/generateKaplanMeierData";
import { kaplanMeierAttributes } from "./kaplanMeierAttributes";

export const KaplanChartContainer: FC = () => {
  const trialId = useTrialId();
  const { subjects, loading, error } = useTimepointFilteredSubjectsQuery(
    trialId
  );

  if (loading) return <Loading />;
  if (error) handleApolloError(error);
  if (!subjects) throw new Error("Subject null with no apollo error");

  const kaplanMeierData = generateKaplanMeierData(subjects);
  return (
    <KaplanChart data={kaplanMeierData} attributes={kaplanMeierAttributes} />
  );
};

function getSample(): KaplanMeierDataPoint[] {
  return [
    {
      x: 0,
      0: {
        predicted: {
          x: 0,
          value: 1,
          range: { min: 1, max: 1 },
        },
        observed: {
          x: 0,
          value: 1,
          range: { min: 1, max: 1 },
        },
      },
      1: {
        predicted: {
          x: 0,
          value: 1,
          range: { min: 1, max: 1 },
        },
        observed: {
          x: 0,
          value: 1,
          range: { min: 1, max: 1 },
        },
      },
    },
    {
      x: 1,
      0: {
        predicted: {
          x: 1,
          value: 0.5,
          range: { min: 0.75, max: 0.25 },
        },
        observed: {
          x: 1,
          value: 0.75,
          range: { min: 0.5, max: 1 },
        },
      },
      1: {
        predicted: {
          x: 1,
          value: 0.5,
          range: { min: 0.75, max: 0.25 },
        },
        observed: {
          x: 1,
          value: 0.75,
          range: { min: 0.5, max: 1 },
        },
      },
    },
    {
      x: 2,
      0: {
        predicted: {
          x: 2,
          value: 0,
          range: { min: 0, max: 0.25 },
        },
        observed: {
          x: 2,
          value: 0,
          range: { min: 0, max: 0.1 },
        },
      },
      1: {
        predicted: {
          x: 2,
          value: 0,
          range: { min: 0, max: 0.25 },
        },
        observed: {
          x: 2,
          value: 0,
          range: { min: 0, max: 0.1 },
        },
      },
    },
  ];
}
