import React from "react";
import ToggleSwitch from "../../../common/components/ToggleSwitch";
import styled from "styled-components";
import { useTrialId } from "../../../Trial/hooks/useTrialId";
import { useMutation, useQuery } from "@apollo/client";
import { gql } from "@apollo/client/core";
import Loading from "../../../common/components/Loading";
import handleApolloError from "../../../common/utils/handleApolloError";
import { SURVIVAL_PREDICTION } from "./JobNames";
import {
  LUNG_SEGMENTATION,
  LUNG_SURVIVAL_PREDICTION,
  PREPROCESS_IMAGES,
  SKIN_SEGMENTATION,
  SurvivalPredictionTasks,
} from "./TaskNames";
import {
  JobStatusType,
  PENDING,
  STARTED,
  SUCCESS,
} from "../../../common/types/JobStatusType";
import { ModelTaskProgress } from "./ModelTaskProgress";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
`;

const ToggleRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;

  width: 100%;
`;

const ToggleText = styled.div`
  /* H3/Regular */
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;

  display: flex;
  align-items: center;
`;

const GET_TRIAL_USER_SETTINGS_AND_JOB_SUMMARY = gql`
  query GetTrialUserSetting($trial_id: Int!, $job_name: String!) {
    trial_user_settings(where: { trial_id: { _eq: $trial_id } }) {
      settings
    }
    job_tasks_summary(where: { job_name: { _eq: $job_name } }) {
      count
      status
      task_name
    }
  }
`;

const UPSERT_TRIAL_USER_SETTINGS = gql`
  mutation UpsertTrialUserSettings($settings: jsonb!, $trial_id: Int!) {
    insert_trial_user_settings_one(
      object: { settings: $settings, trial_id: $trial_id }
      on_conflict: {
        constraint: trial_user_settings_pkey
        update_columns: settings
      }
    ) {
      settings
    }
  }
`;

const ModelSuiteProgress = () => {
  const trialId = useTrialId();

  const handleAutoSwitchChanged = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;

    await updateTrialUserSettings({
      variables: {
        trial_id: trialId,
        settings: {
          automaticallyProcessNewData: isChecked,
        },
      },
    });
  };

  const { data, loading, error } = useQuery<RawQueryResult>(
    GET_TRIAL_USER_SETTINGS_AND_JOB_SUMMARY,
    {
      variables: { trial_id: trialId, job_name: SURVIVAL_PREDICTION },
      pollInterval: 500,
    }
  );
  const [updateTrialUserSettings] = useMutation<RawMutationTrialUserSettings>(
    UPSERT_TRIAL_USER_SETTINGS,
    {
      refetchQueries: ["GetTrialUserSetting"],
    }
  );

  if (loading) return <Loading />;
  if (error) handleApolloError(error);

  if (!data) {
    throw new Error("Data is null with no Apollo error");
  }

  const trialSettings = parseTrialSettings(data);
  const automaticallyProcessNewData = trialSettings
    ? trialSettings.automaticallyProcessNewData
    : false;

  const jobTasksSummary = parseJobTasksSummary(data);
  const progress = getSurvivalJobProgress(jobTasksSummary);
  const allJobsDone = getAllJobsDone(progress);
  const currentState = getCurrentTaskInProgress(progress);

  return (
    <Wrapper>
      <ModelTaskProgress state={currentState} isDone={allJobsDone} />
      <ToggleRowWrapper>
        <ToggleText> Automatically Process New Data </ToggleText>
        <ToggleSwitch
          checked={automaticallyProcessNewData}
          onChange={handleAutoSwitchChanged}
        />
      </ToggleRowWrapper>
    </Wrapper>
  );
};

export default ModelSuiteProgress;

type RawTrialUserSettings = { settings: Record<string, unknown> };
type RawJobTasksSummary = {
  count: number;
  status: string;
  task_name: string;
};

type RawQueryResult = {
  trial_user_settings: RawTrialUserSettings[];
  job_tasks_summary: RawJobTasksSummary[];
};

type RawMutationTrialUserSettings = {
  insert_trial_user_settings_one: RawTrialUserSettings[];
};

type TrialUserSettings = {
  automaticallyProcessNewData: boolean;
};

type SurvivalPredictionJobTasksSummary = {
  count: number;
  status: JobStatusType;
  taskName: SurvivalPredictionTasks;
}[];

function parseTrialSettings(data: RawQueryResult): TrialUserSettings | null {
  if (data.trial_user_settings.length === 0) {
    return null;
  }

  const rawTrialSettings = data.trial_user_settings.map(
    (trial) => trial.settings
  )[0];
  const { automaticallyProcessNewData } = rawTrialSettings;

  return {
    automaticallyProcessNewData: automaticallyProcessNewData as boolean,
  };
}

function parseJobTasksSummary(
  data: RawQueryResult
): SurvivalPredictionJobTasksSummary {
  return data.job_tasks_summary.map((job_task) => {
    const { task_name, status, count } = job_task;
    return {
      taskName: task_name as SurvivalPredictionTasks,
      status: status as JobStatusType,
      count,
    };
  });
}

function getSurvivalJobProgress(
  summary: SurvivalPredictionJobTasksSummary
): SurvivalJobProgress {
  const progress = {
    [LUNG_SURVIVAL_PREDICTION]: { inProgress: 0, completed: 0 },
    [SKIN_SEGMENTATION]: { inProgress: 0, completed: 0 },
    [LUNG_SEGMENTATION]: { inProgress: 0, completed: 0 },
    [PREPROCESS_IMAGES]: { inProgress: 0, completed: 0 },
  };

  for (const jobTask of summary) {
    const { taskName, status, count } = jobTask;
    if (!progress[taskName]) {
      continue;
    }
    const progressEntry = progress[taskName];
    if ([PENDING, STARTED].includes(status)) {
      progressEntry.inProgress += count;
    } else if (status === SUCCESS) {
      progressEntry.completed += count;
    }
  }

  return progress;
}

function getAllJobsDone(progress: SurvivalJobProgress): boolean | undefined {
  const keys: SurvivalPredictionTasks[] = [
    LUNG_SURVIVAL_PREDICTION,
    SKIN_SEGMENTATION,
    LUNG_SEGMENTATION,
    PREPROCESS_IMAGES,
  ];

  const tasks = keys.map((key) => progress[key]);

  const noJobs = tasks.every(
    (task) => !task.completed && task.inProgress === 0
  );
  if (noJobs) {
    return undefined;
  }

  return !tasks.some((task) => task.inProgress > 0);
}

function getCurrentTaskInProgress(
  progress: SurvivalJobProgress
): SurvivalPredictionTasks | null {
  const keys: SurvivalPredictionTasks[] = [
    PREPROCESS_IMAGES,
    SKIN_SEGMENTATION,
    LUNG_SEGMENTATION,
    LUNG_SURVIVAL_PREDICTION,
  ];

  let state = null;

  for (const key of keys) {
    const numberOfTasks = progress[key].inProgress;
    if (numberOfTasks > 0) {
      state = key;
      break;
    }
  }

  return state;
}

type SurvivalJobProgress = Record<
  SurvivalPredictionTasks,
  { inProgress: number; completed: number }
>;
