import React, { useContext, useEffect } from "react";
import { UserType } from "../common/types/UserType";
import { Card } from "../common/components/cards/Card";
import { ContextAwareEditTrialUsers } from "../common/components/UserManagement/ContextAwareEditTrialUsers";
import handleApolloError from "../common/utils/handleApolloError";
import { useTrialUsers } from "./useTrialUsers";
import { annotateClient } from "../apollo/client";
import { useUpdateUserMetadata } from "../common/components/UserManagement/useUpdateUserMetadata";
import { useDeleteUserGroup } from "../common/components/UserManagement/useDeleteUserGroup";
import { UserManagementContext } from "../common/components/UserManagement/UserManagementContext";
import { useSendResetPasswordEmail } from "../common/components/UserManagement/useSendResetPasswordEmail";
import { insertUsersIntoTrial } from "../common/components/UserManagement/insertUsersIntoTrial";
import Loading from "../common/components/Loading";
import { createNewUsers } from "../common/components/UserManagement/createNewUsers";
import { useTrialId } from "../Trial/hooks/useTrialId";
import { useCurrentTrial } from "../common/queries/useCurrentTrial";

export function UserManagementCard(): JSX.Element {
  const { setUsers } = useContext(UserManagementContext);

  const { data: trial, error, loading } = useCurrentTrial();
  const trialId = useTrialId();

  const [
    deleteUserGroup,
    { error: deleteUserGroupError, loading: deleteUserGroupLoading },
  ] = useDeleteUserGroup();

  const [
    updateUserMetadata,
    { loading: updateUserMetadataLoading, error: updateUserMetadataError },
  ] = useUpdateUserMetadata();

  const [
    resetUserPassword,
    { error: resetUserPasswordError, loading: resetUserPasswordLoading },
  ] = useSendResetPasswordEmail();

  const {
    data: trialUsers,
    loading: trialUsersLoading,
    refetch: refetchTrialUsers,
  } = useTrialUsers(trialId);

  useEffect(() => {
    if (!trialUsers || trialUsersLoading) {
      setUsers([]);
      return;
    }
    setUsers(trialUsers);
  }, [trialUsers, trialUsersLoading]);

  if (loading) return <Loading />;
  if (error) handleApolloError(error);
  if (deleteUserGroupLoading) return <Loading />;
  if (deleteUserGroupError) handleApolloError(deleteUserGroupError);
  if (updateUserMetadataLoading) return <Loading />;
  if (updateUserMetadataError) handleApolloError(updateUserMetadataError);
  if (resetUserPasswordLoading) return <Loading />;
  if (resetUserPasswordError) handleApolloError(resetUserPasswordError);

  const handleUsersCreated = async (newUsers: UserType[]) => {
    if (!trial) {
      throw new Error("trial is undefined in handleUsersCreated");
    }

    const allUsers = await createNewUsers(newUsers);

    await insertUsersIntoTrial(allUsers, trial, annotateClient);

    await refetchTrialUsers();
  };

  const handleUserRemoved = async (user: UserType) => {
    if (!trial) {
      throw new Error("trial is undefined in handleUsersCreated");
    }

    const { id: trialId } = trial;

    await deleteUserGroup({
      user,
      trialId,
    });
    await refetchTrialUsers();
  };

  const handleUserEdited = async (user: UserType) => {
    await updateUserMetadata(user, {});
    await refetchTrialUsers();
  };

  const handleUserPasswordReset = async (user: UserType) => {
    await resetUserPassword(user);
  };

  return (
    <Card
      title={"Team Members"}
      content={
        <ContextAwareEditTrialUsers
          actionCallbacks={{
            onRemoved: handleUserRemoved,
            onCreated: handleUsersCreated,
            onEdited: handleUserEdited,
            onPasswordReset: handleUserPasswordReset,
          }}
        />
      }
      noMenu={true}
    />
  );
}
