import React from "react";
import styled from "styled-components";
import CircularProgressBar from "./CircularProgressBar";

interface StatusIndicatorProps {
  status: string;
  color: string;
  isBusy: boolean;
}

interface StyledDotProps {
  color: string;
}

const Wrapper = styled.div`
  display: inline-block;
  white-space: nowrap;
`;

const StyledDot = styled.div<StyledDotProps>`
  margin-right: 3px;
  height: 8px;
  width: 8px;
  background-color: ${(props) => props.color};
  border-radius: 4px;
  display: inline-block;
`;

const StyledText = styled.div`
  display: inline-block;
  color: ${(props) => props.theme.colors.neutral.neutral3};
  font-size: 13px;
  font-weight: 400;
`;

function StatusIndicator({
  status,
  color,
  isBusy,
}: StatusIndicatorProps): React.ReactElement<
  React.ProgressHTMLAttributes<HTMLProgressElement>
> {
  return isBusy ? (
    <Wrapper>
      <CircularProgressBar color={color} />
      <StyledText>{status}</StyledText>
    </Wrapper>
  ) : (
    <Wrapper>
      <StyledDot color={color} />
      <StyledText>{status}</StyledText>
    </Wrapper>
  );
}

export default StatusIndicator;
