import { Avatar } from "./Avatar";
import React from "react";
import { useUserProfileDialog } from "../UserManagement/Dialogs/useUserProfileDialog";
import config from "../../../auth0/auth_config.json";
import { deleteNonDemoPatients } from "../../utils/demo/deleteNonDemoPatients";
import { useAuth0 } from "@auth0/auth0-react";
import handleApolloError from "../../utils/handleApolloError";
import { useCurrentUser } from "../UserManagement/useCurrentUser";
import { CircularProgress } from "@material-ui/core";
import { main } from "../../theme/main";
import { AltLoading } from "../AltLoading";

interface CurrentUserAvatarProps {
  size?: number;
  showProfileOnClick: boolean;
}

export function CurrentUserAvatar({
  size,
  showProfileOnClick,
}: CurrentUserAvatarProps): JSX.Element {
  const { getAccessTokenSilently } = useAuth0();

  const { data: user, loading, error, refetch } = useCurrentUser();

  const handleUserUpdated = async () => {
    await refetch();
  };

  const [
    setShowProfileDialog,
    { dialog: userProfileDialog },
  ] = useUserProfileDialog(user, handleUserUpdated);

  if (loading) return <AltLoading />;
  if (error) handleApolloError(error);
  if (!user) throw new Error("Current user null with no apollo error");

  const handleClick = () => {
    if (!showProfileOnClick) {
      throw new Error("handleClick called but showProfileOnClick is false");
    }

    setShowProfileDialog(true);
  };

  const handleOnDoubleClick = async () => {
    const token = await getAccessTokenSilently(config);
    await deleteNonDemoPatients(token);
  };

  return (
    <>
      {userProfileDialog}
      <Avatar
        user={user}
        onClick={showProfileOnClick ? handleClick : undefined}
        onDoubleClick={handleOnDoubleClick}
        size={size}
      />
    </>
  );
}
