import React, { FC } from "react";
import styled from "styled-components";
import { TumourResponseType } from "../../common/types/TumourResponseType";
import { tumourResponseColors } from "../../common/utils/tumourResponseColors";

interface WrapperProps {
  background: string;
}

const Wrapper = styled.div`
  padding: 2px 4px 2px 4px;
  border-radius: 4px;
  background: ${(props: WrapperProps) => props.background};
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #ffffff;
`;

interface SubjectProgressionChipProps {
  response: TumourResponseType;
}

export const ProgressionChip: FC<SubjectProgressionChipProps> = ({
  response,
}: SubjectProgressionChipProps) => {
  const color = tumourResponseColors[response];
  return <Wrapper background={color}>{response}</Wrapper>;
};
