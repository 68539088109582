import React, { ReactElement } from "react";
import styled from "styled-components";

interface MediumFilledButtonProps {
  text: string;
  icon?: ReactElement;
  onClick: () => void;
  disabled?: boolean;
}

const ButtonWrapper = styled.button<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px;
  background: ${(props) =>
    props.disabled
      ? props.theme.colors.actionPrimary.disabled
      : props.theme.colors.actionPrimary.default};
  border-radius: 8px;
  border-width: 0;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

const TextAndIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TextWrapper = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.actionPrimary.inverted};

  margin-left: 8px;
`;

const MediumFilledButton = ({
  text,
  icon,
  onClick,
  disabled,
}: MediumFilledButtonProps): ReactElement => {
  const handleOnClick = () => {
    onClick();
  };

  return (
    <ButtonWrapper onClick={handleOnClick} disabled={disabled}>
      <TextAndIconWrapper>
        {icon ? icon : null}
        <TextWrapper>{text}</TextWrapper>
      </TextAndIconWrapper>
    </ButtonWrapper>
  );
};

export default MediumFilledButton;
