import { gql } from "@apollo/client/core";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { UserType } from "../../types/UserType";
import { parsedMutation } from "../../queries/utils/parsedMutation";

const MUTATION = gql`
  mutation CreateUsers($user_fields_list: [CreateUserFields!]!) {
    create_users(user_fields_list: $user_fields_list) {
      internal_id
      auth0_user_id
      email
    }
  }
`;

type Input = { users: UserType[]; tenantId: number };
type Data = {
  create_users: { internal_id: number; auth0_user_id: string; email: string }[];
};
type Variables = {
  user_fields_list: { email: string; tenant_id: number; roles: string[] }[];
};

export async function createUsers(
  users: UserType[],
  tenantId: number,
  client: ApolloClient<NormalizedCacheObject>
): Promise<UserType[]> {
  return await parsedMutation<Input, UserType[], Data, Variables>({
    mutation: MUTATION,
    client,
    options: {
      context: {
        headers: { "x-hasura-role": "manager" },
      },
    },
    input: { users, tenantId },
    parseVariables,
    parseData,
  });
}

function parseVariables(input: Input): Variables {
  const { users, tenantId: tenant_id } = input;
  const user_fields_list = users.map(({ email, roles }) => {
    return {
      email,
      roles,
      tenant_id,
    };
  });
  return { user_fields_list };
}

function parseData(data: Data, input: Input): UserType[] {
  const { users } = input;
  const { create_users: rawUsers } = data;

  const usersWithIds: UserType[] = [];

  for (const rawUser of rawUsers) {
    const match = users.find(({ email }) => email === rawUser.email);
    if (!match) {
      throw new Error("Unable to match created user with email");
    }

    const { internal_id: newId, auth0_user_id: newAuth0Id } = rawUser;
    usersWithIds.push({
      ...match,
      id: newId,
      auth0Id: newAuth0Id,
    });
  }

  return usersWithIds;
}
