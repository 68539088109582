import React from "react";
import styled from "styled-components";

export const Label = styled.label<{ required?: boolean; error?: unknown }>`
  z-index: 1000;
  position: absolute;
  margin: 0 0 0 20px;
  padding: 0 4px;
  background: white;
  font-size: 12px;
  color: ${(props) =>
    props.error
      ? props.theme.colors.states.error
      : props.theme.colors.neutral.black};
  ${({ required }) =>
    required &&
    `
&:after {
  content: "*";
  color: red;
}`};
`;
