import { YAxisProps } from "recharts";
import { ALT_TEXT_OPACITY, TEXT_OPACITY } from "./textOpacity";

export function getYAxisProps(showTicks: boolean): YAxisProps {
  return {
    axisLine: {
      strokeWidth: "0.5px",
      opacity: TEXT_OPACITY,
    },
    tick: showTicks
      ? {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "11px",
          opacity: ALT_TEXT_OPACITY,
        }
      : false,
    tickLine: {
      strokeWidth: "0.5px",
      opacity: TEXT_OPACITY,
    },
    width: showTicks ? 30 : 10,
  };
}
