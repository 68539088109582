import styled from "styled-components";
import React, { ReactElement } from "react";
import { TEXT_OPACITY } from "../utils/textOpacity";

const LegendWrapper = styled.div`
  display: flex;
`;

export const LegendItemWrapper = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: rgba(27, 31, 32, ${() => TEXT_OPACITY});
  padding: 4px;
  margin-right: 8px;
`;

const LegendLineWrapper = styled.div`
  margin-right: 8px;
  width: 17px;
  height: 10px;
`;

interface LegendLineWrapperProps {
  borderStyle: string;
  borderColor: string;
}

const LegendLine = styled.div`
  position: relative;
  top: 3px;
  width: 12px;
  height: 0;
  border: 2px ${(props: LegendLineWrapperProps) => props.borderStyle}
    ${(props: LegendLineWrapperProps) => props.borderColor};
`;

const LegendCircle = styled.div`
  position: relative;
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${(props) => props.color};
`;

const LegendSquare = styled.div`
  height: 12px;
  width: 12px;
  position: relative;
  top: -1px;
  background-color: ${(props) => props.color};
`;

interface LegendProps {
  payload: any;
  valueFormatter?: (value: any) => string;
}

export function Legend({ payload, valueFormatter }: LegendProps): ReactElement {
  const itemsToRender: any[] = payload.filter(
    (item: any) => item.type !== "none"
  );

  const getShape = (entry: any): React.ReactElement => {
    switch (entry.type) {
      case "circle":
        return <LegendCircle color={entry.color} />;
      case "square":
        return <LegendSquare color={entry.color} />;
      default:
        return (
          <LegendLine
            borderColor={entry.color}
            borderStyle={getBorderStyle(entry)}
          />
        );
    }
  };

  const getBorderStyle = (entry: any) => {
    const strokeDasharray = entry.payload?.strokeDasharray;
    if (!strokeDasharray) {
      return "solid";
    }
    return entry.payload.strokeDasharray.endsWith("0") ? "solid" : "dashed";
  };

  return (
    <LegendWrapper>
      {itemsToRender.map((entry, index) => {
        const formattedValue = valueFormatter
          ? valueFormatter(entry.value)
          : entry.value;
        return (
          <LegendItemWrapper key={`item-${index}`}>
            <LegendLineWrapper>{getShape(entry)}</LegendLineWrapper>
            {formattedValue}
          </LegendItemWrapper>
        );
      })}
    </LegendWrapper>
  );
}
