import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./rootReducer";

type SetScrollRestorePayload = ScrollRestoreDatum & {
  path: string;
  elementName: string;
};

export interface ScrollRestoreDatum {
  x: number;
  y: number;
}

interface ScrollRestoreData {
  [path: string]: {
    [elementName: string]: ScrollRestoreDatum;
  };
}

export const scrollRestoreSlice = createSlice({
  name: "scrollRestore",
  initialState: {},
  reducers: {
    setScrollRestoreDatum: (
      state: ScrollRestoreData,
      {
        payload: { path, elementName, x, y },
      }: PayloadAction<SetScrollRestorePayload>
    ) => {
      state[path] = {};
      state[path][elementName] = { x, y };
    },
  },
});

export const { setScrollRestoreDatum } = scrollRestoreSlice.actions;

export default scrollRestoreSlice.reducer;

const getScrollRestoreData = (state: RootState): ScrollRestoreData =>
  state.scrollRestore;

export const createScrollRestoreSelector = (
  path: string,
  elementName: string
): any =>
  createSelector([getScrollRestoreData], (data: ScrollRestoreData):
    | ScrollRestoreDatum
    | undefined => {
    return data[path] !== undefined ? data[path][elementName] : undefined;
  });
