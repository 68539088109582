import { gql } from "@apollo/client/core";
import { useParsedMutation } from "../common/queries/utils/useParsedMutation";
import { useParsedMutationReturnType } from "../common/queries/utils/useParsedMutationReturnType";
import { SiteType } from "../common/types/SiteType";

const MUTATION = gql`
  mutation DeleteSite($site_id: Int!) {
    delete_site(where: { id: { _eq: $site_id } }) {
      affected_rows
    }
  }
`;

type Variables = {
  site_id: number;
};

type Data = {
  delete_site: { affected_rows: number };
};

export function useDeleteSite(): useParsedMutationReturnType<SiteType, number> {
  return useParsedMutation<SiteType, number, Data, Variables>({
    mutation: MUTATION,
    parseVariables,
    parseData,
  });
}

function parseVariables({ id: site_id }: SiteType): Variables {
  return { site_id };
}

function parseData(data: Data): number {
  return data.delete_site.affected_rows;
}
