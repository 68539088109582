import { gql } from "@apollo/client/core";
import { useParsedMutation } from "../../queries/utils/useParsedMutation";
import { useParsedMutationReturnType } from "../../queries/utils/useParsedMutationReturnType";
import { UserType } from "../../types/UserType";

const MUTATION = gql`
  mutation SendResetPasswordEmail($email: String!) {
    send_reset_password_email(email: $email) {
      success
    }
  }
`;

type Data = { send_reset_password_email: { success: boolean } };
type Variables = { email: string };

export function useSendResetPasswordEmail(): useParsedMutationReturnType<
  UserType,
  boolean
> {
  return useParsedMutation<UserType, boolean, Data, Variables>({
    mutation: MUTATION,
    parseVariables,
    parseData,
  });
}

function parseVariables({ email }: UserType): Variables {
  return {
    email,
  };
}

function parseData(data: Data): boolean {
  return data.send_reset_password_email.success;
}
