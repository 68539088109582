import PatientJourneyTumourDetailsContainer from "./PatientJourneyTumourDetailsContainer";
import React, { FC } from "react";
import { GraphCardProps } from "../../common/utils/GraphCardProps";
import { Card } from "../../../common/components/cards/Card";

export const PatientLongitudinalGraphCard: FC<GraphCardProps> = ({
  onClickHeader,
}: GraphCardProps) => {
  return (
    <Card
      title={"Patient Journey"}
      content={<PatientJourneyTumourDetailsContainer />}
      onClickHeader={onClickHeader}
    />
  );
};
