import { UserActionCallbacksType } from "./UserActionCallbacksType";
import React, { useContext } from "react";
import { UserManagementContext } from "./UserManagementContext";
import { EditTrialUsers } from "./EditTrialUsers";

export interface ContextAwareEditTrialUsersProps {
  actionCallbacks: UserActionCallbacksType;
}

export function ContextAwareEditTrialUsers({
  actionCallbacks,
}: ContextAwareEditTrialUsersProps): JSX.Element {
  const {
    users,
    rules: { allowRemoveUser, allowEditOtherUser, allowCreateUser },
  } = useContext(UserManagementContext);

  return (
    <EditTrialUsers
      users={users}
      actionCallbacks={actionCallbacks}
      allowRemoveUser={allowRemoveUser}
      allowEditUser={allowEditOtherUser}
      allowCreateUser={allowCreateUser}
    />
  );
}
