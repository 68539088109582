import React, { ReactElement, useState, MouseEvent } from "react";
import { CSVLink } from "react-csv";
import { DownloadIcon } from "../../../common/components/icons/DownloadIcon";
import ShareIcon from "@material-ui/icons/Share";
import { CommentIcon } from "../../../common/components/icons/CommentIcon";
import Menu, {
  StyledLink,
  StyledMenuItem,
  IconWrapper,
} from "../../../common/components/buttons/Menu";
import styled from "styled-components";
import getCsvLink from "../../../common/utils/getCsvLink";
import Loading from "../../../common/components/Loading";
import ClearIcon from "@material-ui/icons/Clear";
import { main } from "../../../common/theme/main";
import { MuiIcon } from "../../../common/components/icons/MuiIcon";

const StyledCSVLink = styled(CSVLink)`
  text-decoration: none;
  display: none;
  color: ${(props) => props.theme.colors.actionPrimary.active};
`;

interface CsvDownloaderProps {
  onClose: () => void;
}

const CsvDownloader = ({ onClose }: CsvDownloaderProps): ReactElement => {
  const { csvData, csvLinkRef, handleDownload, loading } = getCsvLink({
    onClose,
  });
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <StyledMenuItem onClick={handleDownload}>
        <IconWrapper>
          <DownloadIcon />
        </IconWrapper>
        <StyledLink>Download</StyledLink>
      </StyledMenuItem>
      <StyledCSVLink
        asyncOnClick={true}
        onClick={(event, done) => {
          done();
        }}
        data={csvData}
        ref={csvLinkRef}
        filename={"analysis-data.csv"}
      />
    </>
  );
};

const AnalysisMenu = (): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Menu
        handleClick={handleClick}
        id="analysis-menu"
        anchorEl={anchorEl}
        handleClose={handleClose}
      >
        <StyledMenuItem>
          <IconWrapper>
            <CommentIcon />
          </IconWrapper>
          <StyledLink>Add Comment</StyledLink>
        </StyledMenuItem>
        <CsvDownloader onClose={handleClose} />
        <StyledMenuItem>
          <IconWrapper>
            <MuiIcon icon={ShareIcon} color={main.colors.primary.primary50} />
          </IconWrapper>
          <StyledLink>Share</StyledLink>
        </StyledMenuItem>
      </Menu>
    </div>
  );
};

export default AnalysisMenu;
