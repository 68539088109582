export const HISTOLOGY_AC = "AC";
export const HISTOLOGY_LCC = "LCC";
export const HISTOLOGY_SQCC = "SqCC";
export const HISTOLOGY_BRONCHIOLOALVEOLAR = "BAC";
export const HISTOLOGY_MIXED = "Mixed";
export const HISTOLOGY_NOS = "NOS";
export const HISTOLOGY_OTHER = "Other";

export type TumourHistologyType =
  | typeof HISTOLOGY_AC
  | typeof HISTOLOGY_LCC
  | typeof HISTOLOGY_SQCC
  | typeof HISTOLOGY_OTHER
  | typeof HISTOLOGY_BRONCHIOLOALVEOLAR
  | typeof HISTOLOGY_MIXED
  | typeof HISTOLOGY_NOS;

export function getTumourHistologyType(
  histologyStr: string
): TumourHistologyType {
  const dbNames: Record<string, TumourHistologyType> = {
    Adenocarcinoma: HISTOLOGY_AC,
    "Large Cell Carcinoma": HISTOLOGY_LCC,
    "Squamous Cell Carcinoma": HISTOLOGY_SQCC,
    Bronchoalveolar: HISTOLOGY_BRONCHIOLOALVEOLAR,
    Mixed: HISTOLOGY_MIXED,
    Nos: HISTOLOGY_NOS,
  };

  return dbNames[histologyStr] ?? HISTOLOGY_OTHER;
}
