import React, { useState } from "react";
import {
  Bar,
  BarChart,
  Cell,
  Legend as ReChartsLegend,
  ResponsiveContainer,
  Tooltip as ReChartsTooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import { Legend } from "../../common/components/Legend";
import { getXAxisProps } from "../../common/utils/getXAxisProps";
import { getYAxisProps } from "../../common/utils/getYAxisProps";
import { altArmColors, armColors } from "../../common/utils/armColors";
import { formatArmId } from "../../common/utils/formatArmId";
import { formatBinRange, SurvivalBinType } from "../utils/SurvivalBinType";
import { SurvivalHistogramType } from "../utils/SurvivalHistogramType";
import { MortalityDistributionTooltip } from "./MortalityDistributionChartTooltip";
import { BAR_RADIUS } from "../../common/utils/strokeSettings";
import { getYAxisLabel } from "../../common/utils/getYAxisLabel";
import { SUBJECTS_LABEL } from "../../common/utils/SubjectsLabel";
import { useFilteredTrialArmIds } from "../../common/hooks/useFilteredTrialArmIds";

const Wrapper = styled.div`
  flex: 1;
`;

interface MortalityDistributionChartProps {
  histogram: SurvivalHistogramType;
  onSurvivalBinSelected: (
    survivalBin: SurvivalBinType,
    isSelected: boolean
  ) => void;
}

function MortalityDistributionChart({
  histogram,
  onSurvivalBinSelected,
}: MortalityDistributionChartProps): React.ReactElement {
  const trialArmIds = useFilteredTrialArmIds();

  const [selectedBarIndex, setSelectedBarIndex] = useState<number | null>(null);

  const bars = trialArmIds.map((arm) => {
    const getData = (bin: SurvivalBinType) => {
      const { subjectsByArm } = bin;
      const armSubjects = subjectsByArm[arm];
      return armSubjects ? armSubjects.length : 0;
    };

    const handleBarClicked = (survivalBin: SurvivalBinType, index: number) => {
      const isSelected = selectedBarIndex !== index;
      if (isSelected) {
        setSelectedBarIndex(index);
      } else {
        setSelectedBarIndex(null);
      }

      onSurvivalBinSelected(survivalBin, isSelected);
    };

    const color = armColors[arm];

    return (
      <Bar
        onClick={handleBarClicked}
        key={arm}
        name={arm.toString()}
        isAnimationActive={false}
        dataKey={getData}
        fill={color}
        legendType={"circle"}
        type={"circle"}
        radius={BAR_RADIUS}
      >
        {histogram.map((entry, index) => {
          const color =
            selectedBarIndex !== null
              ? selectedBarIndex === index
                ? armColors[arm]
                : altArmColors[arm]
              : armColors[arm];

          return <Cell cursor="pointer" fill={color} key={`cell-${index}`} />;
        })}
      </Bar>
    );
  });

  const renderLegend = ({ payload }: any) => (
    <Legend payload={payload} valueFormatter={formatArmId} />
  );
  const renderToolTip = (props: any) => {
    const { label } = props;
    const bin = histogram[label];
    if (!bin) {
      return null;
    }

    return <MortalityDistributionTooltip bin={bin} {...props} />;
  };

  const formatXValue = (id: any): string => {
    const bin = histogram.find((bin) => bin.id === id);
    if (!bin) {
      return "N/A";
    }
    return formatBinRange(bin);
  };

  const xAxisProps = getXAxisProps();
  const yAxisLabel = getYAxisLabel(SUBJECTS_LABEL);
  const yAxisProps = getYAxisProps(true);

  return (
    <Wrapper>
      <ResponsiveContainer height={350}>
        <BarChart
          margin={{ top: 0, right: 0, left: 15, bottom: 0 }}
          data={histogram}
          barGap={1}
          barCategoryGap={6}
        >
          <XAxis
            {...xAxisProps}
            allowDuplicatedCategory={true}
            dataKey={"id"}
            interval={0}
            tickFormatter={formatXValue}
            tickLine={false}
            angle={-20}
            tickSize={20}
          />
          <YAxis {...yAxisProps}>{yAxisLabel}</YAxis>
          <ReChartsTooltip
            content={renderToolTip}
            //@ts-ignore
            payload={histogram}
          />
          <ReChartsLegend
            align="left"
            verticalAlign="top"
            height={48}
            content={renderLegend}
          />
          {bars}
        </BarChart>
      </ResponsiveContainer>
    </Wrapper>
  );
}

export default MortalityDistributionChart;
