import React from "react";
import ImagingChart from "./ImagingChart";
import { gql, useQuery } from "@apollo/client";
import handleApolloError from "../../../common/utils/handleApolloError";
import Loading from "../../../common/components/Loading";
import {
  parseImagingDeviationData,
  RawImagingDeviationAndViolationsType,
  sortImagingDeviationData,
} from "../utils/parseImagingDeviationData";
import { useTrialId } from "../../../Trial/hooks/useTrialId";

const GET_IMAGING_DEVIATIONS_AND_VIOLATION = gql`
  query getImagingDeviationsAndViolations($trialId: Int!) {
    site(where: { trial_id: { _eq: $trialId } }) {
      id
      studies {
        series {
          imaging_violations {
            violation_type
          }
        }
      }
    }
  }
`;

function ImagingChartContainer(): React.ReactElement {
  const trialId = useTrialId();
  const { loading, error, data } = useQuery<
    RawImagingDeviationAndViolationsType
  >(GET_IMAGING_DEVIATIONS_AND_VIOLATION, {
    variables: { trialId },
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    handleApolloError(error);
  }
  const parsedData = data ? parseImagingDeviationData(data) : [];
  const sortedData = sortImagingDeviationData(parsedData);
  return <ImagingChart data={sortedData} />;
}

export default ImagingChartContainer;
