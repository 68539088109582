import { gql } from "@apollo/client/core";
import { CASE_FRAGMENT } from "./CaseFragment";

export const CASE_ASSIGNMENT_FRAGMENT = gql`
  fragment CaseAssignment on case_assignment {
    id: case_assignment_id
    actionType: action_type
    progress
    userId: user_id
    case {
      ...Case
    }
  }
  ${CASE_FRAGMENT}
`;
