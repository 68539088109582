import React from "react";
import { FC } from "react";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import styled from "styled-components";

const Styled = styled(CreateNewFolderIcon)`
  color: ${(props) => props.theme.colors.primary.inverted};
`;

export const DataManagementIcon: FC = () => <Styled />;
