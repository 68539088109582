export const MUTATION_KRAS = "KRAS";
export const MUTATION_HER2 = "HER2";
export const MUTATION_NRAS = "NRAS";
export const MUTATION_EGFR = "EGFR+";
export const MUTATION_BRAF = "BRAF";
export const MUTATION_MET = "MET";
export const MUTATION_TP53 = "TP53";
export const MUTATION_ALK = "ALK";
export const MUTATION_NTRK = "NTRK";
export const MUTATION_ROS1 = "ROS1";
export const MUTATION_RET = "RET";
export const MUTATION_OTHER = "Other";

export type TumourMutationType =
  | typeof MUTATION_KRAS
  | typeof MUTATION_HER2
  | typeof MUTATION_NRAS
  | typeof MUTATION_EGFR
  | typeof MUTATION_BRAF
  | typeof MUTATION_MET
  | typeof MUTATION_TP53
  | typeof MUTATION_ALK
  | typeof MUTATION_NTRK
  | typeof MUTATION_ROS1
  | typeof MUTATION_RET
  | typeof MUTATION_OTHER;

export function getTumourMutationType(mutationStr: string): TumourMutationType {
  const dbNames: Record<string, TumourMutationType> = {
    "EGFR+": MUTATION_EGFR,
  };

  return dbNames[mutationStr] ?? MUTATION_OTHER;
}
