import { CSVLink } from "react-csv";
import React, { ReactElement } from "react";
import CTAButton from "./CTAButton";
import { DownloadIcon } from "../icons/DownloadIcon";
import getCsvLink from "../../utils/getCsvLink";
import Loading from "../Loading";

interface DownloadReportButtonProps {
  text: string;
}

const DownloadReportButton = ({
  text,
}: DownloadReportButtonProps): ReactElement => {
  const { csvData, csvLinkRef, handleDownload, loading } = getCsvLink({});
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <CTAButton icon={<DownloadIcon />} text={text} onClick={handleDownload} />
      <CSVLink data={csvData} ref={csvLinkRef} filename={"analysis-data.csv"} />
    </>
  );
};

export default DownloadReportButton;
