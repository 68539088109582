import { createSlice } from "@reduxjs/toolkit";

export const trialIdSlice = createSlice({
  name: "trialId",
  initialState: null,
  reducers: {
    setTrialId: (state, action) => action.payload,
    clearTrialId: () => null,
  },
});

export const { setTrialId, clearTrialId } = trialIdSlice.actions;

export default trialIdSlice.reducer;
