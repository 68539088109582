import React, { ReactElement } from "react";
import styled from "styled-components";
import { main } from "../../../common/theme/main";

const StyledFilterButton = styled.div<{
  background: string;
  borderColor: string;
  width: number;
}>`
  width: ${(props) => props.width}px;
  padding: 6px;

  background: ${(props) => props.background};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 6px;

  display: flex;
  justify-content: center;
  cursor: pointer;
`;

const ButtonText = styled.p<{ active: boolean }>`
  font-size: 13px;
  font-weight: 600;

  font-family: Inter;
  font-style: normal;
  line-height: 20px;
  color: ${(props) =>
    props.active
      ? props.theme.colors.neutral.white
      : props.theme.colors.neutral.black};

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

interface FilterButtonProps {
  onClick: (checked: boolean) => void;
  text: string;
  active: boolean;
  width: number;
  backgroundColorOverride?: string;
  borderColorOverride?: string;
}

const FilterButton = ({
  onClick,
  text,
  active,
  width,
  backgroundColorOverride,
  borderColorOverride,
}: FilterButtonProps): ReactElement => {
  const background =
    backgroundColorOverride ??
    (active ? main.colors.neutral.black : main.colors.neutral.white);
  const borderColor = borderColorOverride ?? main.colors.actionPrimary.disabled;

  const handleOnClick = () => {
    onClick(!active);
  };

  return (
    <StyledFilterButton
      width={width}
      onClick={handleOnClick}
      background={background}
      borderColor={borderColor}
    >
      <ButtonText active={active}>{text}</ButtonText>
    </StyledFilterButton>
  );
};

export default FilterButton;
