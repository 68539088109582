import React from "react";
interface ButtonProps {
  content: React.ReactChild | React.ReactChild[] | string;
  onClick?: React.MouseEventHandler;
  type?: "button" | "submit" | "reset" | undefined;
  id?: string;
  active?: boolean;
  className?: string;
}
function Button({
  content,
  onClick,
  type,
  id,
  className,
}: ButtonProps): React.ReactElement<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> {
  return (
    <button onClick={onClick} type={type} id={id} className={className}>
      {content}
    </button>
  );
}

export default Button;
