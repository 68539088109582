import React from "react";
import RTOGDetailPanel from "./RTOGDetailPanel";
import handleApolloError from "../../../common/utils/handleApolloError";
import Loading from "../../../common/components/Loading";
import { parseRTOGDetailPanelContainerData } from "../utils/parseRTOGDetailPanelContainerData";
import { captureSubjectIdParam } from "../../../Trial/utils/captureSubjectIdParam";
import { useTrialId } from "../../../Trial/hooks/useTrialId";
import { useSingleSubjectQuery } from "../../common/hooks/useSingleSubjectQuery";
import { SubjectType } from "../../common/types/SubjectType";
import { MissingPatientPanel } from "./MissingPatientPanel";

function RTOGDetailPanelContainer(): React.ReactElement {
  const subjectId = captureSubjectIdParam();

  const trialId = useTrialId();
  const { subject, loading, error } = useSingleSubjectQuery(trialId, subjectId);

  if (loading) return <Loading />;
  if (error) handleApolloError(error);

  if (!subject) {
    return <MissingPatientPanel />;
  }

  const processedData = parseRTOGDetailPanelContainerData(
    subject ? subject : ({} as SubjectType)
  );
  return <RTOGDetailPanel details={processedData} />;
}

export default RTOGDetailPanelContainer;
