import { UserRoleType } from "./UserRoleType";

export type UserType = {
  id: number;
  auth0Id?: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  organization?: string;
  email: string;
  roles: UserRoleType[];
  avatarImageSource?: string;
};

export function getInitials({ firstName, lastName, email }: UserType): string {
  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`;
  }

  const singleName = firstName ?? lastName ?? email;
  return singleName ? singleName.slice(0, 2) : "N/A";
}
