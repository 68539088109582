import React, { FC } from "react";
import DataIngestionProgressContainer from "../../DataIngestion/components/DataIngestionProgressContainer";
import { Card } from "../../../common/components/cards/Card";
import ImagingChartContainer from "../../ImagingDeviations/components/ImagingChartContainer";
import TrialsCompletionBurndownContainer from "../../TrialCompletionBurndown/components/TrialCompletionBurndownContainer";
import { TrialDetailsCard } from "../../../common/components/TrialDetails/TrialDetailsCard";
import { EnrolmentCard } from "../../EnrolmentChart/components/EnrolmentCard";
import styled from "styled-components";
import { useTrialId } from "../../../Trial/hooks/useTrialId";
import Loading from "../../../common/components/Loading";
import handleApolloError from "../../../common/utils/handleApolloError";
import { useSubjectQuery } from "../../../Analysis/common/hooks/useSubjectQuery";
import { StudyTableCard } from "../../../Analysis/common/components/StudyTableCard";

const HorizontalCardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

//TODO improve this
const HorizontalCardWrapper = styled.div`
  flex-basis: 50%;
`;

const Overview: FC = () => {
  const trialId = useTrialId();
  const { subjects, loading, error } = useSubjectQuery(trialId);

  if (loading) return <Loading />;
  if (error) handleApolloError(error);
  if (!subjects) throw new Error("Subject null with no apollo error");

  return (
    <>
      <TrialDetailsCard />
      <EnrolmentCard title={"Imaging Acquisition"} />
      <HorizontalCardsWrapper>
        <HorizontalCardWrapper>
          <Card
            title={"Projected Completion"}
            content={<TrialsCompletionBurndownContainer />}
          />
        </HorizontalCardWrapper>
        <HorizontalCardWrapper>
          <Card
            title={"Imaging Deviations"}
            content={<ImagingChartContainer />}
          />
        </HorizontalCardWrapper>
      </HorizontalCardsWrapper>
      <Card
        title={"Data Ingestion Progress"}
        content={<DataIngestionProgressContainer />}
      />
      <StudyTableCard hideAnalysisColumn={true} />
    </>
  );
};

export default Overview;
