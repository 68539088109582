import { combineReducers } from "@reduxjs/toolkit";
import { scrollRestoreSlice } from "./scrollRestoreSlice";
import { trialIdSlice } from "./trialIdSlice";
import { trialViewFiltersSlice } from "./trialViewFiltersSlice";

const rootReducer = combineReducers({
  trialId: trialIdSlice.reducer,
  trialViewFilters: trialViewFiltersSlice.reducer,
  scrollRestore: scrollRestoreSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
