import React, { ReactChild, ReactElement } from "react";
import styled from "styled-components";

interface FilterCategoryProps {
  name?: string;
  options: ReactChild | ReactChild[];
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterCategory = ({ options }: FilterCategoryProps): ReactElement => {
  return <FormWrapper>{options}</FormWrapper>;
};

export default FilterCategory;
