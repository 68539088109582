import { TumourBurdenType } from "../../types/TumourBurdenType";
import { RawTumourBurden } from "../../rawTypes/RawTumourBurden";
import { calculateVolumeOfSphereFromBurden } from "../calculateVolumeOfSphereFromBurden";

export function parseTumourBurdenFromQueryResult(
  rawTumourBurden: RawTumourBurden
): TumourBurdenType {
  const {
    tumour_burden: { burden: burden },
  } = rawTumourBurden;
  const volume = calculateVolumeOfSphereFromBurden(burden);
  return { burden, volume };
}
