import {
  ApolloClient,
  from,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";

import { setContext } from "@apollo/client/link/context";

let getTokenCallback: () => Promise<string>;

export function setTokenCallback(callback: () => Promise<string>): void {
  getTokenCallback = callback;
}

const annotateHttpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL_NOTA_ANNOTATE,
});

const authLink = setContext(async (_, { headers }) => {
  if (!getTokenCallback) {
    throw new Error("Auth token callback not set!");
  }

  const token = await getTokenCallback();
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
});

const retryLink = new RetryLink();

export const annotateClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([retryLink, authLink, annotateHttpLink]),
});
