import {
  ETHNICITY_ASIAN,
  ETHNICITY_BLACK,
  ETHNICITY_CAUCASIAN,
  ETHNICITY_HISPANIC,
  ETHNICITY_MULTI_ETHNIC,
} from "../../../Analysis/common/types/EthnicityType";
import { RootState } from "../rootReducer";
import { TrialFilterSelectors } from "./TrialFilterSelectors";
import { DemographicsFilters } from "../../types/DemographicsFilterTypes";

export const clearDemographicsState = {
  sex: {
    male: false,
    female: false,
  },
  age: {
    min: null,
    max: null,
  },
  ethnicity: {
    [ETHNICITY_ASIAN]: false,
    [ETHNICITY_BLACK]: false,
    [ETHNICITY_HISPANIC]: false,
    [ETHNICITY_CAUCASIAN]: false,
    [ETHNICITY_MULTI_ETHNIC]: false,
  },
};

export const defaultDemographicsState = {
  sex: {
    male: true,
    female: true,
  },
  age: {
    min: null,
    max: null,
  },
  ethnicity: {
    [ETHNICITY_ASIAN]: true,
    [ETHNICITY_BLACK]: true,
    [ETHNICITY_HISPANIC]: true,
    [ETHNICITY_CAUCASIAN]: true,
    [ETHNICITY_MULTI_ETHNIC]: true,
  },
};

export const demographicsFilters = new TrialFilterSelectors<DemographicsFilters>(
  defaultDemographicsState,
  clearDemographicsState,
  (state: RootState) => state.trialViewFilters.demographics
);
