import React, { ReactElement } from "react";
import AllTrials from "./AllTrials";
import handleApolloError from "../../common/utils/handleApolloError";
import { useAllTrials } from "../../common/queries/useAllTrials";
import { AltLoading } from "../../common/components/AltLoading";

const AllTrialsContainer = (): ReactElement => {
  const { trials, loading, error } = useAllTrials();

  if (loading) return <AltLoading />;
  if (error) handleApolloError(error);
  if (!trials) {
    throw new Error("Trials null");
  }

  return <AllTrials allTrials={trials} />;
};

export default AllTrialsContainer;
