import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import ShareIcon from "@material-ui/icons/Share";
import IconButton from "../../../common/components/buttons/IconButton";
import { MuiIcon } from "../../../common/components/icons/MuiIcon";
import { main } from "../../../common/theme/main";

interface CopyFilteredURLButtonProps<FiltersType> {
  filters: FiltersType;
  handleFilterLoad: (filters: FiltersType) => void; // eslint-disable-line no-unused-vars
}

export function CopyFilteredURLButton<FiltersType>({
  filters,
  handleFilterLoad,
}: CopyFilteredURLButtonProps<FiltersType>): React.ReactElement {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filters = searchParams.get("filters");
    if (filters !== null) {
      handleFilterLoad(JSON.parse(decodeURIComponent(filters)));
      history.replace({
        search: undefined,
      });
    }
  }, []);
  return (
    <IconButton
      icon={<MuiIcon icon={ShareIcon} size={18} />}
      onClick={() => {
        const filteredURL = `${window.location.origin}${
          window.location.pathname
        }?filters=${encodeURIComponent(JSON.stringify(filters))}`;

        navigator.clipboard.writeText(filteredURL);
      }}
    />
  );
}
