import Loading from "nota-predict-web/src/common/components/Loading";
import handleApolloError from "nota-predict-web/src/common/utils/handleApolloError";
import React, { useState } from "react";
import { useTrialId } from "../../../Trial/hooks/useTrialId";
import { SubjectType } from "../../common/types/SubjectType";
import { useFilteredSubjectsWithFollowUp } from "../../common/hooks/useFilteredSubjectsWithFollowUp";
import MortalityDistributionChart from "./MortalityDistributionChart";
import { getSubjectSurvivalHistogram } from "../utils/getSubjectSurvivalHistogram";
import { SurvivalBinType } from "../utils/SurvivalBinType";
import { LinkedStudyTable } from "../../../common/components/StudyTable/LinkedStudyTable";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ItemWrapper = styled.div`
  flex: 1;
`;

interface DistributionProps {
  followUp: number;
}

const BIN_SIZE_MONTHS = 3;

export default function MortalityDistributionChartContainer({
  followUp,
}: DistributionProps): React.ReactElement {
  const [selectedSubjects, setSelectedSubjects] = useState<
    SubjectType[] | null
  >(null);

  const trialId = useTrialId();
  const { loading, error, subjects } = useFilteredSubjectsWithFollowUp(
    trialId,
    followUp
  );

  if (loading) return <Loading />;
  if (error) handleApolloError(error);
  if (!subjects) throw new Error("Subject null with no apollo error");

  const filteredSubjects = subjects.filter(
    ({ isTempDemoFixedPatient }) => !isTempDemoFixedPatient
  );

  if (!selectedSubjects) {
    setSelectedSubjects(filteredSubjects);
  }

  const histogram = getSubjectSurvivalHistogram(
    filteredSubjects,
    followUp,
    BIN_SIZE_MONTHS
  );

  const handleSurvivalBinSelected = (
    survivalBin: SurvivalBinType,
    isSelected: boolean
  ) => {
    const { subjectsByArm } = survivalBin;
    const survivalBinSubjects = [];
    for (const key in subjectsByArm) {
      if (!Object.prototype.hasOwnProperty.call(subjectsByArm, key)) {
        continue;
      }

      const arm = parseInt(key);
      const armSubjects = subjectsByArm[arm];
      survivalBinSubjects.push(...armSubjects);
    }
    setSelectedSubjects(isSelected ? survivalBinSubjects : null);
  };

  return (
    <Wrapper>
      <ItemWrapper>
        <MortalityDistributionChart
          histogram={histogram}
          onSurvivalBinSelected={handleSurvivalBinSelected}
        />
      </ItemWrapper>
      <ItemWrapper>
        <LinkedStudyTable
          hideAnalysisColumn={false}
          subjects={selectedSubjects ?? filteredSubjects}
        />
      </ItemWrapper>
    </Wrapper>
  );
}
