import React, { ReactElement } from "react";
import { DialogContents } from "./DialogContents";
import { ContentDialogProps } from "./ContentDialogProps";
import { DialogWrapper } from "./DialogWrapper";
import { BaseDialogProps } from "./BaseDialogProps";

interface Props extends ContentDialogProps, BaseDialogProps {}

export function Dialog({
  open,
  setOpen,
  label,
  content,
  options,
}: Props): ReactElement {
  const handleOnClose = () => {
    setOpen(false);
  };

  return (
    <DialogWrapper open={open} setOpen={setOpen}>
      <DialogContents
        label={label}
        content={content}
        options={options}
        onClose={handleOnClose}
      />
    </DialogWrapper>
  );
}
