import React from "react";
import styled from "styled-components";

interface Heading4Props {
  title: string;
}

const StyledH4 = styled.h4`
  margin: 0;
  font-weight: 400;
`;

function Heading4({
  title,
}: Heading4Props): React.ReactElement<
  React.HTMLAttributes<HTMLHeadingElement>
> {
  return <StyledH4>{title}</StyledH4>;
}

export default Heading4;
