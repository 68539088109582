import { ReactText } from "react";

export function formatMonthsRounded(months: ReactText): string {
  if (typeof months != "number") {
    months = parseInt(months);
  }
  if (months === 0) {
    return "Baseline";
  }
  return `${Math.round(months)} Month${months !== 1 ? "s" : ""}`;
}
