import { main } from "../../common/theme/main";
import { SUCCESS, INFO, WARNING, ERROR, StatusType } from "./StatusTypes";

const success = main.colors.states.success;
const info = main.colors.states.info;
const warning = main.colors.states.warning;
const error = main.colors.states.error;

export type StatusColorType =
  | typeof success
  | typeof info
  | typeof warning
  | typeof error;

export function getStatusColorType(status: StatusType): StatusColorType {
  const stateNames: Record<StatusType, StatusColorType> = {
    [SUCCESS]: success,
    [INFO]: info,
    [WARNING]: warning,
    [ERROR]: error,
  };
  if (!stateNames[status]) {
    throw new Error(`Status: ${status} Color not found`);
  }

  return stateNames[status];
}
