import { useQuery } from "@apollo/client";
import { parseSubjectFromQueryResult } from "../utils/rawDataParsers/parseSubjectFromQueryResult";
import { RawSubjectType } from "../rawTypes/RawSubjectType";
import { GET_SUBJECTS_QUERY, RawSubjectsQueryType } from "./useSubjectQuery";
import { SubjectType } from "../types/SubjectType";
import { SingleSubjectQueryResultType } from "../types/SingleSubjectQueryResultType";

export type RawSubjectQueryType = {
  patient: RawSubjectType;
};

export function useSingleSubjectQuery(
  trialId: number,
  subjectId: number
): SingleSubjectQueryResultType {
  const { data, loading, error } = useQuery<RawSubjectsQueryType>(
    GET_SUBJECTS_QUERY,
    {
      variables: { trial_id: trialId },
    }
  );

  let subjects;
  let subject;
  if (!loading && !error && data) {
    const { patient: rawSubjects, temp_nota_annotation_page_url_base } = data;
    subjects = rawSubjects.map((subject) =>
      parseSubjectFromQueryResult(subject, temp_nota_annotation_page_url_base)
    );
    subject = subjects
      ? subjects.find((subject) => subject.id == subjectId)
      : ({} as SubjectType);
  }

  return {
    subject,
    loading,
    error,
  };
}
