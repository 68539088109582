import { configureStore, getDefaultMiddleware, Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import rootReducer, { RootState } from "./rootReducer";

export const storeConfigOptions = {
  reducer: rootReducer,
  middleware: getDefaultMiddleware(),
};

const store = configureStore(storeConfigOptions);

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default store;
