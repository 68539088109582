import { gql } from "@apollo/client/core";
import { useParsedMutation } from "../common/queries/utils/useParsedMutation";
import { SiteType } from "../common/types/SiteType";
import { useParsedMutationReturnType } from "../common/queries/utils/useParsedMutationReturnType";

const MUTATION = gql`
  mutation UpdateSiteName($name: String!, $site_id: Int!) {
    update_site(where: { id: { _eq: $site_id } }, _set: { name: $name }) {
      affected_rows
    }
  }
`;

type Variables = {
  site_id: number;
  name: string;
};

type Data = {
  update_site: { affected_rows: number };
};

export function useUpdateSite(): useParsedMutationReturnType<SiteType, number> {
  return useParsedMutation<SiteType, number, Data, Variables>({
    mutation: MUTATION,
    parseVariables,
    parseData,
  });
}

function parseVariables({ id: site_id, name }: SiteType): Variables {
  return { site_id, name };
}

function parseData(data: Data): number {
  return data.update_site.affected_rows;
}
