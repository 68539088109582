import { createSelector } from "@reduxjs/toolkit";
import React from "react";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterCategory from "./FilterCategory";
import FilterCheckbox from "./FilterCheckbox";
import { formatArmId } from "../../common/utils/formatArmId";
import {
  getTrialViewFilters,
  trialViewFiltersActions,
} from "../../../common/store/trialViewFiltersSlice";

const getTrialArmFilters = createSelector(
  [getTrialViewFilters],
  (trialViewFilters) => trialViewFilters.trialArm
);

export const TrialArmFilters: FC = () => {
  const dispatch = useDispatch();
  const trialArmFilters = useSelector(getTrialArmFilters);

  const options = Object.entries(trialArmFilters).map(
    ([filter, value], index) => {
      const updateTrialArmFilters = (checked: boolean) => {
        dispatch(
          trialViewFiltersActions.setTrialArm({
            ...trialArmFilters,
            [filter]: checked,
          })
        );
      };
      return (
        <FilterCheckbox
          checked={value}
          key={index}
          label={formatArmId(index)}
          onChange={updateTrialArmFilters}
          name={filter}
        />
      );
    }
  );

  return <FilterCategory name={"Trial Arm"} options={options} />;
};
