import React from "react";
import { Redirect, Route, Switch } from "react-router";
import Dashboard from "../../Dashboard/Dashboard";
import { DASHBOARD } from "./routePaths";
import { Trial } from "../../Trial/Trial";

const routes = (
  <Switch>
    <Route exact path={DASHBOARD}>
      <Dashboard />
    </Route>
    <Route path={`/:trialId`}>
      <Trial />
    </Route>
    <Redirect to={DASHBOARD} />
  </Switch>
);
export default routes;
