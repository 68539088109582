import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import React from "react";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

interface DatePickerProps {
  date?: Date;
  onDateChanged: (date?: Date) => void;
}

export function DatePicker({
  date,
  onDateChanged,
}: DatePickerProps): JSX.Element {
  const handleDateChanged = (date: MaterialUiPickersDate | null) => {
    onDateChanged(date?.toDate());
  };

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <KeyboardDatePicker
        fullWidth
        variant="inline"
        format="MM/DD/YYYY"
        value={date}
        onChange={handleDateChanged}
        InputProps={{ disableUnderline: true }}
      />
    </MuiPickersUtilsProvider>
  );
}
