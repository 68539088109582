import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, createStyles } from "@material-ui/core/styles";

interface CircularProgressBarProps {
  color: string;
}

const useStyle = makeStyles(() =>
  createStyles({
    style: (props: { color: string }) => ({
      marginRight: "8px",
      color: props.color,
    }),
  })
);

function CircularProgressBar({
  color,
}: CircularProgressBarProps): React.ReactElement<
  React.ProgressHTMLAttributes<HTMLProgressElement>
> {
  const classes = useStyle({ color });

  return (
    <>
      <CircularProgress
        variant="indeterminate"
        className={classes.style}
        size={10}
        thickness={10}
      />
    </>
  );
}

export default CircularProgressBar;
