import { RawStudyJobType } from "../../rawTypes/RawStudyJobType";
import { JobType } from "../../types/JobType";
import { JobStatusType } from "../../../../common/types/JobStatusType";

export function parseStudyJob(rawStudyJob: RawStudyJobType): JobType {
  const {
    celery_job: { id, status, created, last_modified },
  } = rawStudyJob;
  return {
    id,
    status: status as JobStatusType,
    created: new Date(created),
    lastModified: new Date(last_modified),
  };
}
