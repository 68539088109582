import { gql } from "@apollo/client";
import { TRIAL_DETAILS_FRAGMENT } from "./TrialDetailsFragment";

export const GET_ALL_TRIALS_DETAILS = gql`
  query GetTrial {
    trial {
      ...Trial
    }
  }
  ${TRIAL_DETAILS_FRAGMENT}
`;
