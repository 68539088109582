import React, { FC } from "react";
import styled from "styled-components";

interface SubjectImageProps {
  source: string;
  alt: string;
  maxWidth: number;
  maxHeight: number;
}

interface StyledImageProps {
  maxWidth: number;
  maxHeight: number;
}

const StyledImage = styled.img`
  width: auto;
  height: auto;
  max-width: ${(props: StyledImageProps) => props.maxWidth}px;
  max-height: ${(props: StyledImageProps) => props.maxHeight}px;
`;

export const SubjectImage: FC<SubjectImageProps> = ({
  source,
  alt,
  maxWidth,
  maxHeight,
}: SubjectImageProps) => {
  return (
    <StyledImage
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      src={source}
      alt={alt}
    />
  );
};
