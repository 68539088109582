import { Card } from "../../../common/components/cards/Card";
import AlgorithmSummary from "./AlgorithmSummary";
import React, { FC } from "react";
import Loading from "../../../common/components/Loading";
import handleApolloError from "../../../common/utils/handleApolloError";
import useAlgorithms from "../hooks/useAlgorithms";
import { ModifiedRunJobProp } from "../utils/parseAlgorithms";
import { NO_ALGORITHMS_RUN } from "../utils/getAlgorithmStringConstants";
import { useTrialId } from "../../../Trial/hooks/useTrialId";

interface AlgorithmSummaryCardProps {
  // eslint-disable-next-line no-unused-vars
  onClickAlgorithms: () => void;
}

interface FilledAlgorithmSummaryProps {
  latestRunJobs: ModifiedRunJobProp[];
  onClickAlgorithms: () => void;
}

function FilledAlgorithmSummary({
  latestRunJobs,
  onClickAlgorithms,
}: FilledAlgorithmSummaryProps): React.ReactElement {
  if (latestRunJobs.length === 0)
    return (
      <AlgorithmSummary
        algorithmName={NO_ALGORITHMS_RUN}
        createdOn={""}
        lastRun={""}
        onClickAlgorithms={onClickAlgorithms}
      />
    );

  const { name, createdOn, lastRun } = latestRunJobs[0];
  return (
    <AlgorithmSummary
      algorithmName={name}
      createdOn={createdOn}
      lastRun={lastRun}
      onClickAlgorithms={onClickAlgorithms}
    />
  );
}

export const AlgorithmSummaryCard: FC<AlgorithmSummaryCardProps> = ({
  onClickAlgorithms,
}: AlgorithmSummaryCardProps) => {
  const trialId = useTrialId();
  const { runJobs, loading, error } = useAlgorithms(trialId);
  if (loading) return <Loading />;
  if (error) handleApolloError(error);

  const handleCardClicked = () => {
    onClickAlgorithms();
  };

  return (
    <Card
      noHead={true}
      onClickCard={handleCardClicked}
      content={
        <FilledAlgorithmSummary
          latestRunJobs={runJobs}
          onClickAlgorithms={onClickAlgorithms}
        />
      }
    />
  );
};
