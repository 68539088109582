import { createSelector } from "@reduxjs/toolkit";
import React from "react";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterButton from "./FilterButton";
import FilterCategory from "./FilterCategory";
import { ButtonWrapper } from "./FilterInputWrapper";
import {
  getTrialViewFilters,
  trialViewFiltersActions,
} from "../../../common/store/trialViewFiltersSlice";
import { TumourMutationType } from "../../common/types/TumourMutationType";
import { capitalize } from "../../../common/utils/capitalize";

const getTumourMutationsFilters = createSelector(
  [getTrialViewFilters],
  (trialViewFilters) => trialViewFilters.tumourMutations
);

export const TumourMutationsFilters: FC = () => {
  const dispatch = useDispatch();
  const tumourMutationsFilters = useSelector(getTumourMutationsFilters);

  const mutationsOptions = Object.keys(tumourMutationsFilters).map(
    (key, index) => {
      const mutation = key as TumourMutationType;
      const updateTumourMutationsFilters = (checked: boolean) => {
        dispatch(trialViewFiltersActions.toggleTumourMutation(mutation));
      };

      const isChecked = tumourMutationsFilters[mutation] ?? false;

      return (
        <FilterButton
          key={index}
          text={capitalize(mutation)}
          onClick={updateTumourMutationsFilters}
          active={isChecked}
          width={55}
        />
      );
    }
  );

  const options = (
    <>
      <ButtonWrapper>{mutationsOptions}</ButtonWrapper>
    </>
  );

  return <FilterCategory name={"Mutations"} options={options} />;
};
