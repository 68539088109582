import React from "react";
import { TableToggleAllRowsSelectedProps } from "react-table";
import styled from "styled-components";
import { BlackCheckbox } from "../../../Analysis/ToolPanel/components/FilterCheckbox";

// eslint-disable-next-line react/display-name
export const IndeterminateCheckbox =
  // eslint-disable-next-line react/display-name
  React.forwardRef<any, TableToggleAllRowsSelectedProps>(
    // eslint-disable-next-line react/prop-types
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        // @ts-ignore
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <BlackCheckbox
            disableRipple
            size={"small"}
            inputRef={resolvedRef}
            indeterminate={indeterminate}
            {...rest}
          />
        </>
      );
    }
  );
