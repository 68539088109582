import React, { FC } from "react";
import RadiologyPanel from "./RadiologyPanel";
import RadiologyCaseStatusTableContainer from "./RadiologyCaseStatusTableContainer";

export const RadiologyPanelContainer: FC = () => {
  const data = {
    criteria: "RECIST 1.1",
    followUp: "Double central review with adjudication",
  };

  return (
    <RadiologyPanel criteria={data.criteria} followUp={data.followUp}>
      <RadiologyCaseStatusTableContainer />
    </RadiologyPanel>
  );
};
