import React, { FC } from "react";
import ModelSuiteInfoPanel from "./ModelSuiteInfoPanel";
import ModelSuiteProgress from "./ModelSuiteProgress";
import { Card } from "../../../common/components/cards/Card";
import { useTrialId } from "../../../Trial/hooks/useTrialId";
import Loading from "../../../common/components/Loading";
import handleApolloError from "../../../common/utils/handleApolloError";
import { useSubjectQuery } from "../../common/hooks/useSubjectQuery";
import { StudyTableCard } from "../../common/components/StudyTableCard";

const Algorithm: FC = () => {
  const trialId = useTrialId();
  const { subjects, loading, error } = useSubjectQuery(trialId);

  if (loading) return <Loading />;
  if (error) handleApolloError(error);
  if (!subjects) throw new Error("Subject null with no apollo error");

  return (
    <>
      <Card content={<ModelSuiteInfoPanel />} title={"Algorithm in Use"} />
      <Card content={<ModelSuiteProgress />} noHead={true} />
      <StudyTableCard />
    </>
  );
};

export default Algorithm;
