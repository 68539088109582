import { useSelector } from "react-redux";
import { useFilteredSubjectsQuery } from "./useFilteredSubjectsQuery";
import { SubjectQueryResultType } from "../types/SubjectQueryResultType";
import { applyTimepointLevelFilters } from "../utils/filters/applyTimepointLevelFilters";
import { SubjectType } from "../types/SubjectType";
import {
  FilterTypes,
  getTrialViewFilters,
} from "../../../common/store/trialViewFiltersSlice";

function filter(subjects: SubjectType[], filters: FilterTypes): SubjectType[] {
  // Filter the follow-ups of each subject
  const filteredSubjects = subjects.map((subject) => {
    const followUps = subject.followUps.filter(
      applyTimepointLevelFilters(filters)
    );
    return { ...subject, followUps };
  });

  // Remove subjects with no follow-ups
  return filteredSubjects.filter((subject) => subject.followUps.length > 0);
}

// NOTE: The subjects returned by this hook are also filtered by subject filters
export function useTimepointFilteredSubjectsQuery(
  trialId: number
): SubjectQueryResultType {
  const { subjects, loading, error, refetch } = useFilteredSubjectsQuery(
    trialId
  );

  const filters = useSelector(getTrialViewFilters);
  let filteredSubjects;

  // NOTE: Assumes follow_ups are ordered by descending fu_order
  if (!loading && !error && subjects) {
    // filteredSubjects = useMemo(() => filter(subjects, filters), [filters]);
    filteredSubjects = filter(subjects, filters);
  }

  return {
    subjects: filteredSubjects,
    loading,
    error,
    refetch,
  };
}
