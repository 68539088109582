import React from "react";
import TrialCompletionBurndown from "./TrialCompletionBurndown";
import { useQuery } from "@apollo/client";
import { useTrialId } from "../../../Trial/hooks/useTrialId";
import Loading from "../../../common/components/Loading";
import handleApolloError from "../../../common/utils/handleApolloError";
import { parseTrialsCompletionBurndownData } from "../utils/parseTrialsCompletionBurndown";
import {
  GET_SUBJECTS_QUERY,
  RawSubjectsQueryType,
} from "../../../Analysis/common/hooks/useSubjectQuery";
import { RawTrialDetailsType } from "../../../common/types/RawTrialDetailsType";
import { GET_TRIAL_DETAILS_BY_IDS } from "../../../common/queries/GetTrialDetailsByIds";

function TrialsCompletionBurndownContainer(): React.ReactElement {
  const trialId = useTrialId();
  const {
    data: subjectsData,
    loading: subjectsLoading,
    error: subjectsError,
  } = useQuery<RawSubjectsQueryType>(GET_SUBJECTS_QUERY, {
    variables: { trial_id: trialId },
  });

  const {
    data: trialData,
    loading: trialLoading,
    error: trialError,
  } = useQuery<RawTrialDetailsType>(GET_TRIAL_DETAILS_BY_IDS, {
    variables: { trialIds: [trialId] },
  });

  if (subjectsLoading || !subjectsData || trialLoading || !trialData) {
    return <Loading />;
  }
  if (subjectsError) {
    handleApolloError(subjectsError);
  }
  if (trialError) {
    handleApolloError(trialError);
  }

  const parsedData = parseTrialsCompletionBurndownData(subjectsData, trialData);
  return <TrialCompletionBurndown data={parsedData} />;
}

export default TrialsCompletionBurndownContainer;
