import React from "react";
import { Column } from "react-table";
import StateStatusIndicator from "../StateStatusIndicator";
import { StudyTableRowType } from "./StudyTableRowType";
import styled from "styled-components";
import { AnatomicalStructuresList } from "../../../Annotate/components/AnatomicalStructuresList";
import { ReactComponent as InfoIcon } from "../../../assets/svgs/Info.svg";
import { main } from "../../theme/main";
import { SvgIcon } from "../icons/SvgIcon";
import { ReactComponent as LaunchIcon } from "../../../assets/svgs/Launch.svg";
import { getChanged } from "../../types/ItemsUpdateType";
import { getAnatomicalStructureOption } from "../../../Annotate/components/AnatomicalStructureOptionType";
import { ADD, REMOVE } from "../../types/UpdateModeType";
import { UsersList } from "../UserManagement/UsersList";
import { AddRemoveLabel } from "../UserManagement/AddRemoveLabel";
import { StatusIndicationType } from "../../types/StatusIndicationType";
import { AnatomicalStructuresUpdateType } from "../../../Annotate/components/AnatomicalStructuresUpdateType";
import { AnatomicalStructureType } from "../../../Analysis/common/types/AnatomicalStructureType";
import { UserUpdateType } from "../../types/UserUpdateType";
import { UserType } from "../../types/UserType";
import { SliderColumnFilter } from "../Table/Filters/SliderColumnFilter";
import { CaseAssignmentType } from "../../../Annotate/types/CaseAssignmentType";
import { CONTOUR_ORGAN } from "../../../Annotate/types/ActionTypeType";
import { CaseInstanceType } from "../../../Annotate/types/CaseInstanceType";

export type StudyTableColumnType =
  | typeof INFO_HEADER_ID
  | typeof SUBJECT_ID_HEADER_ID
  | typeof STUDY_ID_HEADER_ID
  | typeof STUDY_DATE_HEADER_ID
  | typeof NUMBER_OF_SERIES_HEADER_ID
  | typeof UPLOAD_STATUS_HEADER_ID
  | typeof QC_STATUS_HEADER_ID
  | typeof ANALYSIS_STATUS_HEADER_ID
  | typeof ANATOMICAL_STRUCTURES_HEADER_ID
  | typeof CASE_IDS_HEADER_ID
  | typeof ASSIGNED_USERS_HEADER_ID;

export const INFO_HEADER_ID = "infoIcon";
export const SUBJECT_ID_HEADER_ID = "subjectId";
export const STUDY_ID_HEADER_ID = "studyId";
export const STUDY_DATE_HEADER_ID = "studyDate";
export const NUMBER_OF_SERIES_HEADER_ID = "numberOfSeries";
export const UPLOAD_STATUS_HEADER_ID = "uploadStatus";
export const QC_STATUS_HEADER_ID = "qcStatus";
export const ANALYSIS_STATUS_HEADER_ID = "analysisStatus";
export const ANATOMICAL_STRUCTURES_HEADER_ID = "anatomicalStructures";
export const CASE_IDS_HEADER_ID = "caseIds";
export const ASSIGNED_USERS_HEADER_ID = "assignedUsers";

export const defaultColumnIds: StudyTableColumnType[] = [
  INFO_HEADER_ID,
  SUBJECT_ID_HEADER_ID,
  STUDY_ID_HEADER_ID,
  STUDY_DATE_HEADER_ID,
  NUMBER_OF_SERIES_HEADER_ID,
];

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
`;

const CaseButton = styled.div`
  cursor: pointer;
`;

interface getColumnsProps {
  columnIds: StudyTableColumnType[];
}

export function getColumns({
  columnIds,
}: getColumnsProps): Column<StudyTableRowType>[] {
  const columnDefinitions: Record<
    StudyTableColumnType,
    Column<StudyTableRowType>
  > = {
    [INFO_HEADER_ID]: {
      id: INFO_HEADER_ID,
      Header: "",
      // eslint-disable-next-line react/display-name
      accessor: () => (
        <SvgIcon
          icon={InfoIcon}
          size={18}
          color={main.colors.neutral.neutral5}
        />
      ),
      flex: 0.15,
    },
    [SUBJECT_ID_HEADER_ID]: {
      id: SUBJECT_ID_HEADER_ID,
      Header: "Subject ID",
      accessor: ({ subjectDicomId }) => subjectDicomId ?? "",
      filter: "fuzzyText",
      showFilter: true,
    },
    [STUDY_ID_HEADER_ID]: {
      id: STUDY_ID_HEADER_ID,
      Header: "Study ID",
      accessor: ({ studyDicomId }) => studyDicomId ?? "",
      filter: "fuzzyText",
      showFilter: true,
    },
    [STUDY_DATE_HEADER_ID]: {
      id: STUDY_DATE_HEADER_ID,
      Header: "Study Date",
      accessor: ({ studyDate }) => studyDate,
    },
    [NUMBER_OF_SERIES_HEADER_ID]: {
      id: NUMBER_OF_SERIES_HEADER_ID,
      Header: "# of Series",
      accessor: ({ series }) => series.length,
      showFilter: true,
      Filter: SliderColumnFilter,
      filter: "equals",
      flex: 0.4,
    },
    [UPLOAD_STATUS_HEADER_ID]: {
      id: UPLOAD_STATUS_HEADER_ID,
      Header: "Upload Status",
      // eslint-disable-next-line react/prop-types,react/display-name
      accessor: ({ uploadStatus }) => uploadStatus,
      Cell: ({ value }: { value: StatusIndicationType }) => (
        <div>
          <StateStatusIndicator {...value} />
        </div>
      ),
      sortType: (
        {
          values: {
            uploadStatus: { status: statusA },
          },
        },
        {
          values: {
            uploadStatus: { status: statusB },
          },
        }
      ) => statusA.localeCompare(statusB),
    },
    [QC_STATUS_HEADER_ID]: {
      id: QC_STATUS_HEADER_ID,
      Header: "QC Status",
      // eslint-disable-next-line react/prop-types,react/display-name
      accessor: ({ qcStatus }) => qcStatus,
      Cell: ({ value }: { value: StatusIndicationType }) => (
        <div>
          <StateStatusIndicator {...value} />
        </div>
      ),
      sortType: (
        {
          values: {
            qcStatus: { status: statusA },
          },
        },
        {
          values: {
            qcStatus: { status: statusB },
          },
        }
      ) => statusA.localeCompare(statusB),
    },
    [ANALYSIS_STATUS_HEADER_ID]: {
      id: ANALYSIS_STATUS_HEADER_ID,
      Header: "Analysis Status",
      // eslint-disable-next-line react/prop-types,react/display-name
      accessor: ({ analysisStatus }) => analysisStatus,
      Cell: ({ value }: { value: StatusIndicationType }) => (
        <div>
          <StateStatusIndicator {...value} />
        </div>
      ),
      sortType: (
        {
          values: {
            analysisStatus: { status: statusA },
          },
        },
        {
          values: {
            analysisStatus: { status: statusB },
          },
        }
      ) => statusA.localeCompare(statusB),
    },
    [ANATOMICAL_STRUCTURES_HEADER_ID]: {
      id: ANATOMICAL_STRUCTURES_HEADER_ID,
      Header: "Labels",
      accessor: ({
        requiredAnatomicalStructures,
        changedAnatomicalStructures,
      }) => ({ requiredAnatomicalStructures, changedAnatomicalStructures }),
      Cell: ({
        value: { requiredAnatomicalStructures, changedAnatomicalStructures },
      }: {
        value: {
          requiredAnatomicalStructures: AnatomicalStructureType[];
          changedAnatomicalStructures:
            | AnatomicalStructuresUpdateType
            | undefined;
        };
      }) => {
        const { changed, mode } = changedAnatomicalStructures ?? {
          changed: [],
          mode: undefined,
        };

        const { untouched, added, removed } = getChanged(
          requiredAnatomicalStructures,
          changed,
          mode
        );
        const anatomicalStructuresOptions = [
          ...untouched.map((a) => getAnatomicalStructureOption(a)),
          ...added.map((a) => getAnatomicalStructureOption(a, ADD)),
          ...removed.map((a) => getAnatomicalStructureOption(a, REMOVE)),
        ];

        return (
          <AnatomicalStructuresList
            anatomicalStructures={anatomicalStructuresOptions}
          />
        );
      },
      flex: 2,
    },
    [CASE_IDS_HEADER_ID]: {
      id: CASE_IDS_HEADER_ID,
      Header: "Open",
      accessor: ({ caseAssignments, caseInstances, notaUrl }) => ({
        caseAssignments,
        caseInstances,
        notaUrl,
      }),
      // eslint-disable-next-line react/prop-types,react/display-name
      Cell: ({
        value: { caseAssignments, caseInstances, notaUrl },
      }: {
        value: {
          caseAssignments: CaseAssignmentType[];
          caseInstances: CaseInstanceType[];
          notaUrl: string;
        };
      }) => {
        const organSegmentationCaseAssignments = caseAssignments.filter(
          ({ actionType }) => actionType === CONTOUR_ORGAN
        );
        const organSegmentationCaseInstances = caseInstances.filter(
          ({ actionType }) => actionType === CONTOUR_ORGAN
        );

        const primaryCase =
          organSegmentationCaseInstances.length > 0
            ? organSegmentationCaseInstances[0]
            : null;

        const isAssigned = primaryCase
          ? organSegmentationCaseAssignments.some(
              ({ case: { id } }) => id === primaryCase.case.id
            )
          : false;

        const {
          neutral: { neutral3, neutral6 },
        } = main.colors;
        const iconColor = isAssigned ? neutral3 : neutral6;

        const handleClick = () => {
          if (!primaryCase) {
            throw new Error("No case available but clicked");
          }

          const {
            case: { id },
          } = primaryCase;
          const url = `${notaUrl}${id}`;
          const win = window.open(url, "_blank");
          win?.focus();
        };

        if (!primaryCase) {
          return null;
        }

        return (
          <CaseButton onClick={handleClick}>
            <SvgIcon icon={LaunchIcon} size={14} color={iconColor} />
          </CaseButton>
        );
      },
      flex: 0.5,
    },
    [ASSIGNED_USERS_HEADER_ID]: {
      id: ASSIGNED_USERS_HEADER_ID,
      Header: "Assigned User(s)",
      // eslint-disable-next-line react/prop-types,react/display-name
      accessor: ({ assignedUsers, changedUsers }) => ({
        assignedUsers,
        changedUsers,
      }),
      Cell: ({
        value: { assignedUsers, changedUsers },
      }: {
        value: {
          assignedUsers: UserType[];
          changedUsers: UserUpdateType | undefined;
        };
      }) => {
        const { changed, mode } = changedUsers ?? {
          changed: [],
          mode: undefined,
        };

        const { untouched, added, removed } = getChanged(
          assignedUsers,
          changed,
          mode
        );

        if (
          untouched.length === 0 &&
          added.length === 0 &&
          removed.length === 0
        ) {
          return <>N/A</>;
        }

        return (
          <Wrapper>
            {untouched.length > 0 && <UsersList users={untouched} />}
            {added.length > 0 && (
              <AddRemoveLabel state={ADD}>
                <UsersList users={added} />
              </AddRemoveLabel>
            )}
            {removed.length > 0 && (
              <AddRemoveLabel state={REMOVE}>
                <UsersList users={removed} />
              </AddRemoveLabel>
            )}
          </Wrapper>
        );
      },
      flex: 2,
    },
  };

  return columnIds.map((id) => columnDefinitions[id]);
}
