import { FollowUpType } from "../../common/types/FollowUpType";
import { getPredictedSurvival } from "./getPredictedSurvival";
import { getFollowUpTumourBurden } from "../../common/utils/getFollowUpTumourBurden";
import { RECIST, IPRO } from "../../ChangeFromBaseline/components/Filter";

export type DomainFilterType = typeof RECIST | typeof IPRO;
export type maxValuesType = {
  [IPRO]: number;
  [RECIST]: number;
};

export function getMaxValues(data: FollowUpType[]): maxValuesType {
  let maxIPRO = 0;
  let maxRECIST = 0;
  data.map((followUp) => {
    const survival =
      getPredictedSurvival(followUp) === null
        ? 0
        : Number(getPredictedSurvival(followUp));
    const tumorBurden =
      getFollowUpTumourBurden(followUp) === null
        ? 0
        : Number(getFollowUpTumourBurden(followUp));
    maxIPRO = Math.max(maxIPRO, survival);
    maxRECIST = Math.max(maxRECIST, tumorBurden);
  });

  return {
    [IPRO]: maxIPRO,
    [RECIST]: maxRECIST,
  };
}
