import { gql } from "@apollo/client/core";
import { UserType } from "../../types/UserType";
import {
  parseUserFragment,
  USER_FRAGMENT,
  UserFragmentType,
} from "../../../Settings/UserFragment";
import { useParsedMutation } from "../../queries/utils/useParsedMutation";
import { useParsedMutationReturnType } from "../../queries/utils/useParsedMutationReturnType";

const MUTATION = gql`
  mutation UpdateUser(
    $user_id: Int!
    $first_name: String
    $last_name: String
    $organization: String
    $title: String
    $roles: [enum_role_enum!]!
    $role_insert_input: [role_insert_input!]!
  ) {
    update_users(
      where: { user_id: { _eq: $user_id } }
      _set: {
        first_name: $first_name
        last_name: $last_name
        organization: $organization
        title: $title
      }
    ) {
      affected_rows
      returning {
        ...UserFragment
      }
    }
    delete_role(
      where: {
        _and: [{ user_id: { _eq: $user_id } }, { role: { _nin: $roles } }]
      }
    ) {
      affected_rows
    }
    insert_role(
      objects: $role_insert_input
      on_conflict: { constraint: role_pkey, update_columns: [] }
    ) {
      affected_rows
    }
  }
  ${USER_FRAGMENT}
`;

type Variables = {
  user_id: number;
  first_name?: string;
  last_name?: string;
  organization?: string;
  title?: string;
  roles: string[];
  role_insert_input: {
    user_id: number;
    role: string;
  }[];
};

type Data = {
  update_users: { affected_rows: number; returning: UserFragmentType[] };
  delete_role: { affected_rows: number };
  insert_role: { affected_rows: number };
};

export function useUpdateUserMetadata(): useParsedMutationReturnType<
  UserType,
  UserType[]
> {
  return useParsedMutation<UserType, UserType[], Data, Variables>({
    mutation: MUTATION,
    parseVariables,
    parseData,
  });
}

function parseVariables(user: UserType): Variables {
  const {
    id: user_id,
    firstName: first_name,
    lastName: last_name,
    title,
    organization,
    roles,
  } = user;
  const role_insert_input = roles.map((role) => ({
    user_id,
    role,
  }));
  return {
    user_id,
    first_name,
    last_name,
    organization,
    title,
    roles,
    role_insert_input,
  };
}

function parseData(data: Data): UserType[] {
  return data.update_users.returning.map(parseUserFragment);
}
